// Copyright 2016-2017 MathWorks, Inc.

define([
    "dojo/_base/declare",
    "mw-filename-utils/FileNameUtil",
    "./FileBrowserSortProperty"
], function (declare, FileNameUtil, FileBrowserSortProperty) {
    return declare(null, {
        clearRemovedFocus: function () {
            delete this._removedFocus;
        },

        getSelectedFilePaths: function () {
            var selectedFilePaths = Object.keys(this.selection);
            selectedFilePaths = selectedFilePaths.length > 0 ? selectedFilePaths : [""];
            return selectedFilePaths;
        },

        getSelectedFileInfos: function () {
            var selectionFileInfos = [];
            var grid = this;
            this.getSelectedFilePaths().forEach(function (selection) {
                selectionFileInfos.push(grid.row(selection).data);
            });
            return selectionFileInfos;
        },

        setCurrentFolderInfo: function (fileInfo) {
            this.currentFolderInfo = fileInfo;
        },

        getCurrentFolderInfo: function () {
            return this.currentFolderInfo;
        },

        getCurrentFolderPath: function () {
            if (this.getCurrentFolderInfo()) {
                return FileNameUtil.pathFromFileInfo(this.getCurrentFolderInfo());
            } else {
                return "";
            }
        },

        down: function (row, steps, visible) {
            if (!row) {
                var firstRow = document.getElementsByClassName("dgrid-row")[0];
                return this.row(firstRow);
            } else {
                return this.inherited(arguments);
            }
        },

        moveFocusRight: function (event) {
            var currentRow = this.row(event) ? this.row(event) : this._focusedNode;
            if (this._expanded[currentRow.id]) {
                var firstChildOfCurrentRow = this.down(this._focusedNode, 1, true);
                this.focus(firstChildOfCurrentRow, false, event);
            } else {
                this.expand(currentRow, true);
                this.focus(currentRow);
            }
        },

        moveFocusLeft: function (event) {
            var currentRow = this.row(event) ? this.row(event) : this._focusedNode;
            if (!this._expanded[currentRow.id]) {
                var parentOfCurrentRow = this.row(currentRow.data.parent);
                this.focus(parentOfCurrentRow, false, event);
            } else {
                this.expand(currentRow, false);
                this.focus(currentRow);
            }
        },

        updateSortArrow: function (sort, updateSort) {
            sort = FileBrowserSortProperty.getFBSortArray();
            this.inherited(arguments);
        },

        _customSelectionHandler: function (event, target) {
            if (event.target.classList.contains("dgrid-cell-data") ||
                event.type === "dgrid-cellfocusin") {
                this._extendedSelectionHandler(event, target);
            } else if (event.target.classList.contains("dgrid-cell")) {
                if (event.button === 2 && !target.classList.contains("dgrid-selected")) {
                    this.clearSelection();
                } else {
                    this._extendedSelectionHandler(event, target);
                }
            } else if (event.target.classList.contains("dgrid-expando-icon")) {
            } else {
                this._extendedSelectionHandler(event, target);
            }
        },

        _focusOnNode: function (element, isHeader, event) {
            if (!(event && (event.target.classList.contains("dgrid-cell") && !event.target.classList.contains("dgrid-selected") && event.button === 2))) {
                this.inherited(arguments);
            }
        }
    });
});