/**
 * <strong>Purpose:</strong> <br>
 * Provides the ability to add a tag to the widget.
 * <br><br>
 * @module mw-mixins/property/TagMixin
 *
 * @copyright Copyright 2015 The MathWorks, Inc.
 */
define([
    "dojo/_base/declare"
], function (declare) {
    return declare(null, {
        tag: "",
        _setTagAttr: function (value) {
            if (typeof value !== "string") {
                throw new Error("tag property expects a 'String'");
            }
            this.domNode.setAttribute("data-tag", value);
            this._set("tag", value);
        },

        postCreate: function() {
            this.inherited(arguments);
            // When the default value is falsy, the setter will not be triggered during the widget's construction phase
            // We need to call the setter explicitly
            this.set("tag", this.get("tag"));
        }

    });
});

