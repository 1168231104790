// Copyright 2016-2017 MathWorks, Inc.

define([
    "dojo/i18n!../../l10n/nls/FileBrowserResources",
    "mw-filename-utils/FileNameUtil",
    "./FileType"
], function (FileBrowserResources, FileNameUtil, FileType) {
    var ICON_PLACE_HOLDER = "id-icon-place-holder";
    var FILE_TYPE = {
        folderOpened: "folderOpened",
        folderClosed: "folderClosed",
        folderClosedAndShared: "folderClosedAndShared",
        folderOpenedAndShared: "folderOpenedAndShared",
        motwFileType: "motwFileType"
    };

    var sharedClassNames = {};
    sharedClassNames[FILE_TYPE.folderClosed] = FILE_TYPE.folderClosedAndShared;
    sharedClassNames[FILE_TYPE.folderOpened] = FILE_TYPE.folderOpenedAndShared;

    var unshareClassNames = {};
    unshareClassNames[FILE_TYPE.folderClosedAndShared] = FILE_TYPE.folderClosed;
    unshareClassNames[FILE_TYPE.folderOpenedAndShared] = FILE_TYPE.folderOpened;

    function getIconNodeFromRow(row) {
        var iconNode = row.element.querySelector("#" + ICON_PLACE_HOLDER);
        return iconNode;
    }

    function getIconClass(fileInfo, isExpanded) {
        if (fileInfo.type === "group") {
            return "group";
        } else {
            if (fileInfo.isDirectory) {
                if (fileInfo.shareAttributes && fileInfo.shareAttributes.isProducedRoot) {
                    if (isExpanded) {
                        return FILE_TYPE.folderOpenedAndShared;
                    } else {
                        return FILE_TYPE.folderClosedAndShared;
                    }
                } else {
                    if (isExpanded) {
                        return FILE_TYPE.folderOpened;
                    } else {
                        return FILE_TYPE.folderClosed;
                    }
                }
            } else {
                return FILE_TYPE.motwFileType + FileType.getExtensionIfValid(fileInfo);
            }
        }
    }

    return {
        ICON_PLACE_HOLDER: ICON_PLACE_HOLDER,
        getIconClass: getIconClass,
        getIconNodeFromRow: getIconNodeFromRow,
        getInfoLabel: function (fileInfo) {
            if (FileNameUtil.isUserPublishedDirectoryPath(fileInfo.path)) {
                return FileBrowserResources.publishedMySite;
            } else if (FileNameUtil.isUserSharedDirectoryPath(fileInfo.path)) {
                return FileBrowserResources.sharedWithMe;
            }
        },

        setSharedOverlayIcon: function (isShared, row) {
            var iconNode = getIconNodeFromRow(row);
            if (isShared) {
                if (sharedClassNames[iconNode.className]) {
                    iconNode.className = sharedClassNames[iconNode.className];
                }
            } else {
                if (unshareClassNames[iconNode.className]) {
                    iconNode.className = unshareClassNames[iconNode.className];
                }
            }
        },

        setNodeClassName: function (row, isExpanded) {
            var iconNode = getIconNodeFromRow(row);
            if (iconNode !== null) {
                iconNode.className = getIconClass(row.data, isExpanded);
            }
        }
    };
});