/* Copyright 2017 The MathWorks, Inc. */

define([
    "dojo/_base/window",
    "mw-html-utils/HtmlUtils",
    "dojo/has"
], function (win, HtmlUtils, has) {
    var browserCheckFunctions = {};
    browserCheckFunctions.isBrowserValid = function () {
        var isValid = true;
        var query = HtmlUtils.parseQueryString();
        /*
            If there is a query parameter touch, then there is no check for ios or android sot MO will load
        */
        if (query["touch"] == null && (has("ios") || has("android"))) {
            isValid = false;
        }

        return isValid;
    };

    return {
        load: function (urlToRedirect, require, loaded) {
            var isBrowserValid = browserCheckFunctions.isBrowserValid();
            if (!isBrowserValid) {
                win.global.location = urlToRedirect;
            } else {
                loaded();
            }
        }
    };
});