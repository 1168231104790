// Copyright 2016-2017 MathWorks, Inc.

define([
    "dojo/_base/declare",
    "dojo/_base/Deferred",
    "dojo/_base/NodeList",
    "dojo/dom-class",
    "dojo/has",
    "dojo/topic",
    "dojo/dnd/Manager",
    "dojo/dnd/Source",
    "dojo/has!touch?dgrid/util/touch",
    "mw-dnd/DnDUtils",
    "mw-filename-utils/FileNameUtil",
    "./FileBrowserGridDnDAvatar"
], function (declare, Deferred, NodeList, domClass, has, topic, DndManager,
             DnDSource, touchUtil, DnDUtils, FileNameUtil,
             FileBrowserGridDnDAvatar) {

    return declare(DnDSource, {
        manager: DndManager.manager(),

        _setCanDrop: function () {
            if (this.manager.target) {
                var targetFolderInfo = this.getTargetFolderInfo();
                if (this.manager.copy || this.canTargetAcceptSource(targetFolderInfo, this.manager.nodes)) {
                    this.manager.canDrop(true);
                    this.highlightTargetNode(this.grid.row(targetFolderInfo).element);
                } else {
                    this.manager.canDrop(false);
                    this.removeHighlightTargetNode();
                }
            }
        },

        onFilesDrop: function (sourceFileInfos, targetFolderInfo, copy) {
        },

        getTargetRow: function () {
            var targetRow = this._targetAnchor = this.targetAnchor,
                grid = this.grid;
            return targetRow && grid.row(targetRow);
        },

        getTargetFolderInfo: function () {
            var grid = this.grid,
                store = grid.store;

            var targetRow = this.getTargetRow();
            var targetFolderInfo;
            if (targetRow) {
                targetFolderInfo = store.get(targetRow.id);
            }
            if (!targetFolderInfo || (!targetFolderInfo.isDirectory && (targetFolderInfo.parent === grid.getCurrentFolderPath()))) {
                targetFolderInfo = grid.getCurrentFolderInfo();
            }
            if (!targetFolderInfo.isDirectory) {
                targetFolderInfo = store.get(targetFolderInfo.parent);
            }
            return targetFolderInfo;
        },

        canTargetAcceptSource: function (targetFolderInfo, sourceNodes) {
            var canTargetAcceptSource = true;
            var isTargetNotWritable = !FileNameUtil.isWritable(targetFolderInfo);
            if (isTargetNotWritable) {
                canTargetAcceptSource = false;
            }
            var targetSource = this;
            var targetPath = FileNameUtil.normalizePath(FileNameUtil.pathFromFileInfo(targetFolderInfo));
            sourceNodes.some(function (node) {
                var sourceFileInfo = targetSource.getObject(node);
                var sourcePath = FileNameUtil.normalizePath(FileNameUtil.pathFromFileInfo(sourceFileInfo));
                var sourceParentPath = FileNameUtil.normalizePath(sourceFileInfo.location);
                var isSourceANonWritableDirectory = sourceFileInfo.isDirectory && !FileNameUtil.isWritable(sourceFileInfo);
                var isSourceAlreadyInTargetDirectory = (targetPath === sourceParentPath);
                var isSourceTheParentOfTargetDirectory = (sourceFileInfo.isDirectory && (targetPath.indexOf(sourcePath) === 0));

                if (isSourceANonWritableDirectory || isSourceAlreadyInTargetDirectory || isSourceTheParentOfTargetDirectory) {
                    canTargetAcceptSource = false;
                    return;
                }
            });

            return canTargetAcceptSource;
        },

        highlightTargetNode: function (targetRowNode) {
            this.removeHighlightTargetNode();
            if (targetRowNode) {
                domClass.add(targetRowNode, "isDnDTarget");
                this.previousTargetRowNode = targetRowNode;
            }
        },

        removeHighlightTargetNode: function () {
            if (this.previousTargetRowNode) {
                domClass.remove(this.previousTargetRowNode, "isDnDTarget");
                this.previousTargetRowNode = null;
            }
        },

        //Copied from "dgrid/extensions/DnD"
        getObject: function (node) {
            //Copied from "dgrid/extensions/DnD" as is.
            // summary:
            //		getObject is a method which should be defined on any source intending
            //		on interfacing with dgrid DnD.

            var grid = this.grid;
            // Extract item id from row node id (gridID-row-*).
            return grid._trackError(function () {
                return grid.store.get(node.id.slice(grid.id.length + 5));
            });
        },

        _legalMouseDown: function (evt) {
            //Copied from "dgrid/extensions/DnD" as is.
            // Fix _legalMouseDown to only allow starting drag from an item
            // (not from bodyNode outside contentNode).
            var legal = this.inherited(arguments);
            return legal && evt.target !== this.grid.bodyNode;
        },

        // DnD method overrides
        constructor: function () {
            DnDUtils.registerMakeAvatar(this, function () {
                return new FileBrowserGridDnDAvatar(DndManager.manager());
            });
        },

        onDndCancel: function () {
            this.inherited(arguments);
            this.removeHighlightTargetNode();
        },

        onDrop: function (sourceSource, nodes, copy) {
            //Copied from "dgrid/extensions/DnD" and Modified
            var targetSource = this;

            var targetFolderInfo = targetSource.getTargetFolderInfo();
            if (targetSource === sourceSource) {
                targetSource.onDropInternal(nodes, copy, targetFolderInfo);
            }
        },

        onDropInternal: function (nodes, copy, targetFolderInfo) {
            //Copied from "dgrid/extensions/DnD" and Modified
            var targetSource = this,
                sourceFileInfos = [];

            nodes.forEach(function (node) {
                Deferred.when(targetSource.getObject(node), function (fileInfo) {
                    sourceFileInfos.push(fileInfo);
                });
            });

            this.onFilesDrop(sourceFileInfos, targetFolderInfo, copy);
        },

        onDndStart: function (source) {
            var that = this;
            //Copied from "dgrid/extensions/DnD" and Modified
            // Listen for start events to apply style change to avatar.
            this.inherited(arguments); // DnDSource.prototype.onDndStart.apply(this, arguments);
            if (source === this) {
                // If TouchScroll is in use, cancel any pending scroll operation.
                if (this.grid.cancelTouchScroll) {
                    this.grid.cancelTouchScroll();
                }

                //stop drag if the current selection contains Published, Shared or is in Users Shared Directory
                this.manager.nodes.forEach(function (node) {
                    var sourceFileInfo = source.getObject(node);
                    var isSourceAPublishedDirectory = FileNameUtil.isUserPublishedDirectoryPath(sourceFileInfo.path);
                    var isSourceASharedDirectory = FileNameUtil.isUserSharedDirectoryPath(sourceFileInfo.path);
                    var isSourceInTheSharedDirectory = FileNameUtil.isInUsersSharedDirectory(sourceFileInfo.path);

                    if (isSourceAPublishedDirectory || isSourceASharedDirectory || isSourceInTheSharedDirectory) {
                        that.manager.canDrop(false);
                        that.manager.stopDrag();
                        topic.publish("/dnd/cancel");
                    }
                });
            }
        },

        onMouseDown: function (evt) {
            //Copied from "dgrid/extensions/DnD" as is.
            // Cancel the drag operation on presence of more than one contact point.
            // (This check will evaluate to false under non-touch circumstances.)
            if (has("touch") && this.isDragging &&
                touchUtil.countCurrentTouches(evt, this.grid.touchNode) > 1) {
                topic.publish("/dnd/cancel");
                this.manager.stopDrag();
            } else {
                this.inherited(arguments);
            }
        },

        onMouseMove: function (evt) {
            //Copied from "dgrid/extensions/DnD" and Modified
            // If we're handling touchmove, only respond to single-contact events.
            if (!has("touch") || touchUtil.countCurrentTouches(evt, this.grid.touchNode) <= 1) {
                this.inherited(arguments);
                if (this.isDragging) {
                    this._setCanDrop();
                }
            }
        },

        checkAcceptance: function (source, nodes) {
            //Copied from "dgrid/extensions/DnD" as is.
            return source.getObject &&
                DnDSource.prototype.checkAcceptance.apply(this, arguments);
        },

        getSelectedNodes: function () {
            //Copied from "dgrid/extensions/DnD" and modified.

            var selectedNodeList = new NodeList(),
                id;
            for (id in this.grid.selection) {
                selectedNodeList.push(this._selectedNodes[id]);
            }
            return selectedNodeList;
        },

        _markDndStatus: function () {
            this.inherited(arguments);
            this._setCanDrop();
        }

    });
});