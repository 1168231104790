define([], function () {
    return {
        APP_SERVICE_HOST: {
            name: 'APP_SERVICE_HOST',
            BASE_PREFIX: '/matlab',
            BASE_END_POINT: 'messageservice/async/blue'
        },
        MATLAB_SERVICE_HOST: {
            name: 'MATLAB_SERVICE_HOST',
            BASE_PREFIX: '/matlab',
            BASE_END_POINT: 'messageservice/async'
        }
    };
});
