/**
 * <strong>Purpose:</strong> <br>
 * Provides ability to manage color of textnode for different menu items.
 * <br><br>
 * <strong>Contract:</strong> <br>
 *     textNode
 *
 * @module mw-mixins/MenuItemTextColorMixin
 *
 * @copyright Copyright 2015 The MathWorks, Inc.
 */

define([
    "dojo/_base/declare",
    "mw-mixins/property/DescriptionMixin",
    "mw-menu/mixins/property/ShowDescriptionMixin"
], function (declare, DescriptionMixin, ShowDescriptionMixin) {

    var MenuItemColorMixin = declare(null, {

        //Text has blue color when Description is present


        _setDescriptionAttr: function (value) {
            this.inherited(arguments);
            if (value !== '') {
                this.textNode.classList.add("mwBlueText");
            } else {
                this.textNode.classList.remove("mwBlueText");
            }

        },

        _setShowDescriptionAttr: function (value) {
            this.inherited(arguments);
            if (value) {
                this.textNode.classList.add("mwBlueText");
            } else {
                this.textNode.classList.remove("mwBlueText");
            }
        },

        postCreate: function () {
            this.inherited(arguments);
            //If showDescription is true and Description is ''
            if ((this.get("showDescription") && this.get("description") === '' ) ||
                (!this.get("showDescription") && this.get("description") !== '' )) {
                this.textNode.classList.remove("mwBlueText");
            }
        }

    });

    MenuItemColorMixin._dependencies = [
        {mixin: DescriptionMixin, orderDependent: true},
        {mixin: ShowDescriptionMixin, orderDependent: true}
    ];

    return MenuItemColorMixin;

});
