/**
 * ActionDataService can be used to perform CRUD operations on a set of actions. Each action object at a minimum should
 * have a a unique string for "id", a boolean for "enabled" and optionally a function for "callback". Any other
 * properties/value pairs that you think are relevant to the action may also be provided when adding an action to the
 * ActionDataService.<br>
 * Each ActionDataService is associated with a namespace. An example of a namespace is a string of
 * the form: "Actions/Main". The users of this service can either provide their own namespace or one
 * is automatically generated for them.<br>
 * The ActionDataService can be instantiated in one of the following three modes:<br>
 * 1. StandAlone - The peer model created in this mode does not have a counter part on the server and runs in sync
 *    disabled or offline mode. Actions can be added/removed/updated using this ActionDataService's methods.
 *    Example: This mode is used when building the Toolstrip in MATLAB Online using the JavaScript API.<br>
 * 2. ActAsServer - The peer model created in this mode has a counter part on the client side and runs in sync enabled
 *    or online mode. Actions can be added/removed/updated using this ActionDataService's methods.
 *    Example: This mode is used by RTE running in an embedded JXBrowser in the swing desktop.<br>
 * 3. ActAsClient - The peer model created in this mode has a counter part on the server side and runs in sync enabled
 *    or online mode. Actions cannot be added/removed using this ActionDataService's methods.
 *    Example: This mode is used when building the Toolstrip from MCOS.<br>
 *
 * @module MW/ActionDataService
 *
 * @copyright Copyright 2012-2014 The MathWorks, Inc.
 **/
define([
    "dojo/_base/declare",
    "mw-peermodel/PeerModelManager",
    "mw-peermodel/_PeerModelListenerMixin",
    "mw-peermodel/_PropertyIndexerMixin",
    "mw-utils/Utils"
], function (declare, PeerModelManager, _PeerModelListenerMixin, _PropertyIndexerMixin, Utils) {

    var DEFAULT_EVENT_TYPE = "peerEvent",
        ROOT_PEER_NODE_TYPE = "Root",
        ACTION_PEER_NODE_TYPE = "Action",
        STAND_ALONE = "StandAlone",
        ACT_AS_SERVER = "ActAsServer",
        ACT_AS_CLIENT = "ActAsClient";

    return declare([_PeerModelListenerMixin, _PropertyIndexerMixin], {
        constructor: function (args) {
            args = args || {};
            this._mode = args.mode || STAND_ALONE;
            this._nameSpace = args.nameSpace || "/actions/" + Utils.generateUuid();

            this._setupPeerModel();

            //A map of actions and callbacks.
            this._callbacks = {};
            this._actionChangeCallbacks = {};

            // This is used by the _PropertyIndexerMixin to make lookup based on the "id" property faster
            this._propertyToIndex = "id";
        },

        /**
         * @private
         */
        _setupPeerModel: function () {
            switch (this._mode) {
            case STAND_ALONE:
                this._peerModelManager = PeerModelManager.getClientInstance(this._nameSpace);
                this.addManagerListeners(this._peerModelManager);
                this._peerModelManager.setRoot(ROOT_PEER_NODE_TYPE);
                break;
            case ACT_AS_SERVER:
                this._peerModelManager = PeerModelManager.getServerInstance(this._nameSpace);
                this.addManagerListeners(this._peerModelManager);
                // Set the syncEnabled to true in PeerModelManager
                this.start(this._peerModelManager);
                this._peerModelManager.setRoot(ROOT_PEER_NODE_TYPE);
                break;
            case ACT_AS_CLIENT:
                this._peerModelManager = PeerModelManager.getClientInstance(this._nameSpace);
                this.addManagerListeners(this._peerModelManager);
                // Set the syncEnabled to true in PeerModelManager
                this.start(this._peerModelManager);
                break;
            default:
                throw new Error("The mode provided is not valid! Please use one of the following: StandAlone, ActAsServer or ActAsClient");
            }
        },

        /**
         * @private
         * @param event
         */
        onPropertySet: function (event) {
            this.onActionChange({
                id: event.target.getProperty("id"),
                key: event.data.key,
                oldValue: event.data.oldValue,
                newValue: event.data.newValue
            });
        },

        /**
         * @private
         * @param event
         */
        onChildAdd: function (event) {
            if (!event.data.child.getProperty("id")) {
                event.data.child.setProperty("id", event.data.child.getId());
            }
            this.onActionAdd({
                id: event.data.child.getProperty("id")
            });
        },

        /**
         * This method loops through the given action arguments and calls the addAction method to create them.
         * @param actions - An array of arguments as expected by the addAction method
         * @instance
         */
        loadActions: function (actions) {
            if (Array.isArray(actions)) {
                actions.forEach(function (action) {
                    this.addAction(action);
                }, this);
            }
        },

        /**
         * This method is used to add new actions to the ActionDataService.
         * @param args - The argument should include a unique string for "id", a boolean for "enabled" and optionally a
         * function for "callback". Any other properties that you think are relevant to the action may also be provided.
         * @instance
         */
        addAction: function (args) {
            if (this._mode === ACT_AS_CLIENT) {
                throw new Error("Actions cannot be added while in ActAsClient Mode!");
            }

            var actionNode;
            args = args || {};

            if (args.id && !!this._getPeerNodeIdForIndexedProperty(args.id)) {
                throw new Error("Action id must be unique!");
            }

            actionNode = this._peerModelManager.getRoot().addChild(ACTION_PEER_NODE_TYPE, args);
            if (!args.id) {
                actionNode.setProperty("id", actionNode.getId());
            }

            if (args.callback) {
                this.addActionCallback(actionNode.getProperty("id"), args.callback);
            }

            return actionNode.getId();
        },

        /**
         * This returns the properties corresponding to the action that has the given id.
         * @param id - The id of the action whose properties should be returned
         * @instance
         */
        getAction: function (id) {
            var action = this._actionById(id);

            if (action) {
                return action.getProperties();
            } else {
                return null;
            }
        },

        /**
         * This returns the properties of all the actions that have been added to the ActionDataService
         * @instance
         */
        getAllActions: function () {
            return this._peerModelManager.getRoot().getChildren().map(function (actionNode) {
                return actionNode.getProperties();
            });
        },

        /**
         * This removes the action corresponding to the given id from the ActionDataService
         * @param id - The id of the action that should be removed from the ActionDataService
         * @instance
         */
        removeAction: function (id) {
            if (this._mode === ACT_AS_CLIENT) {
                throw new Error("Actions cannot be removed while in ActAsClient Mode!");
            }

            var actionPeerNodeId = this._getPeerNodeIdForIndexedProperty(id);
            if (actionPeerNodeId && this._peerModelManager.hasById(actionPeerNodeId)) {
                this._peerModelManager.getById(actionPeerNodeId).destroy();
                delete this._callbacks[id];
            } else {
                throw new Error("This is not a valid action id!");
            }
        },

        /**
         * This sets the "enabled" property of the action corresponding to the given id to be true
         * @param id - The id of the action whose enabled property should be set to true
         * @instance
         */
        enableAction: function (id) {
            this.updateAction(id, {enabled: true});
        },

        /**
         * This sets the "enabled" property of the action corresponding to the given id to be false
         * @param id - The id of the action whose enabled property should be set to false
         * @instance
         */
        disableAction: function (id) {
            this.updateAction(id, {enabled: false});
        },

        /**
         * This updates the action with the corresponding id with the given new property/value pairs.
         * @param id - The id of the action whose properties need to be updated
         * @param newOptions - An object with keys as property names and values as the new values
         * @instance
         */
        updateAction: function (id, newOptions, originator) {
            var action = this._actionById(id);
            if (action) {
                if (newOptions.callback) {
                    this.addActionCallback(id, newOptions.callback);
                }
                action.setProperties(newOptions, originator);
            }
        },

        /**
         * @private
         */
        actionHasProperty: function (id, property) {
            var action = this._actionById(id);
            return action.hasProperty(property);
        },

        /**
         * This invokes the "callback" registered with the action corresponding to the given id
         * @param id - The id of the action whose callback should be executed
         * @param eventData - Any data that should be passed on as an argument to the callback when it is executed
         * @instance
         */
        executeAction: function (id, eventData) {
            var actionNode;
            eventData = eventData || {};

            actionNode = this._actionById(id);

            if (actionNode.getProperty("enabled") === true) {
                actionNode.dispatchEvent(DEFAULT_EVENT_TYPE, actionNode, eventData);
            }
        },

        /**
         * This registers the callback to be called when the action corresponding to the given "id" is executed. Only
         * one callback can be added for each action.
         * @param id - The id of the action for whom the callback is being added
         * @param callback - The callback function to be executed when the action is executed
         * @param scope - The scope that the callback should run in when the action is executed
         * @instance
         */
        addActionCallback: function (id, callback, scope) {
            var actionNode = this._actionById(id), newCallback;

            this.removeActionCallback(id);

            newCallback = function (event) {
                //TODO: Explain why we delete this.
                delete event.data.type;
                callback.call(scope || actionNode.getProperties(), event.data);
            };

            this._callbacks[id] = {
                callback: newCallback,
                scope: scope
            };

            actionNode.addEventListener(DEFAULT_EVENT_TYPE, newCallback, scope);
        },

        /**
         * Removes the registered execution callback from the action corresponding to the given id
         * @param id - The id of the action whose callback should be removed
         * @instance
         */
        removeActionCallback: function (id) {
            var actionNode = this._actionById(id), oldCallback;
            oldCallback = this._callbacks[id];

            if (oldCallback) {
                actionNode.removeEventListener(DEFAULT_EVENT_TYPE, oldCallback.callback, oldCallback.scope);
            }
        },

        /**
         * This registers the callback to be called when any of the action's properties change. Only one callback can be
         * added for each action.
         * @param id - The id of the action for whom the callback is being added
         * @param callback - The callback function to be executed when any of the action's properties change
         * @param scope - The scope that the callback should run in
         * @instance
         */
        addActionChangeCallback: function (id, callback, scope) {
            var actionNode = this._actionById(id), wrapperCallback;

            this.removeActionChangeCallback(id);

            wrapperCallback = function (event) {
                callback.call(scope, {
                    property: event.data.key,
                    oldValue: event.data.oldValue,
                    newValue: event.data.newValue
                }, event.originator);
            };

            this._actionChangeCallbacks[id] = {
                callback: wrapperCallback,
                scope: scope
            };

            actionNode.addEventListener("propertySet", wrapperCallback, scope);
        },

        /**
         * Removes the registered action change callback from the action corresponding to the given id
         * @param id - The id of the action whose callback should be removed
         * @instance
         */
        removeActionChangeCallback: function (id) {
            var actionNode = this._actionById(id);

            if (this._actionChangeCallbacks[id]) {
                actionNode.removeEventListener("propertySet",
                    this._actionChangeCallbacks[id].callback, this._actionChangeCallbacks[id].scope);
            }
        },

        /**
         * This method is triggered every time an action's property changes.
         * @param eventData
         * id - The id of the action that was updated
         * key -  The property that was updated in the action
         * oldValue - Old value of the updated property
         * newValue - New value of the updated property
         * @instance
         */
        onActionChange: function (eventData) {
        },

        /**
         * @private
         */
        onActionAdd: function (eventData) {
        },

        /**
         * @private
         */
        _actionById: function (id) {
            var actionPeerNode,
                actionPeerNodeId = this._getPeerNodeIdForIndexedProperty(id);

            if (actionPeerNodeId && this._peerModelManager.hasById(actionPeerNodeId)) {
                actionPeerNode = this._peerModelManager.getById(actionPeerNodeId);
            }

            return actionPeerNode;
        },

        /**
         * This cleans up the ActionDataService
         * @instance
         */
        destroy: function () {
            PeerModelManager.cleanup(this._nameSpace);
        }
    });
});