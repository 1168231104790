/**
 * <strong>Purpose:</strong> <br>
 * Provides ability to manage opening of Menu
 * <br><br>
 * <strong>Contract:</strong> <br>
 *     Should be a container widget
 *
 * @module mw-mixins/OnOpenMixin
 *
 * @copyright Copyright 2014-2015 The MathWorks, Inc.
 */

define([
    "dojo/_base/declare",
    "dojo/_base/lang",
    "dojo/dom-geometry"
], function (declare, lang, domGeom) {
    return declare(null, {

        _focusOnOpen: true,
        _matchMinWidthToInvokingWidget: true,

        //From Popup.js
        onOpen: function () {
            // Get behind the Dojo focus onTouchNode listener (dijit/focus - line 115)
            setTimeout(lang.hitch(this, function () {
                //For popupMenuItem, do not give focus
                if (this._focusOnOpen && this.focus) {
                    // IE does not support window.scrollX or window.scrollY,
                    // so use pageXOffset/pageYOffset instead.
                    // https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollX
                    var x = window.pageXOffset, y = window.pageYOffset;
                    this.focus();
                    window.scrollTo(x, y);
                }
            }), 0);
            this._emitOpenEvent();
            this._updateLayout();
        },

        _updateLayout: function () {
            this._removeAllWidths();
            if (this._matchMinWidthToInvokingWidget) {
                this._updateWidth();
            }
            this._updateHeight();
        },

        _removeAllWidths: function () {
            this.domNode.style.width = "";

            this.getChildren().forEach(function (child) {
                child.domNode.style.width = "";
            });
        },

        _updateWidth: function () {
            // If the width of the menu is smaller than the width of the button, then adjust the width
            if (this._invokingWidget && (this.domNode.offsetWidth < this._invokingWidget.domNode.offsetWidth)) {
                /*
                 * Remove the parent margin, border, padding to make sure the width of the menu is the same as the width of the widget.
                 * See g1284030
                 * */
                var parentElementWidth = 0;
                if (this.domNode.parentElement) {
                    parentElementWidth = domGeom.getMarginBox(this.domNode.parentElement).w - domGeom.getMarginBox(this.domNode).w;
                }

                this.domNode.style.width = (this._invokingWidget.domNode.offsetWidth - parentElementWidth) + "px";
            }
        },

        // g1353197: The dijitPopup and the widget are both trying to handle scrollbars in certain
        // circumstances.  We just want one to handle this, so we choose the widget for flexibility.
        _updateHeight: function () {
            if (this.domNode &&
                this.domNode.parentElement &&
                this.domNode.parentElement.classList &&
                this.domNode.parentElement.classList.contains("dijitPopup")) {

                // g1524612: dijitPopup stopped scaling popups properly when too large, so we need to
                // calculate when the popup is too tall to fit on screen and what the height should be.
                var pageHeight,
                    content = this.domNode,
                    wrapper = content.parentElement,
                    contentComputesStyles = window.getComputedStyle(content, null),
                    wrapperComputesStyles = window.getComputedStyle(wrapper, null),
                    borderTotals = parseInt(contentComputesStyles.borderTopWidth) + parseInt(contentComputesStyles.borderBottomWidth) +
                                   parseInt(wrapperComputesStyles.borderTopWidth) + parseInt(wrapperComputesStyles.borderBottomWidth);

                // If device has "touch" capabilities we need to calculate page height slightly differently
                if ("ontouchstart" in document ||
                   ("onpointerdown" in document && navigator.maxTouchPoints > 0) ||
                   window.navigator.msMaxTouchPoints) {
                    pageHeight = (window.innerHeight || document.documentElement.clientHeight);
                }else{
                    pageHeight = document.documentElement.clientHeight;
                }

                // If the popup is too tall, then adjust the height to fit within the window.If window is resized larger than popup,make the popup tall again
                if (content.style.height !== "") {
                    content.style.height = "";
                }
                if (wrapper.offsetTop + content.clientHeight > pageHeight) {
                    content.style.height = pageHeight - wrapper.offsetTop - borderTotals + "px";
                }
                // Prevent the dijitPopup from handling scrollbars.
                wrapper.style.overflow = "hidden";
                wrapper.style.overflowX = "hidden";
                wrapper.style.overflowY = "hidden";
            }
        },

        _setFocusOnOpenAttr: function (/* Boolean */ value) {
            if ((typeof value !== "boolean")) {
                throw new Error("'focusOnOpen' property expects a boolean value.");
            }
            this._set("_focusOnOpen", value);
        },

        _emitOpenEvent: function () {
            this.emit("open", {
            });
        }
    });

});