// Copyright 2016-2017 MathWorks, Inc.
//noinspection BadExpressionStatementJS,UnterminatedStatementJS
define({
    root: ({
        name: "Name",
        size: "Size",
        dateModified: "Date Modified",
        type: "Type",
        publishedMySite: "(my site)",
        sharedWithMe: "(with me)",
        stopGrouping: "Stop Grouping",
        groupBy: "Group By",
        dndCopyMessage: "Copy ${0} item(s)",
        dndMoveMessage: "Move ${0} item(s)",
        defaultFolderType: "Folder",
        defaultFileType: "${0} File",
        kb: "KB",
        mb: "MB",
        gb: "GB",
        tb: "TB",
        pb: "PB",
        eb: "EB",
        zb: "ZB",
        yb: "YB",
        fileSize: "${0} ${1}",
        folder: "Folder",
        file: "File",
        small: "Small",
        medium: "Medium",
        large: "Large"
        
    })
});