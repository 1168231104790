/**
 * <strong>Purpose:</strong> <br><br>
 * If the popup associated with this button is not static and you would like to dynamically generate it every time the button is clicked,
 * you can do so by setting this property to true.
 * Once, this property is set, you can now listen to the dropdownclick event and in the callback create a new Menu,
 * associate the new instance with the button's "menu" property then call the showMenu method to display it.
 * <br><br>
 * <strong> Mixin Dependencies</strong> <br><br>
 * <li>{@link module:mw-mixins/property/MenuMixin|MenuMixin}</li> <br><br>
 *
 * @module mw-mixins/property/HasDynamicMenuMixin
 *
 * @copyright Copyright 2014-2015 The MathWorks, Inc.
 */

define([
    "dojo/_base/declare",
    "dojo/_base/lang",
    "mw-mixins/property/MenuMixin"
], function (declare, lang, MenuMixin) {

    var HasDynamicMixin = declare(null, {
        /**
         * @property  {boolean} hasDynamicMenu - If true, a 'dropdownclick' event will be emitted and menu needs to be opened programatically using 'showMenu' method.
         * @instance
         * @default
         */
        hasDynamicMenu: false,

        _setHasDynamicMenuAttr: function (value) {
            this._set("hasDynamicMenu", value);
        },

        postCreate: function () {
            this.inherited(arguments);
            this.domNode.classList.add("mwHasDynamicMenuMixin");
            //When the default value is falsy, the setter will not be triggered during the widget's construction phase
            // We need to call the setter explicitly
            this.set("hasDynamicMenu", this.get("hasDynamicMenu"));
        },

        // Override method from MenuMixin
        openMenu: function () {
            if (this.get("hasDynamicMenu")) {
                this.emit("dropdownclick", {});
            } else {
                this._showMenu();
            }
        },

        showMenu: function () {
            this._showMenu();
            var updateLayoutListener = this.menu.on("updatelayout", lang.hitch(this.menu, function () {
                if (this._updateLayout) {
                    this._updateLayout();
                }
            }));
            var closeListener = this.menu.on("close", function () {
                updateLayoutListener.remove();
                closeListener.remove();
            });
        }
    });

    HasDynamicMixin._dependencies = [
        {mixin: MenuMixin, orderDependent: true}
    ];
    return HasDynamicMixin;
});
