/**
 * <strong>Purpose:</strong> <br>
 * Provides the ability to calculate the size of an icon
 * This mixin decides on amount of spacing to be added for a menu item and a popup menu item
 * <br><br>
 * <strong>Contract:</strong> <br>
 * <em>Attach-point:</em> iconNode <br>
 * The Mixin calculates the size of icon and applies a class
 *
 * @module mw-menu/mixins/property/IconSizeCalculatorMixin
 *
 * @copyright Copyright 2016 The MathWorks, Inc.
 */
define([
    "dojo/_base/declare",
    "dojo/on",
    "dojo/dom-geometry",
    "dojo/_base/lang",
    "dojo/dom-construct"
], function (declare, on, domGeom, lang, domConstruct) {

    return declare(null, {

        _applyNewIcon: function (value) {
            this.inherited(arguments);
            var newIconType = this._getIconType(value);
            if (newIconType === this._iconTypes.CSS_CLASS) {
                var icon = domConstruct.create("div", {"class":value, "style":"position:absolute", "top":"-10000px !important", "left": "-10000px !important"});
                domConstruct.place(icon, document.body);
                var height = domGeom.getMarginBox(icon).h;
                this._addClass(height);
                domConstruct.destroy(icon);
            }
        },

        _addClass: function(height) {
            //Icon height could be kore than 24
            if(height >= 24) {
                this.domNode.classList.add("mwIcon_24");
            } else {
                this.domNode.classList.remove("mwIcon_24");
            }
        },
        _applyImageStyles: function (node, dataUri, img) {
            this.inherited(arguments);
            this._addClass(img.height);
        }
    });

});

