// Copyright 2016-2017 MathWorks, Inc.

define([
    "dojo/_base/declare",
    "dojo/i18n!../l10n/nls/FileBrowserResources",
    "dojo/string",
    "./helpers/NameComparator",
    "./groupingactions/TypeGroupingAction"
], function (declare, FileBrowserResources, string, NameComparator, TypeGroupingAction) {
    function formatType(fileInfo) {
        if (fileInfo.isDirectory) {
            return FileBrowserResources.defaultFolderType;
        } else {
            return string.substitute(FileBrowserResources.defaultFileType, [fileInfo.type.toUpperCase()]);
        }
    }

    return declare([], {
        constructor: function (args) {
            args = args || {};
            this.column = {
                id: "typeColumn",
                label: FileBrowserResources.type,
                renderHeaderCell: function (th) {
                    var typeColumn = document.createElement("div");
                    typeColumn.className = "columnHeaderLabel";
                    var labelNode = document.createTextNode(FileBrowserResources.type);
                    typeColumn.appendChild(labelNode);
                    th.appendChild(typeColumn);
                },
                field: "type",
                sortable: true,
                renderCell: function (fileInfo, data, td, options) {
                    var cellData = document.createElement("span");
                    cellData.className = "dgrid-cell-data";

                    if (!fileInfo.isGroupHeader) {
                        var fileType = document.createTextNode(formatType(fileInfo));

                        cellData.appendChild(fileType);
                    }
                    return cellData;
                },
                sort: function (a, b) {
                    if (a.isDirectory || b.isDirectory) {
                        return NameComparator.separateFoldersFromFiles(a, b);
                    } else if (a.type === b.type) {
                        return NameComparator.caseSensitiveStringComparator(a.name, b.name);
                    } else {
                        return NameComparator.caseSensitiveStringComparator(a.type, b.type);
                    }
                },
                hidden: true,
                groupable: true,
                groupingAction: new TypeGroupingAction()
            };
        }
    });
});