/**
 * <strong>Purpose:</strong> <br>
 * Provides the framework required for a widget to be added as a focusable child of a Menu Widget.
 * <br><br>
 *
 * @module mw-menu/mixins/FocusableMenuChildMixin
 *
 * @copyright Copyright 2015-2016 The MathWorks, Inc.
 */

define([
    "dojo/_base/declare",
    "dojo/_base/lang",
    "dojo/has",
    "dojo/dom",
    "dojo/on",
    "dojo/mouse",
    "dojo/touch",
    "dijit/focus",
    "dijit/registry",
    "mw-dom-utils/domUtils"
], function (declare, lang, has, dom, on, mouse, touch, focusUtil, registry, domUtils) {

    return declare(null, {
        postCreate: function () {
            this.inherited(arguments);
            var touchOnly = has("touch") && (!window.PointerEvent);
            this.own(
                on(this.domNode, "click", lang.hitch(this, "_onMouseClick")),
                on(this.domNode, touchOnly ? touch.release : "mouseup", lang.hitch(this, "_onMouseUp")),
                on(this.domNode, mouse.enter, lang.hitch(this, "_onMouseEnter")),
                on(this.domNode, "focusout", lang.hitch(this, "_focusLost"))
            );
            this.domNode.classList.add("mwFocusableMenuChildMixin");
        },

        _focusLost: function (/*Event*/ e) {
            // In Firefox, the document.activeElement is not set to the gained focus node until
            // after the 'blur' callback is fired.  So use setTimeout to get behind this update.
            setTimeout(lang.hitch(this, function () {
                var parent,
                    gainedFocusNode = domUtils.parseFocusEvent(e).gainedFocusNode,
                    gainedFocusWidget = registry.getEnclosingWidget(gainedFocusNode);

                // TODO: Update logic when CompositionMixin is added
                // If the input is a child in a composite widget, then get the primary node from the value of the "data-composite-child" attribute
                if (gainedFocusWidget) {
                    var node = gainedFocusWidget.focusNode || gainedFocusWidget.domNode;
                    if (node && node.hasAttribute("data-composite-child")) {
                        gainedFocusWidget =
                            registry.getEnclosingWidget(dom.byId(node.getAttribute("data-composite-primary-id")));

                        if (gainedFocusWidget === this) {
                            return;
                        }
                    }
                }
                // g1414396: If the gainedFocusWidget is the same widget as 'this', then 'this' did not lose focus
                if (this !== gainedFocusWidget) {
                    parent = this.getParentWidget();
                    if ((gainedFocusWidget == null ||
                        domUtils.isFocusable(gainedFocusNode)) &&
                        parent && parent._focusChange) {
                        parent._focusChange(gainedFocusWidget);
                    }
                    if (this.focusLost) {
                        this.focusLost(gainedFocusWidget);
                    }
                }
            }), 0);
        },

        _onMouseClick: function (/*Event*/ e) {
            if (e.mwEventData) {
                this.focus();

                if (this.closeMenuOnClick) {
                    this._requestClose(false);
                }
            } else {
                // Stop the default "click" event
                e.preventDefault();
                e.stopImmediatePropagation();
            }
        },

        _onMouseEnter: function (/*Event*/ e) {
            if (this.focusNode && domUtils.isFocusable(this.focusNode)) {
                this.focus();
            }
        },

        _onMouseUp: function (/*Event*/ e) {
            e.preventDefault();
            e.stopPropagation();
            if (!(this.getParentWidget() && this.getParentWidget()._mouseDownTriggered === true) && domUtils.isFocusable(this.focusNode)) {
                if (this._emitClickEvent &&
                    (domUtils.isLeftMouseButton(e) || (e.type === "dojotouchend"))) {
                    this._emitClickEvent(e);
                }
            }
        },

        _requestClose: function (focusParent) {
            var parent = this.getParentWidget();
            if (parent != null && parent.closeRequest) {
                parent._closeEventData = {
                    mwEventData: {
                        gainedFocusWidget: null
                    }
                };
                parent.closeRequest(focusParent ? focusParent : false);
            }
        },

        focus: function () {
            this.inherited(arguments);

            if (domUtils.isFocusable(this.focusNode)) {
                // focus using focusUtil in order to trigger the watch being used by other mixins
                focusUtil.focus(this.focusNode || this.domNode);

                // IE9 has trouble with focusUtil for unknown reasons, so focus again just to be sure
                (this.focusNode || this.domNode).focus();
            }
        },

        getParentWidget: function (node) {
            if (node == null) {
                if (this.domNode) {
                    node = this.domNode;
                } else {
                    return null;
                }
            }

            if (node.domNode) {
                node = node.domNode;
            }

            if (node.parentNode) {
                node = domUtils.getParent(node);
            } else {
                return null;
            }

            return registry.getEnclosingWidget(node);
        }
    });
});
