/**
 * <strong>Purpose:</strong> <br>
 * Provides the ability to emit a "change" event.<br>
 * This event is triggered after the user interacted with the widget and the widget property is updated <br><br>
 *
 * <strong>Event Data:</strong><br>
 * This can be found in the property "mwEventData" present on the event object and it consists of
 * <li> propertyName - the property that changed</li>
 * <li> oldValue - old value of the property that changed</li>
 * <li> newValue - new value of the property </li>
 *
 * <strong>Contract:</strong> <br>
 * This mixin should be used only for widgets that involve "change" in a property via user interaction.
 * The method "_triggerChangeEvent" should be called after the widget's property has been updated.
 *
 * @module mw-mixins/event/ChangeEventMixin
 *
 * @copyright Copyright 2014 The MathWorks, Inc.
 */




define([
        "dojo/_base/declare",
        "dojo/_base/lang"
    ],
    function (declare, lang) {
        return declare(null, {

            _triggerChangeEvent: function (propertyName, oldValue, newValue) {
                // _WidgetBase's emit method
                this.emit("change", {
                    mwEventData: {
                        propertyName: propertyName,
                        oldValue: oldValue,
                        newValue: newValue
                    }
                });
            },

            _qeTypeOverWithEnter: function (value) {
                // _WidgetBase's emit method
                var qeOldValue = this.get("value");
                this.set("value", value);
                this._triggerChangeEvent("value", qeOldValue, value);
            },

            postCreate: function () {
                this.inherited(arguments);
                //Listen to change event from DOM and emit our own change event with "mwEventData"
                this.own(this.on("change", lang.hitch(this, function (event) {
                    event.preventDefault();
                    //Prevent Infinite Loop. For the DOM "change" event there will be no "mwEventData"
                    if (!event.mwEventData) {
                        event.stopPropagation();
                        this._triggerChangeEvent("checked", !this.get("checked"),
                            this.get("checked"));
                    }
                })));
            }



        });
    });