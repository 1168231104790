define([
  "underscore",
  "jquery",
  "backbone",
  "util",
  "config",
  "pages/AbstractPage",
  "dao/gdsdao",
  "filebrowser/FbTreeController",
  "templates/searchResultsPage",
  "sharingFileService",
  "dojo/i18n!nls/mldoStringResource",
  "dojo/string"
], function ( _, $, Backbone, Util, GCONFIG, AbstractPage, GDSDAO, FbTreeController, SearchResultsTemplate, FileService, MLDOStrings, DojoString) {
  const SEARCH_CONTEXT = {
    FILES: "files",
    SHARING: "sharing",
    TRASH: "trash"
  };

  let SearchResultsPage = AbstractPage.extend({

    initialize: function (params) {

      this.template = SearchResultsTemplate;

      this.events = {
        "click #searchResultsView div.facets-files": "goToFiles",
        "click #searchResultsViewHeader li.facets-files": "goToFiles",
        "click #searchResultsView div.facets-sharing": "goToSharing",
        "click #searchResultsViewHeader li.facets-sharing" : "goToSharing",
        "click #searchResultsView div.facets-trash": "goToTrashCan",
        "click #searchResultsViewHeader li.facets-trash" : "goToTrashCan",
        "click div.appTitle": "deSelectGrid",
        "click #searchResultsView div.generalToolbar div.actionIconContainer": "deSelectGrid",
        "click #searchResultsView.treeView div.wrapper": "deSelectGrid",
        "click div.myfilesTitlebar": "deSelectGrid",
        "click div#mldoFooter": "deSelectGrid",
        "click #searchResultsView div.facets": "deSelectGrid",
        "click #searchResultsViewHeader.headerContainer .navbar": "deSelectGrid",
        "click #searchResultsViewHeader .matlabDriveUserName": "deSelectGrid",
        "dragstart #searchResultsView.treeView div.wrapper": "dragoverNotAllowed"
      };

      this.baseActionSelector = 'div#searchResultsView.treeView div.toolbar_container';

      this.getFileName = function () {
        return this.fileName;
      };
      this.setFileName = function (fileName) {
        this.fileName = fileName;
      };

      this.getFolderPath = function () {
        return this.folderPath;
      };
      this.setFolderPath = function (folderPath) {
        this.folderPath = folderPath;
      };

      this.getSearchContext = function () {
        return this.searchContext;
      };
      this.setSearchContext = function (searchContext) {
        this.searchContext = searchContext;
      };

      this.getFilesTreeController = function () {
        return this.filesTreeController;
      };
      this.setFilesTreeController = function (filesTreeController) {
        this.filesTreeController = filesTreeController;
      };

      this.getSharingTreeController = function () {
        return this.sharingTreeController;
      };
      this.setSharingTreeController = function (sharingTreeController) {
        this.sharingTreeController = sharingTreeController;
      };

      this.getTrashTreeController = function () {
        return this.trashTreeController;
      };
      this.setTrashTreeController = function (trashTreeController) {
        this.trashTreeController = trashTreeController;
      };

      this.getBaseActionSelector = function() { return this.baseActionSelector; };

      this.startPage = function (msgHandler) {
        this.stopPage(); // stop any previous listeners before starting new listeners
        if (!msgHandler || typeof msgHandler !== "function") {
          throw new TypeError("Invalid message handler argument");
        }
        let context = this;
        $('body').addClass('');
        if (context.getTreeController()) {
          context.getTreeController().start(msgHandler);
        }
        $(document).on("click", "a#menu1:not(.show)", context.updateUserQuota);
        $(document).on("click", "a#menu1:not(.show)", context.updateSettings);
        context.$el.on("click", "li.dropdown-additionalApplications input", context.changeApplication);
        $(document).on("click", "#mldoFooter", context.deSelectGrid);
      };

      this.stopPage = function () {
        let context = this;
        if (context.getTreeController()) {
          context.getTreeController().stop();
        }
        $(document).off("click", "a#menu1:not(.show)", context.updateUserQuota);
        $(document).off("click", "a#menu1:not(.show)", context.updateSettings);
        context.$el.off("click", "li.dropdown-additionalApplications input", context.changeApplication);
        $(document).off("click", "#mldoFooter", context.deSelectGrid);
      };

      this.getTreeControllerOptions = function(id, searchContext) {
        let context = this;
        let options =  {
          id: id,
          moveEnabled: this.config.isMoveEnabled && this.config.isMoveEnabled(),
          copyEnabled: ("isCopyEnabled" in this.config && this.config.isCopyEnabled()),
          indentWidth: 25,
          enableNavigation: false,
          gdsDAO: this.getAuthManager().getDAO(),
          viewerServiceEnabled: (this.config.isViewerServiceEnabled && this.config.isViewerServiceEnabled()),
          viewerServiceURL: this.config.getViewerServiceURL(),
          showUploadDetails: false,
          viewFileEnabled: (this.config.isFileViewEnabled && this.config.isFileViewEnabled()),
          inlineFileView: (this.config.getInlineFileView && this.config.getInlineFileView()),
          prohibitChangeTreeRoot: false,
          viewInNewTabEnabled: (this.config.isViewInNewTabEnabled && this.config.isViewInNewTabEnabled()),
          mockOpenwith: (this.config.mockOpenwith && this.config.mockOpenwith()),
          directoryUploadEnabled: false,
          openWithEnabled: (this.config.isOpenWithEnabled && this.config.isOpenWithEnabled()),
          allowDnD: false,
          sharingEnabled: true,
          personalInvitationsEnabled: true,
          editPermissionsEnabled: false,
          folderDownloadEnabled: (this.config.isFolderDownloadEnabled && this.config.isFolderDownloadEnabled() === true),
          listContentsMethod: function () {
            return context.getTreeController().getFileService().gdsDAO.searchByFileName(context.getFolderPath(), context.getFileName(), searchContext);
          }
        };
        if (searchContext === SEARCH_CONTEXT.FILES) {
          options.actions = {
            openFileLocation: true,
            openInMatlabOnline: (this.config.isOpenWithEnabled && this.config.isOpenWithEnabled()),
            viewinbrowser: (this.config.isFileViewEnabled && this.config.isFileViewEnabled()),
            viewnewtab: (this.config.isFileViewEnabled && this.config.isFileViewEnabled()) && (this.config.isViewInNewTabEnabled && this.config.isViewInNewTabEnabled()),
            download: this.options.allowDownloads,
            rename: true,
            cut: !this.config.isMoveEnabled || !this.config.isMoveEnabled(),
            copy: ("isCopyEnabled" in this.config && this.config.isCopyEnabled()),
            paste: !this.config.isMoveEnabled || !this.config.isMoveEnabled(),
            move: this.config.isMoveEnabled && this.config.isMoveEnabled(),
            deleteitem: true,
            share: (this.config.isSharingEnabled && this.config.isSharingEnabled())
          };
          options.columns = {
            name: true,
            location: true,
            size: true,
            clientModifiedDate: true,
            ownedBy: true,
            type: true
          };
        } else if (searchContext === SEARCH_CONTEXT.SHARING) {
          options.actions = {
            manage: (this.config.isSharingEnabled && this.config.isSharingEnabled()),
            link: (this.config.isSharingEnabled && this.config.isSharingEnabled())
          };
          options.columns = {
            linkName: true,
            location: true,
            sharedBy: true,
            sharedOn: true,
            accessType: true
          };
        } else {
          options.actions = {
            restore: true,
            permanentlydelete: true,
            permanentlydeleteall: true
          };
          options.columns = {
            name: true,
            tombstone: true,
            originalLocation: true,
            size: true,
            type: true,
            deletedDate: true
          };
        }
        return options;
      };

      this.getFolderNameFromSearchContext = function() {
        if (this.getSearchContext() === SEARCH_CONTEXT.FILES) {
          return Util.getFolderNameFromPath(this.getFolderPath());
        } else if (this.getSearchContext() === SEARCH_CONTEXT.SHARING) {
          return MLDOStrings.sharingPageFacetsSharing;
        } else {
          return MLDOStrings.trashPageFacetsDeletedFiles;
        }
      };

      this.render = function (msgHandler) {
        let touchevents = (('ontouchstart' in window) || ("DocumentTouch" in window && window.DocumentTouch && document instanceof window.DocumentTouch));
        let templateParameters = {};  // new object
        templateParameters.headerNavigation = this.renderNavigationHeader();
        templateParameters.facetsFiles = MLDOStrings.filesPageFacetsFiles;
        templateParameters.facetsSharing = MLDOStrings.previewPageFacetsSharing;
        templateParameters.facetsDeletedFiles = MLDOStrings.filesPageFacetsDeletedFiles;
        templateParameters.pageTitle = MLDOStrings.searchResultsPageTitle;
        templateParameters.searchResultsFrom = DojoString.substitute(MLDOStrings.searchResultsFrom, [this.getFolderNameFromSearchContext()]);
        templateParameters.previewBannerText = MLDOStrings.previewBannerText;
        templateParameters.previewBannerEnabled = ((this.config.isPreviewBannerEnabled && this.config.isPreviewBannerEnabled()) ? true : false);
        templateParameters.searchContext = this.getSearchContext();
        templateParameters.moveEnabled = this.config.isMoveEnabled && this.config.isMoveEnabled();
        templateParameters.copyEnabled = ("isCopyEnabled" in this.config && this.config.isCopyEnabled()) ? true : false;

        templateParameters.renameActionLabel = MLDOStrings.filesPageRenameLabel;
        templateParameters.moveActionLabel = MLDOStrings.filesPageMoveLabel;
        templateParameters.deleteActionLabel = MLDOStrings.filesPageDeleteLabel;
        templateParameters.viewInNewTabLabel = MLDOStrings.viewInNewTab;
        templateParameters.cutActionLabel = MLDOStrings.filesPageCutLabel;
        templateParameters.copyActionLabel = MLDOStrings.filesPageCopyLabel;
        templateParameters.copyToActionLabel = MLDOStrings.filesPageCopyToLabel;
        templateParameters.pasteActionLabel = MLDOStrings.filesPagePasteLabel;
        templateParameters.shareActionLabel = MLDOStrings.filesPageShareLabel;
        templateParameters.manageActionLabel = MLDOStrings.filesPageManageLabel;
        templateParameters.linkActionLabel = MLDOStrings.filesPageLinkLabel;
        templateParameters.downloadActionLabel = MLDOStrings.filesPageDownloadLabel;
        templateParameters.pasteActionLabel = MLDOStrings.filesPagePasteLabel;
        templateParameters.searchPageOpenFileLocationLabel = MLDOStrings.searchPageOpenFileLocationLabel;

        templateParameters.downloadToolTipText = MLDOStrings.downloadToolTipText;
        templateParameters.openToolTipText = MLDOStrings.openToolTipText;
        templateParameters.viewToolTipText = MLDOStrings.viewToolTipText;
        templateParameters.renameToolTipText = MLDOStrings.renameToolTipText;
        templateParameters.moveToolTipText = MLDOStrings.moveToolTipText;
        templateParameters.cutToolTipText = MLDOStrings.cutToolTipText;
        templateParameters.copyToolTipText = MLDOStrings.copyToolTipText;
        templateParameters.pasteToolTipText = MLDOStrings.pasteToolTipText;
        templateParameters.deleteToolTipText = MLDOStrings.deleteToolTipText;
        templateParameters.viewInNewTabToolTipText = MLDOStrings.viewInNewTabToolTipText;
        templateParameters.shareToolTipText = MLDOStrings.shareToolTipText;
        templateParameters.manageToolTipText = MLDOStrings.manageToolTipText;
        templateParameters.linkToolTipText = MLDOStrings.linkToolTipText;
        templateParameters.openFileLocationToolTipText = MLDOStrings.openFileLocationToolTipText;
        templateParameters.openLiveEditor = MLDOStrings.viewOpenLiveEditor;
        templateParameters.viewFile = MLDOStrings.viewFile;
        templateParameters.mockOpenwith = params.mockOpenwith || false;
        templateParameters.viewerServiceEnabled = params.viewerServiceEnabled || false;
        templateParameters.liveEditorSupported = (params.liveEditorSupported && Util.isBrowserValid()) || false;

        templateParameters.manageActionLabel = MLDOStrings.filesPageManageLabel;
        templateParameters.linkActionLabel = MLDOStrings.filesPageViewLinkLabel;
        templateParameters.manageToolTipText = MLDOStrings.manageToolTipText;
        templateParameters.linkToolTipText = MLDOStrings.linkToolTipText;

        templateParameters.restoreToolTipText = MLDOStrings.restoreToolTipText;
        templateParameters.restoreActionLabel = MLDOStrings.trashPageRestoreLabel;
        templateParameters.permanentlyDeleteToolTipText = MLDOStrings.permanentlyDeleteToolTipText;
        templateParameters.permanentlyDeleteAllToolTipText = MLDOStrings.permanentlyDeleteAllToolTipText;
        templateParameters.deleteAllActionLabel = MLDOStrings.trashPageEmptyTrashLabel;

        let htmlResult = this.template(templateParameters);
        this.$el.empty().html(htmlResult);
        // render subviews

        $('.appContainer').removeClass('ff-delete');
        $('.mainFooter').addClass('ff-delete');
        $('#previewView.treeView').addClass('ff-delete');
        $('.right-column').addClass('ff-delete');
        $('.facets').addClass('ff-delete');

        // Create different TreeControllers as FileService, listContent, actions and columns are different
        if (!this.getFilesTreeController()) {
          this.setFilesTreeController(new FbTreeController(this.getTreeControllerOptions("searchResultsFiles", SEARCH_CONTEXT.FILES)));
        }
        if (!this.getSharingTreeController()) {
          this.setSharingTreeController(new FbTreeController(this.getTreeControllerOptions("searchResultsSharing", SEARCH_CONTEXT.SHARING)));
        }

        if (!this.getTrashTreeController()) {
          this.setTrashTreeController(new FbTreeController(this.getTreeControllerOptions("searchResultsTrash", SEARCH_CONTEXT.TRASH)));
        }

        // Set the tree controller based on the search context
        if (this.getSearchContext() === SEARCH_CONTEXT.FILES) {
          this.setTreeController(this.getFilesTreeController());
        } else if (this.getSearchContext() === SEARCH_CONTEXT.SHARING) {
          this.setTreeController(this.getSharingTreeController());
        } else {
          this.setTreeController(this.getTrashTreeController());
        }

        let tree = this.getTreeController();
        if (tree) {
          tree.start(msgHandler);
          tree.render(this.getTreeOptions());
        }
        this.$el.off('dragover'); // clear previous listeners
        this.$el.on('dragover', this.dragNotAllowed);
        this.getUserQuotaController().setElement($('.quotaListItem'));
        this.getUserQuotaController().render();
        this.updateSettings();
        if (!touchevents) {
          $('[data-bs-toggle="tooltip"]').tooltip();
          $('.share a.dropdown-toggle').tooltip({placement: "top", container: ".myfilesTitlebar"});
        }
        this.renderMatrix();
        return this;
      };

      this.getTreeOptions = function() {
        return {
          id: 'searchResultsTree',
          authenticated: true,
          shareSelector : this.isSharingEnabled ? this.getBaseActionSelector() + ' .share' : '',
          manageSelector : this.isSharingEnabled ? this.getBaseActionSelector() + ' .manage' : '',
          linkSelector : this.isSharingEnabled ? this.getBaseActionSelector() + ' .link' : '',
          downloadSelector : this.getBaseActionSelector() + ' .downloadFiles',
          openFileLocationSelector: this.getBaseActionSelector() + ' .openFileLocation',
          openSelector : this.getBaseActionSelector() + ' .open',
          viewSelector : this.getBaseActionSelector() + ' .view',
          viewInNewTabSelector : this.getBaseActionSelector() + ' .newTabWindow',
          deleteSelector : this.getBaseActionSelector() + ' .deleteFile',
          renameSelector : this.getBaseActionSelector() + ' .rename',
          cutSelector : this.getBaseActionSelector() + ' .cut',
          copySelector : ("isCopyEnabled" in this.config && this.config.isCopyEnabled()) ? this.getBaseActionSelector() + ' .copy' : '',
          pasteSelector : this.getBaseActionSelector() + ' .paste',
          moveSelector : this.getBaseActionSelector() + ' .move',
          restoreSelector : '.restore',
          permanentlyDeleteSelector : '.permanentlyDelete',
          permanentlyDeleteAllSelector: '.permanentlyDeleteAll'
        };
      };

      this.htmlLocalStorage = (typeof(Storage) !== "undefined");
      // Test and turn off if browser settings doesn't allow access.
      if (this.htmlLocalStorage) {
        try {
          localStorage.getItem("mldo.currentFolder");
        } catch (e) {
          this.htmlLocalStorage = false;
        }
      }

      this.closeFileViewerAndShowTree = function() {
        this.getTreeController().getActionHandler().closeFileViewerAndShowTree();
      };

      this.refresh = function () {
        if (this.getTreeController()) {
          this.getTreeController().refresh();
        }
      };

      this.dragNotAllowed = function (e) {
        if (e && e.preventDefault && e.originalEvent && e.originalEvent.dataTransfer) {
          e.preventDefault();
          e.originalEvent.dataTransfer.dropEffect = 'none';
        }
      };

      this.constructor.__super__.initializeParams.apply(this, arguments);

      _.bindAll(this, "goToFiles", "goToSharing", "goToTrashCan",
        "changeTreeRoot", "changeApplication", "getTreeControllerOptions", "closeFileViewerAndShowTree",
        "deSelectGrid", "render", "stopPage", "updateUserQuota", "updateSettings");
    }
  });

  return SearchResultsPage;
}); // require
