/**
 * This is a utility that provides a way to generate user facing shortcut strings for the given key code array, taking
 * care of the platform as well Mac, Windows, Linux.
 * For example, [DefaultKeys.CTRL, DefaultKeys.S] gets converted to "⌘S" on Mac and "Ctrl+S" on windows
 */

define([
    "dojo/sniff",
    "mw-keybindings/DefaultKeys",
    "dojo/i18n!./l10n/nls/keyBindings"
], function (sniff, DefaultKeys, keyBindings) {

    var map = {};

    map[DefaultKeys.SYSCTRL] = {
        macString: "\u2303",
        otherPlatformsString: "Ctrl"
    };

    // bindings made with the COMMAND key will only be triggered
    // on the mac so the "otherPlatformString" will never be used
    map[DefaultKeys.COMMAND] = {
        macString: "\u2303",
        otherPlatformsString: ""
    };

    map[DefaultKeys.BACKSPACE] = {
        macString: "\u232B",
        otherPlatformsString: keyBindings.BACKSPACE

    };

    map[DefaultKeys.TAB] = {
        macString: "\u21E5",
        otherPlatformsString: keyBindings.TAB

    };

    map[DefaultKeys.CLEAR] = {
        macString: "\u2327",
        otherPlatformsString: keyBindings.CLEAR

    };

    map[DefaultKeys.ENTER] = {
        macString: "\u23CE",
        otherPlatformsString: keyBindings.ENTER
    };

    map[DefaultKeys.SHIFT] = {
        macString: "\u21E7",
        otherPlatformsString: keyBindings.SHIFT
    };

    map[DefaultKeys.CTRL] = {
        macString: "\u2303",
        otherPlatformsString: keyBindings.CTRL

    };

    map[DefaultKeys.ALT] = {
        macString: "\u2325",
        otherPlatformsString: keyBindings.ALT
    };

    map[DefaultKeys.PAUSE_BREAK] = {
        macString: keyBindings.PAUSE_BREAK,
        otherPlatformsString: keyBindings.PAUSE_BREAK
    };

    map[DefaultKeys.CAPS_LOCK] = {
        macString: "\u21EA",
        otherPlatformsString: keyBindings.CAPS_LOCK

    };

    map[DefaultKeys.ESCAPE] = {
        macString: "\u238B",
        otherPlatformsString: keyBindings.ESCAPE

    };

    map[DefaultKeys.SPACE] = {
        macString: "\u2423",
        otherPlatformsString: keyBindings.SPACE

    };

    map[DefaultKeys.PAGE_UP] = {
        macString: "\u23CE",
        otherPlatformsString: keyBindings.PAGE_UP
    };

    map[DefaultKeys.PAGE_DOWN] = {
        macString: "\u2191",
        otherPlatformsString: keyBindings.PAGE_DOWN
    };

    map[DefaultKeys.END] = {
        macString: "\u2198",
        otherPlatformsString: keyBindings.END
    };

    map[DefaultKeys.HOME] = {
        macString: "\u2196",
        otherPlatformsString: keyBindings.HOME
    };

    map[DefaultKeys.LEFT_ARROW] = {
        macString: "\u2190",
        otherPlatformsString: keyBindings.LEFT
    };

    map[DefaultKeys.UP_ARROW] = {
        macString: "\u2191",
        otherPlatformsString: keyBindings.UP
    };

    map[DefaultKeys.RIGHT_ARROW] = {
        macString: "\u2192",
        otherPlatformsString: keyBindings.RIGHT
    };

    map[DefaultKeys.DOWN_ARROW] = {
        macString: "\u2193",
        otherPlatformsString: keyBindings.DOWN
    };

    map[DefaultKeys.INSERT] = {
        macString: keyBindings.INSERT,
        otherPlatformsString: keyBindings.INSERT
    };

    map[DefaultKeys.DELETE] = {
        macString: "\u2326",
        otherPlatformsString: keyBindings.DELETE
    };

    map[DefaultKeys.LEFT_WINDOWS] = {
        macString: "",
        otherPlatformsString: keyBindings.LEFT + " " + keyBindings.WINDOWS
    };

    map[DefaultKeys.RIGHT_WINDOWS] = {
        macString: "",
        otherPlatformsString: keyBindings.RIGHT + " " + keyBindings.WINDOWS
    };

    map[DefaultKeys.NUMPAD_MULTIPLY] = {
        macString: "\u2328 *",
        otherPlatformsString: keyBindings.NUM_PAD + " *"
    };

    map[DefaultKeys.NUMPAD_PLUS] = {
        macString: "\u2328 +",
        otherPlatformsString: keyBindings.NUM_PAD + " +"
    };

    map[DefaultKeys.NUMPAD_MINUS] = {
        macString: "\u2328 -",
        otherPlatformsString: keyBindings.NUM_PAD + " -"
    };

    map[DefaultKeys.NUMPAD_PERIOD] = {
        macString: "\u2328 .",
        otherPlatformsString: keyBindings.NUM_PAD + " ."
    };

    map[DefaultKeys.NUMPAD_DIVIDE] = {
        macString: "\u2328 /",
        otherPlatformsString: keyBindings.NUM_PAD + " /"
    };

    map[DefaultKeys.NUM_LOCK] = {
        macString: keyBindings.NUM_LOCK,
        otherPlatformsString: keyBindings.NUM_LOCK
    };

    map[DefaultKeys.SCROLL_LOCK] = {
        macString: keyBindings.SCROLL_LOCK,
        otherPlatformsString: keyBindings.SCROLL_LOCK
    };

    map[DefaultKeys.DASH] = {
        macString: "-",
        otherPlatformsString: "-"
    };

    map[DefaultKeys.EQUALS] = {
        macString: "=",
        otherPlatformsString: "="
    };

    map[DefaultKeys.OPEN_BRACKET] = {
        macString: "[",
        otherPlatformsString: "["
    };

    map[DefaultKeys.CLOSE_BRACKET] = {
        macString: "]",
        otherPlatformsString: "]"
    };

    map[DefaultKeys.COMMA] = {
        macString: ",",
        otherPlatformsString: ","
    };

    map[DefaultKeys.PERIOD] = {
        macString: ".",
        otherPlatformsString: "."
    };

    map[DefaultKeys.FORWARD_SLASH] = {
        macString: "/",
        otherPlatformsString: "/"
    };

    map[DefaultKeys.BACKWARD_SLASH] = {
        macString: "\\",
        otherPlatformsString: "\\"
    };

    map[DefaultKeys.SINGLE_QUOTE] = {
        macString: "'",
        otherPlatformsString: "'"
    };

    map[DefaultKeys.SEMI_COLON] = {
        macString: ";",
        otherPlatformsString: ";"
    };

    map[DefaultKeys.BACK_TICK] = {
        macString: "`",
        otherPlatformsString: "`"
    };

    return {
        getString: function (keyCodeArray) {
            var shortcutArray = keyCodeArray.map(function (keyCode) {
                var mapEntry = map[keyCode];
                var result = "";
                if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90)) {
                    /**
                     * Use String.fromCharCode for characters like number "0-9" and letters "A-Z":
                     * 48-57: 0-9
                     * 65-90: A-Z
                     */
                    result = String.fromCharCode(keyCode);
                } else if (keyCode >= 96 && keyCode <= 105) {
                    // For NumPad 0 through 9
                    result = sniff("mac") ? "\u2328" : "NumPad";
                    result = [result, keyCode - 96].join(" ");
                } else if (keyCode >= 112 && keyCode <= 126) {

                    // For F1 through F15
                    result = "F" + (keyCode - 111);
                } else {
                    if (!mapEntry) {
                        console.warn("This keyCode " + keyCode + " doesn't have a corresponding shortcutString map entry defined!");
                        mapEntry = {
                            macString: "",
                            otherPlatformsString: ""
                        };
                    } else {
                        if (!mapEntry.macString) {
                            console.warn("This keyCode " + keyCode + " doesn't have a corresponding 'macString' defined!");
                            mapEntry.macString = "";
                        }
                        if (!mapEntry.otherPlatformsString) {
                            console.warn("This keyCode " + keyCode + " doesn't have a corresponding 'otherPlatformsString' defined!");
                            mapEntry.otherPlatformsString = "";
                        }
                    }

                    result = sniff("mac") ? mapEntry.macString : mapEntry.otherPlatformsString;
                }

                return result;
            });

            var shortcutString = sniff("mac") ? shortcutArray.join("") : shortcutArray.join("+");

            return shortcutString;
        }
    };
});