// Copyright 2016 The MathWorks, Inc.

define([
    "dojo/_base/declare",
    "dojo/dom-construct",
    "dojo/query"
], function (declare, domConstruct, querySelector) {
    return declare(null, {
        //https://github.com/SitePen/dgrid/issues/997
        _onNotify: function (object, existingId) {
            if (object === undefined) {
                delete this._expanded[existingId];
            }
            this.inherited(arguments);
        },

        //https://github.com/SitePen/dgrid/issues/999
        handleRemoveRow: function (rowElement, justCleanup) {
            var connected = rowElement.connected;
            var grid = this.grid;
            if (connected) {
                querySelector(">.dgrid-row", connected).forEach(function (element) {
                    grid.removeRow(element, true);
                });
                if (!justCleanup) {
                    domConstruct.destroy(connected);
                }
            }
        }
    });
});