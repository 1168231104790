/**
 * This is one of the Standard Widgets in the Web Widgets Framework. It uses a custom template compared
 * to DOJO's Menu Item
 * Menu Items are supposed to be used with in a Menu. So, a Menu must be created first and then MenuItem
 * should be added to the Menu.<br>
 * To see all the available properties for this widget, please look at the corresponding Property Mixins below.
 * <br>
 * <br>
 * <strong>Property Mixins:</strong><br>
 * {@link module:mw-mixins/property/DescriptionMixin}<br>
 * {@link module:mw-mixins/property/DisabledMixin}<br>
 * {@link module:mw-mixins/property/IconMixin}<br>
 * {@link module:mw-mixins/property/ShortcutMixin}<br>
 * {@link module:mw-menu/mixins/property/ShowDescriptionMixin}<br>
 * {@link module:mw-menu/mixins/property/ShowIconMixin}<br>
 * {@link module:mw-menu/mixins/property/ShowTextMixin}<br>
 * {@link module:mw-menu/mixins/property/ShowShortcutMixin}<br>
 * {@link module:mw-mixins/property/TextMixin}<br>
 * {@link module:mw-mixins/property/VisualFamilyMixin}<br>
 *
 * <strong> Events: </strong>
 * MenuItem emits a "click" event whenever it is clicked as a result of user-interaction
 *
 * @example <caption>Creating MenuItems</caption>
 * var myMenuItem1 = new MenuItem({
 *      icon: "help_24",
 *      text: "My Menu Item",
 *      shortcut: "Ctrl+z",
 *      description: "Item has description available",
 * });
 *
 *
 * myMenuItem1.placeAt("someMenuId");
 * myMenuItem1.startup();
 *
 * @module mw-menu/MenuItem
 *
 * @copyright Copyright 2015 The MathWorks, Inc.
 */

// TODO: Remove _CSSStateMixin dependency, as seen in SplitButton and ToggleSplitButton

define([
    "dojo/_base/declare",
    "dijit/_WidgetBase",
    "dijit/_TemplatedMixin",
    "dijit/_CssStateMixin",
    "dijit/_Contained",
    "dijit/_FocusMixin",
    "mw-menu/mixins/MenuItemTextColorMixin",
    "mw-mixins/property/TagMixin",
    "mw-menu/mixins/property/CloseMenuOnClickMixin",
    "mw-mixins/property/DescriptionMixin",
    "mw-mixins/property/DisabledMixin",
    "mw-mixins/property/IconMixin",
    "mw-menu/mixins/property/IconSizeCalculatorMixin",
    "mw-mixins/property/ShortcutMixin",
    "mw-menu/mixins/property/ShowDescriptionMixin",
    "mw-menu/mixins/property/ShowIconMixin",
    "mw-menu/mixins/property/ShowTextMixin",
    "mw-menu/mixins/property/ShowShortcutMixin",
    "mw-mixins/property/TextMixin",
    "mw-menu/mixins/SingleLineTextMixin",
    "mw-mixins/property/VisualFamilyMixin",
    "mw-mixins/AccessibilityClickMixin",
    "mw-mixins/PreventSelectionMixin",
    "mw-menu/mixins/FocusableMenuChildMixin",
    "mw-menu/mixins/MenuChildResizeMixin",
    "mw-mixins/mixinDependencyValidator",
    "dojo/text!./templates/MenuItem.html"

], function (declare, _WidgetBase, _TemplatedMixin, _CssStateMixin, _Contained, _FocusMixin,
             MenuItemTextColorMixin, TagMixin, CloseMenuOnClickMixin, DescriptionMixin,
             DisabledMixin, IconMixin, IconSizeCalculatorMixin, ShortcutMixin, ShowDescriptionMixin,
             ShowIconMixin, ShowTextMixin, ShowShortcutMixin, TextMixin, SingleLineTextMixin, VisualFamilyMixin,
             AccessibilityClickMixin, PreventSelectionMixin, FocusableMenuChildMixin,
             MenuChildResizeMixin, mixinDependencyValidator, template) {

    return declare(mixinDependencyValidator.validate([_WidgetBase, _TemplatedMixin, _CssStateMixin,
        _Contained, _FocusMixin, TagMixin, CloseMenuOnClickMixin, DescriptionMixin, DisabledMixin,
        IconMixin, IconSizeCalculatorMixin, ShortcutMixin, ShowDescriptionMixin, ShowIconMixin,
        ShowTextMixin, ShowShortcutMixin, TextMixin, SingleLineTextMixin,
        VisualFamilyMixin, MenuItemTextColorMixin, AccessibilityClickMixin, PreventSelectionMixin,
        FocusableMenuChildMixin, MenuChildResizeMixin]), {
        baseClass: "mwWidget mwSharedMenuItem mwMenuItem",
        templateString: template,
        closeMenuOnClick: true, // Do close Menu when clicked

        _emitClickEvent: function () {
            this.emit("click", {
                mwEventData: {
                    value: this.get("text")
                },
                _dojo_click: true
            });
        },
        _qeClick: function() {
            this._emitClickEvent();
        }
    });

});
