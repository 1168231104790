// Copyright 2016, The MathWorks, Inc

define([
    "dojo/_base/declare",
    "dojo/_base/lang",
    "dojo/Evented",
    "dojo/on",
    "../util/RemovableGroup",
    "mw-log/Log"
], function (declare, lang, Evented, on, RemovableGroup, Log) {

    return declare([Evented, RemovableGroup], {

        RECOGNIZER_STATES: {
            INACTIVE: "inactive",
            ACTIVE: "active",
            MATCHED: "matched"
        },

        constructor: function (args) {
            this._recognizerState = this.RECOGNIZER_STATES.INACTIVE;
            this._options = (args && args.options) ? args.options : {};

            this._blockedRecognizers = [];
            this._inputHandlers = new RemovableGroup();
        },

        setInputCapturer: function (inputCapturer) {
            this._inputHandlers.remove();
            this._inputCapturer = inputCapturer;
            if (this._enabled) {
                this._attachInputHandlers();
            }
        },

        enable: function () {
            Log.assert(this._inputCapturer, "Gesture recognizer must have an input capturer to be enabled.");
            if (this._enabled) {
                return;
            }
            this._enabled = true;
            this._attachInputHandlers();
        },

        _attachInputHandlers: function () {
            this._inputHandlers.remove();
            this._inputHandlers.own(on(this._inputCapturer, "receiveInput", lang.hitch(this, "receiveInput")));
            this._inputHandlers.own(on(this._inputCapturer, "checkBlockers", lang.hitch(this, "_checkBlockers")));
            this._inputHandlers.own(on(this._inputCapturer, "broadcastEvents", lang.hitch(this, "broadcastEvents")));
        },

        disable: function () {
            this._enabled = false;
            this._inputHandlers.remove();
            this.reset();
        },

        remove: function () {
            this.disable();
            this.inherited(arguments);
        },

        prevents: function (recognizer) {
            this._blockedRecognizers.push(recognizer);
        },

        setOptions: function (options) {
            this._options = options ? options : {};
        },

        _checkBlockers: function () {
            var that = this;
            this._blockedRecognizers.forEach(function (blockedRecognizer) {
                if (that.getRecognizerState() !== that.RECOGNIZER_STATES.INACTIVE) {
                    blockedRecognizer.reset();
                }
            });
        },

        setRecognizerState: function (state) {
            if (state !== this._recognizerState) {
                this._recognizerState = state;
                this.onRecognizerStateChange();
            }
        },

        getRecognizerState: function () {
            return this._recognizerState;
        },

        reset: function () {
            this.setRecognizerState(this.RECOGNIZER_STATES.INACTIVE);
        },

        onRecognizerStateChange: function () { },
        receiveInput: function (evt) { },
        broadcastEvents: function (evt) {}

    });

});