/**
 * This is one of the Standard Widgets in the Web Widgets Framework. It uses a custom template compared
 * to DIJIT's Menu
 * Menu widget is a container widget and Menu Items are supposed to be used with in a Menu.
 * So, a Menu must be created first and then Menu item's should be added to the Menu.<br>
 * To see all the available properties for this widget, please look at the corresponding Property Mixins below.
 * <br>
 * <br>
 * <strong>Property Mixins:</strong><br>
 * {@link module:mw-menu/mixins/property/MaxHeightMixin}<br>
 * {@link module:mw-mixins/property/VisualFamilyMixin}<br>
 * {@link module:mw-mixins/property/TagMixin}<br>
 *
 * <strong> Events: </strong>
 * Menu emits "open", which fires immediately after a Menu opens and "close" event, which
 * Fires immediately after a Menu closes
 *  detail: {

 *  }
 *
 * @example <caption>Creating Menu</caption>
 * var Menu1 = new Menu({
       maxHeight: 600
 * });
 *
 *
 * Menu1.placeAt("someDivId");
 * Menu1.startup();
 *
 * @module mw-menu/Menu
 *
 * @copyright Copyright 2015 The MathWorks, Inc.
 */

define([
    "dojo/_base/declare",
    "dijit/focus",
    "dijit/_WidgetBase",
    "dijit/_TemplatedMixin",
    "dijit/_Container",
    "mw-menu/mixins/KeyNavMixin", /* Custom key Navigation */
    "mw-menu/mixins/OnOpenMixin",
    "mw-menu/mixins/OnCloseMixin",
    "mw-menu/mixins/ContainerFocusMixin",
    "mw-menu/mixins/MenuFocusMixin",
    "mw-menu/mixins/property/MaxHeightMixin",
    "mw-mixins/property/TagMixin",
    "mw-mixins/property/DestroyedMixin",
    "mw-mixins/property/VisualFamilyMixin",
    "mw-mixins/mixinDependencyValidator",
    "dojo/text!./templates/Menu.html"
], function (declare, focusUtil, _WidgetBase, _TemplatedMixin, _Container,
             KeyNavMixin, OnOpenMixin, OnCloseMixin, ContainerFocusMixin, MenuFocusMixin,
             MaxHeightMixin, TagMixin, DestroyedMixin, VisualFamilyMixin, mixinDependencyValidator, template) {

    return declare(mixinDependencyValidator.validate([_WidgetBase, KeyNavMixin, _TemplatedMixin,
        _Container, TagMixin, OnOpenMixin, OnCloseMixin, ContainerFocusMixin, MenuFocusMixin,
        MaxHeightMixin, DestroyedMixin, VisualFamilyMixin]), {
        baseClass: "mwWidget mwMenu",
        templateString: template,

        _getTargetNode: function (node) {
            // TODO: Update logic when CompositionMixin is added
            // If the node is a child in a composite widget, then get the primary node from the value of the "data-composite-primary-id" attribute
            if (node.hasAttribute("data-composite-child")) {
                node = document.getElementById(node.getAttribute("data-composite-primary-id"));
            }

            return node;
        },

        isMenuOpen: function () {
            return (this.domNode && this.domNode.parentElement) ? (this.domNode.parentElement.style.display !== "none") : false;
        }
    });

});
