/**
 * <strong>Purpose:</strong> <br>
 * Prevents user selection on widget nodes. By default the selection is prevented on the domNode.
 * This mixin should not be used with widgets that require text inputs from user like text fields etc. since the user
 * would expect to be able to select the entered text.
 * This mixin provides a cross browser way of preventing selection as CSS user select works only for webkit
 * <br><br>
 * <strong>Contract:</strong> <br>
 * The widget can provide it's own set of nodes that it would like to prevent selection on by overriding the
 * _getNonSelectableNodes method.
 *
 * @module mw-mixins/PreventSelectionMixin
 *
 * @copyright Copyright 2014-2015 The MathWorks, Inc.
 */

define([
    "dojo/_base/declare",
    "dojo/on"
], function (declare, on) {

    return declare(null, {

        postCreate: function () {
            this.inherited(arguments);
            this._getNonSelectableNodes().forEach(function (node) {
                //cant use dom.setSelectable in IE9: Geck 1223279
                on(node, "selectstart", function (event) {
                    var hasIE = document.documentMode || (navigator.appName === 'Netscape' && navigator.appVersion.indexOf('Trident') === -1);
                    //Only in IE, selectstart is triggered for input elements
                    // on chrome and firefox selectstart does not trigger for input elements at all
                    //Toolstrip has css that prevents userselection
                    if(hasIE && (event.target.nodeName !== "INPUT" || event.target.nodeName !== "TEXTAREA")) {
                       return false;
                    } else {
                       event.preventDefault();
                    }

                });
            });
        },

        // This method may be overridden by the widget using this mixin if they want different or more node(s) to be
        // non-selectable
        _getNonSelectableNodes: function () {
            return [this.domNode];
        }

    });

});
