/**
 * <strong>Purpose:</strong> <br>
 * Provides the ability to close a Menu on click. When the value of this property is false, the Menu does not close
 * <br><br>
 * @module mw-menu/mixins/property/CloseMenuOnClickMixin
 *
 * @copyright Copyright 2014 The MathWorks, Inc.
 */

define([
    "dojo/_base/declare",
    "dojo/_base/lang",
    "dojo/on",
    "dijit/popup"
], function (declare, lang, on, popup) {

    return declare(null, {

        /**
         * @property  {boolean} closeMenuOnClick - Supported Values: true/false
         * @instance
         * @default
         */
        closeMenuOnClick: false,

        postCreate: function () {
            this.inherited(arguments);
            this.own(on(this.domNode, "change", lang.hitch(this, "_onChange")));
            this.domNode.classList.add("mwCloseMenuOnClickMixin");
            //When the default value is falsy, the setter will not be triggered during the widget's construction phase
            // We need to call the setter explicitly
            this.set("closeMenuOnClick", this.get("closeMenuOnClick"));
        },

        _setCloseMenuOnClickAttr: function (value) {
            if (typeof value !== "boolean") {
                throw new Error("'closeMenuOnClick' property expects a Boolean!");
            }
            this._set("closeMenuOnClick", value);

            if (value) {
                this.domNode.classList.add("mwClosePopupOnClick");
            } else {
                this.domNode.classList.remove("mwClosePopupOnClick");
            }

        },

        _onChange: function() {
            if(this.get("closeMenuOnClick")) {
                popup.close();
            }
        }
    });

});
