/**
 *  PlatformUtils is a module that defines strings for the various platforms to create key bindings
 *
 *  @copyright Copyright 2015 The MathWorks, Inc.
 */

define([

], function () {

    var platforms =
    {
        Windows: "Windows",
        Mac: "Mac",
        Linux: "Linux",
        Unspecified: "Unspecified"
    }

    return platforms;
});
