/**
 *  DefaultKeys is an object that defines the valid set of keys that can be used to create a binding.
 *
 *
 *  @copyright Copyright 2015 The MathWorks, Inc.
 */

define([
        // dojo
        "dojo/_base/lang",

        // keybindings
        "mw-keybindings/KeyboardKeys"

    ],
    function (lang,KeyboardKeys ) {

        var specialKeys = {

            // if a key binding is created with the SYSCTRL key, the framework will automatically
            // create the correct platform-specific binding.
            // For example, [KeyBindings.Keys.SYSCTRL, KeyBindings.Keys.S] will map to "Ctrl-S" on windows
            // and "Cmd-S" on mac
            // Set the id to be a number that won't clash with a key code
            SYSCTRL: 9000,

            // if a binding is made with the COMMAND key, the framework will set the "hasMetaKey" state
            // of the binding to be true so that the left and right Command keys will trigger the binding.
            // In this way there is no need to specify the individual LEFT and RIGHT Command keys.
            // Set the id to be a number that won't clash with a key code
            COMMAND: 9001

        };

        // return a mixin of the keyboard keys and the keys defined above
        return lang.mixin(KeyboardKeys, specialKeys);
    });

