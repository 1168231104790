/**
 *  ModifierKeys is a module that defines the modifier keys used to create bindings
 *
 *  @copyright Copyright 2015 The MathWorks, Inc.
 */

define([
        // dojo
        "mw-keybindings/DefaultKeys"

    ],
    function (DefaultKeys ) {

        var modifierKeys = [DefaultKeys.CTRL, DefaultKeys.ALT, DefaultKeys.SHIFT,
                            DefaultKeys.COMMAND, DefaultKeys.SYSCTRL,
                            DefaultKeys.LEFT_WINDOWS, DefaultKeys.RIGHT_WINDOWS];

        return modifierKeys;
    });

