// Copyright 2016, The MathWorks, Inc

define([
    "dojo/_base/declare",
    "../../GestureUtils",
    "../DiscreteGestureRecognizer"
], function (declare, GestureUtils, DiscreteGestureRecognizer) {

    return declare([DiscreteGestureRecognizer], {

        constructor: function (args) {
            this._eventList = [];
        },

        reset: function () {
            this.inherited(arguments);
            this._resetEventList();
        },

        _resetEventList: function () {
            this._eventList = [];
        },

        receiveInput: function (evt) {
            var detectionResult;

            /*
             Optimization: replace move events rather than adding consecutive move events since multiple consecutive move
             events currently have no effect on the gesture detection. May have to remove at some point. This prevents
             the list from getting too long, and saves us some milliseconds in the current gesture detection when
             someone does a long move
             */
            if ((this._eventList.length > 0) &&
                GestureUtils.isMoveEvent(evt) &&
                GestureUtils.isMoveEvent(this._eventList[this._eventList.length-1])) {
                this._eventList[this._eventList.length-1] = evt;
            } else {
                this._eventList.push(evt);
            }

            detectionResult = GestureUtils.detectGesture(this._eventList, this._matchFcn, this._options);

            if (this._resetOnMatch && detectionResult.detected) {
                this._resetEventList();
            } else {
                this._eventList = detectionResult.potentialEventList;
            }

            if (detectionResult.detected) {
                this.setRecognizerState(this.RECOGNIZER_STATES.MATCHED);
            }
        }
    });

});