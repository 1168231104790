// Copyright 2016, The MathWorks, Inc

define([
    "dojo/_base/declare",
    "../GestureUtils",
    "../gestures/_impl/EventListDiscreteRecognizer",
    "../gestures/_impl/DiscreteSequenceContinuousRecognizer"
], function (declare, GestureUtils, EventListDiscreteRecognizer, DiscreteSequenceContinuousRecognizer) {

    var discrete = {
        events: {
            cancel: declare([EventListDiscreteRecognizer], {_matchFcn: GestureUtils.matchCancel, _resetOnMatch: true}),
            down: declare([EventListDiscreteRecognizer], {_matchFcn: GestureUtils.matchDown, _resetOnMatch: true}),
            downMove: declare([EventListDiscreteRecognizer], {_matchFcn: GestureUtils.matchDownMove, _resetOnMatch: false}),
            downUp: declare([EventListDiscreteRecognizer], {_matchFcn: GestureUtils.matchDownUp, _resetOnMatch: true}),
            tap: declare([EventListDiscreteRecognizer], {_matchFcn: GestureUtils.matchTap, _resetOnMatch: true}),
            tapDown: declare([EventListDiscreteRecognizer], {_matchFcn: GestureUtils.matchTapDown, _resetOnMatch: true}),
            tapMove: declare([EventListDiscreteRecognizer], {_matchFcn: GestureUtils.matchTapMove, _resetOnMatch: false}),
            tapUp: declare([EventListDiscreteRecognizer], {_matchFcn: GestureUtils.matchTapUp, _resetOnMatch: true}),
            doubleTap: declare([EventListDiscreteRecognizer], {_matchFcn: GestureUtils.matchDoubleTap, _resetOnMatch: true}),
            doubleTapDown: declare([EventListDiscreteRecognizer], {_matchFcn: GestureUtils.matchDoubleTapDown, _resetOnMatch: true}),
            doubleTapMove: declare([EventListDiscreteRecognizer], {_matchFcn: GestureUtils.matchDoubleTapMove, _resetOnMatch: false}),
            doubleTapUp: declare([EventListDiscreteRecognizer], {_matchFcn: GestureUtils.matchDoubleTapUp, _resetOnMatch: true}),
            tripleTap: declare([EventListDiscreteRecognizer], {_matchFcn: GestureUtils.matchTripleTap, _resetOnMatch: true}),
            secondaryDown: declare([EventListDiscreteRecognizer], {_matchFcn: GestureUtils.matchSecondaryDown, _resetOnMatch: true}),
            secondaryMove: declare([EventListDiscreteRecognizer], {_matchFcn: GestureUtils.matchSecondaryDownMove, _resetOnMatch: false}),
            secondaryUp: declare([EventListDiscreteRecognizer], {_matchFcn: GestureUtils.matchSecondaryDownUp, _resetOnMatch: true}),
            dragStart: declare([EventListDiscreteRecognizer], {_matchFcn: GestureUtils.matchDragStart, _resetOnMatch: true})
        },
        blockers: {
            tripleTap: ["doubleTap", "tap"],
            doubleTap: ["tap"],
            doubleTapDown: ["tapDown", "down"],
            tapDown: ["down"],
            doubleTapMove: ["tapMove", "downMove"],
            tapMove: ["downMove"],
            doubleTapUp: ["tapUp", "downUp", "doubleTapMove", "tapMove"],
            tapUp: ["downUp", "tapMove"]
        }
    };

    var continuous = {
        gestures: {
            down: declare([DiscreteSequenceContinuousRecognizer], {
                _recognizerTypes: {
                    start: discrete.events.down,
                    progress: [discrete.events.downMove],
                    end: [discrete.events.downUp],
                    cancel: [discrete.events.cancel, discrete.events.dragStart]
                }
            }),
            tapDown: declare([DiscreteSequenceContinuousRecognizer], {
                _recognizerTypes: {
                    start: discrete.events.tapDown,
                    progress: [discrete.events.tapMove],
                    end: [discrete.events.tapUp],
                    cancel: [discrete.events.cancel, discrete.events.dragStart]
                }
            }),
            doubleTapDown: declare([DiscreteSequenceContinuousRecognizer], {
                _recognizerTypes: {
                    start: discrete.events.doubleTapDown,
                    progress: [discrete.events.doubleTapMove],
                    end: [discrete.events.doubleTapUp],
                    cancel: [discrete.events.cancel, discrete.events.dragStart]
                }
            }),
            secondaryDown: declare([DiscreteSequenceContinuousRecognizer], {
                _recognizerTypes: {
                    start: discrete.events.secondaryDown,
                    progress: [discrete.events.secondaryMove],
                    end: [discrete.events.secondaryUp],
                    cancel: [discrete.events.cancel, discrete.events.dragStart]
                }
            })
        },
        blockers: {
            doubleTapDown: ["tapDown", "down"],
            tapDown: ["down"]
        }
    };

    return {
        continuous: continuous,
        discrete: discrete
    };

});