define([
    "dojo/_base/declare",
    "mw-filebrowser/actions/Action",
    "mw-keybindings/DefaultKeys",
    "mw-filename-utils/FileNameUtil",
    "dojo/i18n!nls/mldoStringResource",
    "dojo/string"
], function (declare, Action, DefaultKeys, FileNameUtil, MLDOStrings, DojoString) {
  return declare(Action, {
    constructor: function (args) {
        args = args || {};
        var context = args.context;
        var makeActionAvailable = args.showAction || false;
        this.folderDownloadEnabled = args.folderDownloadEnabled || false;
        this.name =  "downloadAction";
        this.label = MLDOStrings.contextMenuDownloadLabel;
        this.enabled = makeActionAvailable;
        this.section = {
            id: "MISC",
            name: "MISC",
            allowSubMenu: false,
            forceSubMenu: false
        };
        this.getInvitationDetailsMethod = args.getInvitationDetailsMethod;
        this.isApplicableTo = function (path) {
          var selectedFileCount = context.getSelectedRowCount();
          var type = 'TYPE_FILE';
          if (selectedFileCount === 0) {
            return true;
          } else {
            var fileInfos = context.getSelectedItemFileInfos();
            var fileInfo;
            for (var i = 0; i < fileInfos.length; i++) {
              fileInfo = fileInfos[i];
              if (fileInfo.isDirectory) {
                if (fileInfo.isSharedFolder) {
                  type = 'TYPE_SHAREDFOLDER';
                } else {
                  type = 'TYPE_FOLDER';
                }
                break;
              }
            }
            if (type === 'TYPE_FILE' || (this.folderDownloadEnabled && (type === 'TYPE_FOLDER' || type === 'TYPE_SHAREDFOLDER') && selectedFileCount <=1)) {
              this.enabled = makeActionAvailable;
            } else {
              this.enabled = false;
            }
          }
          return this.enabled;
        };
        this.callback = function () {
          var fileInfos = context.getSelectedItemFileInfos();
          var path;
          var invitationId;
          if (this.isApplicableTo()) {
            context.cleanupDownloadIframes();
            if (this.getInvitationDetailsMethod && typeof this.getInvitationDetailsMethod === "function" && this.getInvitationDetailsMethod()) {
              invitationId = this.getInvitationDetailsMethod().invitationId;
            }
            if (!fileInfos || fileInfos.length === 0) {
              path = context.getCurrentFolder();
              context.handleDownloadAction(path, invitationId);
            } else if (fileInfos.length === 1 && fileInfos[0]) {
              var currentFolder = context.getCurrentFolder();
              path = FileNameUtil.pathFromFileInfo(fileInfos[0]);
              if (currentFolder !== "/") {
                path = currentFolder + path;
              }
              context.handleDownloadAction(path, invitationId);
            } else if (fileInfos.length > 1) {
              context.handleMultiSelectDownload(fileInfos, invitationId);
            }
          }
        };
    }
  });

});
