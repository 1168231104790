// Copyright 2016-2017 MathWorks, Inc.

define([
    "dojo/_base/declare",
    "dojo/i18n!../l10n/nls/FileBrowserResources",
    "./helpers/NameComparator",
    "./helpers/DateComparator"
], function (declare, FileBrowserResources, NameComparator, DateComparator) {
    function formatDate(utcDate) {
        var date = new Date(utcDate);
        return date.toLocaleDateString() + " " + date.toLocaleTimeString(navigator.language, {
                hour: '2-digit',
                minute: '2-digit'
            });
    }

    return declare([], {
        constructor: function (args) {
            args = args || {};
            this.column = {
                id: "dateModifiedColumn",
                label: FileBrowserResources.dateModified,
                renderHeaderCell: function (th) {
                    var dateModifiedColumn = document.createElement("div");
                    dateModifiedColumn.className = "columnHeaderLabel";
                    var labelNode = document.createTextNode(FileBrowserResources.dateModified);
                    dateModifiedColumn.appendChild(labelNode);
                    th.appendChild(dateModifiedColumn);

                },
                field: "modifiedUtc",
                sortable: true,
                renderCell: function (fileInfo, data, td, options) {
                    var cellData = document.createElement("span");
                    cellData.className = "dgrid-cell-data";

                    if (!fileInfo.isGroupHeader) {
                        var dateModified = document.createTextNode(formatDate(fileInfo.modifiedUtc));
                        cellData.appendChild(dateModified);
                    }
                    return cellData;
                },
                sort: function (a, b) {
                    var aDate = new Date(a.modifiedUtc);
                    var bDate = new Date(b.modifiedUtc);
                    if (a.isDirectory || b.isDirectory) {
                        return DateComparator.separateFoldersFromFiles(a, b);
                    } else if (aDate === bDate) {
                        return NameComparator.caseSensitiveStringComparator(a.name, b.name);
                    } else {
                        return DateComparator.compareDate(aDate, bDate);
                    }
                },
                hidden: true
            };
        }
    });
});