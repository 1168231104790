define({
    indexPageTitle: `登录 MATLAB Drive - MATLAB & Simulink - MathWorks`,
    indexPageToggleNavigation: `切换主导航`,
    indexPageStoreAnywhere: `从任何位置存储、访问和管理您的文件。`,
    indexPageLearnMore: `了解有关 MATLAB Drive 的更多信息。`,
    indexPageInstallConnector: `安装 MATLAB Drive Connector。`,
    loggedInPageTitle: `MATLAB Drive`,
    rootFolderNameMatlabDrive: `MATLAB Drive`,
    incompatibleBrowser: `不兼容的浏览器`,
    moreFolders: `更多文件夹`,
    footerPatents: `专利`,
    footerTrademarks: `商标`,
    footerPrivacy: `隐私政策`,
    footerPiracy: `防盗版`,
    filesPageUserFirstNameFiles: `文件`,
    filesPageFacetsFiles: `文件`,
    filesPageFacetsSharing: `共享内容`,
    filesPageFacetsDeletedFiles: `已删除的文件`,
    filesPageToggleNavigation: `切换主导航`,
    filesPageDownloadConnectorLinkText: `安装 MATLAB Drive Connector`,
    filesPageLogoutLabel: `注销`,
    filesPageHelpLabel: `帮助`,
    filesPageUploadLabel: `上传`,
    filesPageUploadFilesLabel: `文件`,
    filesPageUploadFolderLabel: `文件夹`,
    filesPageNewFolderLabel: `新建文件夹`,
    filesPageDownloadLabel: `下载`,
    filesPageRenameLabel: `重命名`,
    filesPageCutLabel: `剪切`,
    filesPageCopyLabel: `复制`,
    filesPageCopyToLabel: `复制到`,
    filesPagePasteLabel: `粘贴`,
    filesPageDeleteLabel: `删除`,
    openToolTipText: `在 MATLAB Online 中打开所选内容。`,
    viewToolTipText: `查看此文件。`,
    uploadToolTipText: `将文件从桌面端上传到 MATLAB Drive。`,
    uploadFolderToolTipText: `将文件夹从桌面端上传到 MATLAB Drive。`,
    newFolderToolTipText: `创建文件夹。`,
    downloadToolTipText: `将文件从 MATLAB Drive 下载到您的桌面端。`,
    renameToolTipText: `重命名所选项。`,
    moveToolTipText: `移动所选项。`,
    cutToolTipText: `将所选项复制到剪贴板。`,
    copyToolTipText: `复制所选项。`,
    pasteToolTipText: `粘贴到所选文件夹。`,
    deleteToolTipText: `将所选内容移动到“已删除的文件”。`,
    viewInNewTabToolTipText: `在新标签页中查看文件。`,
    shareToolTipText: `通过邀请或仅限查看链接共享所选内容。`,
    inviteToolTipText: `邀请特定人员共享。`,
    manageToolTipText: `管理此共享文件夹的用户。`,
    linkToolTipText: `通过仅限查看链接共享文件夹。`,
    leaveToolTipText: `离开共享文件夹`,
    filesPageShareLabel: `共享`,
    filesPageInviteLabel: `邀请成员`,
    filesPageManageLabel: `管理成员`,
    filesPageViewMembersLabel: `查看成员`,
    filesPageLinkLabel: `链接`,
    filesPageCreateLinkLabel: `创建链接`,
    filesPageManageLinkLabel: `管理链接`,
    filesPageViewLinkLabel: `查看链接`,
    filesPageLeaveLabel: `离开`,
    filesPageMoveLabel: `移动到`,
    trashPageRestoreFolderDialogTitle: `还原文件夹`,
    trashPageRestoreFolderWarningText: `此文件夹已删除。`,
    trashPageRestoreFolderDialogText: `要查看此文件夹，请从您的“已删除的文件”中将其还原。`,
    trashPageUserFirstNameDeletedFiles: `已删除的文件`,
    trashPageFacetsFiles: `文件`,
    trashPageFacetsSharing: `共享内容`,
    trashPageFacetsDeletedFiles: `已删除的文件`,
    trashPageToggleNavigation: `切换主导航`,
    trashPageDownloadConnectorLinkText: `安装 MATLAB Drive Connector`,
    trashPageLogoutLabel: `注销`,
    trashPageHelpLabel: `帮助`,
    trashPageRestoreLabel: `还原`,
    trashPageDeleteLabel: `永久删除`,
    trashPageEmptyTrashLabel: `全部永久删除`,
    trashPageDeletionPolicy: `所有删除的文件将在 30 天后永久删除。`,
    restoreToolTipText: `将所选内容还原到原始位置。`,
    permanentlyDeleteToolTipText: `永久删除所选项。您将无法再访问此所选项。`,
    permanentlyDeleteAllToolTipText: `永久删除“已删除的文件”中的所有项目。您将无法再访问这些项目。`,
    sharingPageFacetsFiles: `文件`,
    sharingPageFacetsSharing: `共享内容`,
    sharingPageFacetsDeletedFiles: `已删除的文件`,
    sharingPageUserFirstNameFiles: `共享内容`,
    sharingPageToggleNavigation: `切换主导航`,
    sharingPageDownloadConnectorLinkText: `下载 MATLAB Drive Connector`,
    sharingPageLogoutLabel: `注销`,
    sharingPageHelpLabel: `帮助`,
    sharingPagePendingInvitationsGroupLegend: `待定邀请`,
    sharingPageCurrentlySharedGroupLegend: `当前共享`,
    sharingPageNoPendingInvitations: `当前您没有待定邀请。`,
    sharingPageInitiator: `共享者 `,
    sharingPageSharedDate: `共享时间 `,
    sharingPageSharedViaLink: `URL`,
    sharingPageUnknownSharedOnDate: `未知`,
    sharingPageNameTitle: `名称`,
    sharingPageAccessTitle: `权限`,
    sharingPagePreviewTitle: `预览`,
    sharingPageCopyToDriveTitle: `复制文件夹`,
    sharingPageDeclineTitle: `拒绝`,
    sharingPageAcceptInvitationNameConflictDialogTitle: `接受 \"\${0}\" 的邀请`,
    sharingPageAcceptInvitationInitialNamePrompt: `指定要用于此共享文件夹的名称。默认为 \${0}。`,
    sharingPageAcceptInvitationNameConflictDialogLabel: `名为 \"\${0}\" 的文件夹已存在。请重命名新文件夹。`,
    sharingPageCopyInvitationNameConflictDialogLabel: `名为 \"\${0}\" 的文件夹已存在。请重命名新文件夹。`,
    sharingPageCopyInvitationNameConflictDialogTitle: `复制文件夹 \"\${0}\"`,
    sharingPageInvitationNotFound: `找不到共享邀请。它可能已被删除。`,
    sharingPageInvitationAuthorizationError: `共享邀请必须使用与您的 MathWorks 帐户相关联的用户 ID 相同的电子邮件地址。请与文件夹所有者联系以解决此问题。`,
    previewPageFacetsSharing: `共享内容`,
    previewPageAddToDriveLabel: `添加快捷方式`,
    previewPageShareLinkLabel: `共享链接`,
    previewPageGoToDriveLabel: `转至 Drive`,
    addToDriveToolTipText: `添加快捷方式或复制此文件夹。`,
    shareLinkToolTipText: `通过仅限查看链接共享文件夹。`,
    goToDriveToolTipText: `转至您的 MATLAB Drive 中的文件夹`,
    previewPageLoginLabel: `登录`,
    acceptToolTipText: `添加快捷方式将使该文件夹在您的文件中可见，并且您将继续看到对其内容所做的更新。`,
    previewPageAcceptLabel: `接受`,
    declineToolTipText: `拒绝邀请`,
    previewPageDeclineLabel: `拒绝`,
    previewPageAddLabel: `添加到我的文件`,
    copyToDriveToolTipText: `复制此文件夹将在您的文件中创建此文件夹及其内容的非共享副本。`,
    previewPageCopyToDriveLabel: `复制文件夹`,
    previewPageDownloadActionLabel: `下载共享文件夹`,
    previewPageDownloadActionSelectionLabel: `下载所选项`,
    invitationPreviewModalTitle: `共享预览`,
    invitationPreviewInvitationDeclined: `对共享文件夹 \${0} 的邀请被拒绝。`,
    invitationPreviewInvitationAccepted: `共享文件夹 \${0} 已添加到您的 Drive 中。`,
    invitationPreviewInvitationNotExist: `找不到共享内容。它可能已被其所有者删除。`,
    invitationPreviewLoginForPersonalInvitation: `请登录以预览共享内容。`,
    invitationPreviewInvitationAlreadyAddedToDrive: `共享文件夹 \${0} 已添加到您的 Drive 中`,
    invitationPreviewInvitationUndefined: `找不到共享内容。请验证您的 URL。`,
    invitationPreviewAuthorizationError: `您没有访问此共享内容的权限。`,
    panelInfoIcon: `展开以查看有关当前文件夹的信息。`,
    editAccessTypeInfoIcon: `如果您已将其快捷方式添加到 Drive，您将能够编辑共享文件夹及其内容。`,
    viewAccessTypeInfoIcon: `您将能够查看共享文件夹及其内容。要编辑共享文件夹及其内容，请联系其所有者。`,
    ownerAccessTypeInfoIcon: `作为共享文件夹的所有者，您可以编辑其内容、管理其成员以及管理此内容的共享链接。`,
    panelOwnedBy: `所有者`,
    panelAccessType: `访问类型`,
    panelCreatedOn: `创建日期`,
    panelModifiedOn: `修改时间`,
    panelSharedFolderRoot: `共享父级`,
    panelFolderSize: `文件夹大小`,
    panelChildCount: `项目总数`,
    smallTitleSharedBy: `共享者`,
    smallTitleSharedOn: `共享时间`,
    smallTitleAccess: `权限`,
    originalLocationColumnLabel: `原始位置`,
    clientDateModifiedColumnLabel: `修改日期`,
    deletedDateColumnLabel: `删除日期`,
    tombstoneColumnLabel: `Tombstone`,
    sharedOnColumnLabel: `共享时间 `,
    sharedByColumnLabel: `共享者 `,
    accessTypeColumnLabel: `权限`,
    ownedByColumnLabel: `所有者 `,
    contextMenuCutLabel: `剪切`,
    contextMenuCopyLabel: `复制`,
    contextMenuCopyToLabel: `复制到`,
    contextMenuDeleteLabel: `删除`,
    contextMenuDownloadLabel: `下载`,
    contextMenuEmptyTrashLabel: `全部永久删除`,
    contextMenuNewFolderLabel: `新建文件夹`,
    contextMenuPasteLabel: `粘贴`,
    contextMenuPermanentlyDeleteLabel: `永久删除`,
    contextMenuRenameLabel: `重命名`,
    contextMenuRestoreLabel: `还原`,
    contextMenuUploadLabel: `上传`,
    contextMenuUploadFilesLabel: `文件`,
    contextMenuUploadFolderLabel: `文件夹`,
    contextMenuViewFileLabel: `查看`,
    contextMenuViewNewTabLabel: `在新标签页中查看`,
    contextMenuOpenWithLabel: `在 MATLAB Online 中打开`,
    contextMenuShareLabel: `共享`,
    contextMenuManageShareLabel: `共享内容`,
    contextMenuLinkLabel: `链接`,
    contextMenuCreateLinkLabel: `创建链接`,
    contextMenuManageLinkLabel: `管理链接`,
    contextMenuViewLinkLabel: `查看链接`,
    contextMenuInviteLabel: `邀请成员`,
    contextMenuManageLabel: `管理成员`,
    contextMenuViewMembersLabel: `查看成员`,
    contextMenuLeaveLabel: `离开`,
    contextMenuMoveLabel: `移动到`,
    contextMenuShareLinkLabel: `共享链接`,
    createFolderDialogCreateFolderTitle: `新建文件夹`,
    createFolderDialogFolderNameInputLabel: `输入此文件夹的名称：`,
    createFolderDialogInputError2Status: `（错误）`,
    createFolderDialogButtonCancel: `取消`,
    createFolderDialogButtonCreate: `创建`,
    createFolderDialogValidationText: `文件夹名称不能包含字符 \${arg1}`,
    createFolderDialogResourceAlreadyExists: `同名文件或文件夹已存在。请选择其他名称。`,
    createFolderDialogIllegalPathElement: `文件夹名称包含不允许使用的字符。`,
    createFolderDialogFolderCreated: `\${arg1} 已添加到 \${arg2}。`,
    initiateSharingDialogResourceNotSharable: `\"\${0}\" 无法共享。它是共享文件夹的父文件夹，或由于其他原因而受到限制。`,
    initiateSharingDialogTitle: `共享 \"\${0}\"`,
    initiateSharingDialogTitleCreateLinks: `创建 \"\${0}\" 的链接`,
    initiateSharingDialogTitleManageLinks: `管理 \"\${0}\" 的链接`,
    initiateSharingDialogTitleViewLinks: `通过链接共享 \"\${0}\"`,
    initiateSharingDialogTitleManage: `管理 \"\${0}\" 的成员`,
    initiateSharingDialogTitleInvite: `邀请成员共享 \"\${0}\"`,
    initiateSharingDialogTitleView: `查看 \"\${0}\" 的成员`,
    initiateSharingDialogFolderNameInputLabel: `邀请`,
    initiateSharingDialogCurrentlySharedWithLabel: `共享的用户`,
    initiateSharingDialogNoParticipants: `当前没有参与者。`,
    initiateSharingDialogNoParticipantsButHasLink: `当前没有亲自邀请的参与者，但存在公共链接。`,
    initiateSharingDialogCreateLink: `创建仅限查看链接`,
    initiateSharingDialogCopyLink: `复制链接`,
    initiateSharingDialogDeleteLink: `删除链接`,
    initiateSharingDialogButtonShare: `应用`,
    initiateSharingDialogButtonClose: `关闭`,
    initiateSharingDialogButtonReturn: `后退`,
    initiateSharingDialogValidationEmptyEmailAddress: `电子邮件地址不能为空`,
    initiateSharingDialogValidationAtSignText: `电子邮件地址包含多个 \"@\" 字符。`,
    initiateSharingDialogInvalidEmailTooLong: `电子邮件地址超过 70 个字符。`,
    initiateSharingDialogInvalidEmailIllegal: `电子邮件地址包含非法字符序列。`,
    initiateSharingDialogInvalidEmailDomain: `电子邮件地址 \${0} 无效。正确的格式是 name@example.com。`,
    initiateSharingDialogInvalidEmailToSelf: `不允许向您自己发送邀请。`,
    initiateSharingDialogIllegalPathElement: `电子邮件地址包含不允许使用的字符。`,
    initiateSharingDialogFolderShared: `\${0} 已添加到 \"\${1}\"。`,
    initiateSharingDialogPermission_read_write: `可以编辑`,
    initiateSharingDialogPermission_read_only: `可以查看`,
    initiateSharingDialogPermission_manage_access: `可以管理`,
    initiateSharingDialogPermission_owner: `所有者`,
    initiateSharingDialogStatusPending: `待定`,
    initiateSharingDialogStatusAccepted: `已采纳`,
    initiateSharingDialogStatusDeclined: `已拒绝`,
    initiateSharingDialogStatusNewInvite: `正在发送...`,
    initiateSharingDialogErrorWarning: `尝试共享时出错。有关详细信息，请参阅以下列表。`,
    initiateSharingDialogSharingSuccessful: `\${0} 已成功共享给：`,
    initiateSharingDialogSomeErrorsWarning: `\${0} 已成功共享，但出现一些错误。有关详细信息，请参阅以下列表。`,
    initiateSharingDialogLinkCopied: `仅限查看链接已复制到剪贴板。`,
    initiateSharingDialogCreateLinkFailed: `您尝试创建的链接已存在。`,
    initiateSharingDialogCopyLinkFailed: `无法复制链接：\${0}。`,
    initiateSharingDialogManuallyCopyLink: `选择并复制显示的 URL。`,
    initiateSharingDialogConfirmLinkDelete: `删除仅限查看链接？`,
    initiateSharingDialogConfirmCollaboratorDelete: `从共享文件夹中删除 \"\${0}\"？`,
    initiateSharingDialogDeleteCollaboratorWarning: `\"\${0}\" 将无法访问共享文件夹或其内容。`,
    initiateSharingDialogDeleteLinkButton: `删除`,
    initiateSharingDialogRemovePermissionButton: `移除`,
    initiateSharingDialogCancelButton: `取消`,
    initiateSharingDialogDeleteWarning: `拥有该链接的任何人都将无法访问该文件夹或其内容。`,
    initiateSharingDialogDeleteCollaboratorSuccessful: `已从共享文件夹中删除 \"\${0}\"。`,
    initiateSharingDialogAddUserButtonLabel: `发送`,
    initiateSharingDialogNoLinksAvailable: `此文件夹并未通过链接与特定人员共享，因此没有可用的链接。`,
    initiateSharingDailogInviteeAlreadyInList: `此电子邮件地址的此文件夹邀请已存在。`,
    initiateSharingDailaogEmailAlreadyInList: `您已为此文件夹向此电子邮件地址发出邀请。`,
    initiateSharingDailogEnterEmailAddress: `输入电子邮件地址`,
    initiateSharingDialogEnterEmailComments: `添加消息（可选）`,
    initiateSharingDailogExplainCreateLink: `创建链接来为其他人提供文件夹 \${0} 的仅查看访问权限。`,
    initiateSharingDailogExplainCopyLink: `分发链接来为其他人提供文件夹 \${0} 的仅查看访问权限。`,
    initiateSharingDailogExplainDeleteLink: `删除共享内容的链接和对其的访问权限。不影响收到个人邀请的成员访问。`,
    initiateSharingDialogmultiEmailTextWarning: `使用分号或以逗号分隔多个电子邮件`,
    initiateSharingDialogIllegalFolderName: `文件夹名称 \${0} 包含不支持的 \".\"、\"..\"、\"\\\\\" 或控制字符。`,
    initiateSharingDialogNoPermToCreateLink: `您没有权限为此文件夹创建邀请。`,
    initiateSharingDialogNoPermToDeleteLink: `您没有权限删除此文件夹的共享邀请。`,
    initiateSharingDialogLinkFolderDoesNotExist: `\${0} 不存在。它可能已被删除或移动。`,
    initiateSharingDialogInvitationDoesNotExist: `找不到共享邀请。它可能已被删除。`,
    initiateSharingDialogNoPermToUpdateInvitation: `此时无法更新共享邀请。`,
    initiateSharingDialogActiveUsersLabel: `活动用户： `,
    initiateSharingDialogUpdateAccessTypeButtonLabel: `保存`,
    initiateSharingDialogUpdateAccessTypeError: `无法更新 \${0} 个受邀人的访问类型。`,
    initiateSharingDialogUpdateAccessTypeSuccessful: `已成功更新 \${0} 个受邀人的访问类型。`,
    initiateSharingDialogUpdateAccessTypePartial: `无法更新 \${0} 个受邀人的访问类型，已更新 \${1} 个受邀人的访问类型。`,
    userQuotaStorageFull: `存储空间已满。请删除文件以释放空间。`,
    userQuotaServerError: `出现服务器错误。请稍后重试。`,
    userQuotaUnableToFetchData: `无法获取数据。`,
    userQuotaAmountUsed: `已使用 \${0} \${1} / \${2} \${3}`,
    actionAborted: `操作已中止。`,
    actionHandlerFile: `文件`,
    actionHandlerFiles: `文件`,
    actionHandlerFolder: `文件夹`,
    actionHandlerItem: `项目`,
    actionHandlerItems: `项目`,
    actionHandlerCannotDisplayMsgTooBig: `文件太大，无法显示。请下载文件以查看其内容。`,
    actionHandlerCannotDisplayMsgWrongType: `此文件的预览不可用。请下载文件以查看其内容。`,
    actionHandlerCannotDisplayMsgSecurity: `不允许预览此文件。请下载文件以查看其内容。`,
    actionHandlerViewFilePleaseWaitText: `正在加载...`,
    actionHandlerViewFileDownloadTimeOut: `下载超时。请重试。`,
    actionHandlerDownloadInProgress: `已成功开始下载。如果您正在下载大量文件/文件夹，这可能需要几分钟时间。`,
    actionHandlerViewFileFileNotExists: `找不到指定的文件/文件夹。它可能已被删除或移动。`,
    actionHandlerMaxConcurrentDownloads: `同时最多允许 \${0} 个下载。请选择少些文件。`,
    actionHandlerPastedTo: `\${0} 已粘贴到 \${1}。 `,
    actionHandlerPasteFailed: `\${1} 中已存在同名的 \${0}。请粘贴到不同位置或为 \${2} 指定唯一名称。`,
    actionHandlerMultiPasteSuccess: `已将 \${0} \${1} 粘贴到 \${2}。 `,
    actionHandlerMultiPasteFailed: `无法将 \${0} \${1} 粘贴到 \${2}。 `,
    actionHandlerMultiMoveSuccess: `已将 \${0} \${1} 移动到 \${2}。 `,
    actionHandlerMultiMoveFailed: `无法将 \${0} \${1} 移动到 \${2}。 `,
    actionHandlerCopiedTo: `\${0} 已复制到 \${1}。 `,
    actionHandlerCopyFailed: `\${1} 中已存在同名的 \${0}。请复制到不同位置或为 \${2} 指定唯一名称。`,
    actionHandlerMultiCopySuccess: `已将 \${0} \${1} 复制到 \${2}。 `,
    actionHandlerMultiCopyFailed: `无法将 \${0} \${1} 复制到 \${2}。 `,
    actionHandlerMultiDeleteSuccess: `已删除 \${0} 个项目。 `,
    actionHandlerMultiDeleteFailed: `\${0} 个项目无法删除 。 `,
    actionHandlerSingleDeleteSuccess: `\${0} 已移动到“已删除的文件”。`,
    actionHandlerSingleSharedFolderDeleteSuccess: `共享文件夹 \${0} 已移动到“已删除的文件”，但这仅适用于您。其他成员将继续拥有访问权限。`,
    actionHandlerSingleSharedFolderOwnerDeleteSuccess: `共享文件夹 \${0} 已移动到您的“已删除的文件”，但这仅适用于您。其他成员将继续拥有访问权限。`,
    actionHandlerSingleDelectFailed: `无法将 \${0} 移动到“已删除的文件”：\${1}`,
    actionHandlerGoToLocation: `转至位置`,
    actionHandlerServerError: `服务器错误`,
    actionHandlerNameConflictPrompt: `\${1} 中已存在名为 \${0} 的文件或文件夹。请提供新名称。 `,
    actionHandlerRenamePromptTitle: `还原 \${0}`,
    actionHandlerRenamePromptLabel: `已存在同名的 \${0}。请重命名 \${1}。 `,
    actionHandlerPerformRestoreSuccess: `\${0} 已还原到 \${1}。`,
    actionHandlerPerformRestoreFailed: `无法将 \${0} 还原到位置 \${1}：\${2}`,
    actionHandlerPerformRestoreUnknownFailure: `无法还原 \${0}：\${1}`,
    actionHandlerMultiRestoreSuccess: `已还原 \${0} 个项目。 `,
    actionHandlerMultiRestoreFailed: `无法还原 \${0} 个项目。 `,
    actionHandlerSinglePermanentDeleteSuccess: `\${0} 已永久删除。`,
    actionHandlerSinglePermanentDeleteSharedFolderSuccess: `已删除共享文件夹 \${0}，但这仅适用于您。`,
    actionHandlerSingleUnshareAndPermanentDeleteSharedFolderSuccess: `共享文件夹 \${0} 已取消共享并已删除。`,
    actionHandlerSinglePermanentDeleteSharedLinkFolderSuccess: `已删除共享文件夹 \${0}，但这仅适用于您。请使用原始 URL 重新加入。`,
    actionHandlerSinglePermanentDeleteFailed: `无法删除 \${0}：\${1}`,
    actionHandlerMultiPermanentDeleteSuccess: `\${0} 个项目已永久删除。`,
    actionHandlerMultiPermanentDeleteFailed: `无法删除 \${0} 个项目：\${1}`,
    actionHandlerGeneralDeleteFailed: `删除失败：\${0}`,
    actionHandlerTrashEmptied: `“已删除的文件”的内容已永久删除。`,
    actionHandlerTrashEmptyFailed: `无法清空“已删除的文件”：\${0}`,
    actionHandlerDeleteDialogTitle: `永久删除 \${0}`,
    actionHandlerDeleteDialogFilePrompt: `是否确定要永久删除 <b>\${0}</b>？<br>注意：共享内容不会被删除。`,
    actionHandlerDeleteDialogFolderPrompt: `<b>无法还原文件夹及其内容。</b>其他成员将继续拥有对 \${0} 中共享文件夹的访问权限。`,
    actionHandlerDeleteDialogSharedFolderPrompt: `您将无法还原共享文件夹 \${0} 或其内容。<br/>其他成员将继续拥有访问权限。`,
    actionHandlerDeleteDialogSharedFolderOwnerPrompt: `<b>您将无法还原共享文件夹 \${0} 或其内容。</b><br/>其他成员将继续拥有访问权限。`,
    actionHandlerDeleteDialogShouldUnshareFolderLabel: `取消与当前成员共享文件夹 \${0}`,
    actionHandlerDeleteDialogShouldUnshareMultipleFoldersLabel: `取消与当前成员共享所有选定文件夹`,
    actionHandlerDeleteDialogShouldUnshareFolderExplanation: `共享文件夹的成员将无法访问`,
    actionHandlerDeleteDialogShouldUnshareMultipleFoldersExplanation: `这些共享文件夹的成员将无法访问`,
    actionHandlerDeleteDialogShouldUnshareSubFoldersLabel: `取消共享 \${0} 中的所有共享文件夹`,
    actionHandlerDeleteDialogShouldUnshareMultipleSubFoldersLabel: `取消共享此选择中包含的任何共享文件夹`,
    actionHandlerDeleteDialogShouldUnshareSubFoldersExplanation: `这些共享文件夹的成员将无法访问`,
    actionHandlerDeleteDialogFolderContentsToo: ` 及其内容`,
    actionHandlerDeleteDialogDeleteButtonlabel: `永久删除`,
    actionHandlerDeleteDialogCancelButtonLabel: `取消`,
    actionHandlerDeleteDialogMultiTitle: `删除项目`,
    actionHandlerDeleteDialogMultiSingularPrompt: `删除此项目？`,
    actionHandlerDeleteDialogMultiPluralPrompt: `删除这些项目？`,
    actionHandlerPermanentlyDeleteAllDialogTitle: `全部永久删除`,
    actionHandlerDeleteAllDialogPrompt: `<b>无法还原文件夹及其内容。</b><br/>共享文件夹的成员将继续拥有访问权限。`,
    actionHandlerUndo: `撤消`,
    actionHandlerOpenWithLoadFailed: `打开方式服务无法加载 \${0}。`,
    actionHandlerLargeOperationNotification: `大文件操作正在进行。这时某些文件将不可用。请刷新页面以查看更新。`,
    actionHandlerIllegalMoveOrCopyTarget: `无法移动或复制 \${0}。无法将其移动或复制到自身或其子文件夹中。`,
    actionHandlerMovedTo: `已将 \${0} 移动到 \${1}。 `,
    actionHandlerMoveMany: `\${0} 个项目`,
    fbTreeResourceAlreadyExists: `同名文件或文件夹已存在。请重命名 \${0}。`,
    fbTreeFile: `文件`,
    fbTreeFiles: `文件`,
    fbTreeFolder: `文件夹`,
    fbTreeIllegalPath: `该名称包含不允许使用的字符。请选择其他名称。`,
    fbTreeShouldNotContainFolders: `请确保所选项不包含文件夹。`,
    fsServerError: `出现服务器错误。请稍后重试。`,
    fsFolderNotFoundResetting: `找不到指定的文件夹 \${0}。将重置到 \"MATLAB Drive\"。`,
    fsFolderNotFound: `找不到指定的文件夹。它可能已被删除或移动。`,
    fsMovedFileTo: `已将 \${0} 移动到 \${1}。`,
    fsCopiedFileTo: `已将 \${0} 复制到 \${1}。`,
    fsMovedFileToFailed: `无法将 \${0} 移动到 \${1}。`,
    fsCopiedFileToFailed: `无法将 \${0} 复制到 \${1}。`,
    fsFile: `文件`,
    fsFolder: `文件夹`,
    fsResourceAlreadyExists: `同名文件或文件夹已存在。请移动到其他位置或为 \${0} 指定唯一名称。`,
    fsIllegalPathElement: `该名称包含不允许使用的字符。请选择其他名称。`,
    fsUnknownError: `未知错误`,
    fsItem: `项目`,
    fsItems: `项目`,
    fsMultiMoveSuccess: `已将 \${0} \${1} 移动到 \${2}。 `,
    fsMultiCopySuccess: `已将 \${0} \${1} 复制到 \${2}。 `,
    fsMultiMoveFailed: `无法将 \${0} \${1} 移动到 \${2}。 `,
    fsMultiCopyFailed: `无法将 \${0} \${1} 复制到 \${2}。 `,
    fsNoMoveAccess: `您拥有“可查看”访问权限，但无法修改共享文件夹 \${0} 的内容。`,
    fsAcceptInvitationFailed: `无法接受邀请：\${0}`,
    fsDeclineInvitationFailed: `无法拒绝邀请：\${0}`,
    fsFollowLinkFailed: `无法跟踪链接：\${0}`,
    fsFailedToGetPendingInvitations: `无法获取待定邀请：\${0}。`,
    fsFailedToGetFolderSettings: `无法获取文件夹设置：\${0}。`,
    fsNotAuthorized: `您无权查看此资源。`,
    apsInvalidToken: `AuthNZ 返回的 apsToken 值无效`,
    apsInvalidResponse: `从 ApsAuthNZ 服务器收到的响应无效`,
    apsActionPermanentlyDeletedFrom: `已从 \${1} 中永久删除 \${0}。`,
    apsActionDeletedFrom: `已从 \${1} 中删除 \${0}。`,
    apsActionMovedTo: `\${0} 已移动到 \${1}。`,
    apsActionUploadedTo: `\${0} 已上传到 \${1}。`,
    apsActionRestoredTo: `\${0} 已还原到 \${1}。`,
    apsActionCreatedIn: `已在 \${1} 中创建 \${0}。`,
    apsActionRenamedTo: `\${0} 已重命名为 \${1}。`,
    apsActionInvitationChangedOn: `\${0} 已移动到 \${1}。`,
    apsActionCopiedTo: `\${0} 已复制到 \${1}。`,
    renamePromptInvalidCharacters: `名称不能包含字符 \${0}`,
    renameAcceptLabel: `接受`,
    renameButtonLabel: `重命名`,
    renameCancelButtonLabel: `取消`,
    renameErrorLabel: `（错误）`,
    viewFile: `查看`,
    viewCloseButton: `关闭`,
    viewDeleteAction: `删除`,
    viewDownloadAction: `下载`,
    viewInNewTab: `在新标签页中查看`,
    viewOpenLiveEditor: `在 MATLAB Online 中打开`,
    viewOpenLiveEditorMock: `无法打开 \"\${0}\"。`,
    viewOpenLiveEditorNotSupported: `此环境不支持 \"在 MATLAB Online 中打开\" \n功能。`,
    simpleUploadTitle: `将文件上传到 \${0}`,
    simpleUploadFolderTitle: `将文件夹上传到 \${0}`,
    simpleUploadFilesSelectionText: `选择要上传的文件`,
    simpleUploadFolderSelectionText: `选择要上传的文件夹。`,
    simpleUploadEmptyFolderUploadWarning: `（空文件夹无法上传）`,
    simpleUploadStatusText: `正在上传...`,
    simpleUploadUploadCancelButtonLabel: `取消`,
    simpleUploadUploadButtonLabel: `上传`,
    simpleUploadCloseButtonLabel: `关闭`,
    simpleUploadFileUploadSuccess: `文件上传成功。`,
    simpleUploadStatusCancelled: `上传已取消。  `,
    simpleUploadStatusWithErrors: `上传已完成，但有错误。  `,
    simpleUploadSummary: `\${0} 个已完成，\${1} 个已取消，\${2} 个失败。`,
    simpleUploadSummaryNoCancelled: `\${0} 个已完成，\${1} 个失败。`,
    simpleUploadSummaryNoFailed: `\${0} 个已完成，\${1} 个已取消。`,
    simpleUploadSummaryNoCompleted: `\${0} 个已取消，\${1} 个失败。`,
    simpleUploadSummaryNoCancelledNoFailed: `\${0} 个已完成。`,
    simpleUploadSummaryNoCompletedNoCancelled: `\${0} 个失败。`,
    simpleUploadSummaryNoCompletedNoFailed: `\${0} 个已取消。`,
    simpleUploadCompletedText: `上传已完成。  `,
    simpleUploadFile: `文件`,
    simpleUploadFiles: `文件`,
    simpleUploadNumCompleted: `\${0} 个 \${1} 已上传。`,
    simpleUploadCancelOnly: `上传到 \${1} 的 \${0} 个上传已取消。`,
    simpleUploadCancelFail: `\${0} 个上传已取消，\${1} 个文件无法上传到 \${2}。`,
    simpleUploadCancelUpload: `\${0} 个上传已取消，\${1} 个文件已上传到 \${2}。`,
    simpleUploadCancelFailUpload: `\${0} 个上传已取消，\${1} 个文件无法上传，\${2} 个文件已上传到 \${3}。`,
    simpleUploadNumberFailed: `\${0} 个文件无法上传到 \${1}。`,
    simpleUploadFailedUpload: `\${0} 个文件无法上传，\${1} 个文件已上传到 \${2}。`,
    simpleUploadOnlyUpload: `\${0} 个文件已上传到 \${1}。`,
    simpleUploadResourceAlreadyExists: `MATLAB Drive 上已存在同名文件夹，且 MATLAB Drive 不区分大小写。请重命名文件。`,
    simpleUploadResourceDoesNotExist: `上传失败。请重试。`,
    simpleUploadFileTooLarge: `文件大于 MATLAB Drive 允许的大小(1 GB)。请减小文件。`,
    simpleUploadAuthorizationError: `没有权限保存到此文件夹。`,
    simpleUploadQuotaExceeded: `MATLAB Drive 存储空间已满。请删除内容以释放空间。`,
    simpleUploadIllegalPathElement: `文件名包含不允许使用的字符。请重命名文件。`,
    simpleUploadSessionNotFound: `上传失败。请重试。`,
    simpleUploadProgressText: `\${0} 个，共 \${1} 个...`,
    simpleUploadFolderUploadForbidden: `不允许上传文件夹。`,
    simpleUploadFileTooLargeToUpload: `文件太大，无法使用网页端上传。允许的最大文件大小为 \${0}GB。 `,
    simpleUploadCanWithConnector: `此文件可以用 \${0} 添加到 MATLAB Drive。`,
    simpleUploadMatlabDriveConnector: `MATLAB Drive Connector`,
    simpleUploadReasonInvalid: `文件太大，无法使用网页端上传。此文件可以用 MATLAB Drive Connector 添加到 MATLAB Drive。`,
    simpleUploadProgressStatus: `正在上传第 \${0} 个项目，共 \${1} 个...`,
    simpleUploadUploadCancelled: `上传已取消。`,
    simpleUploadInvalidData: `无效数据：无法上传文件。`,
    simpleUploadResolveButtonLabel: `解决`,
    simpleUploadUploadTypeChoiceGroupLabelText: `上传类型： `,
    simpleUploadUploadUploadFilesChoiceLabelText: `文件`,
    simpleUploadUploadUploadDirectoryChoiceLabelText: `文件夹`,
    openInMOTitle: `在 MATLAB Online 中打开 \"\${0}\"`,
    openInMOLabel: `要继续在 MATLAB Online 中打开此文件，请为共享文件夹选择以下操作之一：`,
    openInMOFileInTrash: `您请求在 MATLAB Online 中打开的文件/文件夹在垃圾桶中。请将其还原并重试。`,
    saveToDriveSuccess: `\${0} 已作为 \${2} 成功保存到文件夹 \${1}。`,
    fconflictDialogTitle: `文件冲突`,
    fconflictFolderDialogTitle: `文件夹冲突`,
    fconflictSelectAll: `全选`,
    fconflictReplaceButtonLabel: `替换`,
    fconflictKeepSeparateButtonLabel: `分别保存`,
    fconflictFolderHasConflict: `该文件夹包含 \${0} 个同名文件。`,
    fconflictFolderHasConflicts: `该文件夹包含 \${0} 个同名文件。`,
    fconflictReplaceSuccessAndFailure: `已替换 \${0} 个文件，无法替换 \${1} 个文件。`,
    fconflictReplaceSuccessesAndFailure: `已替换 \${0} 个文件，无法替换 \${1} 个文件。`,
    fconflictReplaceSuccessesAndFailures: `已替换 \${0} 个文件，无法替换 \${1} 个文件。`,
    fconflictReplaceSuccessAndFailures: `已替换 \${0} 个文件，无法替换 \${1} 个文件。`,
    fconflictReplaceSuccessOnly: `已替换 \${0} 个文件。`,
    fconflictReplaceSuccessesOnly: `已替换 \${0} 个文件。`,
    fconflictReplaceFailureOnly: `无法替换 \${0} 个文件。`,
    fconflictReplaceFailuresOnly: `无法替换 \${0} 个文件。`,
    fconflictSaveSuccessesAndFailures: `已保存 \${0} 个文件，无法保存 \${1} 个文件。`,
    fconflictSaveSuccessesAndFailure: `已保存 \${0} 个文件，无法保存 \${1} 个文件。`,
    fconflictSaveSuccessAndFailures: `已保存 \${0} 个文件，无法保存 \${1} 个文件。`,
    fconflictSaveSuccessAndFailure: `已保存 \${0} 个文件，无法保存 \${1} 个文件。`,
    fconflictSaveSuccessesOnly: `已保存 \${0} 个文件。`,
    fconflictSaveSuccessOnly: `已保存 \${0} 个文件。`,
    fconflictSaveFailureOnly: `无法保存 \${0} 个文件。`,
    fconflictSaveFailuresOnly: `无法保存 \${0} 个文件。`,
    fconflictPleaseWait: `请稍候...`,
    restoreButtonLabel: `还原`,
    restoreCancelButtonLabel: `取消`,
    personalInvitationLimitExceeded: `您已达到 MATLAB Drive 中共享文件夹的最大数量。在添加新共享文件夹之前，请从您的 MATLAB Drive 中删除共享文件夹。`,
    resourceInvitationLimitExceeded: `超出成员数量限制。请删除当前成员以添加新成员。`,
    linkResourceInvitationLimitExceeded: `无法添加共享文件夹。超出成员数量限制。请联系所有者。`,
    showHiddenFilesLabel: `显示隐藏文件`,
    chooseApplicationLabel: `选择应用程序：`,
    defaultApplicationLabel: `MATLAB Drive`,
    addonApplicationLabel: `附加功能`,
    thingspeakApplicationLabel: `ThingSpeak`,
    instantAddonsApplicationLabel: `瞬时附加功能`,
    graderApplicationLabel: `MATLAB Grader`,
    otherApplicationLabel: `其他 App`,
    previewBannerText: `预览`,
    meMyself: `本人`,
    folderChooserFileNameLabel: `文件名:`,
    folderChooserChooseButton: `选择`,
    folderChooserChooseMoveButton: `移动`,
    folderChooserChooseCopyButton: `复制`,
    folderChooserCloseButton: `关闭`,
    folderChooserModalTitle: `选择文件夹`,
    moveFolderChooserModalTitle: `将 \${0} 移动到`,
    copyToFolderChooserModalTitle: `将 \${0} 复制到`,
    folderChooserNoSelectionWarning: `在未选择任何内容的情况下点击 \"\${0}\" 按钮将选择 \${1} 文件夹。`,
    folderChooserNoSelectionMoveWarning: `如果没有做出选择，内容将移动到当前文件夹。`,
    folderChooserNoSelectionCopyWarning: `如果没有做出选择，内容将复制到当前文件夹。`,
    folderChooserCurrentRootFolder: `当前`,
    folderChooserRenameSourceNotAllowed: `不允许在移动或复制过程中重命名源路径。`,
    saveToDriveSaveButtonLabel: `保存`,
    saveToDriveCancelButtonLabel: `取消`,
    saveToDriveModalTitle: `保存到 MATLAB Drive`,
    widgetTestAppPageTitle: `嵌入式小组件测试页`,
    widgetTestAppChooseLabel: `选择小组件`,
    widgetTestAppUseMatlabStyle: `使用 MATLAB 桌面端样式`,
    widgetTestAppOverrideCss: `用自定义样式表覆盖 CSS`,
    widgetTestAppCssStylesheetToUse: `要使用的 CSS 样式表的 URL：`,
    widgetTestAppIgnoreResizeMsg: `忽略小组件窗口大小调整消息`,
    widgetTestAppIframeWidthPx: `iframe 宽度（以像素为单位）： `,
    widgetTestAppIframeHeightPx: `iframe 高度（以像素为单位）： `,
    widgetTestAppRunWidgetButton: `运行所选小组件`,
    widgetTestAppResetButton: `重置`,
    widgetTestAppSelectedWidgetContents: `所选小组件测试内容`,
    widgetTestAppLoggedInIntro: `您当前拥有有效的登录凭据。嵌入式小组件将使用这些凭据来获取其数据。`,
    widgetTestAppNotLoggedInIntro: `您当前未登录，因此没有有效凭据。`,
    widgetTestAppWidgetsWillNotWork: `在没有有效登录凭据的情况下，嵌入式小组件将无法工作。`,
    widgetTestAppNeedLogin: `您需要先登录到同一域来获得有效凭据。`,
    widgetTestOptionNoOptions: `没有选项`,
    widgetTestOptionActions: `操作`,
    widgetTestOptionColumns: `列`,
    widgetTestOptionWidgetOptions: `小组件选项`,
    widgetTestOptionTrashWidgetTitle: `垃圾桶内容小组件`,
    widgetTestOptionsColumnName: `名称`,
    widgetTestOptionsColumnSize: `大小`,
    widgetTestOptionStartButton: `启动小组件`,
    widgetTestOptionPleaseWait: `请稍候...`,
    widgetTestOptionResetWidgetButton: `重置小组件选项`,
    widgetTestOptionSearchWidgetTitle: `搜索文件小组件`,
    widgetTestOptionColumnClientDateModified: `客户端修改日期`,
    widgetTestOptionColumnDateModified: `修改日期`,
    widgetTestOptionColumnType: `类型`,
    widgetTestOptionShowShared: `显示共享项目`,
    widgetTestOptionShowHidden: `显示隐藏文件`,
    widgetTestOptionIncludeFolders: `包括子文件夹`,
    widgetTestOptionEnableViewing: `启用查看`,
    widgetTestOptionEnableDnD: `启用拖放`,
    widgetTestOptionEnableNavigation: `启用导航`,
    widgetTestOptionSaveToDriveTitle: `SaveToDrive 小组件`,
    widgetTestOptionUseFolderChooserUI: `使用 FolderChooser UI`,
    widgetTestOptionSaveToDriveResultsLabel: `保存到 Drive 的结果： `,
    widgetTestOptionFullPathPrompt: `输入要保存的文件的完整路径： `,
    widgetTestOptionChooseContentSourcePrompt: `选择文件的内容源： `,
    widgetTestOptionContentChoiceUrl: `URL`,
    widgetTestOptionContentChoiceDirect: `直接内容`,
    widgetTestOptionContentChoiceFromFile: `从文件`,
    widgetTestOptionContentChooseFilesPrompt: `选择文件：`,
    widgetTestOptionUrlPrompt: `输入要使用的内容的 URL：`,
    widgetTestOptionTextPrompt: `输入文本：`,
    widgetTestOptionSaveToDriveButton: `保存到 Drive`,
    widgetTestOptionClearButton: `清除`,
    widgetTestOptionQuotaTitle: `配额小组件`,
    widgetTestOptionInitiateSharingTitle: `InitiateSharing 小组件`,
    widgetTestOptionFolderFullPathPrompt: `输入文件夹的完整路径： `,
    widgetTestOptionLinkButton: `链接`,
    widgetTestOptionManageButton: `管理`,
    widgetTestOptionFolderChooserTitle: `文件夹选择器小组件`,
    widgetTestOptionShowFileNameSelector: `显示文件名选择器`,
    widgetTestOptionChooseButton: `选择`,
    widgetTestOptionSelectedFolder: `所选完整路径： `,
    widgetTestOptionDesiredHeightInPixels: `所需的高度（以像素为单位）： `,
    widgetTestOptionDesiredWidthInPixels: `所需的宽度（以像素为单位）： `,
    widgetTestOptionFileNameSuggestionPrompt: `文件名选择建议： `,
    widgetTestOptionStartingFolderPrompt: `启动文件夹完整路径： `,
    widgetTestOptionFolderPathContentTitle: `FolderPath 内容小组件`,
    widgetTestOptionsSearchNumberOfDays: `从今日开始要向之前搜索的天数： `,
    widgetTestOptionsSearchMaxResults: `最大结果大小： `
});