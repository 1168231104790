define({
    indexPageTitle: `MATLAB Drive에 로그인 - MATLAB 및 Simulink - MathWorks`,
    indexPageToggleNavigation: `기본 탐색 화면 전환`,
    indexPageStoreAnywhere: `어디에서나 파일을 저장하고 액세스하고 관리할 수 있습니다.`,
    indexPageLearnMore: `MATLAB Drive에 대해 자세히 알아봅니다.`,
    indexPageInstallConnector: `MATLAB Drive Connector를 설치합니다.`,
    loggedInPageTitle: `MATLAB Drive`,
    rootFolderNameMatlabDrive: `MATLAB Drive`,
    incompatibleBrowser: `호환되지 않는 브라우저임`,
    moreFolders: `추가 폴더`,
    footerPatents: `특허`,
    footerTrademarks: `상표`,
    footerPrivacy: `개인정보 취급방침`,
    footerPiracy: `불법 복제 방지`,
    filesPageUserFirstNameFiles: `파일`,
    filesPageFacetsFiles: `파일`,
    filesPageFacetsSharing: `공유된 내용`,
    filesPageFacetsDeletedFiles: `삭제된 파일`,
    filesPageToggleNavigation: `기본 탐색 화면 전환`,
    filesPageDownloadConnectorLinkText: `MATLAB Drive Connector 설치`,
    filesPageLogoutLabel: `로그아웃`,
    filesPageHelpLabel: `도움말`,
    filesPageUploadLabel: `업로드`,
    filesPageUploadFilesLabel: `파일`,
    filesPageUploadFolderLabel: `폴더`,
    filesPageNewFolderLabel: `새 폴더`,
    filesPageDownloadLabel: `다운로드`,
    filesPageRenameLabel: `이름 바꾸기`,
    filesPageCutLabel: `잘라내기`,
    filesPageCopyLabel: `복사`,
    filesPageCopyToLabel: `복사`,
    filesPagePasteLabel: `붙여넣기`,
    filesPageDeleteLabel: `삭제`,
    openToolTipText: `MATLAB Online에서 선택 항목을 엽니다.`,
    viewToolTipText: `이 파일을 봅니다.`,
    uploadToolTipText: `데스크탑에서 MATLAB Drive로 파일을 업로드합니다.`,
    uploadFolderToolTipText: `데스크탑에서 MATLAB Drive로 폴더를 업로드합니다.`,
    newFolderToolTipText: `폴더를 만듭니다.`,
    downloadToolTipText: `MATLAB Drive에서 데스크탑으로 파일을 다운로드합니다.`,
    renameToolTipText: `선택 항목의 이름을 바꿉니다.`,
    moveToolTipText: `선택 항목을 이동합니다.`,
    cutToolTipText: `선택 항목을 클립보드에 복사합니다.`,
    copyToolTipText: `선택 항목을 복사합니다.`,
    pasteToolTipText: `선택한 폴더에 붙여 넣습니다.`,
    deleteToolTipText: `선택 항목을 [삭제된 파일]로 이동합니다.`,
    viewInNewTabToolTipText: `새 탭에서 파일을 봅니다.`,
    shareToolTipText: `초대 또는 보기 전용 링크를 통해 선택 항목을 공유합니다.`,
    inviteToolTipText: `공유할 특정 사용자를 초대합니다.`,
    manageToolTipText: `이 공유 폴더의 사용자를 관리합니다.`,
    linkToolTipText: `보기 전용 링크를 통해 폴더를 공유합니다.`,
    leaveToolTipText: `공유 폴더에서 나갑니다`,
    filesPageShareLabel: `공유`,
    filesPageInviteLabel: `구성원 초대`,
    filesPageManageLabel: `구성원 관리`,
    filesPageViewMembersLabel: `구성원 보기`,
    filesPageLinkLabel: `링크`,
    filesPageCreateLinkLabel: `링크 만들기`,
    filesPageManageLinkLabel: `링크 관리`,
    filesPageViewLinkLabel: `링크 보기`,
    filesPageLeaveLabel: `나가기`,
    filesPageMoveLabel: `이동`,
    trashPageRestoreFolderDialogTitle: `폴더 복원`,
    trashPageRestoreFolderWarningText: `이 폴더는 삭제되었습니다.`,
    trashPageRestoreFolderDialogText: `이 폴더를 보려면 삭제된 파일에서 폴더를 복원하십시오.`,
    trashPageUserFirstNameDeletedFiles: `삭제된 파일`,
    trashPageFacetsFiles: `파일`,
    trashPageFacetsSharing: `공유된 내용`,
    trashPageFacetsDeletedFiles: `삭제된 파일`,
    trashPageToggleNavigation: `기본 탐색 화면 전환`,
    trashPageDownloadConnectorLinkText: `MATLAB Drive Connector 설치`,
    trashPageLogoutLabel: `로그아웃`,
    trashPageHelpLabel: `도움말`,
    trashPageRestoreLabel: `복원`,
    trashPageDeleteLabel: `영구 삭제`,
    trashPageEmptyTrashLabel: `모두 영구 삭제`,
    trashPageDeletionPolicy: `삭제된 모든 파일은 30일 후에 영구히 삭제됩니다.`,
    restoreToolTipText: `선택 항목을 원래 위치에 복원합니다.`,
    permanentlyDeleteToolTipText: `선택 항목을 영구히 제거합니다. 더 이상 이 선택 항목에 액세스할 수 없게 됩니다.`,
    permanentlyDeleteAllToolTipText: `[삭제된 파일] 내 모든 항목을 영구히 제거합니다. 이러한 항목에 더 이상 액세스할 수 없게 됩니다.`,
    sharingPageFacetsFiles: `파일`,
    sharingPageFacetsSharing: `공유된 내용`,
    sharingPageFacetsDeletedFiles: `삭제된 파일`,
    sharingPageUserFirstNameFiles: `공유된 내용`,
    sharingPageToggleNavigation: `기본 탐색 화면 전환`,
    sharingPageDownloadConnectorLinkText: `MATLAB Drive Connector 다운로드`,
    sharingPageLogoutLabel: `로그아웃`,
    sharingPageHelpLabel: `도움말`,
    sharingPagePendingInvitationsGroupLegend: `대기 중인 초대`,
    sharingPageCurrentlySharedGroupLegend: `현재 공유됨`,
    sharingPageNoPendingInvitations: `현재 대기 중인 초대가 없습니다.`,
    sharingPageInitiator: `공유한 사람 `,
    sharingPageSharedDate: `공유 날짜 `,
    sharingPageSharedViaLink: `URL`,
    sharingPageUnknownSharedOnDate: `알 수 없음`,
    sharingPageNameTitle: `이름`,
    sharingPageAccessTitle: `액세스`,
    sharingPagePreviewTitle: `미리보기`,
    sharingPageCopyToDriveTitle: `폴더 복사`,
    sharingPageDeclineTitle: `거절`,
    sharingPageAcceptInvitationNameConflictDialogTitle: `\"\${0}\"에 대한 초대 수락`,
    sharingPageAcceptInvitationInitialNamePrompt: `이 공유 폴더에 사용할 이름을 지정하십시오. 디폴트 값은 \${0}입니다.`,
    sharingPageAcceptInvitationNameConflictDialogLabel: `이름이 \"\${0}\"인 폴더가 이미 있습니다. 새 폴더의 이름을 바꾸십시오.`,
    sharingPageCopyInvitationNameConflictDialogLabel: `이름이 \"\${0}\"인 폴더가 이미 있습니다. 새 폴더의 이름을 바꾸십시오.`,
    sharingPageCopyInvitationNameConflictDialogTitle: `폴더 \"\${0}\" 복사`,
    sharingPageInvitationNotFound: `공유 초대를 찾을 수 없습니다.  삭제되었을 수 있습니다.`,
    sharingPageInvitationAuthorizationError: `공유 초대에는 MathWorks 계정에 연결된 사용자 ID와 동일한 이메일 주소를 사용해야 합니다. 이 문제를 해결하려면 폴더 소유자에게 문의하십시오.`,
    previewPageFacetsSharing: `공유된 내용`,
    previewPageAddToDriveLabel: `바로 가기 추가`,
    previewPageShareLinkLabel: `링크 공유`,
    previewPageGoToDriveLabel: `Drive로 이동`,
    addToDriveToolTipText: `바로 가기를 추가하거나 이 폴더를 복사합니다.`,
    shareLinkToolTipText: `보기 전용 링크를 통해 폴더를 공유합니다.`,
    goToDriveToolTipText: `MATLAB Drive의 폴더로 이동합니다`,
    previewPageLoginLabel: `로그인`,
    acceptToolTipText: `바로 가기를 추가하면 파일과 함께 이 폴더가 표시되므로, 폴더 내용의 업데이트된 사항을 계속 볼 수 있습니다.`,
    previewPageAcceptLabel: `수락`,
    declineToolTipText: `초대를 거절합니다`,
    previewPageDeclineLabel: `거절`,
    previewPageAddLabel: `내 파일에 추가`,
    copyToDriveToolTipText: `이 폴더를 복사하면 이 폴더와 그 내용에 대한 공유되지 않은 복사본이 파일들 사이에 만들어집니다.`,
    previewPageCopyToDriveLabel: `폴더 복사`,
    previewPageDownloadActionLabel: `공유 폴더 다운로드`,
    previewPageDownloadActionSelectionLabel: `선택 항목 다운로드`,
    invitationPreviewModalTitle: `공유 미리보기`,
    invitationPreviewInvitationDeclined: `공유 폴더 \${0}에 대한 초대가 거절되었습니다.`,
    invitationPreviewInvitationAccepted: `공유 폴더 \${0}이(가) Drive에 추가되었습니다.`,
    invitationPreviewInvitationNotExist: `공유된 내용을 찾을 수 없습니다. 소유자에 의해 삭제되었을 수 있습니다.`,
    invitationPreviewLoginForPersonalInvitation: `공유된 내용을 미리 보려면 로그인하십시오.`,
    invitationPreviewInvitationAlreadyAddedToDrive: `공유 폴더 \${0}이(가) 이미 Drive에 추가됨`,
    invitationPreviewInvitationUndefined: `공유된 내용을 찾을 수 없습니다. URL을 확인하십시오.`,
    invitationPreviewAuthorizationError: `이 공유된 내용에 액세스할 수 있는 권한이 없습니다.`,
    panelInfoIcon: `현재 폴더에 대한 정보를 보려면 확장하십시오.`,
    editAccessTypeInfoIcon: `Drive에 대한 바로 가기를 추가한 경우 공유 폴더와 그 내용을 편집할 수 있습니다.`,
    viewAccessTypeInfoIcon: `공유 폴더와 그 내용을 볼 수 있습니다. 공유 폴더와 그 내용을 편집하려면 소유자에게 문의하십시오.`,
    ownerAccessTypeInfoIcon: `공유 폴더의 소유자는 공유 폴더의 내용을 편집하고 구성원을 관리하며 이 내용의 공유된 링크를 관리할 수 있습니다.`,
    panelOwnedBy: `소유한 사람`,
    panelAccessType: `액세스 유형`,
    panelCreatedOn: `생성 날짜`,
    panelModifiedOn: `수정한 날짜`,
    panelSharedFolderRoot: `공유 부모`,
    panelFolderSize: `폴더 크기`,
    panelChildCount: `총 항목 수`,
    smallTitleSharedBy: `공유한 사람`,
    smallTitleSharedOn: `공유 날짜`,
    smallTitleAccess: `액세스`,
    originalLocationColumnLabel: `원래 위치`,
    clientDateModifiedColumnLabel: `수정한 날짜`,
    deletedDateColumnLabel: `삭제한 날짜`,
    tombstoneColumnLabel: `삭제 표식`,
    sharedOnColumnLabel: `공유 날짜 `,
    sharedByColumnLabel: `공유한 사람 `,
    accessTypeColumnLabel: `액세스`,
    ownedByColumnLabel: `소유한 사람 `,
    contextMenuCutLabel: `잘라내기`,
    contextMenuCopyLabel: `복사`,
    contextMenuCopyToLabel: `복사`,
    contextMenuDeleteLabel: `삭제`,
    contextMenuDownloadLabel: `다운로드`,
    contextMenuEmptyTrashLabel: `모두 영구 삭제`,
    contextMenuNewFolderLabel: `새 폴더`,
    contextMenuPasteLabel: `붙여넣기`,
    contextMenuPermanentlyDeleteLabel: `영구 삭제`,
    contextMenuRenameLabel: `이름 바꾸기`,
    contextMenuRestoreLabel: `복원`,
    contextMenuUploadLabel: `업로드`,
    contextMenuUploadFilesLabel: `파일`,
    contextMenuUploadFolderLabel: `폴더`,
    contextMenuViewFileLabel: `보기`,
    contextMenuViewNewTabLabel: `새 탭에서 보기`,
    contextMenuOpenWithLabel: `MATLAB Online에서 열기`,
    contextMenuShareLabel: `공유`,
    contextMenuManageShareLabel: `공유된 내용`,
    contextMenuLinkLabel: `링크`,
    contextMenuCreateLinkLabel: `링크 만들기`,
    contextMenuManageLinkLabel: `링크 관리`,
    contextMenuViewLinkLabel: `링크 보기`,
    contextMenuInviteLabel: `구성원 초대`,
    contextMenuManageLabel: `구성원 관리`,
    contextMenuViewMembersLabel: `구성원 보기`,
    contextMenuLeaveLabel: `나가기`,
    contextMenuMoveLabel: `이동`,
    contextMenuShareLinkLabel: `링크 공유`,
    createFolderDialogCreateFolderTitle: `새 폴더`,
    createFolderDialogFolderNameInputLabel: `이 폴더의 이름 입력:`,
    createFolderDialogInputError2Status: `(오류)`,
    createFolderDialogButtonCancel: `취소`,
    createFolderDialogButtonCreate: `만들기`,
    createFolderDialogValidationText: `폴더 이름에는 \${arg1} 문자를 사용할 수 없습니다`,
    createFolderDialogResourceAlreadyExists: `이 이름을 가진 파일 또는 폴더가 이미 있습니다. 다른 이름을 선택하십시오.`,
    createFolderDialogIllegalPathElement: `폴더 이름에 허용되지 않는 문자가 포함되어 있습니다.`,
    createFolderDialogFolderCreated: `\${arg1}이(가) \${arg2}에 추가되었습니다.`,
    initiateSharingDialogResourceNotSharable: `\"\${0}\"을(를) 공유할 수 없습니다. 공유 폴더의 부모이거나 다른 이유로 제한됩니다.`,
    initiateSharingDialogTitle: `\"\${0}\" 공유`,
    initiateSharingDialogTitleCreateLinks: `\"\${0}\"에 대한 링크 만들기`,
    initiateSharingDialogTitleManageLinks: `\"\${0}\"에 대한 링크 관리`,
    initiateSharingDialogTitleViewLinks: `링크를 통해 \"\${0}\" 공유`,
    initiateSharingDialogTitleManage: `\"\${0}\"의 구성원 관리`,
    initiateSharingDialogTitleInvite: `\"\${0}\"을(를) 공유할 구성원 초대`,
    initiateSharingDialogTitleView: `\"\${0}\"의 구성원 보기`,
    initiateSharingDialogFolderNameInputLabel: `초대`,
    initiateSharingDialogCurrentlySharedWithLabel: `다음 사용자와 공유됨`,
    initiateSharingDialogNoParticipants: `현재 참가자가 없습니다.`,
    initiateSharingDialogNoParticipantsButHasLink: `현재 개인적으로 초대된 참가자가 없는데도 공개 링크가 있습니다.`,
    initiateSharingDialogCreateLink: `보기 전용 링크 만들기`,
    initiateSharingDialogCopyLink: `링크 복사`,
    initiateSharingDialogDeleteLink: `링크 삭제`,
    initiateSharingDialogButtonShare: `적용`,
    initiateSharingDialogButtonClose: `닫기`,
    initiateSharingDialogButtonReturn: `뒤로`,
    initiateSharingDialogValidationEmptyEmailAddress: `이메일 주소는 비어 있을 수 없음`,
    initiateSharingDialogValidationAtSignText: `이메일 주소에 두 개 이상의 \"@\" 문자가 포함되어 있습니다.`,
    initiateSharingDialogInvalidEmailTooLong: `이메일 주소가 70자를 초과합니다.`,
    initiateSharingDialogInvalidEmailIllegal: `이메일 주소에 잘못된 문자 시퀀스가 들어 있습니다.`,
    initiateSharingDialogInvalidEmailDomain: `이메일 주소 \${0}이(가) 유효하지 않습니다. 올바른 형식은 name@example.com입니다.`,
    initiateSharingDialogInvalidEmailToSelf: `나 자신에게 초대를 보내는 것은 허용되지 않습니다.`,
    initiateSharingDialogIllegalPathElement: `이메일 주소에 허용되지 않는 문자가 포함되어 있습니다.`,
    initiateSharingDialogFolderShared: `\${0}이(가) \"\${1}\"에 추가되었습니다.`,
    initiateSharingDialogPermission_read_write: `편집 가능`,
    initiateSharingDialogPermission_read_only: `보기 가능`,
    initiateSharingDialogPermission_manage_access: `관리 가능`,
    initiateSharingDialogPermission_owner: `소유자`,
    initiateSharingDialogStatusPending: `대기 중`,
    initiateSharingDialogStatusAccepted: `수락됨`,
    initiateSharingDialogStatusDeclined: `거절됨`,
    initiateSharingDialogStatusNewInvite: `보내는 중...`,
    initiateSharingDialogErrorWarning: `공유하는 동안 오류가 발생했습니다. 자세한 내용은 아래 목록을 참조하십시오.`,
    initiateSharingDialogSharingSuccessful: `\${0}이(가) 다음 사용자와 공유됨:`,
    initiateSharingDialogSomeErrorsWarning: `\${0}이(가) 공유되었지만 오류가 발생했습니다. 자세한 내용은 아래 목록을 참조하십시오.`,
    initiateSharingDialogLinkCopied: `보기 전용 링크가 클립보드에 복사되었습니다.`,
    initiateSharingDialogCreateLinkFailed: `만들려는 링크가 이미 존재합니다.`,
    initiateSharingDialogCopyLinkFailed: `링크를 복사하지 못함: \${0}.`,
    initiateSharingDialogManuallyCopyLink: `표시된 URL을 선택한 다음 복사하십시오.`,
    initiateSharingDialogConfirmLinkDelete: `보기 전용 링크를 삭제하시겠습니까?`,
    initiateSharingDialogConfirmCollaboratorDelete: `공유 폴더에서 \"\${0}\"을(를) 제거하시겠습니까?`,
    initiateSharingDialogDeleteCollaboratorWarning: `\"\${0}\"에게는 더 이상 공유 폴더 또는 그 내용에 대한 액세스 권한이 없습니다.`,
    initiateSharingDialogDeleteLinkButton: `삭제`,
    initiateSharingDialogRemovePermissionButton: `제거`,
    initiateSharingDialogCancelButton: `취소`,
    initiateSharingDialogDeleteWarning: `링크가 있는 모든 사용자가 더 이상 폴더나 그 내용에 액세스할 수 없게 됩니다.`,
    initiateSharingDialogDeleteCollaboratorSuccessful: `공유 폴더에서 \"\${0}\"이(가) 제거되었습니다.`,
    initiateSharingDialogAddUserButtonLabel: `보내기`,
    initiateSharingDialogNoLinksAvailable: `이 폴더는 특정 사용자와 링크가 아닌 다른 방식으로 공유되었습니다. 따라서 제공되는 링크는 없습니다.`,
    initiateSharingDailogInviteeAlreadyInList: `이 이메일 주소와 관련하여 이 폴더에 대한 초대가 이미 있습니다.`,
    initiateSharingDailaogEmailAlreadyInList: `이 폴더에 대한 초대를 이 이메일 주소로 이미 보냈습니다.`,
    initiateSharingDailogEnterEmailAddress: `이메일 주소 입력`,
    initiateSharingDialogEnterEmailComments: `메시지 추가(선택 사항)`,
    initiateSharingDailogExplainCreateLink: `다른 사용자에게 폴더 \${0}에 대한 보기 전용 권한을 부여하기 위한 링크를 만듭니다.`,
    initiateSharingDailogExplainCopyLink: `다른 사용자에게 폴더 \${0}에 대한 보기 전용 권한을 부여하기 위한 링크를 배포합니다.`,
    initiateSharingDailogExplainDeleteLink: `공유된 내용에 대한 링크와 액세스 권한을 삭제합니다. 개인적 초대를 받은 구성원의 액세스에는 영향을 미치지 않습니다.`,
    initiateSharingDialogmultiEmailTextWarning: `여러 개의 이메일을 세미콜론 또는 쉼표로 구분하십시오`,
    initiateSharingDialogIllegalFolderName: `폴더 \${0}에 폴더 이름에 지원되지 않는 \".\", \"..\", \"\\\\\" 또는 제어 문자가 포함되어 있습니다.`,
    initiateSharingDialogNoPermToCreateLink: `이 폴더에 대한 초대를 만들 수 있는 권한이 없습니다.`,
    initiateSharingDialogNoPermToDeleteLink: `이 폴더에 대한 공유 초대를 삭제할 수 있는 권한이 없습니다.`,
    initiateSharingDialogLinkFolderDoesNotExist: `\${0}이(가) 존재하지 않습니다.  삭제되었거나 이동되었을 수 있습니다.`,
    initiateSharingDialogInvitationDoesNotExist: `공유 초대를 찾을 수 없습니다.  삭제되었을 수 있습니다.`,
    initiateSharingDialogNoPermToUpdateInvitation: `지금은 공유 초대를 업데이트할 수 없습니다.`,
    initiateSharingDialogActiveUsersLabel: `활성 사용자: `,
    initiateSharingDialogUpdateAccessTypeButtonLabel: `저장`,
    initiateSharingDialogUpdateAccessTypeError: `\${0}명의 받는 사람에 대해 액세스 유형을 업데이트하지 못했습니다.`,
    initiateSharingDialogUpdateAccessTypeSuccessful: `\${0}명의 받는 사람에 대해 액세스 유형을 업데이트했습니다.`,
    initiateSharingDialogUpdateAccessTypePartial: `\${0}명의 받는 사람에 대해서는 액세스 유형을 업데이트하지 못했지만, \${1}명의 받는 사람에 대해서는 업데이트했습니다.`,
    userQuotaStorageFull: `저장공간이 꽉 찼습니다.  파일을 삭제하여 공간을 확보하십시오.`,
    userQuotaServerError: `서버 오류가 발생했습니다. 나중에 다시 시도하십시오.`,
    userQuotaUnableToFetchData: `데이터를 가져올 수 없습니다.`,
    userQuotaAmountUsed: `\${2}\${3} 중 \${0}\${1} 사용됨`,
    actionAborted: `동작이 중단되었습니다.`,
    actionHandlerFile: `파일`,
    actionHandlerFiles: `파일`,
    actionHandlerFolder: `폴더`,
    actionHandlerItem: `항목`,
    actionHandlerItems: `항목`,
    actionHandlerCannotDisplayMsgTooBig: `파일이 너무 커서 표시할 수 없습니다. 내용을 보려면 파일을 다운로드하십시오.`,
    actionHandlerCannotDisplayMsgWrongType: `이 파일에 대한 미리보기를 사용할 수 없습니다. 내용을 보려면 파일을 다운로드하십시오.`,
    actionHandlerCannotDisplayMsgSecurity: `이 파일에 대한 미리보기는 허용되지 않습니다.  내용을 보려면 파일을 다운로드하십시오.`,
    actionHandlerViewFilePleaseWaitText: `불러오는 중...`,
    actionHandlerViewFileDownloadTimeOut: `다운로드 시간이 초과되었습니다. 다시 시도하십시오.`,
    actionHandlerDownloadInProgress: `다운로드를 시작했습니다. 많은 수의 파일이나 폴더를 다운로드할 경우에는 몇 분 정도 소요될 수 있습니다.`,
    actionHandlerViewFileFileNotExists: `지정한 파일/폴더를 찾을 수 없습니다. 삭제되었거나 이동되었을 수 있습니다.`,
    actionHandlerMaxConcurrentDownloads: `한 번에 최대 \${0}개만 허용됩니다. 선택한 파일 수를 줄이십시오.`,
    actionHandlerPastedTo: `\${0}을(를) \${1}에 붙여 넣었습니다. `,
    actionHandlerPasteFailed: `이 이름을 사용하는 \${0}이(가) 이미 \${1}에 있습니다. 다른 위치에 붙여 넣거나 \${2}에 고유한 이름을 지정하십시오.`,
    actionHandlerMultiPasteSuccess: `\${0}개의 \${1}을(를) \${2}에 붙여 넣었습니다. `,
    actionHandlerMultiPasteFailed: `\${0}개의 \${1}을(를) \${2}에 붙여 넣지 못했습니다. `,
    actionHandlerMultiMoveSuccess: `\${0}개의 \${1}을(를) \${2}(으)로 이동했습니다. `,
    actionHandlerMultiMoveFailed: `\${0}개의 \${1}을(를) \${2}(으)로 이동하지 못했습니다. `,
    actionHandlerCopiedTo: `\${0}이(가) \${1}에 복사되었습니다. `,
    actionHandlerCopyFailed: `이 이름을 사용하는 \${0}이(가) 이미 \${1}에 있습니다. 다른 위치에 복사하거나 \${2}에 고유한 이름을 지정하십시오.`,
    actionHandlerMultiCopySuccess: `\${0}개의 \${1}을(를) \${2}에 복사했습니다. `,
    actionHandlerMultiCopyFailed: `\${0}개의 \${1}을(를) \${2}에 복사하지 못했습니다. `,
    actionHandlerMultiDeleteSuccess: `\${0}개 항목을 삭제했습니다. `,
    actionHandlerMultiDeleteFailed: `\${0}을(를) 삭제하지 못했습니다. `,
    actionHandlerSingleDeleteSuccess: `\${0}이(가) [삭제된 파일]로 이동되었습니다.`,
    actionHandlerSingleSharedFolderDeleteSuccess: `공유 폴더 \${0}이(가) 나 자신에 대해서만 [삭제된 파일]로 이동되었습니다. 다른 구성원은 계속해서 액세스할 수 있습니다.`,
    actionHandlerSingleSharedFolderOwnerDeleteSuccess: `공유 폴더 \${0}이(가) 나 자신에 대해서만 [삭제된 파일]로 이동되었습니다.  다른 구성원은 계속해서 액세스할 수 있습니다.`,
    actionHandlerSingleDelectFailed: `\${0}을(를) [삭제된 파일]로 이동하지 못함: \${1}`,
    actionHandlerGoToLocation: `위치로 이동`,
    actionHandlerServerError: `서버 오류`,
    actionHandlerNameConflictPrompt: `\${0} 이름의 파일 또는 폴더가 이미 \${1}에 있습니다. 새 이름을 입력하십시오. `,
    actionHandlerRenamePromptTitle: `\${0} 복원`,
    actionHandlerRenamePromptLabel: `이 이름을 사용하는 \${0}이(가) 이미 있습니다. \${1} 이름을 바꾸십시오. `,
    actionHandlerPerformRestoreSuccess: `\${0}이(가) \${1}(으)로 복원되었습니다.`,
    actionHandlerPerformRestoreFailed: `\${0}을(를) 위치 \${1}(으)로 복원하지 못함: \${2}`,
    actionHandlerPerformRestoreUnknownFailure: `\${0}을(를) 복원하지 못함: \${1}`,
    actionHandlerMultiRestoreSuccess: `\${0}개 항목을 복원했습니다. `,
    actionHandlerMultiRestoreFailed: `\${0}개 항목을 복원하지 못했습니다. `,
    actionHandlerSinglePermanentDeleteSuccess: `\${0}이(가) 영구히 삭제되었습니다.`,
    actionHandlerSinglePermanentDeleteSharedFolderSuccess: `공유 폴더 \${0}이(가) 나 자신에 대해서만 삭제되었습니다.`,
    actionHandlerSingleUnshareAndPermanentDeleteSharedFolderSuccess: `공유 폴더 \${0}이(가) 공유 해제되고 삭제되었습니다.`,
    actionHandlerSinglePermanentDeleteSharedLinkFolderSuccess: `공유 폴더 \${0}이(가) 나 자신에 대해서만 삭제되었습니다. 다시 참가하려면 원래 URL을 사용하십시오.`,
    actionHandlerSinglePermanentDeleteFailed: `\${0}을(를) 삭제하지 못함: \${1}`,
    actionHandlerMultiPermanentDeleteSuccess: `\${0}개 항목이 영구히 삭제되었습니다.`,
    actionHandlerMultiPermanentDeleteFailed: `\${0}개 항목을 삭제하지 못함: \${1}`,
    actionHandlerGeneralDeleteFailed: `삭제 실패: \${0}`,
    actionHandlerTrashEmptied: `[삭제된 파일]의 내용이 영구히 삭제되었습니다.`,
    actionHandlerTrashEmptyFailed: `[삭제된 파일]을 비우지 못함: \${0}`,
    actionHandlerDeleteDialogTitle: `\${0} 영구 삭제`,
    actionHandlerDeleteDialogFilePrompt: `<b>\${0}</b>을(를) 영구히 삭제하시겠습니까?<br>참고: 공유된 내용은 삭제되지 않습니다.`,
    actionHandlerDeleteDialogFolderPrompt: `<b>폴더 및 그 내용을 복원할 수 없습니다.</b> 다른 구성원은 \${0} 내에 포함된 모든 공유 폴더에 계속해서 액세스할 수 있습니다.`,
    actionHandlerDeleteDialogSharedFolderPrompt: `공유 폴더 \${0} 또는 그 내용을 복원할 수 없게 됩니다.<br/> 다른 구성원은 계속해서 액세스할 수 있습니다.`,
    actionHandlerDeleteDialogSharedFolderOwnerPrompt: `<b>공유 폴더 \${0} 또는 그 내용을 복원할 수 없게 됩니다.</b><br/> 다른 구성원은 계속해서 액세스할 수 있습니다.`,
    actionHandlerDeleteDialogShouldUnshareFolderLabel: `폴더 \${0}에 대한 현재 구성원과의 공유 해제`,
    actionHandlerDeleteDialogShouldUnshareMultipleFoldersLabel: `선택된 모든 폴더에 대한 현재 구성원과의 공유 해제`,
    actionHandlerDeleteDialogShouldUnshareFolderExplanation: `공유 폴더의 구성원은 더 이상 액세스할 수 없게 됩니다.`,
    actionHandlerDeleteDialogShouldUnshareMultipleFoldersExplanation: `이러한 공유 폴더의 구성원은 더 이상 액세스할 수 없게 됩니다.`,
    actionHandlerDeleteDialogShouldUnshareSubFoldersLabel: `\${0} 내에 포함된 모든 공유 폴더의 공유 해제`,
    actionHandlerDeleteDialogShouldUnshareMultipleSubFoldersLabel: `이 선택 항목 내에 포함된 모든 공유 폴더의 공유 해제`,
    actionHandlerDeleteDialogShouldUnshareSubFoldersExplanation: `이러한 공유 폴더의 구성원은 더 이상 액세스할 수 없게 됩니다.`,
    actionHandlerDeleteDialogFolderContentsToo: ` 및 그 내용`,
    actionHandlerDeleteDialogDeleteButtonlabel: `영구 삭제`,
    actionHandlerDeleteDialogCancelButtonLabel: `취소`,
    actionHandlerDeleteDialogMultiTitle: `항목 삭제`,
    actionHandlerDeleteDialogMultiSingularPrompt: `이 항목을 삭제하시겠습니까?`,
    actionHandlerDeleteDialogMultiPluralPrompt: `이러한 항목을 삭제하시겠습니까?`,
    actionHandlerPermanentlyDeleteAllDialogTitle: `모두 영구 삭제`,
    actionHandlerDeleteAllDialogPrompt: `<b>폴더와 그 내용을 복원할 수 없습니다.</b><br/>공유 폴더의 구성원은 계속해서 액세스할 수 있습니다.`,
    actionHandlerUndo: `실행 취소`,
    actionHandlerOpenWithLoadFailed: `연결 프로그램 서비스를 사용해 \${0}을(를) 불러오지 못했습니다.`,
    actionHandlerLargeOperationNotification: `대용량 파일 작업이 진행 중입니다. 지금은 일부 파일을 사용할 수 없습니다. 업데이트 내용을 보려면 페이지를 새로 고치십시오.`,
    actionHandlerIllegalMoveOrCopyTarget: `\${0}을(를) 이동하거나 복사할 수 없습니다. 자신 또는 그 하위 폴더 중 하나로 이동하거나 복사할 수 없습니다.`,
    actionHandlerMovedTo: `\${0}을(를) \${1}(으)로 이동했습니다. `,
    actionHandlerMoveMany: `\${0}개 항목`,
    fbTreeResourceAlreadyExists: `이 이름을 가진 파일 또는 폴더가 이미 있습니다. \${0} 이름을 바꾸십시오.`,
    fbTreeFile: `파일`,
    fbTreeFiles: `파일`,
    fbTreeFolder: `폴더`,
    fbTreeIllegalPath: `이름에 허용되지 않는 문자가 포함되어 있습니다. 다른 이름을 선택하십시오.`,
    fbTreeShouldNotContainFolders: `선택 항목에 폴더가 포함되지 않도록 하십시오.`,
    fsServerError: `서버 오류가 발생했습니다. 나중에 다시 시도하십시오.`,
    fsFolderNotFoundResetting: `지정된 폴더 \${0}을(를) 찾을 수 없습니다. \"MATLAB Drive\"로 재설정됩니다.`,
    fsFolderNotFound: `지정한 폴더를 찾을 수 없습니다. 삭제되었거나 이동되었을 수 있습니다.`,
    fsMovedFileTo: `\${0}을(를) \${1}(으)로 이동했습니다.`,
    fsCopiedFileTo: `\${0}을(를) \${1}에 복사했습니다.`,
    fsMovedFileToFailed: `\${0}을(를) \${1}(으)로 이동하지 못했습니다.`,
    fsCopiedFileToFailed: `\${0}을(를) \${1}에 복사하지 못했습니다.`,
    fsFile: `파일`,
    fsFolder: `폴더`,
    fsResourceAlreadyExists: `이 이름을 가진 파일 또는 폴더가 이미 있습니다. 다른 위치로 이동하거나 \${0}에 고유한 이름을 지정하십시오.`,
    fsIllegalPathElement: `이름에 허용되지 않는 문자가 포함되어 있습니다. 다른 이름을 선택하십시오.`,
    fsUnknownError: `알 수 없는 오류`,
    fsItem: `항목`,
    fsItems: `항목`,
    fsMultiMoveSuccess: `\${0}개의 \${1}을(를) \${2}(으)로 이동했습니다. `,
    fsMultiCopySuccess: `\${0}개의 \${1}을(를) \${2}에 복사했습니다. `,
    fsMultiMoveFailed: `\${0}개의 \${1}을(를) \${2}(으)로 이동하지 못했습니다. `,
    fsMultiCopyFailed: `\${0}개의 \${1}을(를) \${2}에 복사하지 못했습니다. `,
    fsNoMoveAccess: `공유 폴더 \${0}에 대해 보기 가능 권한이 있으며 그 내용은 수정할 수 없습니다.`,
    fsAcceptInvitationFailed: `초대 수락 실패: \${0}`,
    fsDeclineInvitationFailed: `초대 거절 실패: \${0}`,
    fsFollowLinkFailed: `링크를 따라가지 못함: \${0}`,
    fsFailedToGetPendingInvitations: `대기 중인 초대를 가져오지 못함: \${0}.`,
    fsFailedToGetFolderSettings: `폴더 설정을 가져오지 못함: \${0}.`,
    fsNotAuthorized: `이 리소스를 볼 수 있는 권한이 없습니다.`,
    apsInvalidToken: `AuthNZ에서 반환된 apsToken 값이 유효하지 않음`,
    apsInvalidResponse: `ApsAuthNZ 서버에서 받은 응답이 유효하지 않음`,
    apsActionPermanentlyDeletedFrom: `\${0}이(가) \${1}에서 영구히 삭제되었습니다.`,
    apsActionDeletedFrom: `\${0}이(가) \${1}에서 삭제되었습니다.`,
    apsActionMovedTo: `\${0}이(가) \${1}(으)로 이동되었습니다.`,
    apsActionUploadedTo: `\${0}이(가) \${1}에 업로드되었습니다.`,
    apsActionRestoredTo: `\${0}이(가) \${1}(으)로 복원되었습니다.`,
    apsActionCreatedIn: `\${0}이(가) \${1}에 생성되었습니다.`,
    apsActionRenamedTo: `\${0} 이름이 \${1}(으)로 바뀌었습니다.`,
    apsActionInvitationChangedOn: `\${0}이(가) \${1}(으)로 이동되었습니다.`,
    apsActionCopiedTo: `\${0}이(가) \${1}에 복사되었습니다.`,
    renamePromptInvalidCharacters: `이름에는 \${0} 문자를 사용할 수 없음`,
    renameAcceptLabel: `수락`,
    renameButtonLabel: `이름 바꾸기`,
    renameCancelButtonLabel: `취소`,
    renameErrorLabel: `(오류)`,
    viewFile: `보기`,
    viewCloseButton: `닫기`,
    viewDeleteAction: `삭제`,
    viewDownloadAction: `다운로드`,
    viewInNewTab: `새 탭에서 보기`,
    viewOpenLiveEditor: `MATLAB Online에서 열기`,
    viewOpenLiveEditorMock: `\"\${0}\"을(를) 열 수 없습니다.`,
    viewOpenLiveEditorNotSupported: `\"MATLAB Online에서 열기\" 기능은 이 환경에서\n지원되지 않습니다.`,
    simpleUploadTitle: `\${0}에 파일 업로드`,
    simpleUploadFolderTitle: `\${0}에 폴더 업로드`,
    simpleUploadFilesSelectionText: `업로드할 파일 선택`,
    simpleUploadFolderSelectionText: `업로드할 폴더를 선택합니다.`,
    simpleUploadEmptyFolderUploadWarning: `(빈 폴더는 업로드할 수 없음)`,
    simpleUploadStatusText: `업로드하는 중...`,
    simpleUploadUploadCancelButtonLabel: `취소`,
    simpleUploadUploadButtonLabel: `업로드`,
    simpleUploadCloseButtonLabel: `닫기`,
    simpleUploadFileUploadSuccess: `파일이 업로드되었습니다.`,
    simpleUploadStatusCancelled: `업로드가 취소되었습니다.  `,
    simpleUploadStatusWithErrors: `업로드가 완료되었지만 오류가 있습니다.  `,
    simpleUploadSummary: `\${0}개 완료, \${1}개 취소, \${2}개 실패.`,
    simpleUploadSummaryNoCancelled: `\${0}개 완료, \${1}개 실패.`,
    simpleUploadSummaryNoFailed: `\${0}개 완료, \${1}개 취소.`,
    simpleUploadSummaryNoCompleted: `\${0}개 취소, \${1}개 실패.`,
    simpleUploadSummaryNoCancelledNoFailed: `\${0}개 완료.`,
    simpleUploadSummaryNoCompletedNoCancelled: `\${0}개 실패.`,
    simpleUploadSummaryNoCompletedNoFailed: `\${0}개 취소.`,
    simpleUploadCompletedText: `업로드가 완료되었습니다.  `,
    simpleUploadFile: `파일`,
    simpleUploadFiles: `파일`,
    simpleUploadNumCompleted: `\${0}개의 \${1}이(가) 업로드되었습니다.`,
    simpleUploadCancelOnly: `\${1}(으)로의 \${0}개 업로드가 취소되었습니다.`,
    simpleUploadCancelFail: `\${0}개 업로드가 취소되었으며, \${1}개 파일이 \${2}에 업로드되지 못했습니다.`,
    simpleUploadCancelUpload: `\${0}개 업로드가 취소되었으며, \${1}개 파일이 \${2}에 업로드되었습니다.`,
    simpleUploadCancelFailUpload: `\${0}개 업로드가 취소되었으며, \${1}개 파일이 업로드되지 못했으며, \${2}개 파일이 \${3}에 업로드되었습니다.`,
    simpleUploadNumberFailed: `\${0}개 파일이 \${1}에 업로드되지 못했습니다.`,
    simpleUploadFailedUpload: `\${0}개 파일이 업로드되지 못했으며, \${1}개 파일이 \${2}에 업로드되었습니다.`,
    simpleUploadOnlyUpload: `\${0}개 파일이 \${1}에 업로드되었습니다.`,
    simpleUploadResourceAlreadyExists: `이 이름을 가진 폴더가 MATLAB Drive에 이미 있으며, MATLAB Drive는 대/소문자를 구분하지 않습니다. 파일 이름을 바꾸십시오.`,
    simpleUploadResourceDoesNotExist: `업로드에 실패했습니다. 다시 시도하십시오.`,
    simpleUploadFileTooLarge: `파일이 MATLAB Drive에서 허용하는 크기(1GB)보다 더 큽니다. 파일 크기를 줄이십시오.`,
    simpleUploadAuthorizationError: `이 폴더에 저장할 수 있는 권한이 없습니다.`,
    simpleUploadQuotaExceeded: `MATLAB Drive 저장공간이 꽉 찼습니다. 내용을 삭제하여 공간을 확보하십시오.`,
    simpleUploadIllegalPathElement: `파일 이름에 허용되지 않는 문자가 포함되어 있습니다. 파일 이름을 바꾸십시오.`,
    simpleUploadSessionNotFound: `업로드에 실패했습니다. 다시 시도하십시오.`,
    simpleUploadProgressText: `\${1}개 중 \${0}개...`,
    simpleUploadFolderUploadForbidden: `폴더 업로드는 허용되지 않습니다.`,
    simpleUploadFileTooLargeToUpload: `파일이 너무 커서 온라인으로 업로드할 수 없습니다. 허용되는 파일 최대 크기는 \${0}GB입니다. `,
    simpleUploadCanWithConnector: `이 파일은 \${0}을(를) 사용하여 MATLAB Drive에 추가할 수 있습니다.`,
    simpleUploadMatlabDriveConnector: `MATLAB Drive Connector`,
    simpleUploadReasonInvalid: `파일이 너무 커서 온라인으로 업로드할 수 없습니다. 이 파일은 MATLAB Drive Connector를 사용하여 MATLAB Drive에 추가할 수 있습니다.`,
    simpleUploadProgressStatus: `\${1}개 항목 중 \${0}개를 업로드하는 중...`,
    simpleUploadUploadCancelled: `업로드가 취소되었습니다.`,
    simpleUploadInvalidData: `잘못된 데이터: 파일 업로드를 수행할 수 없습니다.`,
    simpleUploadResolveButtonLabel: `해결`,
    simpleUploadUploadTypeChoiceGroupLabelText: `업로드 유형: `,
    simpleUploadUploadUploadFilesChoiceLabelText: `파일`,
    simpleUploadUploadUploadDirectoryChoiceLabelText: `폴더`,
    openInMOTitle: `MATLAB Online에서 \"\${0}\" 열기`,
    openInMOLabel: `계속해서 MATLAB Online에서 이 파일을 열려면 공유 폴더에 대해 다음 작업 중 하나를 선택하십시오:`,
    openInMOFileInTrash: `MATLAB Online에서 열기를 요청한 파일/폴더가 휴지통에 있습니다. 복원한 후에 다시 시도하십시오.`,
    saveToDriveSuccess: `\${0}이(가) 폴더 \${1}에 \${2}(으)로 저장되었습니다.`,
    fconflictDialogTitle: `파일 충돌`,
    fconflictFolderDialogTitle: `폴더 충돌`,
    fconflictSelectAll: `모두 선택`,
    fconflictReplaceButtonLabel: `바꾸기`,
    fconflictKeepSeparateButtonLabel: `별도로 유지`,
    fconflictFolderHasConflict: `폴더에 동일한 이름을 가진 \${0}개 파일이 있습니다.`,
    fconflictFolderHasConflicts: `폴더에 동일한 이름을 가진 \${0}개 파일이 있습니다.`,
    fconflictReplaceSuccessAndFailure: `\${0}개 파일을 바꾸었으며 \${1}개 파일은 바꾸지 못했습니다.`,
    fconflictReplaceSuccessesAndFailure: `\${0}개 파일을 바꾸었으며 \${1}개 파일은 바꾸지 못했습니다.`,
    fconflictReplaceSuccessesAndFailures: `\${0}개 파일을 바꾸었으며 \${1}개 파일은 바꾸지 못했습니다.`,
    fconflictReplaceSuccessAndFailures: `\${0}개 파일을 바꾸었으며 \${1}개 파일은 바꾸지 못했습니다.`,
    fconflictReplaceSuccessOnly: `\${0}개 파일을 바꾸었습니다.`,
    fconflictReplaceSuccessesOnly: `\${0}개 파일을 바꾸었습니다.`,
    fconflictReplaceFailureOnly: `\${0}개 파일을 바꾸지 못했습니다.`,
    fconflictReplaceFailuresOnly: `\${0}개 파일을 바꾸지 못했습니다.`,
    fconflictSaveSuccessesAndFailures: `\${0}개 파일을 저장했으며 \${1}개 파일은 저장하지 못했습니다.`,
    fconflictSaveSuccessesAndFailure: `\${0}개 파일을 저장했으며 \${1}개 파일은 저장하지 못했습니다.`,
    fconflictSaveSuccessAndFailures: `\${0}개 파일을 저장했으며 \${1}개 파일은 저장하지 못했습니다.`,
    fconflictSaveSuccessAndFailure: `\${0}개 파일을 저장했으며 \${1}개 파일은 저장하지 못했습니다.`,
    fconflictSaveSuccessesOnly: `\${0}개 파일을 저장했습니다.`,
    fconflictSaveSuccessOnly: `\${0}개 파일을 저장했습니다.`,
    fconflictSaveFailureOnly: `\${0}개 파일을 저장하지 못했습니다.`,
    fconflictSaveFailuresOnly: `\${0}개 파일을 저장하지 못했습니다.`,
    fconflictPleaseWait: `기다려 주십시오...`,
    restoreButtonLabel: `복원`,
    restoreCancelButtonLabel: `취소`,
    personalInvitationLimitExceeded: `MATLAB Drive 내에 있는 공유 폴더의 최대 개수에 도달했습니다. 공유 폴더를 새로 추가하려면 MATLAB Drive에서 공유 폴더를 제거하십시오.`,
    resourceInvitationLimitExceeded: `구성원 한도를 초과했습니다. 새 구성원을 추가하려면 현재 구성원을 제거하십시오.`,
    linkResourceInvitationLimitExceeded: `공유 폴더를 추가할 수 없습니다. 구성원 한도를 초과했습니다. 소유자에게 문의하십시오.`,
    showHiddenFilesLabel: `숨겨진 파일 표시`,
    chooseApplicationLabel: `응용 프로그램 선택:`,
    defaultApplicationLabel: `MATLAB Drive`,
    addonApplicationLabel: `애드온`,
    thingspeakApplicationLabel: `ThingSpeak`,
    instantAddonsApplicationLabel: `즉시 사용 애드온`,
    graderApplicationLabel: `MATLAB Grader`,
    otherApplicationLabel: `기타 앱`,
    previewBannerText: `미리보기`,
    meMyself: `나`,
    folderChooserFileNameLabel: `파일 이름:`,
    folderChooserChooseButton: `선택`,
    folderChooserChooseMoveButton: `이동`,
    folderChooserChooseCopyButton: `복사`,
    folderChooserCloseButton: `닫기`,
    folderChooserModalTitle: `폴더 선택`,
    moveFolderChooserModalTitle: `\${0}을(를) 이동할 위치`,
    copyToFolderChooserModalTitle: `\${0}을(를) 복사할 위치`,
    folderChooserNoSelectionWarning: `아무 것도 선택하지 않은 상태에서 \"\${0}\" 버튼을 클릭하면 \${1} 폴더가 선택됩니다.`,
    folderChooserNoSelectionMoveWarning: `선택 항목이 없으면 콘텐츠가 현재 폴더로 이동됩니다.`,
    folderChooserNoSelectionCopyWarning: `선택 항목이 없으면 콘텐츠가 현재 폴더에 복사됩니다.`,
    folderChooserCurrentRootFolder: `현재`,
    folderChooserRenameSourceNotAllowed: `이동 또는 복사 중에 소스 경로 이름을 바꾸는 것은 허용되지 않습니다.`,
    saveToDriveSaveButtonLabel: `저장`,
    saveToDriveCancelButtonLabel: `취소`,
    saveToDriveModalTitle: `MATLAB Drive에 저장`,
    widgetTestAppPageTitle: `내장된 위젯 테스트 페이지`,
    widgetTestAppChooseLabel: `위젯 선택`,
    widgetTestAppUseMatlabStyle: `MATLAB 데스크탑 스타일 지정 사용`,
    widgetTestAppOverrideCss: `사용자 지정 스타일시트로 CSS 재정의`,
    widgetTestAppCssStylesheetToUse: `사용할 CSS 스타일시트 URL:`,
    widgetTestAppIgnoreResizeMsg: `위젯 창 크기 조정 메시지 무시`,
    widgetTestAppIframeWidthPx: `iframe 너비(픽셀 단위): `,
    widgetTestAppIframeHeightPx: `iframe 높이(픽셀 단위): `,
    widgetTestAppRunWidgetButton: `선택한 위젯 실행`,
    widgetTestAppResetButton: `초기화`,
    widgetTestAppSelectedWidgetContents: `선택한 위젯 테스트 콘텐츠`,
    widgetTestAppLoggedInIntro: `현재 유효한 로그인 자격 증명이 있습니다. 내장된 위젯은 데이터를 가져올 때 이 자격 증명을 사용합니다.`,
    widgetTestAppNotLoggedInIntro: `현재 로그인 상태가 아니며 유효한 자격 증명이 없습니다.`,
    widgetTestAppWidgetsWillNotWork: `유효한 로그인 자격 증명이 없으면 내장된 위젯이 작동하지 않습니다.`,
    widgetTestAppNeedLogin: `우선 동일한 이 도메인에 로그인하여 유효한 자격 증명을 얻으십시오.`,
    widgetTestOptionNoOptions: `옵션 없음`,
    widgetTestOptionActions: `동작`,
    widgetTestOptionColumns: `열`,
    widgetTestOptionWidgetOptions: `위젯 옵션`,
    widgetTestOptionTrashWidgetTitle: `휴지통 콘텐츠 위젯`,
    widgetTestOptionsColumnName: `이름`,
    widgetTestOptionsColumnSize: `크기`,
    widgetTestOptionStartButton: `위젯 시작`,
    widgetTestOptionPleaseWait: `기다려 주십시오...`,
    widgetTestOptionResetWidgetButton: `위젯 옵션 초기화`,
    widgetTestOptionSearchWidgetTitle: `파일 검색 위젯`,
    widgetTestOptionColumnClientDateModified: `수정한 클라이언트 날짜`,
    widgetTestOptionColumnDateModified: `수정한 날짜`,
    widgetTestOptionColumnType: `유형`,
    widgetTestOptionShowShared: `공유 항목 표시`,
    widgetTestOptionShowHidden: `숨겨진 파일 표시`,
    widgetTestOptionIncludeFolders: `폴더 포함`,
    widgetTestOptionEnableViewing: `보기 사용`,
    widgetTestOptionEnableDnD: `끌어서 놓기 사용`,
    widgetTestOptionEnableNavigation: `탐색 사용`,
    widgetTestOptionSaveToDriveTitle: `SaveToDrive 위젯`,
    widgetTestOptionUseFolderChooserUI: `FolderChooser UI 사용`,
    widgetTestOptionSaveToDriveResultsLabel: `Drive에 저장 결과: `,
    widgetTestOptionFullPathPrompt: `저장할 파일의 전체 경로 입력: `,
    widgetTestOptionChooseContentSourcePrompt: `파일의 콘텐츠 원본 선택: `,
    widgetTestOptionContentChoiceUrl: `URL`,
    widgetTestOptionContentChoiceDirect: `직접적인 콘텐츠`,
    widgetTestOptionContentChoiceFromFile: `파일에서`,
    widgetTestOptionContentChooseFilesPrompt: `파일 선택:`,
    widgetTestOptionUrlPrompt: `사용할 콘텐츠의 URL 입력:`,
    widgetTestOptionTextPrompt: `텍스트 입력:`,
    widgetTestOptionSaveToDriveButton: `Drive에 저장`,
    widgetTestOptionClearButton: `지우기`,
    widgetTestOptionQuotaTitle: `할당량 위젯`,
    widgetTestOptionInitiateSharingTitle: `InitiateSharing 위젯`,
    widgetTestOptionFolderFullPathPrompt: `폴더의 전체 경로 입력: `,
    widgetTestOptionLinkButton: `링크`,
    widgetTestOptionManageButton: `관리`,
    widgetTestOptionFolderChooserTitle: `폴더 선택기 위젯`,
    widgetTestOptionShowFileNameSelector: `파일 이름 선택기 표시`,
    widgetTestOptionChooseButton: `선택`,
    widgetTestOptionSelectedFolder: `선택한 전체 경로: `,
    widgetTestOptionDesiredHeightInPixels: `원하는 높이(픽셀 단위): `,
    widgetTestOptionDesiredWidthInPixels: `원하는 너비(픽셀 단위): `,
    widgetTestOptionFileNameSuggestionPrompt: `파일 이름 선택 제안: `,
    widgetTestOptionStartingFolderPrompt: `시작 폴더 전체 경로: `,
    widgetTestOptionFolderPathContentTitle: `FolderPath 콘텐츠 위젯`,
    widgetTestOptionsSearchNumberOfDays: `최대 검색 일수: `,
    widgetTestOptionsSearchMaxResults: `최대 결과 크기: `
});