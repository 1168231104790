/**
 * <strong>Properties:</strong><br>
 * <br>
 * <li>{@link module:mw-mixins/property/CheckedMixin|checked}</li>
 * <br>
 * <li>{@link module:mw-mixins/property/DescriptionMixin|description}</li>
 * <br>
 * <li>{@link module:mw-mixins/property/DisabledMixin|disabled}</li>
 * <br>
 * <li>{@link module:mw-mixins/property/TagMixin|tag}</li>
 * <br>
 * <li>{@link module:mw-mixins/property/TextMixin|text}</li>
 * <br>
 * <li>{@link module:mw-mixins/property/ValidationMixin|validationState, errorText, warningText}</li>
 * <br>
 * <li>{@link module:mw-mixins/property/SizeMixin|width, height}</li>
 * <br>
 * <br>
 * <strong>Events:</strong>
 * <br>
 * <br>
 * <li>{@link module:mw-mixins/event/ChangeEventMixin|change}</li>
 * <br>
 * <br>
 * <strong>Example:</strong>
 * <br>
 * <br>
 * For a working example, see the {@link http://inside-files/dev/eps/WIT/WebWidgets/Gallery/Gallery/#checkbox_anchor|gallery}
 * <br>
 * <br>
 * @module mw-form/CheckBox
 *
 */

// TODO: Remove _CSSStateMixin dependency, as seen in SplitButton and ToggleSplitButton

define([
    "dojo/_base/declare",

    "dijit/_WidgetBase",

    "dijit/_Contained",
    "dijit/_CssStateMixin",
    "dijit/_FocusMixin",
    "dijit/_TemplatedMixin",

    "mw-mixins/property/TagMixin",
    "mw-mixins/property/CheckedMixin",
    "mw-mixins/property/DescriptionMixin",
    "mw-mixins/property/DisabledMixin",
    "mw-mixins/property/SizeMixin",
    "mw-mixins/property/TextMixin",
    "mw-mixins/property/VisualFamilyMixin",
    "mw-mixins/property/ValidationMixin",
    "mw-mixins/event/ChangeEventMixin",
    "mw-mixins/AccessibilityClickMixin",
    "mw-form/mixins/CheckBoxRadioButtonTextSizingMixin",
    "mw-mixins/PreventSelectionMixin",
    "mw-mixins/mixinDependencyValidator",

    "dojo/text!./templates/CheckBox.html"
], function (declare, _WidgetBase, _Contained, _CssStateMixin, _FocusMixin, _TemplatedMixin, TagMixin, CheckedMixin,
             DescriptionMixin, DisabledMixin, SizeMixin, TextMixin, VisualFamilyMixin, ValidationMixin,
             ChangeEventMixin, AccessibilityClickMixin, CheckBoxRadioButtonTextSizingMixin, PreventSelectionMixin,
             mixinDependencyValidator, template) {

    return declare(mixinDependencyValidator.validate([_WidgetBase, _Contained, _CssStateMixin, _FocusMixin,
        _TemplatedMixin, TagMixin, CheckedMixin, DescriptionMixin, DisabledMixin, SizeMixin, TextMixin,
        VisualFamilyMixin, ValidationMixin, ChangeEventMixin, AccessibilityClickMixin,
        CheckBoxRadioButtonTextSizingMixin, PreventSelectionMixin
    ]), {

        baseClass: "mwWidget mwCheckBox",
        templateString: template
    });
});
