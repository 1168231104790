/**
 * <strong>Purpose:</strong> <br>
 * Provides ability to manage closing of Menu
 * <br><br>
 * <strong>Contract:</strong> <br>
 *     Should be a container widget
 *
 * @module mw-mixins/OnCloseMixin
 *
 * @copyright Copyright 2014-2015 The MathWorks, Inc.
 */

define([
    "dojo/_base/declare",
    "mw-menu/mixins/OnOpenMixin"
], function (declare, OnOpenMixin) {
    var OnCloseMixin = declare(null, {
        _closeEventData: {},

        //DIJIT/_menuBase has methods onOpen and onClose
        onClose: function () {
            this._emitCloseEvent();
            this._closeEventData = {};
        },

        _emitCloseEvent: function () {
            this.emit("close", this._closeEventData);
        }
    });
    OnCloseMixin._dependencies = [
        {mixin: OnOpenMixin, orderDependent: false}
    ];
    return OnCloseMixin;

});

