/**
 * <strong>Purpose:</strong> <br><br>
 * Provides the ability to show a descriptive string for the widget either as a tooltip or at another dedicated place
 * in the widget.<br>
 * If the widget provides "descriptionNode" attach point, then the description string is placed inside this node.
 * The string is escaped and the white space is preserved. If the widget does not provide this attach point,
 * then the description string shows up as a tooltip on the domNode of the widget ie it is set to the value of the
 * "title" attribute
 * <br><br>
 * <strong>Attach-point Dependencies:</strong> <br><br>
 * <li>descriptionNode</li> <br>
 *
 *
 * @module mw-mixins/property/DescriptionMixin
 *
 * @copyright Copyright 2014-2017 The MathWorks, Inc.
 */

define([
    "dojo/_base/declare",
    "mw-event-utils/platformize"

], function (declare, platformize) {

    return declare(null, {
        /**
         * @property  {string} description Supports any unicode text
         * @instance
         * @default ""
         */
        description: "",

        postCreate: function () {
            this.inherited(arguments);
            this.domNode.classList.add("mwDescriptionMixin");

            this.set("description", this.get("description"));
        },

        // A description can either be displayed within the widget's DOM or as a tooltip.
        _setDescriptionAttr: function (description) {
            if (typeof description !== "string") {
                throw new Error("'description' property expects a literal string!");
            }

            this._updateDescription(description);
            this._set("description", description);
        },

        _updateDescription: function (description) {
            // this property represents the current description which will be displayed
            // on the widget. It could be description or descriptionOverride based on
            // which property is set.
            this._lastKnownDescription = description;

            if (this.descriptionNode) {
                // Use 'textContent' instead of innerText or innerHTML for performance and security
                // https://developer.mozilla.org/en-US/docs/Web/API/node.textContent
                this.descriptionNode.textContent = description;
                if(this.get("showDescription") === false) {
                    this.domNode.classList.add("mwHideDescription");
                } else {
                    if (description === '') {
                        this.domNode.classList.add("mwHideDescription");
                    } else {
                        this.domNode.classList.remove("mwHideDescription");
                    }
                }
            }
            // Otherwise show it as the native tooltip
            else {
                var that = this;
                
                setTimeout(function () {
                    if (!that._destroyed) {
                        that._updateTitle(description);
                    }
                }, 200);
            }
        },

        _updateTitle: function (value, isOriginShortcut) {
            var title;

            if (isOriginShortcut) {
                // need to check for undefined for the case when description or descriptionOverride is being set only
                if (value!=="" && value!==undefined) {
                    // remove extra space before the opening parentheses if description is empty
                    value = this._lastKnownDescription === "" ? value : " (" + value + ")";
                }

                title = this._lastKnownDescription + value;
            } else {
                if (this.get("shortcut")) {
                    // remove extra space before the opening parentheses if description is empty
                    title = value ? value + " (" + platformize.getPlatformSpecificString(this.get("shortcut")) + ")" : platformize.getPlatformSpecificString(this.get("shortcut"));
                } else {
                    title = value;
                }
            }

            this.domNode.setAttribute("title", title);
        }
    });
});