define([
    "./Deferred"
], function (Deferred) {


    var DeferredList = function (deferreds) {
        Deferred.apply(this);

        this._successes = 0;
        this._total = deferreds.length;

        var i;
        for (i = 0; i < this._total; i += 1) {
            deferreds[i].then(this._callback.bind(this), this.reject.bind(this));
        }
    };

    DeferredList.prototype = new Deferred();
    DeferredList.prototype.constructor = DeferredList;

    DeferredList.prototype._callback = function () {
        this._successes += 1;
        if (this._successes === this._total) {
            this.resolve();
        }
    };

    return DeferredList;
});
