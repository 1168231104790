// Copyright 2016, The MathWorks, Inc

define([
    "dojo/_base/declare",
    "dojo/_base/lang",
    "dojo/Evented",
    "dojo/Deferred",
    "dojo/aspect",
    "dojo/on",
    "../ContinuousGestureRecognizer",
    "../../util/RemovableGroup"
], function (declare, lang, Evented, Deferred, aspect, on, ContinuousGestureRecognizer, RemovableGroup) {

    return declare([ContinuousGestureRecognizer], {

        _recognizerTypes: {
            start: {},
            progress: [],
            end: [],
            cancel: []
        },

        constructor: function (args) {
            var that = this;

            this._recognizerHandlers = new RemovableGroup();
            this.own(on(this, "always", function () {
                that.reset();
                that._recognizerHandlers.remove();
            }));

            this._recognizers = {
                start: {},
                progress: [],
                end: [],
                cancel: []
            };
            this._recognizers.start = new this._recognizerTypes.start(args);
            this._recognizerTypes.progress.forEach(function (recognizerType) {
                that._recognizers.progress.push(new recognizerType(args));
            });
            this._recognizerTypes.end.forEach(function (recognizerType) {
                that._recognizers.end.push(new recognizerType(args));
            });
            this._recognizerTypes.cancel.forEach(function (recognizerType) {
                that._recognizers.cancel.push(new recognizerType(args));
            });

            aspect.after(this._recognizers.start, "onRecognizerStateChange", function () {
                var recognizerState = that._recognizers.start.getRecognizerState();
                if (recognizerState === that.RECOGNIZER_STATES.MATCHED) {
                    that.setRecognizerState(that.RECOGNIZER_STATES.MATCHED);
                }
            });

            this.own(on(that._recognizers.start, "discreteGestureOccurred", function (domEvent) {
                that._addTemporaryHandlers(that._recognizers.progress,
                    that._recognizers.end, that._recognizers.cancel);
                that.onStart(domEvent);
            }));
        },

        setInputCapturer: function (inputCapturer) {
            this.inherited(arguments);
            this._callGestureRecognizersFcn("setInputCapturer", inputCapturer);
        },

        enable: function () {
            this.inherited(arguments);
            this._callGestureRecognizersFcn("enable");
        },

        disable: function () {
            this.inherited(arguments);
            this._callGestureRecognizersFcn("disable");
        },

        prevents: function (blockedRecognizer) {
            this.inherited(arguments);
            this._callGestureRecognizersFcn("prevents", blockedRecognizer);
        },

        reset: function () {
            this.inherited(arguments);
            this._callGestureRecognizersFcn("reset");
        },

        setOptions: function (options) {
            this.inherited(arguments);
            this._callGestureRecognizersFcn("setOptions", options);
        },

        _callGestureRecognizersFcn: function (fcnName, args) {
            if (!this._recognizers) {
                return;
            }

            var applyCall = function (recognizer) {
                recognizer[fcnName].call(recognizer, args);
            };
            applyCall(this._recognizers.start);
            this._recognizers.progress.forEach(applyCall);
            this._recognizers.end.forEach(applyCall);
            this._recognizers.cancel.forEach(applyCall);
        },

        _addTemporaryHandlers: function (progressRecognizers, endRecognizers, cancelRecognizers) {
            var that = this;
            progressRecognizers.forEach(function (recognizer) {
                that._recognizerHandlers.own(on(recognizer, "discreteGestureOccurred", lang.hitch(that, "onProgress")));
            });
            endRecognizers.forEach(function (recognizer) {
                that._recognizerHandlers.own(on(recognizer, "discreteGestureOccurred", lang.hitch(that, "onEnd")));
            });
            cancelRecognizers.forEach(function (recognizer) {
                that._recognizerHandlers.own(on(recognizer, "discreteGestureOccurred", lang.hitch(that, "onCancel")));
            });
        }

    });

});