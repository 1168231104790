/**
 * <strong>Purpose:</strong> <br>
 * Provides the ability to specify a visual family for the widget. By specifying this per widget, each widget can get
 * a different visual. If no visual family is specified, the default will be used, which will be per the MathWorks
 * visual design standard.
 * <br><br>
 * <strong>Contract:</strong> <br>
 * The widget consumer will need to include the specified visual family's CSS.
 *
 * @module mw-mixins/property/VisualFamilyMixin
 *
 * @copyright Copyright 2014 The MathWorks, Inc.
 */
define([
    "dojo/_base/declare"
], function (declare) {

    var MW_PREFIX = "mw",
        VISUAL_FAMILY_SUFFIX = "VisualFamily";

    return declare(null, {

        /**
         * @property  {string} text  Currently support one value: "default"
         * @instance
         * @default
         */
        visualFamily: "default",

        _setVisualFamilyAttr: function (visualFamily) {
            if ((typeof visualFamily !== "string") || (visualFamily.length === 0)) {
                throw new Error("'visualFamily' property expects a literal non-empty string!");
            }

            var classNameToRemove = this._getClassNameForVisualFamily(this.get("visualFamily"));
            this.domNode.classList.remove(classNameToRemove);

            var classNameToAdd = this._getClassNameForVisualFamily(visualFamily);
            this.domNode.classList.add(classNameToAdd);

            this._set("visualFamily", visualFamily);
        },

        _getClassNameForVisualFamily: function (visualFamily) {
            return MW_PREFIX + visualFamily.charAt(0).toUpperCase() + visualFamily.slice(1) + VISUAL_FAMILY_SUFFIX;
        }

    });

});
