/**
 * Copyright 2014-2016 MathWorks, Inc.
 * This utility module provides platform(Mac/Windows) related support.
 * The getPlatformSpecificString method can be used to provide the sanitized and platformized
 * version of a string.
 * For example:
 * input: "Control+s" => Windows: "Ctrl+S" & Mac: "⌘S"
 * input: "cntrl_shift_enter" => Windows: "Ctrl+Shift+Enter" & Mac: "⌘⇧Enter"
 */

define([
    "dojo/sniff"
], function (sniff) {
    var stringMaps = [
        {
            regExp: /ctrl|cntrl|control/gi,
            macString: "\u2303",
            otherPlatformsString: "Ctrl"
        },
        {
            regExp: /meta/gi,
            macString: "\u2318",
            otherPlatformsString: "Ctrl"
        },
        {
            regExp: /shift/gi,
            macString: "\u21E7",
            otherPlatformsString: "Shift"
        },
        {
            regExp: /alt/gi,
            macString: "\u2325",
            otherPlatformsString: "Alt"
        },
        {
            regExp: /enter/gi,
            macString: "\u23CE",
            otherPlatformsString: "Enter"
        },
        // The order for del and delete is important here
        // If we flip the order, there will be a match for both "del" and "delete" as "del" is a substring of "delete"
        // del's unicode will be updated on mac, then "ete" of delete will be appended
        {
            regExp: /delete/gi,
            macString: "\u2326",
            otherPlatformsString: "Delete"
        },
        {
            regExp: /del/gi,
            macString: "\u2326",
            otherPlatformsString: "Del"
        },
        // The order for space and spacebar is important here
        {
            regExp: /Spacebar/gi,
            macString: "\u2423",
            otherPlatformsString: "Spacebar"
        },
        {
            regExp: /space/gi,
            macString: "\u2423",
            otherPlatformsString: "Space"
        },

        {
            regExp: /up/gi,
            macString: "\u2191",
            otherPlatformsString: "Up"
        },
        {
            regExp: /down/gi,
            macString: "\u2193",
            otherPlatformsString: "Down"
        },
        {
            regExp: /left/gi,
            macString: "\u2190",
            otherPlatformsString: "Left"
        },
        {
            regExp: /right/gi,
            macString: "\u2192",
            otherPlatformsString: "Right"
        }
    ];

    return {
        getPlatformSpecificString: function (str, options) {
            options = options || {};

            // Remove all spaces. For example: control + f => control+f
            str = str.replace(/ /g, "");

            // Replace underscores with '+'. For example: control_f => control+f
            str = str.replace(/_/g, "+");

            // Make everything uppercase. For example: control+f => CONTROL+F
            str = str.toUpperCase();

            // Convert all special keys to their corresponding platform equivalents.
            // For example: CONTROL+F => Ctrl+F or ⌘+F
            stringMaps.forEach(function (item) {
                var platformString;
                if (sniff("mac")) {
                    platformString = item.macString;

                    // By default, replace ^ with ⌘
                    if (!options.dontConvertCtrlToCmd && (platformString === "\u2303")) {
                        platformString = "\u2318";
                    }
                } else {
                    platformString = item.otherPlatformsString;
                }

                str = str.replace(item.regExp, platformString);
            });

            // Remove "+" in case of Mac only if it is not followed by any character or digit.
            // For example: ⌘+F => ⌘F and ⌘+ => ⌘+

            str = sniff("mac") ? str.replace(/\+(?!$)/g, "") : str;

            return str;
        }
    };
});
