// Copyright 2016-2017 MathWorks, Inc.

define([
    "dojo/_base/declare",
    "dojo/dom-class",
    "dojo/i18n!../l10n/nls/FileBrowserResources",
    "dgrid/editor",
    "dgrid/tree",
    "dijit/form/ValidationTextBox",
    "./helpers/FileIconUtils",
    "./helpers/NameComparator"
], function (declare, domClass, FileBrowserResources, editor, tree, ValidationTextBox,
             FileIconUtils, NameComparator) {
    return declare([], {
        constructor: function (args) {
            args = args || {};
            this.column = tree(editor({
                id: "nameColumn",
                label: FileBrowserResources.name,
                field: "name",
                renderHeaderCell: function (th) {
                    var nameColumn = document.createElement("div");
                    nameColumn.className = "columnHeaderLabel";
                    var labelNode = document.createTextNode(FileBrowserResources.name);
                    nameColumn.appendChild(labelNode);
                    th.appendChild(nameColumn);
                },
                renderCell: function (fileInfo, data, td, options) {
                    var cellData = document.createElement("span");
                    cellData.className = "dgrid-cell-data";

                    if (fileInfo.type !== "group") {
                        var icon = document.createElement("span");
                        icon.id = FileIconUtils.ICON_PLACE_HOLDER;
                        icon.className = FileIconUtils.getIconClass(fileInfo, this.grid._expanded[fileInfo.path]);
                        cellData.appendChild(icon);
                    }

                    var fileName = document.createTextNode(fileInfo.name);
                    cellData.appendChild(fileName);

                    if (fileInfo.type === "group") {
                        domClass.add(cellData, "group");
                    }
                    var infoLabelText = FileIconUtils.getInfoLabel(fileInfo);
                    if (infoLabelText) {
                        var infoLabel = document.createElement("span");
                        infoLabel.className = "infoLabel";
                        infoLabel.innerText = infoLabelText;
                        cellData.appendChild(infoLabel);
                    }
                    return cellData;
                },

                shouldExpand: function (row, level, previouslyExpanded) {
                    if (row.data.type === "group") {
                        return true;
                    } else {
                        return previouslyExpanded;
                    }
                },

                canEdit: function(row){
                    return row && !row.isGroupHeader;
                },

                expandOn: ".dgrid-expando-icon:click",
                editor: ValidationTextBox,
                editorArgs: {style: "width:100%;", trim: true, required: true},
                editOn: "renameEvent",
                sortable: true,
                sort: function (a, b) {
                    if (a.isDirectory || b.isDirectory) {
                        return NameComparator.separateFoldersFromFiles(a, b);
                    } else {
                        return NameComparator.caseSensitiveStringComparator(a.name, b.name);
                    }
                },
                unhidable: true,
                reorderable: false,
                setSharedOverlayIcon: FileIconUtils.setSharedOverlayIcon,
                setNodeClassName: FileIconUtils.setNodeClassName
            }));
        }
    });
});