define([
    "underscore",
    "jquery"
], function( _, $ ) {

    class LoginPageManager {

        constructor(options, config) {
            let defaults = {
                authContainerId: "authContainer",
                appContainerId: "appContainer",
                applicationTitle: ""
            };
            // options are either passed in, or use the default values
            if (typeof options == 'object') {
                options = {...defaults, ...options}; // Merge objects: override defaults with any passed in values
            } else {
                options = defaults;
            }
            // config should be provided and have a getLoginURL method -- if not, throw exception
            if (!config || !config.getLoginURL) {
                throw new Error("Config parameter missing or incorrect. Unable to configure login service connection.");
            }
            this.config = config;
            this.authContainerId = options.authContainerId;
            // The id of the container of the application itself
            this.appContainerId = options.appContainerId;
            // The title of the application to display above the login prompt
            this.appTitle = options.applicationTitle;
            this.loginTextContainerId = options.loginTextContainerId;
            this.loginFooterId = options.loginFooterId;
            this.loginLinkAreaId = options.loginLinkAreaId;
            this.clientString = "MLDO"; // default value
        }

        /*
         * Getters for the above items
         */
        getAuthContainer() {
            // if it is not set yet
            if (!this.authContainer) {
                // set it
                this.authContainer = $('#' + this.authContainerId); // jQuery ID selector
            }
            return this.authContainer;
        }
        getAppContainer() {
            if (!this.appContainer) {
                this.appContainer = $('#' + this.appContainerId);
            }
            return this.appContainer;
        }
        getAppContainerId() {
            return '#' + this.appContainerId;
        }
        getLoginTextContainerId() {
            return '#' + this.loginTextContainerId;
        }
        getLoginFooterId() {
            return '#' + this.loginFooterId;
        }
        getLoginLinkAreaId() {
            return '#' + this.loginLinkAreaId;
        }
        setClientString(clientString) {
            if (clientString && typeof clientString === "string") {
                this.clientString = clientString;
            }
        }
        getClientString() {
            return this.clientString;
        }
        setView(view) {
            if (view && typeof view === "string") {
                this.view = view;
            }
        }
        getView() {
            return this.view;
        }
        setInvitationId(invId) {
            if (invId && typeof invId === "string") {
                this.invId = invId;
            }
        }
        getInvitationId() {
            return this.invId;
        }

        /**
         * Start the embedded login form
         */
        start (clientString, errorText, view, invId) {
            this.setClientString(clientString);
            this.setView(view);
            this.setInvitationId(invId);
            // If command is set, go to Sharing page to complete action, else preview the invitation
            let path = view;
            if (!localStorage.getItem("mldo:command") && view === "sharing" && invId!== null && typeof invId !== "undefined") {
                path += "/" + invId;
            }
            window.location.href = this.config.getLoginURL() + path;
      /*      let $authCon = this.getAuthContainer();
            let $loginText = $(this.getLoginTextContainerId());
            let $linkArea = $(this.getLoginLinkAreaId());
            $authCon.show();
            $loginText.show();
            $linkArea.show();
            // Add special Text and turn on if provided.
            if (errorText && typeof errorText === "string" && errorText.length) {
                $('.errorTextContainer').show();
                $('.errorText').html(errorText);
            } else {
                $('.errorTextContainer').hide();
            }*/
        }
        /**
         * stop the login manager. Do any cleanup
         */
        stop() {
            window.removeEventListener('message', this.receiver, false);
        }
        /**
         * Reset the embedded login form so we can use it again (i.e. redirect to login)
         * Currently the iframe contents must be reloaded to work properly.
         */
        reset(keepLoad) {
            let $authCon = this.getAuthContainer();
            // Show the auth container and initialize the embedded login form.
            $authCon.show();
        }
        /**
         * Hide the embedded login widget container
         */
        hide() {
            let $loginText = $(this.getLoginTextContainerId());
            let $footer = $(this.getLoginFooterId());
            let $linkArea = $(this.getLoginLinkAreaId());
            $loginText.hide();
            $linkArea.hide();
            $footer.show();
            this.getAuthContainer().hide();
        }

        showFooter() {
            $(this.getLoginFooterId()).show();
        }

    }

    return LoginPageManager;
}); // require