/**
 * <strong>Properties:</strong><br>
 * <br>
 * <li>{@link module:mw-mixins/property/DescriptionMixin|description}</li>
 * <br>
 * <li>{@link module:mw-mixins/property/DisabledMixin|disabled}</li>
 * <br>
 * <li><a href=#items>items</a></li>
 * <br>
 * <li><a href=#selectionMode>selectionMode</a></li>
 * <br>
 * <li>{@link module:mw-mixins/property/TagMixin|tag}</li>
 * <br>
 * <li>{@link module:mw-mixins/property/ValidationMixin|validationState, errorText, warningText}</li>
 * <br>
 * <li><a href=#value>value</a></li>
 * <br>
 * <li>{@link module:mw-mixins/property/SizeMixin|width, height}</li>
 * <br>
 * <br>
 * <strong>Events:</strong>
 * <br>
 * <br>
 * <li>{@link module:mw-mixins/event/ChangeEventMixin|change}</li>
 * <br>
 * <br>
 * <strong>Example:</strong>
 * <br>
 * <br>
 * For a working example, see the {@link http://inside-files/dev/eps/WIT/WebWidgets/Gallery/Gallery/#listbox_anchor|gallery}
 * <br>
 * <br>
 * @module mw-form/ListBox
 *
 * @copyright Copyright 2015-2016 The MathWorks, Inc.
 */

// TODO: Remove _CSSStateMixin dependency, as seen in SplitButton and ToggleSplitButton

define([
    "dojo/_base/declare",
    "dojo/_base/lang",
    "dojo/dom-construct",
    "dojo/query",
    "dojo/on",
    "dojo/keys",

    // Dijit infrastructure
    "dijit/_WidgetBase",
    "dijit/_TemplatedMixin",
    "dijit/_CssStateMixin",
    "dijit/Destroyable",
    "dijit/Tooltip",

    // properties
    "mw-mixins/property/TagMixin",
    "mw-mixins/property/DisabledMixin",
    "mw-mixins/property/DescriptionMixin",
    "mw-mixins/property/SizeMixin",
    "mw-mixins/property/VisualFamilyMixin",

    "mw-mixins/property/ValidationMixin",

    // events
    "mw-mixins/event/ChangeEventMixin",

    // behavior
    "mw-mixins/PreventSelectionMixin",
    "mw-mixins/mixinDependencyValidator",

    //utils
    "mw-html-utils/HtmlUtils",

    "dojo/text!./templates/ListBox.html"

], function (declare, lang, domConstruct, query, on, keys, _WidgetBase, _TemplatedMixin,
             _CssStateMixin, Destroyable, Tooltip, TagMixin, DisabledMixin, DescriptionMixin, SizeMixin, VisualFamilyMixin, ValidationMixin, ChangeEventMixin,
             PreventSelectionMixin, mixinDependencyValidator, HtmlUtils, template) {


    // Define User interaction logic using the Strategy Design pattern

    var AbstractSelectionStrategy = declare(Destroyable, {

        constructor: function (widget) {

            this.widget = widget;

            this.own(
                on(widget.containerNode, "mousedown", lang.hitch(this, "_handleMouseDown")),
                on(widget.containerNode, "keydown", lang.hitch(this, "_handleKeyDown"))
            );
        },

        _handleMouseDown: function (event) {
            // if the user clicked in no-man's land, dont change the selection.
            // this can happen when the height of the list is taller than the number of items that it contains
            if (!event.target.classList.contains("mwListItem")) {
                return;
            }

            // get the index of the item from the data-index attribute in the DOM
            var clickedIndex = Number(event.target.getAttribute("data-mw-index")),
                didUserClickOnAlreadySelectedItem = this.widget._selectedIndices.indexOf(clickedIndex);

            var newSelectedIndices = this._getNewSelectedIndexOnMouseDown(event, clickedIndex, didUserClickOnAlreadySelectedItem);
            this._setSelectedItems(newSelectedIndices);
        },

        _handleKeyDown: function (event) {
            var newSelectedIndices = this._getNewSelectedIndexOnKeyDown(event);
            if (newSelectedIndices !== -1) {
                this._setSelectedItems(newSelectedIndices);
                // Scroll the new index into view, where newSelectedIndices is currently a 1-element array with the new index
                this.widget.scrollToIndex(newSelectedIndices[0]);
            }
        },

        _setSelectedItems: function (newSelectedIndices) {

            if (newSelectedIndices !== -1) {
                // null is to prevent change in selection

                var valuesCorrespondingToNewSelectedIndices = [],
                    oldValue = this.widget.get("value");

                // get the values corresponding to the new indexes
                newSelectedIndices.forEach(function (newSelectedIndex) {
                    valuesCorrespondingToNewSelectedIndices.push(this.widget._indexValueMap[newSelectedIndex]);
                }, this);
                this.widget.set("value", valuesCorrespondingToNewSelectedIndices);

                this.widget._triggerChangeEvent("value", oldValue, valuesCorrespondingToNewSelectedIndices);
            }
        },

        // Default implementation - used by both Single and Multiple Selection Strategise
        _getNewSelectedIndexOnKeyDown: function (event) {

            //if the list box does not have any items just return do nothing
            if (this.widget.items.length === 0) {
                return -1;
            }

            var currentSelectedIndex = this.widget._selectedIndices[0],
                indices = this.widget._getIndexes(),
                minIndex = Math.min.apply(null, indices),
                maxIndex = Math.max.apply(null, indices),
                newIndex = [];

            switch (event.keyCode) {
                case keys.UP_ARROW:
                    // if the user has reached the topmost item
                    if (currentSelectedIndex === minIndex ||
                        //if the user has reached top most item and unselect
                        (currentSelectedIndex === undefined && this._anchorIndex === minIndex)) {
                        // dont change the selection
                        newIndex = [minIndex];
                    } else if (currentSelectedIndex === undefined && this._anchorIndex === undefined) {
                        //incase of non of the items selected, make it maxindex
                        newIndex = [maxIndex];
                    } else if (currentSelectedIndex === undefined && this._anchorIndex !== undefined) {
                        //incase of non of the items selected, and it has anchor
                        newIndex = [this._anchorIndex - 1];
                    } else {
                        // go back one item
                        newIndex = [currentSelectedIndex - 1];
                    }
                    // Prevent additional scrolling 
                    event.stopPropagation();
                    event.preventDefault();
                    break;
            case keys.DOWN_ARROW:
                    // if the user has reached the bottom-most item
                    if (currentSelectedIndex === maxIndex ||
                        //if the user has reached bottom-most item and unselect
                        (currentSelectedIndex === undefined && this._anchorIndex === maxIndex)) {
                        // dont change the selection
                        newIndex = [maxIndex];
                    } else if (currentSelectedIndex === undefined && this._anchorIndex === undefined) {
                        //incase of non of the items selected, make it minindex
                        newIndex = [minIndex];
                    } else if (currentSelectedIndex === undefined && this._anchorIndex !== undefined) {
                        //incase of non of the items selected, and it has anchor
                        newIndex = [this._anchorIndex + 1];
                    } else {
                        // go forward one item
                        newIndex = [currentSelectedIndex + 1];
                    }
                    // Prevent additional scrolling 
                    event.stopPropagation();
                    event.preventDefault();                    
                    break;
                default:
                    newIndex = -1;
            }
            return newIndex;
        }
    });

    var SingleSelectionStrategy = declare(AbstractSelectionStrategy, {

        _getNewSelectedIndexOnMouseDown: function (event, clickedIndex, didUserClickOnAlreadySelectedItem) {

            var newSelectedIndex = [clickedIndex];

            // if the clicked item is the current selected item
            if (didUserClickOnAlreadySelectedItem >= 0) {
                // and if Ctrl-key on Windows(or Command Key on a Mac) is pressed
                if (event.ctrlKey || event.metaKey) {
                    // deselect it
                    newSelectedIndex = [];
                } else {
                    // the user simply clicked on the selected item(w/o holding down any meta key)
                    newSelectedIndex = -1;
                }
            }
            this._anchorIndex = clickedIndex;
            return newSelectedIndex;
        }

    });

    var MultipleSelectionStrategy = declare(AbstractSelectionStrategy, {


        constructor: function () {
            // _anchorIndex points to one end of the range of indexes for subsequent Shift + Click interactions
            // Updated on all interactions except Shift keypress
            this._anchorIndex = this.widget._selectedIndices[0] || 1;
        },

        _getNewSelectedIndexOnMouseDown: function (event, clickedIndex, didUserClickOnAlreadySelectedItem) {

            var newSelectedIndex = [clickedIndex],
                isShiftKey;

            // if the clicked item is the current selected item
            if (didUserClickOnAlreadySelectedItem >= 0) {

                if (event.shiftKey) {

                    newSelectedIndex = this._getRangeOfIndices(clickedIndex);

                } else {
                    // if Ctrl-key on Windows or Command Key on a Mac is pressed
                    if (event.ctrlKey || event.metaKey) {

                        // deselect the clicked item
                        newSelectedIndex = this.widget._selectedIndices.slice(0);
                        newSelectedIndex.splice(didUserClickOnAlreadySelectedItem, 1);


                    } else
                    // Ctrl-key not pressed
                    // and if there is only one currently selected item
                    if (this.widget._selectedIndices.length === 1) {
                        // the user simply clicked on the selected item(w/o holding down any meta key)
                        newSelectedIndex = -1;
                    }
                    isShiftKey = false;
                }
            } else {

                if (event.shiftKey) {
                    newSelectedIndex = this._getRangeOfIndices(clickedIndex);
                } else {
                    isShiftKey = false;

                    // user Ctrl-clicked on a different item than the currently selected item
                    if (event.ctrlKey || event.metaKey) {
                        // select it
                        newSelectedIndex = this.widget._selectedIndices.slice(0);
                        newSelectedIndex.push(clickedIndex);
                    }
                }
            }
            // save this for later potential Shift selection
            if(isShiftKey === false){
                this._anchorIndex = clickedIndex;
            }
            return newSelectedIndex;
        },


        // Helper method that returns the list of new indices that must be selected
        //
        // Example: if endIndex were 10 and startIndex was 5
        // then the following array is generated [5, 6, 7, 8, 9, 10]

        _getRangeOfIndices: function (endIndex) {
            var i,
                newSelectedIndex = [],
                startIndex = this._anchorIndex;

            if (endIndex <= startIndex) {
                for (i = endIndex; i <= startIndex; i += 1) {
                    newSelectedIndex.push(i);
                }
            } else {
                for (i = startIndex; i <= endIndex; i += 1) {
                    newSelectedIndex.push(i);
                }
            }
            return newSelectedIndex;
        }

    });

    return declare(mixinDependencyValidator.validate([_WidgetBase, _TemplatedMixin, _CssStateMixin, TagMixin, DisabledMixin, DescriptionMixin, SizeMixin, VisualFamilyMixin, ValidationMixin, ChangeEventMixin,
        PreventSelectionMixin]), {

        templateString: template,

        baseClass: "mwWidget mwListBox",

        /**
         * @property  {string} selectionMode - Supported Values: "single"/"multiple" .
         * When in single selection mode: zero or one of the items in the list can be selected.
         * In multiple selection mode: zero, one or more items can be selected
         * @instance
         * @default
         */
        selectionMode: "single",

        constructor: function () {
            // Internal data structures: straight and reverse mapping between index and value
            this._indexValueMap = {};
            this._valueIndexMap = {};

            /**
             * @property  {array} items -  Must be an array of objects, where each object should consist of
             * a label and a value property
             * @instance
             * @default []
             */
            this.items = [];

            /**
             * @property  {array} value -  Must be an array of values (one of the values from the items array of objects)
             * @instance
             * @default []
             */
            this.value = [];

            // Internal data structure to track selected-item.
            // This makes it relatively simpler to implement the Selection Strategies because the next and previous will
            // be simple + and -, based on the anchor index(explained in the Selection Strategy class)
            this._selectedIndices = [];
        },

        /**
         * Scroll to the given value
         *
         * If the item is not in view, the minimal amount of scrolling to make it completely in view will be applied
         * @instance
         * @param {Number/String} value - one of the user-specified values from the list
         */
        scrollToValue: function (value) {
            var indexCorrespondingToValue = this._valueIndexMap[value];

            this.scrollToIndex(indexCorrespondingToValue);
        },        

        /**
         * Scroll to the given index
         *
         * If the item is not in view, the minimal amount of scrolling to make it completely in view will be applied
         * @instance
         * @param {Number} index - a valid index of an item in the list
         */
        scrollToIndex: function(index) {            
            //get the element to scroll to based on the index value
            var itemNodes = this._getItemNodes();
            var itemNodeToScrollTo =
                itemNodes.filter(function (itemNode) {
                    return (Number(itemNode.getAttribute("data-mw-index")) === index);
                }, this)[0]; // filter() returns a 1-element array, get the element out of it

            if (itemNodeToScrollTo) {
                var domNode = this.domNode;
                if (itemNodeToScrollTo.offsetTop + itemNodeToScrollTo.offsetHeight > domNode.offsetHeight + domNode.scrollTop) {  
                    // scroll down
                    this.domNode.scrollTop = itemNodeToScrollTo.offsetTop + itemNodeToScrollTo.offsetHeight - this.domNode.offsetHeight; 
                } else if (itemNodeToScrollTo.offsetTop < domNode.scrollTop) { 
                    // scroll up
                    this.domNode.scrollTop = itemNodeToScrollTo.offsetTop;
                }  
            }     
        },        

        postCreate: function () {
            this.inherited(arguments);

            // When the default value is falsy, the setter will not be triggered during the widget's construction phase
            // We need to call the setter explicitly
            this.set("items", this.get("items"));
            this.set("value", this.get("value"));

            this.own(
                on(this.containerNode, "mouseover", lang.hitch(this, function (evt) {
                    var toolTipText = this._getEscapedToolTipString(evt);
                    if (toolTipText && toolTipText.length > 0) { // the first check is to ensure that the mouseover was over an actual item
                        Tooltip.show(toolTipText, evt.srcElement);
                    }
                })),
                on(this.containerNode, "mouseout", function (evt) {
                    Tooltip.hide(evt.srcElement);
                })
            );
            this._selectionStrategy = new this._selectionStrategyModule(this);

        },

        /**
         * Escape the html entities before sending the text to the dojo tooltip
         * as the dojo tooltip uses the innerHtml to show the content
         *
         */
        _getEscapedToolTipString: function (evt) {
            var toolTipText = "";
            var value = evt.srcElement.getAttribute("data-mw-tooltiptext");
            // srcElement will be having the complete string in case of longer strings
            // if the string is less than the size of the widget the srcElement value
            // is return as null
            if (value !== null) {
                toolTipText = HtmlUtils.escapeHtml(value);
            }
            return toolTipText;
        },

        startup: function () {
            this.inherited(arguments);
            this._updateTooltipForClippedItems();
            
        },

        /**
         * Updates the selectionStrategy, i.e. the logic that handles user interaction
         * based on if the selection mode is single or multiple
         * @param selectionMode: String "single" / "multiple"
         * @private
         */
        _setSelectionModeAttr: function (selectionMode) {

            if (selectionMode.toLowerCase().indexOf("single") === 0) {
                this._selectionStrategyModule = SingleSelectionStrategy;
            } else if (selectionMode.toLowerCase().indexOf("multiple") === 0) {
                this._selectionStrategyModule = MultipleSelectionStrategy;
            } else {
                throw new Error("Selection Mode should be one of: single, multiple");
            }
            // check for if the widget has been created, else the Dom elements wont be available
            if (this._created) {
                this._selectionStrategy.destroy();
                this._selectionStrategy = new this._selectionStrategyModule(this);
            }
            this._set("selectionMode", selectionMode);
        },

        // Updates the view with the new items
        // The itemLabels is used used to create items in the list by creating
        // <li> nodes under the <ul> node
        // @param itemLabels: Array of Strings
        _setItemsAttr: function (itemObjectList) {
            this._validateItemsArray(itemObjectList);
            var listToUpdateView = this._getItemsListAndInitInternalDs(itemObjectList);
            this._updateDom(listToUpdateView);
            // update the value property to be empty only when the items
            // change after the widget has been fully constructed
            // not during construction.
            if (this._created) {
                this.set("value", []);
            }
            this._set("items", itemObjectList);
            this._updateTooltipForClippedItems();
        },

        _setWidthAttr: function () {
            this.inherited(arguments);
            this._updateTooltipForClippedItems();
        },

        _setHeightAttr: function () {
            this.inherited(arguments);
            this._updateTooltipForClippedItems();
        },

        _validateItemsArray: function (items) {
            var errorString = 'The "items" must be an array of objects, where each object should consist of a ' +
                'label and a value property. Like: [ ' +
                '{label: "Item 1", value: 1}, ' +
                '{label: "Item 2", value: 2}, ' +
                '{label: "Item 3", value: 3}, ' +
                '{label: "Item 4", value: 4} ' +
                ']';

            if (!Array.isArray(items)) {
                throw new Error(errorString)
            } else {
                items.forEach(function (item) {
                    if (item.label === undefined || item.value === undefined) {
                        throw new Error(errorString);
                    }
                });
            }
        },

        // Makes a  subset of the items in the list the current value
        // @param values: Array
        _setValueAttr: function (valueArray) {
            this._validateValueArray(valueArray);

            this._set("value", valueArray);

            var selectedIndexesCorrespondingToValue = [];

            valueArray.forEach(function (value) {
                selectedIndexesCorrespondingToValue.push(this._valueIndexMap[value]);
            }, this);

            this._setSelectedIndex(selectedIndexesCorrespondingToValue);
        },

        _validateValueArray: function (valueArray) {
            if (!Array.isArray(valueArray)) {

                throw new Error("'value' property expects an array.");

            } else {

                if(this.get("selectionMode") === "single" && valueArray.length > 1){
                    throw new Error("'value' property must be a single element array in single selection mode.");
                }

                valueArray.forEach(function (value) {
                    if (!this.items.some(function (item) {
                        return item.value === value;
                    })) {
                        throw new Error("'value' must be a subset of the items' values.");
                    }
                }, this);
            }
        },

        // Helper method which:
        //  1.  Manages the internal _selectedIndices data-structure
        //  2.  Applies the 'selected' visual to the item in the list
        _setSelectedIndex: function (selectedIndex) {

            var itemNodes = this._getItemNodes();

            itemNodes.forEach(function (itemNode) {
                itemNode.classList.remove("mwListItemSelected");
            });

            // this is here because there is a potential return following this
            this._selectedIndices = selectedIndex;

            if (selectedIndex.length === 0) {
                return;
            }

            // Apply the CSS class on the <li> corresponding to the selectedIndex
            itemNodes.forEach(function (itemNode) {
                var itemNodeIndex = Number(itemNode.getAttribute("data-mw-index"));
                // simplify using indexof
                selectedIndex.forEach(function (index) {
                    if (index === itemNodeIndex) {
                        itemNode.classList.add("mwListItemSelected");
                    }
                });
            });
        },

        // Helper method
        // 1. to create an array of items to populate the <li>s
        // 2. to keep internal data structures updated

        _getItemsListAndInitInternalDs: function (itemsObjList) {
            var optionsList = [],
                listLength = itemsObjList.length,
                i;

            this._indexValueMap = {};
            this._valueIndexMap = {};

            if (listLength > 0) {
                for (i = 0; i < listLength; i += 1) {
                    var index = i + 1; // 1-based index to keep it simple
                    optionsList[i] = {
                        index: index,
                        label: itemsObjList[i].label,
                        value: itemsObjList[i].value
                    };
                    // update internal data-structures
                    this._indexValueMap[index] = itemsObjList[i].value;
                    this._valueIndexMap[itemsObjList[i].value] = index;
                }
            } else {
                optionsList = [];
            }
            return optionsList;
        },

        // Helper method to update the Dom of the <ul>
        // Each <li> is updated with a data-mw-index attribute
        // to keep track of its index
        // @param itemLabels
        //
        _updateDom: function (itemsObjList) {

            // Remove the current 'li' nodes
            domConstruct.empty(this.containerNode);

            if (itemsObjList.length > 0) {
                itemsObjList.forEach(function (itemObj) {
                    domConstruct.create("li", {
                        textContent: itemObj.label,
                        "data-mw-index": itemObj.index,
                        "data-mw-tooltiptext": "",
                        "class": "mwListItem"
                    }, this.containerNode);
                }, this);
            }
        },

        _updateTooltipForClippedItems: function () {
            var itemNodes = this._getItemNodes();
            itemNodes.forEach(function (itemNode) {
                var isClipped = itemNode.offsetWidth < itemNode.scrollWidth;
                if (isClipped) {
                    itemNode.setAttribute("data-mw-tooltiptext", itemNode.textContent);
                } else {
                    // Remove tooltip info for unclipped content
                    itemNode.setAttribute("data-mw-tooltiptext", "");
                }
            });
        },

        // Helper method which returns an array of the indices of each item
        // by looking at the data-mw-index value of each item Node
        _getIndexes: function () {
            var itemNodes = this._getItemNodes(),
                indices = [];
            itemNodes.forEach(function (itemNode) {
                indices.push(Number(itemNode.getAttribute("data-mw-index")));
            });

            return indices;
        },

        // returns array of item nodes
        _getItemNodes: function () {
            return query("li", this.containerNode);
        },

        /**
         * Following are QE utility methods for callback testing
         * */
        _qeSelectItemsByLabel: function(labels){
            var items = this.get("items");
            var valueToSet = items.filter(function(item){
                return labels.indexOf(item.label) > -1;
            }).map(function(item) {
                return item.value;
            });
            this.set("value", valueToSet);
        },

        _qeSelectItemsByIndex: function(indices) {
            var items = this.get("items");
            var valueToSet = items.filter(function(item, i){
                return indices.indexOf(i) > -1;
            }).map(function(item) {
                return item.value;
            });
            this.set("value", valueToSet);
        }
    });
});