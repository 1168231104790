/**
 * <strong>Purpose:</strong> <br><br>
 * Provides the ability to block interaction with the widget. This mixin disables interactivity with the widget
 * by preventing both mouse interactions and tabbing to this widget via the keyboard.
 * <br><br>
 * <strong>Attach-point Dependencies:</strong>
 * <li>focusNode</li><br><br>
 *
 * @module mw-mixins/property/DisabledMixin
 *
 * @copyright Copyright 2014-2016 The MathWorks, Inc.
 */

define([
    "dojo/_base/declare"
], function (declare) { 

    return declare(null, {

        /**
         * @property  {boolean} disabled  -  Supported Values: true/false
         * @instance
         * @default
         */
        disabled: false,

        postCreate: function () {
            this.inherited(arguments);
            this.domNode.classList.add("mwDisabledMixin");

            // When the default value is falsy, the setter will not be triggered during the widget's construction phase
            // We need to call the setter explicitly
            this.set("disabled", this.get("disabled"));
        },

        startup: function () {
            this.inherited(arguments);
            //After postCreate, dojoClick property is being set to True by a11yclick
            //Pressing space or enter on a disabled button the button should not emit click event
            if (this.get("disabled")) {
                this.domNode.dojoClick = false;
            }
        },

        _setDisabledAttr: function (disabled) {
            if (typeof disabled !== "boolean") {
                throw new Error("'disabled' property expects a boolean!");
            }

            var node = this.disabledNode ? this.disabledNode : this.domNode;

            if (disabled) {
                node.classList.add("mwDisabled");
                node.classList.remove("mwEnabled");
            } else {
                node.classList.add("mwEnabled");
                node.classList.remove("mwDisabled");
            }

            // g1347275 - Hover and Active state are monitored by _CSSStateMixin and are driven by mouse events.
            // But _CSSStateMixin stops reacting to mouse events when the widget is disabled which can cause the widget
            // to get stuck in a hover or active state even when the mouse has been moved out or unpressed.
            // The ideal solution is to not use _CSSStateMixin and use :hover and :active pseudo selectors everywhere,
            // but unfortunately we need to support IE and :active doesn't work as expected with nested elements.
            this.set("hovering", false);
            this.set("active", false);

            this._set("disabled", disabled);

            this._setAriaDisabled();

            // Duplicates _CssStateMixin behavior, but is needed for widgets that don't mix in _CssStateMixin
            this.baseClass.split(" ").forEach(function (className) {
                if (disabled) {
                    node.classList.add(className + "Disabled");
                } else {
                    node.classList.remove(className + "Disabled");
                }
            }.bind(this));
            
            if (disabled) {
                node.classList.add("dijitDisabled");
            } else {
                node.classList.remove("dijitDisabled");
            }

            this._setTabIndexOnNode(this.focusNode);
        },

        _setTabIndexOnNode: function (node) {
            if (this.get("disabled") && !this.get("dropDownNeverDisabled")) {
                // Remove the ability of the widget to be focused when disabled
                node.removeAttribute("tabIndex");
            } else {
                //TODO: when we support tabIndex as an API, set the appropriate tabIndex instead of 0
                node.setAttribute("tabIndex", "0");
            }
        },

        _setAriaDisabled: function () {
            var node = this.disabledNode ? this.disabledNode : this.domNode;
            node.setAttribute("aria-disabled", (this.get("disabled") && !this.get("dropDownNeverDisabled")) ? "true" : "false");
        }

    });

});
