define(["dojo/has"], function (has) {

    has.add("pointer", function (global) {
        return "PointerEvent" in global ? "pointer" :
            "MSPointerEvent" in global ? "MSPointer" : false;
    });

    var pointer = has("pointer"),
        MSPointer = pointer && pointer.slice(0, 2) === "MS";

    var hasPointer = {};
    hasPointer.pointer = pointer;
    hasPointer.MSPointer = MSPointer;

    return hasPointer;

});