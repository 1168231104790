/**
 * <strong>Purpose:</strong> <br>
 * Prevents user focusing a widget's nodes. By default focus is prevented on the domNode.
 * <br><br>
 * <strong>Contract:</strong> <br>
 * The widget can provide it's own set of nodes that it would like to prevent focus on by overriding the
 * _getNonFocusableNodes method.
 *
 * @module mw-mixins/PreventFocusMixin
 *
 * @copyright Copyright 2015 The MathWorks, Inc.
 */

define([
    "dojo/_base/declare",
    "dojo/on"
], function (declare, on) {

    return declare(null, {

        postCreate: function () {
            this.inherited(arguments);
            this._getNonFocusableNodes().forEach(function (node) {
                on(node, "mousedown", function (event) {
                    event.preventDefault();
                });
            });
        },

        // This method may be overridden by the widget using this mixin if they want different or more node(s) to be
        // non-focusable
        _getNonFocusableNodes: function () {
            return [this.domNode];
        }

    });

});
