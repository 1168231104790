/**
 * <strong>Purpose:</strong> <br>
 * Provides keyboard accessibility to invoke a click on the widget. When Enter & Space keys are pressed when this widget
 * is in focus, the click event is triggered.
 * <br><br>
 * <strong>Contract:</strong> <br>
 * The widget should be focusable.
 *
 * @module mw-mixins/AccessibilityClickMixin
 *
 * @copyright Copyright 2014 The MathWorks, Inc.
 */

define([
    "dojo/_base/declare",
    "dojo/_base/lang",
    "dojo/on",
    "dojo/keys"
], function (declare, lang, on, keys) {

    return declare(null, {

        postCreate: function () {
            this.inherited(arguments);

            this.own(on(this.domNode, "keydown", lang.hitch(this, function (evt) {
                // g1311166 - Use setTimeout to ensure that keydown logic finishes before the click is emitted
                setTimeout(lang.hitch(this, function () {
                    if (this.get("disabled") === true) {
                        return;
                    }

                    switch (evt.keyCode) {
                        case keys.ENTER:
                            this._emitClickEvent(evt);
                            break;
                        case keys.SPACE:
                            this._emitClickEvent(evt);
                            evt.preventDefault(); // prevent scrolling on spacebar
                            break;
                    }
                }), 0);
            })));

            //dojoClick + a11yclick do something similar to AccessibilityClickMixin. We want to prevent both from
            // firing click events, so we detect when a11yclick would fire (which occurs on keyup, not keydown like
            // we have here) and then stop the keyup event propagation
            this.own(on(this.domNode, "keyup", lang.hitch(this, function (evt) {
                var isClickKey = (evt.keyCode === keys.ENTER) || (evt.keyCode === keys.SPACE);
                var browserHandlesKeyboardClickNatively = /input|button|textarea/i.test(evt.target.nodeName);
                var dojoClickCouldFire = (isClickKey && !browserHandlesKeyboardClickNatively);
                if (dojoClickCouldFire) {
                    evt.stopPropagation();
                }
            })));
        },

        _emitClickEvent: function (evt) {
            on.emit(evt.target, "click", {
                bubbles: true,
                cancelable: true,
                mwEventData: {
                    keyCode: evt.keyCode
                },
                _dojo_click: true
            });
        },

        _qeClick: function () {
            this.emit("click");
        }
    });

});
