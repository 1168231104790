define([
], function (
) {
    class RecipientRecommendations {
        constructor (args) {
            this.fileService = args.fileService;
            this.path = args.path;
      }

      async updateRecommendations() {
        this._dataPromise = this.fileService.getRecipientRecommendations(this.path);
        return this._dataPromise;
      }

        async filter (searchString) {
            const result = await this._dataPromise;
            const data = result.recipientRecommendations
            searchString = searchString.toLocaleLowerCase()
            return data.map(x => {
                const name = `${x.firstName} ${x.lastName}`
                if((name && name.toLocaleLowerCase().includes(searchString)) || (x.emailAddress && x.emailAddress.toLocaleLowerCase().includes(searchString))) {
                    return {
                        fullName: name,
                        email: x.emailAddress
                    }
                }
        }).filter(x => !!x);
    }
    }
    return RecipientRecommendations;
});