/**
 * <strong>Purpose:</strong> <br>
 * Provides the ability to display text in multiple lines. Can accept the standard \n separated strings from
 * localization bundles. It escapes HTML and preserves whitespaces.
 * <br><br>
 * <strong>Contract:</strong> <br>
 * <em>Attach-point:</em> textNode <br>
 * The Mixin will add the multiple text nodes for each line of text into the textNode attach-point.
 *
 * @module mw-mixins/property/TextMixin
 *
 * @copyright Copyright 2014 The MathWorks, Inc.
 */
define([
    "dojo/_base/declare"
], function (declare) {

    return declare(null, {

        /**
         * @property  {string} text  Supports any unicode text
         * @instance
         */
        text: "",

        postCreate: function () {
            this.inherited(arguments);
            this.domNode.classList.add("mwTextMixin");

            // When the default value is falsy, the setter will not be triggered during the widget's construction phase
            // We need to call the setter explicitly
            this.set("text", this.get("text"));
        },

        _setTextAttr: function (text) {
            if (typeof text !== "string") {
                throw new Error("'text' property expects a literal string!");
            }

            this._updateTextInDOM(text);

            this._set("text", text);
        },

        _updateTextInDOM: function (text) {
            this._placeTextInNode(text);
            if (this._getTextForDOM(text).length === 0) {
                this.domNode.classList.add("mwNoText");
            } else {
                this.domNode.classList.remove("mwNoText");
            }
        },

        _placeTextInNode: function (text, node) {
            if (node === undefined) {
                node = this.textNode;
            }

            while (node.firstChild) {
                node.removeChild(node.firstChild);
            }

            var domText = this._getTextForDOM(text, node);

            if (domText.length > 0) {
			    // Create a <span> element and update its textContent property with the text
                // Use 'textContent' instead of innerText or innerHTML for performance and security
                // https://developer.mozilla.org/en-US/docs/Web/API/node.textContent
                var textSpanElement = document.createElement("span");
                textSpanElement.classList.add("mwTextLine");                
                textSpanElement.textContent = domText;
                node.appendChild(textSpanElement);
                node.classList.remove("mwEmptyNode");
            } else {
                node.classList.add("mwEmptyNode");
            }
        },

        /**
         * This method can be overridden in the cases where the text to be displayed in the DOM is differently formatted
         * than the actual text value.
         * @param text
         * @private
         */
        _getTextForDOM: function (text) {
            return text;
        }

    });

});
