// Copyright 2013-2017 MathWorks, Inc.

define([
    "dojo/_base/declare",
    "dojo/_base/lang",
    "dojo/aspect",
    "dojo/dom-class",
    "dojo/dom-style",
    "dojo/Evented",
    "dojo/has",
    "dojo/on",
    "dojo/text!./filebrowser.html",
    "dijit/_TemplatedMixin",
    "dijit/_WidgetBase",
    "dijit/Tooltip",
    "dgrid/extensions/DnD",
    "dgrid/Keyboard",
    "dgrid/OnDemandGrid",
    "dgrid/Selection",
    "dgrid/extensions/ColumnHider",
    "dgrid/extensions/ColumnReorder",
    "mw-filename-utils/FileNameUtil",
    "mw-gesture/GestureDetector",
    "mw-menu/ContextMenu",
    "./actions/contextmenu/ActionsContextMenuBuilder",
    "./actions/keyboard/KeyboardActionsBuilder",
    "./columns/ColumnHiderContextMenuBuilder",
    "./grid/draganddrop/FileBrowserGridDnDSource",
    "./grid/workarounds/ColumnResizer",
    "./grid/workarounds/DgridWorkarounds",
    "./grid/FileBrowserSortProperty",
    "./grid/GridHelper",
    "dojo/domReady!"
], function (declare, lang, aspect, domClass, domStyle, Evented, has, on,
             filebrowserTemplate, _TemplatedMixin, _WidgetBase, Tooltip, DnD,
             Keyboard, OnDemandGrid, Selection, ColumnHider, ColumnReorder,
             FileNameUtil, GestureDetector, ContextMenu,
             ActionsContextMenuBuilder, KeyboardActionsBuilder,
             ColumnHiderContextMenuBuilder, FileBrowserGridDnDSource,
             ColumnResizer, DgridWorkarounds, FileBrowserSortProperty,
             GridHelper) {
    /**
     * FileBrowser2 is a dgrid based file browser.  It responds to user interactions and triggers events to listening
     * modules.
     */

    var shouldGiveFocusBackToDgrid = false;

    var attachEventListenersForDragAndDropUpload = function (that) {
        that.fileBrowserContainer.ondragstart = function (event) {
            if (isAcceptableDataTransferType(event.dataTransfer)) {
                event.preventDefault();
            }
        };
        function isAcceptableDataTransferType(dataTransfer) {
            return dataTransfer.types.length > 0 && dataTransfer.types[0] === "Files";
        }

        that.fileBrowserContainer.ondragenter = function (event) {
            if (isAcceptableDataTransferType(event.dataTransfer)) {
                event.preventDefault();
                domClass.add(that.fileBrowserContainer, "indicateDropZone");
            }
        };
        that.fileBrowserContainer.ondragleave = function (event) {
            event.preventDefault();
            if (event.target.className.indexOf("indicateDropZone") >= 0) {
                domClass.remove(that.fileBrowserContainer, "indicateDropZone");
            }
            event.dataTransfer.dropEffect = "none";
        };
        that.fileBrowserContainer.ondragover = function (event) {
            if (isAcceptableDataTransferType(event.dataTransfer)) {
                event.preventDefault();
                event.dataTransfer.dropEffect = "copy";
            }
        };
        that.fileBrowserContainer.ondragend = function (event) {
            event.preventDefault();
        };
        that.fileBrowserContainer.ondrop = function (event) {
            event.preventDefault();
            domClass.remove(that.fileBrowserContainer, "indicateDropZone");
            that.emit("fileBrowserDropEvent", event);
        };
    };

    var nameColumnField = "name";

    return declare([_WidgetBase, _TemplatedMixin, Evented], {
        templateString: filebrowserTemplate,
        idAttr: "data-tag",
        collapseIcon: "collapseIcon",
        expandIcon: "expandIcon",
        // constructor cannot rely on DOM being ready
        constructor: function (args) {
            args = args || {};
            this.fileService = args.fileService;
            this.actionServices = args.actionRegistry;
            this.columnRegistry = args.columnRegistry;
            this.contextMenuEnabled = args.contextMenuEnabled;
            this.allowDnD = args.allowDnD;
            this.keybindings = args.keybindings;
        },

        // postCreate can rely on the DOM being ready
        postCreate: function () {
            this.inherited(arguments);

            // Current path display
            this.tooltip = new Tooltip({
                connectId: this.currentFolderNode,
                position: ["above", "after"]
            });

            // set default sort
            if (!FileBrowserSortProperty.getFBCompareFunction()) {
                var defaultCompareFunction = this.columnRegistry.getCompareFunction(nameColumnField, false);
                FileBrowserSortProperty.setFBSortProperties(defaultCompareFunction, nameColumnField, false);
            }

            // Create a new instance of the dgrid including all the extensions and plugins that are
            // required.
            this.own(
                this.grid = declare([OnDemandGrid, Keyboard, Selection, DnD, ColumnHider, ColumnReorder, ColumnResizer, DgridWorkarounds, GridHelper])({
                    minRowsPerPage: 1000,
                    sort: FileBrowserSortProperty.getFBCompareFunction(),
                    store: this.fileService.fileStore.store,
                    columns: this.columnRegistry.getColumns(),
                    selectionMode: 'custom',
                    allowSelectAll: true,
                    deselectOnRefresh: false,
                    cellNavigation: false,
                    dndConstructor: FileBrowserGridDnDSource,
                    dndParams: {
                        allowNested: true,
                        skipForm: true,
                        isSource: this.allowDnD
                    }
                }, this.dgridContainer),
                aspect.before(this.grid, "removeRow", lang.hitch(this, this.grid.handleRemoveRow), true),
                aspect.after(this.grid, "expand", lang.hitch(this, this.onExpandOrCollapse), true),
                aspect.after(this.grid.dndSource, "onFilesDrop", lang.hitch(this, "onFilesDrop"), true)
            );

            if (this.contextMenuEnabled) {
                this.own(
                    this.grid.contextMenu = new ContextMenu({
                        willOpenCallback: lang.hitch(this, this._updateContextMenu),
                        targetNodes: [".fileBrowser2 .dgrid-row", ".fileBrowser2 .dgrid-row *:not(.dgrid-input)", ".fileBrowser2 .dgrid-content"]
                    }),
                    this.grid.headerContextMenu = new ContextMenu({
                        targetNodes: [".fileBrowser2 .dgrid-header"]
                    })
                );
            }

            if (this.grid.headerContextMenu) {
                ColumnHiderContextMenuBuilder.buildContextMenu(this.grid.headerContextMenu, this.columnRegistry, this.grid, lang.hitch(this, this.updateColumnVisible));
            }

            this.own(
                aspect.after(this.fileService, "onMove", lang.hitch(this, this._handleMoveFile), true),
                aspect.after(this.fileService, "onRename", lang.hitch(this, this._handleRenameFile), true)
            );

            this.grid.on("dgrid-editor-show", lang.hitch(this, this._initializeEditor));
            this.grid.on("dgrid-datachange", lang.hitch(this, this._handleFileNameChanged));

            this.grid.on(".dgrid-content:mousedown", lang.hitch(this, this._handleWhiteSpaceMouseDown));
            this.grid.on(".dgrid-row:dblclick", lang.hitch(this, this._handleRowDoubleClick));
            this.grid.on("dgrid-select", lang.hitch(this, this._handleGridSelectionChange));
            this.grid.on("dgrid-deselect", lang.hitch(this, this._handleGridSelectionChange));
            this.grid.on("dgrid-sort", lang.hitch(this, this._handleSort));

            if (has("ios")) {
                var gestureDetector = new GestureDetector(this.grid.domNode);
                var fileBrowser = this;
                gestureDetector.events.doubleTap.addEventHandler(function (domEvent) {
                    fileBrowser._handleRowDoubleClick(domEvent);
                });
            }
            attachEventListenersForDragAndDropUpload(this);
            this.hiddenFileInputForUpload.onchange = lang.hitch(this, this._handleFileInputChange, this.hiddenFileInputForUpload, this.uploadForm);

            KeyboardActionsBuilder.buildKeyMap(this.actionServices, this);
        },

        /**
         *
         * @param columnId - column ID (e.g., sizeColumn, typeColumn) as a string
         * @param makeVisible - true if columnId should be made visible; false if columnId should be made hidden
         */
        updateColumnVisible: function (columnId, makeVisible) {
            this.grid.toggleColumnHiddenState(columnId, !makeVisible); // update column visibility

            // update header context menu checkboxes
            var headerContextMenuChildren = this.grid.headerContextMenu.getChildren();
            for (var checkboxIndex = 0; checkboxIndex < headerContextMenuChildren.length; checkboxIndex++) {
                var checkbox = headerContextMenuChildren[checkboxIndex];
                // Once we've found the matching checkbox, update its "checked" value
                if (checkbox.id === columnId) {
                    checkbox.set("checked", makeVisible);
                    break;
                }
            }

            // emit colChange event to
            this.emit("columnstatechange", {"columnId": columnId, "makeVisible": makeVisible});
        },

        _handleFileInputChange: function (fileInput, uploadForm) {
            this.onFileInputChange(fileInput, uploadForm);
        },

        /**
         *  start the grid up and set the initial sort to name
         */
        startupFB: function () {
            this.grid.startup();
        },

        renameFile: function (fileInfos) {
            var cell = this.grid.cell(fileInfos[0], this.columnRegistry.getColumn(nameColumnField).id);
            on.emit(cell.element, "renameEvent", {});
        },

        _initializeEditor: function (event) {
            var editorDomNode = event.editor.domNode;
            on(editorDomNode, "click, dblclick, contextmenu", function (event) {
                event.stopPropagation();
            });
            var fileInfo = this.grid.row(event).data;
            var currentName = fileInfo.name;
            var numSelectedChars = (currentName.lastIndexOf(".") === -1 || fileInfo.isDirectory) ? currentName.length :
                currentName.lastIndexOf(".");
            var obj = event.editor.textbox;
            if (obj.createTextRange) {
                event.preventDefault();
                //For IE.
                var range = obj.createTextRange();
                range.collapse();
                range.moveStart('character', 0);
                range.moveEnd('character', numSelectedChars);
                range.select();
            } else {
                obj.setSelectionRange(0, numSelectedChars);
            }
        },

        _handleFileNameChanged: function (event) {
            event.preventDefault();
            this.onFileNameChanged(this.grid.row(event).data, event.value);
        },

        /**
         *   This method handles resizing the fileBrowser and the dgrid widget.
         * @param changeSize
         */
        resize: function (changeSize) {
            // The containing domNodes and the dgrid should be re-sized every time the cfb module is resized.
            domStyle.set(this.domNode, {
                height: changeSize.h.toString() + "px",
                width: changeSize.w.toString() + "px"
            });
            domStyle.set(this.dgridContainer, {
                height: changeSize.h.toString() + "px",
                width: changeSize.w.toString() + "px"
            });

            this.grid.resize();
        },

        changeCurrentFolder: function (currentFolderInfo) {
            // g999378. Set the query for the dgrid's store, which is the first level of the current folder.
            // Children of open subfolders are queried from the Store's getChildren method, not here.
            this.grid.setCurrentFolderInfo(currentFolderInfo);
            this.grid.set("query", {parent: FileNameUtil.pathFromFileInfo(currentFolderInfo)});
            this.grid.clearSelection();
            this.onSelectionChange(this.grid.getSelectedFileInfos(), this.grid.getSelectedFilePaths());
            if (shouldGiveFocusBackToDgrid) {
                this.grid._focusedNode = null;
                this.grid.focus();
                this.setShouldGiveFocusBackToDgrid(false);
            }
        },

        setShouldGiveFocusBackToDgrid: function (bolValue) {
            shouldGiveFocusBackToDgrid = bolValue;
        },

        _updateCompareFunction: function (field, isDescending) {
            var compareFunction = this.columnRegistry.getCompareFunction(field, isDescending);
            FileBrowserSortProperty.setFBSortProperties(compareFunction, field, isDescending);
            this.grid.set("sort", compareFunction);
        },

        _updateContextMenu: function (event) {
            if (this.contextMenuEnabled) {
                ActionsContextMenuBuilder.buildContextMenu(this.grid.contextMenu, this.actionServices, this.grid.getSelectedFilePaths(), this.keybindings);
            } 
        },

        _handleSort: function (event) {
            event.preventDefault();
            var newSort = event.sort[0];
            var currentSort = FileBrowserSortProperty.getFBSortArray()[0];
            var isDescending = currentSort.attribute === newSort.attribute && !currentSort.descending;
            this._updateCompareFunction(newSort.attribute, isDescending);
        },

        _handleMoveFile: function (fileInfo) {
            var newSelectedRow;
            var newFilePath = FileNameUtil.pathFromFileInfo(fileInfo);
            var parentPath = FileNameUtil.getParentPath(newFilePath);
            if (this.grid._expanded[parentPath] ||
                parentPath === FileNameUtil.pathFromFileInfo(this.fileService.getCurrentFolderInfo())) {
                // select the moved file if the parent is expanded or the parent is the current folder (white space drop)
                newSelectedRow = newFilePath;
            } else {
                // select the collapsed parent of the moved file
                newSelectedRow = parentPath;
            }
            this.grid.clearRemovedFocus();
            this.grid.focus(this.grid.row(newSelectedRow).element);
        },

        _handleRenameFile: function (fileInfo) {
            this.grid.clearRemovedFocus();
            this.grid.focus(this.grid.row(FileNameUtil.pathFromFileInfo(fileInfo)).element);
        },

        _handleWhiteSpaceMouseDown: function (event) {
            //if there is a selection deselect it.
            if (!this.grid.row(event)) {
                this.grid.clearSelection();
            }
        },

        _handleRowDoubleClick: function (event) {
            var row = this.grid.row(event);
            if (row && !row.data.isGroupHeader) {
                this.onDoubleClick(row.data);
            }
        },

        _handleGridSelectionChange: function () {
            this.onSelectionChange(this.grid.getSelectedFileInfos(), this.grid.getSelectedFilePaths());
        },

        // Events listened by CurrentFolderBrowser
        onSelectionChange: function (fileInfos, filePaths) {
        },

        onDoubleClick: function (fileInfo) {
        },

        onFilesDrop: function (sourceFileInfos, targetFileInfo, copy) {
        },

        onFileNameChanged: function (fileInfo, newName) {
        },

        //icon column
        onExpandOrCollapse: function (row) {
            var rowId = this.grid.row(row).id;
            var fileInfo = this.grid.row(row).data;
            if (fileInfo && !fileInfo.isGroupHeader) {
                var isExpanded = !!this.grid._expanded[rowId];
                if (this.fileService.onExpandOrCollapse) {
                    this.fileService.onExpandOrCollapse(rowId, isExpanded);
                }
            }
            if (row.data && row.data.isDirectory) {
                this.columnRegistry.getColumn("name").setNodeClassName(row, this.grid._expanded[row.id]);
            }
        }
    });
});