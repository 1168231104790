/**
 * <strong>Purpose:</strong> <br>
 * Provides utilities for working with DOM elements including functions to help with
 * the ability to determine the position and visibility of DOM elements based on obscuring components,
 * and finding and focusing DOM elements relative to other DOM elements.
 * <br><br>
 *
 * @module MW/utils/domUtils
 *
 * @copyright Copyright 2015-2016 The MathWorks, Inc.
 */

define([
    "dojo/sniff"
], function (has) {

    // Local Variables
    var domUtils = {},
        errorMessages = {
            INVALID_ELEMENT: "The input parameter must be an instance of an Element object.",
            INVALID_STRING: "The input parameter must be of type 'string'.",
            INVALID_BOOLEAN: "The input parameter must be of type 'boolean'.",
            INVALID_OBJECT: "The input parameter must be of type 'object'.",
            INVALID_FOCUS_EVENT: "The input parameter must be a 'FocusEvent' object.",
            INVALID_STYLE: "The style specified is invalid.",
            INVALID_VALUE: "The value specified is invalid.",
            INTEGER_INDEX: "index argument expects an integer.",
            STRING_INDEX: "index argument expects an string.",
            SPOUSE_NODE: "A function which returns a spouse node if it exists must be provided."
        },
        validateElement = function (/*Element*/ element) {
            if (!(element instanceof Element)) {
                throw new Error(errorMessages.INVALID_ELEMENT);
            }
        },
        validateString = function (/*String*/ value) {
            if (["String", "string"].indexOf(typeof value) === -1) {
                throw new Error(errorMessages.INVALID_STRING);
            }
        },
        validateBoolean = function (/*Boolean*/ value) {
            if (["Boolean", "boolean"].indexOf(typeof value) === -1) {
                throw new Error(errorMessages.INVALID_BOOLEAN);
            }
        },
        validateObject = function (/*Object*/ value) {
            if (["Object", "object"].indexOf(typeof value) === -1) {
                throw new Error(errorMessages.INVALID_OBJECT);
            }
        };

    domUtils._qeSetup = function () {
        domUtils._qeTools = {};
        domUtils._qeTools.errorMessages = errorMessages;
        domUtils._qeTools.validateElement = validateElement;
        domUtils._qeTools.validateString = validateString;
        domUtils._qeTools.validateBoolean = validateBoolean;
        domUtils._qeTools.validateObject = validateObject;
        domUtils._qeTools.overrideErrorMessaged = function (override) {
            errorMessages = override;
        };
        domUtils._qeTools.overrideValidateElement = function (override) {
            validateElement = override;
        };
        domUtils._qeTools.overrideValidateString = function (override) {
            validateString = override;
        };
        domUtils._qeTools.overrideValidateBoolean = function (override) {
            validateBoolean = override;
        };
        domUtils._qeTools.overrideValidateObject = function (override) {
            validateObject = override;
        };
    };

    // Events
    domUtils.isLeftMouseButton = function (/*Event*/ event) {
        if ((event.synthetic === true) && has("trident")) {
            return event.button === 1;
        } else {
            return event.button === 0;
        }
    };

    domUtils.parseFocusEvent = function (/*Event*/ event) {
        if (!(event instanceof FocusEvent)) {
            throw new Error(errorMessages.INVALID_FOCUS_EVENT);
        }

        var evtData = {};

        evtData.lostFocusNode = event.target;
        evtData.gainedFocusNode = (has("chrome") || has("safari")) ? event.relatedTarget : document.activeElement;
        evtData.type = event.type;
        evtData.eventdata = event;

        return evtData;
    };

    // General
    domUtils.getParent = function (/*Element*/ element) {
        validateElement(element);

        return element.parentNode;
    };

    domUtils.findParentNode = function (node, /*String*/ selector, /*String?*/ modifier) {
        var parent = null, parentPopupId;

        if (node.domNode) {
            node = node.domNode;
        }

        while (node && node !== window.document) {
            if (modifier) {
                if (node.hasAttribute(selector) && (node.getAttribute(selector) === modifier)) {
                    parent = node;
                    break;
                }
            } else {
                if (node.hasAttribute(selector)) {
                    parent = node;
                    break;
                }

                if (node.classList.contains(selector)) {
                    parent = node;
                    break;
                }
            }

            parentPopupId = node.getAttribute("dijitpopupparent");

            // If the widget is in one of the popups, then go up the popup parent chain
            if (parentPopupId) {
                node = document.getElementById(parentPopupId);
                // If its not one of the popups, then go to parent
            } else {
                node = node.parentNode;
            }
        }

        return parent;
    };

    domUtils.getNodeType = function (/*Element*/ element) {
        var nodeTypeMap = {
            1: "Element",
            3: "Text",
            7: "Processing Instruction",
            8: "Comment",
            9: "Document",
            10: "Document Type",
            11: "Document Fragment"
        };

        validateElement(element);

        return nodeTypeMap[element.nodeType];
    };

    // DOM Queries
    domUtils.getById = function (/*String*/ id) {
        validateString(id);
        return window.document.getElementById(id);
    };

    domUtils.getByClass = function (/*String*/ className) {
        validateString(className);
        return window.document.getElementsByClassName(className);
    };

    domUtils.getByAttr = function (/*String*/ attr) {
        validateString(attr);
        return window.document.querySelectorAll("[" + attr + "]"); //data-foo=value
    };

    domUtils.getByAttrValue = function (/*String*/ attr, /*String*/ value) {
        validateString(attr);
        validateString(value);
        return window.document.querySelectorAll("[" + attr + "=" + value.replace(/['".\\]/g, '\\$&').replace(/[\\]/g, '\\$&') + "]");
    };

    domUtils.getByName = function (/*String*/ name) {
        validateString(name);
        return window.document.getElementById("[name=" + name + "]");
    };

    domUtils.getByTag = function (/*String*/ tag) {
        validateString(tag);
        return window.document.getElementsByTagName(tag);
    };

    domUtils.matchesSelector = function (/*Element*/ element, /*String*/ selector) {
        var matchesMethod;

        if (!element || !selector) {
            return false;
        }

        validateElement(element);
        validateString(selector);

        if (element.msMatchesSelector) {
            matchesMethod = "msMatchesSelector";
        } else {
            matchesMethod = "matches";
        }

        return element[matchesMethod](selector);
    };

    // Styles
    domUtils.getComputedStyle = function (/*Element*/ element, /*String*/ style) {
        validateElement(element);
        if (!style) {
            // Second argument to window.getComputedStyle is for pseudo-elements,
            // and is not needed in this utility
            return window.getComputedStyle(element, null);
        } else {
            validateString(style);
            return window.getComputedStyle(element, null)[style];
        }
    };

    domUtils.getStyle = function (/*Element*/ element, /*String*/ style) {
        validateElement(element);
        validateString(style);
        if (element.style[style] === undefined) {
            throw new Error(errorMessages.INVALID_STYLE);
        }
        return element.style[style];
    };

    domUtils.setStyle = function (/*Element*/ element, /*String*/ style, /*String*/ value) {
        validateElement(element);
        validateString(style);
        validateString(value);
        element.style[style] = value;
        if (element.style[style] !== value) {
            throw new Error(errorMessages.INVALID_VALUE);
        }
    };

    // Attributes
    domUtils.hasAttr = function (/*Element*/ element, /*String*/ name) {
        validateElement(element);
        validateString(name);

        return element.hasAttribute(name);
    };
    domUtils.hasAttrValue = function (/*Element*/ element, /*String*/ name, /*String*/ value) {
        validateElement(element);
        validateString(name);
        validateString(value);

        return element.hasAttribute(name) && (element.getAttribute(name) === value);
    };
    domUtils.getAttr = function (/*Element*/ element, /*String*/ name) {
        validateElement(element);
        validateString(name);

        return element.getAttribute(name);
    };
    domUtils.setAttr = function (/*Element*/ element, /*String*/ name, /*String*/ value) {
        validateElement(element);
        validateString(name);
        validateString(value);

        element.setAttribute(name, value);
    };
    domUtils.addAttr = function (/*Element*/ element, /*String*/ name) {
        validateElement(element);
        validateString(name);

        element.setAttribute(name,'');
    };
    domUtils.removeAttr = function (/*Element*/ element, /*String*/ name) {
        validateElement(element);
        validateString(name);

        element.removeAttribute(name);
    };

    // Classes
    domUtils.getClasses = function (/*Element*/ element) {
        validateElement(element);

        if (element.classList) {
            return element.classList;
        } else {
            // Mimic a DOMTokenList
            return {
                _element: element,
                _getClassArray: function () {
                    return this._element.className.split(" ");
                },
                _updateClassName: function (arr) {
                    this._element.className = arr.join(" ");
                    this.length = arr.length;
                },
                length: (function () {
                    return element.className.split(" ").length;
                })(),
                item: function (idx) {
                    return this._getClassArray()[idx];
                },
                contains: function (token) {
                    var arr = this._getClassArray();
                    for (var i = 0; i < arr.length; i++) {
                        if (arr[i] === token) {
                            return true;
                        }
                    }
                    return false;
                },
                add: function (val) {
                    var arr = this._getClassArray();
                    arr.push(val);
                    this._updateClassName(arr);
                },
                remove: function (val) {
                    var arr = this._getClassArray();
                    var idx = arr.indexOf(val);
                    if (idx >= 0) {
                        arr.splice(idx, 1);
                        this._updateClassName(arr);
                    }
                },
                toggle: function (val) {
                    if (this.contains(val)) {
                        this.remove(val);
                        return false;
                    } else {
                        this.add(val);
                        return true;
                    }
                }
            };
        }
    };

    domUtils.getClass = function (/*Element*/ element, /*Number*/ index) {
        if ((index == null) || ((typeof index) !== "number") || (index < 0) || ((index % 1) !== 0)) {
            throw new Error(errorMessages.INTEGER_INDEX);
        }

        return domUtils.getClasses(element).item(index);
    };

    domUtils.addClass = function (/*Element*/ element, /*String*/ className) {
        if ((className == null) || ((typeof className) !== "string")) {
            throw new Error(errorMessages.STRING_INDEX);
        }

        return domUtils.getClasses(element).add(className);
    };

    domUtils.removeClass = function (/*Element*/ element, /*String*/ className) {
        if ((className == null) || ((typeof className) !== "string")) {
            throw new Error(errorMessages.STRING_INDEX);
        }

        return domUtils.getClasses(element).remove(className);
    };

    domUtils.toggleClass = function (/*Element*/ element, /*String*/ className, /*Boolean*/ value) {
        if ((className == null) || ((typeof className) !== "string")) {
            throw new Error(errorMessages.STRING_INDEX);
        }

        if (value) {
            return domUtils.getClasses(element).toggle(className, value);
        } else {
            return domUtils.getClasses(element).toggle(className);
        }
    };

    domUtils.containsClass = function (/*Element*/ element, /*String*/ className) {
        if ((className == null) || ((typeof className) !== "string")) {
            throw new Error(errorMessages.STRING_INDEX);
        }

        return domUtils.getClasses(element).contains(className);
    };

    domUtils.classCount = function (/*Element*/ element) {
        return domUtils.getClasses(element).length;
    };

    // Containers
    domUtils.getChildCount = function (/*Element*/ element) {
        validateElement(element);

        return element.childElementCount;
    };

    domUtils.getChildren = function (/*Element*/ element) {
        validateElement(element);

        // Use element.children instead of elements.childNodes because we only care about elements of nodeType === 1
        return element.children;
    };

    domUtils.isDescendant = function(/*Element*/ child, /*Element*/ ancestor, /*Boolean?*/ inclusive){
        validateElement(child);
        validateElement(ancestor);

        if (inclusive) {
            if(child === ancestor){
                return true;
            }
        }
        while(child && child !== window.document){
            child = domUtils.getParent(child);
            if(child === ancestor){
                return true;
            }
        }
        return false;
    };

    // In the DOM hierarchy, an in-law is a node who is not a direct descendant or ancestor, but has
    // a similar relationship through a spouse node.  A spouse node is a node who is "married" to
    // another node semantically, but not a descendant or ancestor in the dom hierarchy.  For
    // example a spouse node to a drop down button would be the popup node, and the popup's
    // descendant elements would be in-laws of the drop down and all of it's descendants and
    // ancestors.
    // Note: This utility includes spouses in the descendant hierarchy.
    domUtils.isDescendantOrInLaw = function (/*Element*/ child, /*Element*/ ancestor,
      /*Function*/ getSpouseOfNode, /*Boolean?*/ inclusive) {
        var parentOrSpouse, isDescendant;

        if (typeof getSpouseOfNode !== "function") {
            throw new Error(errorMessages.SPOUSE_NODE);
        }

        // node validation is performed by domUtils.isDescendant, so no need to duplicate that effort here

        isDescendant = domUtils.isDescendant(child, ancestor, inclusive);

        if (isDescendant === false) {
            var getParentOrSpouse = function (node) {
                return getSpouseOfNode(node) || node.parentNode;
            };

            // If node is a child of a sub-menu then return true
            parentOrSpouse = getParentOrSpouse(child);

            if (parentOrSpouse && parentOrSpouse !== window.document) {
                isDescendant = domUtils.isDescendantOrInLaw(parentOrSpouse, ancestor,
                    getSpouseOfNode, inclusive);
            }
        }

        return isDescendant;
    };

    domUtils.getFirstChild = function (/*Element*/ element) {
        validateElement(element);

        if (!domUtils.isContainer(element) || domUtils.getChildCount(element) < 1) {
            return null;
        }

        return domUtils.getChildren(element)[0];
    };

    domUtils.getLastChild = function (/*Element*/ element) {
        var childCount;

        validateElement(element);

        childCount =  domUtils.getChildCount(element);

        if (!domUtils.isContainer(element) || childCount < 1) {
            return null;
        }

        return domUtils.getChildren(element)[childCount - 1];
    };

    domUtils.isContainer = function (/*Element*/ element) {
        validateElement(element);

        return domUtils.getChildCount(element) > 0;
    };

    // Element Navigation
    domUtils.getAdjacentElement = function(/*Element*/ element, /*Element?*/ direction){
        validateElement(element);

        if (!direction || ["next","previous"].indexOf(direction) < 0) {
            direction = "next";
        }

        element = element[direction + "Sibling"];

        return element;
    };

    // Element Position
    domUtils.getElementPositionInViewPort = function (/*Element*/ element) {
        var rect;

        validateElement(element);

        rect = element.getBoundingClientRect();

        // IE8 and below does not include rect.width and rect.height
        return {x: rect.left, y: rect.top, w: rect.right - rect.left, h: rect.bottom - rect.top};
    };

    domUtils.getElementPositionOnPage = function (/*Element*/ element) {
        var pos;

        validateElement(element);

        pos = domUtils.getElementPositionInViewPort(element);

        pos.x += window.pageXOffset;
        pos.y += window.pageYOffset;

        return pos;
    };

    /**
     * The position extents are the values of top, bottom, left, and right, which don't have a
     * proper name to describe the group, so "position extents" will have to do.  This method
     * allows for any combination of valid position extent values to be passed in as properties
     * of an object and sets the position extents of the input element accordingly.
     *
     * @param element - A DOM Element to which position extent values will be applied.
     * @param extents - {t/top: topValue, r/right: rightValue, b/bottom: bottomValue, l/left: leftValue}
     */
    domUtils.setPositionExtents = function (/*Element*/ element, /*Object*/ extents) {
        // 'element' input will be validated by domUtils.setStyle()
        validateObject(extents);

        var positionExtents = {};

        positionExtents.top = extents.t ? extents.t : extents.top ? extents.top : "auto";
        positionExtents.right = extents.r ? extents.r : extents.right ? extents.right : "auto";
        positionExtents.bottom = extents.b ? extents.b : extents.bottom ? extents.bottom : "auto";
        positionExtents.left = extents.l ? extents.l : extents.left ? extents.left : "auto";

        ["top", "right", "bottom", "left"].forEach(function (value) {
            domUtils.setStyle(element, value, positionExtents[value]);
        });
    };

    // Element Focusability/Visibility
    domUtils.isFocusable = function (/*Element*/ element) {
        validateElement(element);

        return (element &&
            !element.hasAttribute("disabled") &&
            (!element.hasAttribute("aria-disabled") || (element.getAttribute("aria-disabled") !== "true")) &&
            (element.style.display !== "none") &&
            !element.hasAttribute("data-refuse-key-nav")); // Used in menu and form widgets
    };

    domUtils.isElementInViewport = function (/*Element*/ element, /*Boolean?*/ strict) {
        var rect, vWidth, vHeight, efp, hCenter, vCenter;

        validateElement(element);

        rect     = element.getBoundingClientRect();
        vWidth   = window.innerWidth || document.documentElement.clientWidth;
        vHeight  = window.innerHeight || document.documentElement.clientHeight;
        efp      = function (x, y) {
            return document.elementFromPoint(x, y);
        };

        // Return false if element has either no width or no height
        if (rect.width <= 0 || rect.height <= 0) {
            return false;
        }

        // Return false if it's not in the viewport
        if (rect.right < 0 || rect.bottom < 0
            || rect.left > vWidth || rect.top > vHeight) {
            return false;
        }

        // g1654934 - Browsers will sometimes return the parent element of 'element' when the
        // boundingClientRect limits are used with elementFromPoint().  We are adjusting
        // for this in our final return statement, but we need to account for cases where
        // the offsets being used in our final return statment would cause problems.
        if (rect.width < 2 && rect.height < 2) {
            // Point
            hCenter = rect.left + ((rect.right - rect.left) / 2);
            // rect.top is numerically less than rect.bottom due to browser axes orientation
            vCenter = rect.top + ((rect.bottom - rect.top) / 2);
            return element.contains(efp(hCenter, vCenter));
        } else if (rect.width < 2) {
            // Vertical Line
            hCenter = rect.left + ((rect.right - rect.left) / 2);
            if (strict) {
                return (element.contains(efp(hCenter,  rect.top + 1)) && element.contains(efp(hCenter,  rect.bottom - 1)));
            } else {
                return (element.contains(efp(hCenter,  rect.top + 1)) || element.contains(efp(hCenter,  rect.bottom - 1)));
            }
        } else if (rect.height < 2) {
            // Horizontal Line
            vCenter = rect.top + ((rect.bottom - rect.top) / 2);
            if (strict) {
                return (element.contains(efp(rect.left + 1, vCenter)) && element.contains(efp(rect.right - 1, vCenter)));
            } else {
                return (element.contains(efp(rect.left + 1, vCenter)) || element.contains(efp(rect.right - 1, vCenter)));
            }
        }

        // Return true if any of its four corners are visible.
        // g1654934 - offsets are accounting for efp() issue.
        if (strict) {
            return (
                element.contains(efp(rect.left + 1,  rect.top + 1))
                && element.contains(efp(rect.right - 1, rect.top + 1))
                && element.contains(efp(rect.right - 1, rect.bottom - 1))
                && element.contains(efp(rect.left + 1,  rect.bottom - 1))
            );
        } else {
            return (
                element.contains(efp(rect.left + 1,  rect.top + 1))
                || element.contains(efp(rect.right - 1, rect.top + 1))
                || element.contains(efp(rect.right - 1, rect.bottom - 1))
                || element.contains(efp(rect.left + 1,  rect.bottom - 1))
            );
        }
    };

    domUtils._obscuredProps = {};
    domUtils._obscuredProps.sawPosAbsolute = false;
    domUtils._obscuredProps.elementPosition = null;

    domUtils.isElementObscuredByParent = function (/*Element*/ element, /*Boolean {internal}?*/ _calledByIsElementObscuredByAncestor) {
        // Basic traversal logic is borrowed from dijit\place.js "around" method
        var elementPosition,
            sawPosAbsolute,
            parent,
            obscured;

        validateElement(element);

        obscured = false;

        // Compute position and size of visible part of element and see if it is partially hidden by ancestor elements
        if(parent = domUtils.getParent(element)){
            if (!_calledByIsElementObscuredByAncestor) {
                domUtils._obscuredProps.elementPosition = domUtils.getElementPositionOnPage(element);
                domUtils._obscuredProps.sawPosAbsolute = window.getComputedStyle(element, null).position === "absolute"; // ignore elements between position:relative and position:absolute
            }

            elementPosition = domUtils._obscuredProps.elementPosition;
            sawPosAbsolute = domUtils._obscuredProps.sawPosAbsolute;

            if (parent && (domUtils.getNodeType(parent) === "Element") && (parent.nodeName !== "BODY")){  //ignoring the body will help performance
                var parentPos = domUtils.getElementPositionOnPage(parent),
                    pcs = window.getComputedStyle(parent, null);
                if(/relative|absolute/.test(pcs.position)){
                    sawPosAbsolute = domUtils._sawPosAbsolute = false;
                }
                if(!sawPosAbsolute && /hidden|auto|scroll/.test(pcs.overflow)){
                    obscured = elementPosition.y < parentPos.y || elementPosition.y + elementPosition.h > parentPos.y + parentPos.h ||
                        elementPosition.x < parentPos.x || elementPosition.x + elementPosition.w > parentPos.x + parentPos.w;
                }
            }
        }

        return obscured;
    };

    domUtils.isElementObscuredByAncestor = function (/*Element*/ element) {
        var obscured, parent;

        validateElement(element);

        obscured = false;

        // If the element has a parent then check if it is obscured, otherwise return false
        domUtils._obscuredProps.elementPosition = domUtils.getElementPositionOnPage(element);
        domUtils._obscuredProps.sawPosAbsolute = window.getComputedStyle(element, null).position === "absolute";
        while((parent = domUtils.getParent(element)) && (domUtils.getNodeType(parent) === "Element") && (parent.nodeName !== "BODY")){  //ignoring the body will help performance
            obscured = domUtils.isElementObscuredByParent(element, true);
            element = parent;
        }

        return obscured;
    };

    return domUtils;
});
