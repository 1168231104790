/**
 *  KeyboardKeys is an object that defines the valid set of keys that can be used to create a binding.
 *
 *  @copyright Copyright 2015 The MathWorks, Inc.
 */

define([
        // dojo
        "dojo/_base/lang",

        // keybindings
        "mw-keybindings/Platforms"
    ],
    function () {

        var keyboardKeys = {

            BACKSPACE: 8,
            TAB: 9,
            CLEAR: 12,
            ENTER: 13,

            SHIFT: 16,
            CTRL: 17,
            ALT: 18,

            PAUSE_BREAK : 19,
            CAPS_LOCK: 20,
            ESCAPE: 27,
            SPACE: 32,

            PAGE_UP: 33,
            PAGE_DOWN: 34,
            END: 35,
            HOME: 36,

            LEFT_ARROW: 37,
            UP_ARROW: 38,
            RIGHT_ARROW: 39,
            DOWN_ARROW: 40,

            INSERT: 45,
            DELETE: 46,

            0: 48,
            1: 49,
            2: 50,
            3: 51,
            4: 52,
            5: 53,
            6: 54,
            7: 55,
            8: 56,
            9: 57,

            A: 65,
            B: 66,
            C: 67,
            D: 68,
            E: 69,
            F: 70,
            G: 71,
            H: 72,
            I: 73,
            J: 74,
            K: 75,
            L: 76,
            M: 77,
            N: 78,
            O: 79,
            P: 80,
            Q: 81,
            R: 82,
            S: 83,
            T: 84,
            U: 85,
            V: 86,
            W: 87,
            X: 88,
            Y: 89,
            Z: 90,

            LEFT_WINDOWS: 91,
            RIGHT_WINDOWS: 92,

            NUMPAD_0: 96,
            NUMPAD_1: 97,
            NUMPAD_2: 98,
            NUMPAD_3: 99,
            NUMPAD_4: 100,
            NUMPAD_5: 101,
            NUMPAD_6: 102,
            NUMPAD_7: 103,
            NUMPAD_8: 104,
            NUMPAD_9: 105,
            NUMPAD_MULTIPLY: 106,
            NUMPAD_PLUS: 107,
            NUMPAD_MINUS: 109,
            NUMPAD_PERIOD: 110,
            NUMPAD_DIVIDE: 111,

            F1: 112,
            F2: 113,
            F3: 114,
            F4: 115,
            F5: 116,
            F6: 117,
            F7: 118,
            F8: 119,
            F9: 120,
            F10: 121,
            F11: 122,
            F12: 123,
            F13: 124,
            F14: 125,
            F15: 126,

            NUM_LOCK: 144,
            SCROLL_LOCK: 145,

            DASH: 189,
            EQUALS: 187,

            OPEN_BRACKET: 219,
            CLOSE_BRACKET: 221,

            COMMA: 188,
            PERIOD: 190,

            FORWARD_SLASH: 191,
            BACKWARD_SLASH: 220,
            SINGLE_QUOTE: 222,
            SEMI_COLON: 186,
            BACK_TICK: 192

        };

        // return the keys
        return keyboardKeys;
    });

