
/**
 * <strong>Purpose:</strong> <br>
 * Provides the ability to set a Menu. Create a Menu widget and then include the widget as a property.
 * <br><br>
 * @module mw-mixins/property/MenuMixin
 *
 * @copyright Copyright 2014-2017 The MathWorks, Inc.
 */


define([
    "dojo/_base/declare",
    "dojo/_base/lang",
    "dojo/aspect",
    "dojo/dom",
    "dojo/dom-geometry",
    "dojo/has",
    "dojo/keys",
    "dojo/on",
    "dojo/touch",
    "dijit/focus",
    "dijit/popup",
    "dijit/Viewport",
    "dijit/_WidgetBase"

], function (declare, lang, aspect, dom, domGeometry, has, keys, on, touch, focusUtil, popup, Viewport, _WidgetBase) {

    var CLICK_VALUES = {
        "left": [0],
        "center": [1],
        "right": [2],
        "all": [0, 1, 2],
        "none": []
    };

    var getMouseButtonFromEvent = function (/*Event*/ event) {
        if (event.synthetic === true && window.syn && (event.type.indexOf("mouse") !== -1)) {
            switch (event.button) {
                case window.syn.mouse.browser.left[event.type].button:
                    return 0;
                case window.syn.mouse.browser.right[event.type].button:
                    return 2;
                default:
                    return 1;
            }
        } else {
            return event.button;
        }
    };

    var KEY_PRESS_VALUES = {
        "down_arrow": [keys.DOWN_ARROW],
        "right_arrow": [keys.RIGHT_ARROW],
        "left_arrow": [keys.LEFT_ARROW],
        "up_arrow": [keys.UP_ARROW],
        "enter": [keys.ENTER],
        "space": [keys.SPACE],
        "a11y": [keys.ENTER, keys.SPACE],
        "down_arrow_a11y": [keys.DOWN_ARROW, keys.ENTER, keys.SPACE],
        "right_arrow_a11y": [keys.RIGHT_ARROW, keys.ENTER, keys.SPACE],
        "left_arrow_a11y": [keys.LEFT_ARROW, keys.ENTER, keys.SPACE],
        "up_arrow_a11y": [keys.UP_ARROW, keys.ENTER, keys.SPACE],
        "none": []
    };

    var ORIENTATION_VALUES = {
        "below": ["below", "below-alt"],
        "vertical": ["below", "below-alt", "above", "above-alt"],
        "horizontal": ["after", "before"]
    };

    return declare(null, {
        menu: null, // handle to the Menu widget

        menuNode: null, // Node which opens menu when clicked or space/enter key is pressed
        aroundNode: null, // Node which determines where the menu is placed in the page

        _clickButtons: CLICK_VALUES["left"], // Array indicating which mouse buttons trigger menu open
        _keyPressButtons: KEY_PRESS_VALUES["a11y"], // Array indicating which keyboard buttons trigger menu open
        _menuOrientation: ORIENTATION_VALUES["vertical"], // Array indicating which direction the menu will open relative to the around node

        _setMenuAttr: function (menu) {
            this._validateMenuAttr(menu);
            this._set("menu", menu);
        },

        _validateMenuAttr: function (menu) {
            if ((menu !== null) && !(menu instanceof _WidgetBase)) {
                throw new Error("'menu' property expects a widget");
            }
        },

        _setMenuNodeAttr: function (node) {
            if (!(node instanceof HTMLElement)) {
                throw new Error("'menuNode' property expects a DOM node.");
            }

            if (node) {
                this._set("menuNode", node);
            } else {
                this._set("menuNode", this.focusNode || this.domNode);
            }
        },

        _setAroundNodeAttr: function (node) {
            if (!(node instanceof HTMLElement)) {
                throw new Error("'aroundNode' property expects a DOM node.");
            }

            if (node) {
                this._set("aroundNode", node);
            } else {
                this._set("aroundNode", this.domNode);
            }
        },

        // Provides a more semantic syntax for defining which mouse buttons will toggle the menu
        _setClickButtonsAttr: function (/*String*/ button) {
            if ((typeof button !== "string") || (CLICK_VALUES.hasOwnProperty(button) === -1)) {
                throw new Error("'clickButtons' property expects one of these values: " + Object.keys(CLICK_VALUES));
            }
            this._set("_clickButtons", CLICK_VALUES[button]);
        },

        // Provides a more semantic syntax for defining which keyboard buttons will toggle the menu
        _setKeyPressButtonsAttr: function (/*String*/ button) {
            if ((typeof button !== "string") || (KEY_PRESS_VALUES.hasOwnProperty(button) === -1)) {
                throw new Error("'keyPressButtons' property expects one of these values: " + Object.keys(KEY_PRESS_VALUES));
            }
            this._set("_keyPressButtons", KEY_PRESS_VALUES[button]);
        },

        // Provides a more semantic syntax for defining which direction the menu will open relative to the around node
        _setMenuOrientationAttr: function (/*String*/ orientation) {
            if ((typeof orientation !== "string") || (ORIENTATION_VALUES.hasOwnProperty(orientation) === -1)) {
                throw new Error("'menuOrientation' property expects one of these values: " + Object.keys(ORIENTATION_VALUES));
            }
            this._set("_menuOrientation", ORIENTATION_VALUES[orientation]);
        },

        postCreate: function () {
            var touchAndMouseOnly;

            this.inherited(arguments);

            this.domNode.classList.add("mwMenuMixin");

            this.menuNode = this.menuNode || this.focusNode || this.domNode;
            this.aroundNode = this.aroundNode || this.domNode;

            touchAndMouseOnly = has("touch") && (!window.PointerEvent);

            this.own(
                on(this.menuNode, touchAndMouseOnly ? touch.press : "mousedown", lang.hitch(this, "_onPress")),
                on(this.menuNode, "mousedown", lang.hitch(this, "_onMouseDown")),
                on(this.menuNode, "keydown", lang.hitch(this, "_onKeyDown")),
                on(this.menuNode, "keyup", lang.hitch(this, "_onKeyUp")),
                on(this.domNode, "click", lang.hitch(this, "_onMouseClick")),
                this.watch("disabled", lang.hitch(this, function (name, oldValue, newValue) {
                    if (newValue) {
                        this._handleClosingPopup(false);
                    }
                }))
            );
        },

        _onPress: function (/*Event*/ e) {
            var isTogglingPress = (this._clickButtons.indexOf(getMouseButtonFromEvent(e)) > -1) || (e.type === "touchstart");
            var shouldToggleMenu = (!this.isMenuOpen() || this._closeMenuOnParentClick());
            if (this.get("menu")) {
                this.get("menu")._lastPressEvent = e;
            }
            if (isTogglingPress && shouldToggleMenu) {
                if (this.get("menu")) {
                    this.get("menu")._mouseDownTriggered = true;
                    setTimeout(lang.hitch(this, function () {
                        if (this.get("menu") && ("_mouseDownTriggered" in this.get("menu"))) {
                            delete this.get("menu")._mouseDownTriggered;
                        }
                    }), 300);
                }
                this.toggleMenu(e.target);
            } else if (this.isMenuOpen()) {
                e.preventDefault();
                e.stopImmediatePropagation();
            }
        },

        _onMouseDown: function (e) {
            // Browsers inherently switch focus after mousedown events. When pressing on a menu and that caused a
            // popup, we don't want focus on the menu itself, but rather let the popup have it. To achieve this,
            // we prevent the mousedown default when the menu is open or is about to be opened.
            this.inherited(arguments);
            var eventTriggeredMenuToggle = (this.get("menu") && ("_mouseDownTriggered" in this.get("menu")));
            var lastPressWasTouch = this.get("menu") && ("_lastPressEvent" in this.get("menu")) &&
                (this.get("menu")._lastPressEvent.type === "touchstart");
            if (this.isMenuOpen() || (eventTriggeredMenuToggle && lastPressWasTouch)) {
                e.preventDefault();
                e.stopImmediatePropagation();
            }
        },

        _onMouseClick: function (/*Event*/ e) {
            // Remove the _mouseDownTriggered property as soon as possible if a click occurs
            if (this.get("menu") && ("_mouseDownTriggered" in this.get("menu"))) {
                delete this.get("menu")._mouseDownTriggered;
            }

            this.inherited(arguments);

            // Stop the "click" event if we are stopping them or the menu node was the node clicked
            if (dom.isDescendant(e.target, this.menuNode)) {
                e.stopImmediatePropagation();
            }
        },

        _onKeyDown: function (/*Event*/ e) {
            this.inherited(arguments);
            if (this.get("disabled") && !this.get("dropDownNeverDisabled")) {
                return;
            }

            //On ESC, close the Menu
            if (e.keyCode === keys.ESCAPE) {
                if (this.domNode.classList.contains("mwFocusableMenuChildMixin")) {
                    this.closeMenu(false);
                } else {
                    this.closeMenu(true);
                }
            } else if ((this._keyPressButtons.indexOf(e.keyCode) > -1) &&
                ((!this.isMenuOpen()) || (this._closeMenuOnParentClick() && this.isMenuOpen()))) {
                this._toggleOnKeyUp = true;
            }
        },

        _onKeyUp: function (/*Event*/ e) {
            this.inherited(arguments);
            if (this._toggleOnKeyUp) {
                delete this._toggleOnKeyUp;
                if (!this.isMenuOpen() || this.closeMenuOnClick) {
                    this.toggleMenu(e.target);
                }
            }
        },

        // On blur here allows for Button with Gallery Popup to close the gallery popup on blur.
        // Called in focus._setStack, which in turn is called by focus._onTouchNode.
        // When the widget is no longer on the stack the widget._onBlur method is called.
        _onBlur: function(){
            // MW/menu/Menu handles its own close logic on blur, all others need to be explicitly closed.
            // If this check is removed then PopupMenu focus logic breaks.
            // g1515606:
            //      - Check whether the menu is open before attempting to close the menu,
            //        and if it is open, whether the popup was most recently opened by the blurred
            //        widget (if two buttons share a menu widget then the menu could have been
            //        opened by the second button and the first button still would have been blurred).
            //      - this.isMenuOpen() calls this.get("menu"), so no need to check that here as well
            if (this.isMenuOpen() && (this.get("menu")._invokingWidget === this) && !(this.get("menu").domNode.classList.contains("mwMenu")) && !(this.get("menu").domNode.classList.contains("mwSectionPopup"))) {
                // Prevents the widget from grabbing focus back from another element if it was not meant to be the focused element
                if (dom.isDescendant(document.activeElement, this.domNode)) {
                    this.closeMenu(true);
                } else {
                    this.closeMenu(false);
                }
            }

            this.inherited(arguments);
        },

        _qeDropDownClick: function() {
           this.toggleMenu();
        },

        toggleMenu: function (/*Widget/DomNode*/ target) {
            // If target is provided, verify it is inside the menu node, otherwise force the toggle
            target = target || this.menuNode;

            // If target is a widget, then get the domNode
            target = target.domNode || target;

            if (dom.isDescendant(target, this.menuNode)) {
                if (this.isMenuOpen()) {
                    this.closeMenu(true);
                } else {
                    this.openMenu();
                }
            }
        },

        // Exposed method
        openMenu: function () {
            this._showMenu();
        },

        _showMenu: function () {
            if (this.get("menu") == null) {
                return;
            }

            // g1374906: gainedFocusWidget._showMenu gets called before lostFocusWidget.closeMenu on
            // Edge when clicking between two drop downs with the same menu, thus causing the menu
            // to get closed immediately.
            // g1413834: Mousing over PopupMenuItems can cause all open menus to close on IE11, the
            // setTimeout prevents the focus event timing issue from causing this behavior.
            setTimeout(lang.hitch(this, function () {
                var x, y,
                    that = this;

                // g1574440: sporadically the menu is null at this point
                if (that.get("menu") == null) {
                    return;
                }

                // If around node has been nullified, then use the absolute x and y values.
                if (this.aroundNode == null) {
                    x = 0; // Could be a property in the future if requested
                    y = 0;
                }

                // If the Menu is opened again before being closed, then grab the last invoking widget
                if (("_invokingWidget" in this.get("menu")) && this.get("menu")._invokingWidget) {
                    this.get("menu")._lastInvokingWidget = this.get("menu")._invokingWidget;
                }
                // The Menu should know what widget invoked popup.open
                this.get("menu")._invokingWidget = this;

                this.domNode.classList.add("mwHasMenuOpened");

                // g1463822: Capture any calls to domGeometry.position() and do not include additional scroll if zoomed on a touch device.
                this.get("menu")._onPositionListener = aspect.around(domGeometry, "position", function (originalPosition) {
                    return function (node, includeScroll) {
                        var ret,
                            isTouch = 'ontouchstart' in window || navigator.maxTouchPoints,
                            isZoomed = document.body.clientWidth > window.innerWidth;
                        // If is a touch device and is zoomed, then do not include scroll, as it positions the menu incorrectly
                        if (includeScroll && isTouch && isZoomed) {
                            ret = originalPosition(node, false);
                        } else {
                            ret = originalPosition(node, includeScroll);
                        }

                        return ret;
                    }
                });

                this.get("menu").own(this.get("menu")._onPositionListener);

                popup.open({
                    parent: this,
                    popup: that.get("menu"),
                    x: x,
                    y: y,
                    around: that.aroundNode,
                    orient: that._menuOrientation,
                    maxHeight: that.get("maxHeight"),
                    onCancel: function () {
                        if (that.domNode.classList.contains("mwFocusableMenuChildMixin")) {
                            var parent = that.getParent();
                            that.closeMenu(false);
                            parent._closeEventData = {mwEventData: {
                                gainedFocusWidget: null
                            }};
                            parent.closeRequest(false);
                        } else {
                            that.closeMenu(true);
                        }
                    },
                    onClose: function () {
                        // Called every time popup.open or popup.close is called
                        // If we stored a last invoking widget, then clean up the widget
                        if (that.get("menu") && ("_lastInvokingWidget" in that.get("menu"))) {
                            if (that.get("menu")._lastInvokingWidget != null) {
                                that._cleanUpAfterClose(that, true);
                            }
                            delete that.get("menu")._lastInvokingWidget;
                        } else {
                            that._cleanUpAfterClose(that, false);
                        }
                        if (that.get("menu") && ("_onPositionListener" in that.get("menu"))) {
                            that.get("menu")._onPositionListener.remove();
                            delete that.get("menu")._onPositionListener;
                        }
                    }
                });

                var resizeListener = that.own(on(Viewport, "resize", function () {
                    if (that.getParentWidget) {
                        that.closeMenu(false);
                    } else {
                        that.closeMenu(true);
                    }
                    if (resizeListener.length) {
                        [].slice.call(resizeListener).forEach(function (listener) {
                            listener.remove();
                        });
                    } else {
                        resizeListener.remove();
                    }
                }));

            }), 0);
        },

        closeMenu: function (focus) {
            // Close sub-menu before closing this menu
            // close only if no submenus are present
            var closeEventData = this._handleClosingPopup(focus);
            this._handleFocusOnClose(focus, closeEventData);
        },

        _handleClosingPopup: function(focus) {
            if(!this.isMenuOpen()) {
                if (this.get("menu")) {
                    return this.get("menu")._closeEventData;
                } else {
                    return;
                }
            }
            if (this.get("menu")) {
                this.get("menu").getChildren().forEach(function (childWidget) {
                    if (("menu" in childWidget) && childWidget.isMenuOpen()) {
                        childWidget.get("menu")._closeEventData = this._closeEventData;
                        childWidget.closeMenu(focus);
                    }
                }, this);

                // Store the close event data in a variable since it is deleted by popup.close();
                var closeEventData = this.get("menu")._closeEventData;

                popup.close(this.get("menu"));
                return closeEventData;
            }

        },
        _handleFocusOnClose: function(focus, closeEventData) {
            if (focus === true) {
                if (this.focus) {
                    // If focus method is defined use that method
                    this.focus();
                } else {
                    // Otherwise focus the focusNode if it exists, or just the domNode
                    focusUtil.focus(this.focusNode || this.domNode);
                }
            } else {
                if (closeEventData &&
                    ("mwEventData" in closeEventData) &&
                    ("gainedFocusWidget" in closeEventData.mwEventData) &&
                    (closeEventData.mwEventData.gainedFocusWidget)) {
                    focusUtil.focus(closeEventData.mwEventData.gainedFocusWidget.focusNode ||
                        closeEventData.mwEventData.gainedFocusWidget.domNode);
                } else {
                    // If we are not focusing the invoking widget, then explicitly focus the document
                    // body in order to trigger the focus manager's watch method.
                    focusUtil.focus(document.body);
                }
            }
        },

        startup: function(){
            if(this._started){
                return;
            }

            if(!this.get("menu") && this.srcNodeRef){
                // The first child widget of srcNodeRef should be the menu widget's domNode.
                var menuWidgetNode = query("[widgetId]", this.srcNodeRef)[0];
                if(menuWidgetNode){
                    this.set("menu", registry.byNode(menuWidgetNode));
                }
            }
            if(this.get("menu")){
                // Add menu to the DOM and hide it.
                popup.hide(this.get("menu"));
            }

            this.inherited(arguments);
        },

        destroy: function () { //HDD
            // If menu is open, close it, to avoid leaving dijit/focus in a strange state.
            // Put focus back on me to avoid the focused node getting destroyed, which flummoxes IE.
            if(this.isMenuOpen()){
                this.closeMenu(true);
            }
            this.inherited(arguments);
        },

        _closeMenuOnParentClick: function () {
            return true;
        },

        _cleanUpAfterClose: function (target, keepInvokingWidget) {
            if (!keepInvokingWidget && target.get("menu")) {
                delete target.get("menu")._invokingWidget;
            }
            // TODO: R2016b - verify that this 'if' statement is necessary
            if(target.domNode) {
                target.domNode.classList.remove("mwHasMenuOpened");
            }
        },

        isMenuOpen: function () {

            // check for parentElement incase the menu is not placed in the DOM
            if(this.get("menu") && this.get("menu")._invokingWidget && (this.get("menu")._invokingWidget === this) &&
                this.get("menu").domNode && this.get("menu").domNode.parentElement &&
                this.get("menu").domNode.parentElement.classList.contains("dijitPopup"))  {
                var isClosed = this.get("menu").domNode.parentElement.style.display === "none";
                return !isClosed;
            }
            return false;
        }
    });
});
