/**
 * This implementation of Deferred is NOT drop in compatible with dojo's Deferred classes. These are
 * simplified and don't provide the promise object. This class can be useful when dojo's built in
 * Deferred implementation is too slow.
 */
define([], function () {
    var PENDING = 0, RESOLVED = 1, REJECTED = 2, CANCELED = 3;

    // Use a custom deferred because the dojo makes extensive use of closures which can be slow.

    var Deferred = function () {
        this._state = PENDING;
        this._callbacks = [];
        this._errbacks = [];
    };

    Deferred.prototype.then = function (callback, errback) {
        if (this._state === RESOLVED && callback) {
            callback();
        } else if (this._state === REJECTED && errback) {
            errback();
        } else {
            if (callback) {
                this._callbacks.push(callback);
            }
            if (errback) {
                this._errbacks.push(errback);
            }
        }
        return this;
    };

    Deferred.prototype.resolve = function () {
        if (this._state === PENDING) {
            this._state = RESOLVED;
            while (this._callbacks.length) {
                this._callbacks.pop()();
            }
            this._callbacks = [];
            this._errbacks = [];
        }
    };

    Deferred.prototype.reject = function () {
        if (this._state === PENDING) {
            this._state = REJECTED;
            while (this._errbacks.length) {
                this._errbacks.pop()();
            }
            this._callbacks = [];
            this._errbacks = [];
        }
    };

    Deferred.prototype.cancel = function () {
        if (this._state === PENDING) {
            this._state = CANCELED;
            this._callbacks = [];
            this._errbacks = [];
        }
    };

    Deferred.prototype.isCanceled = function () {
        return this._state === CANCELED;
    };

    return Deferred;
});
