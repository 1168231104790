// Copyright 2016, The MathWorks, Inc

/**
 * Defines a device-agnostic gesture detection layer that clients can use in place of device-specific DOM events (like
 * mousedown, touchstart, etc.)
 *
 * This file defines two additional layers (gesture events and gestures) on top of existing DOM event layers like
 * dojo/touch and dojo/on
 *
 * ~~<gesture client>~~~~~~~~~~~~~~~~~~~
 *    ↑↑             ↑↑  ↑↑  ↑↑  ↑↑  ↑↑
 * ~~gestures~~~~~~~~~~~ ↑↑  ↑↑  ↑↑  ↑↑        mw-gesture (down, tapDown, etc.)
 *    ↑↑                 ↑↑  ↑↑  ↑↑  ↑↑
 * ~~gesture events~~~~~~~~~ ↑↑  ↑↑  ↑↑        mw-gesture (down, up, tapDown, doubleTap, etc.)
 *    ↑↑                     ↑↑  ↑↑  ↑↑
 * ~~event standardization~~~~~~ ↑↑  ↑↑        dojo/touch  (press, move, release, etc.)
 *    ↑↑                         ↑↑  ↑↑
 * ~~event polyfill~~~~~~~~~~~~~~~~~ ↑↑        dojo/on (mousedown, mouseup, touchstart, etc.)
 *    ↑↑                             ↑↑
 * ~~DOM events~~~~~~~~~~~~~~~~~~~~~~~~~       window.addEventListener, window.removeEventListener
 *
 * Gesture events do the detection of an event based on a sequence of other events.  Gestures provide an object with
 * a lifecycle that tells you when the gesture is complete
 *
 * GestureEvent example:
 *   gestureDetector = new GestureDetector(dom.byId("hitArea"));
 *   domGestures.events.tap.addEventHandler(function (domEvent) {  // the mouseup event that caused the tap
 *     console.log("tap event occurred");
 *   });
 *
 * Gesture example:
 *   domGestures = new GestureDetector(dom.byId("hitArea"));
 *   domGestures.gestures.down.addGestureHandler({
 *     start : <start callback>,
 *     progress : <progress callback>,
 *     end: <end callback>,
 *     cancel: <cancel callback>
 *     always: <callback after end or cancel>
 *   });
 */

define([
    "dojo/_base/declare",
    "dojo/_base/lang",
    "dojo/Evented",
    "dojo/Deferred",
    "dojo/touch",
    "dojo/on",
    "./GestureUtils",
    "./domEvents/InputCapturer",
    "./registry/GestureRegistry",
    "./util/RemovableGroup"
], function (declare, lang, Evented, Deferred, touch, on, GestureUtils, InputCapturer,
             GestureRegistry, RemovableGroup) {

    return declare([RemovableGroup], {

        constructor: function (node, options) {
            var gestureEventName, gestureEvent, gestureName, gesture;
            var that = this;

            this.inputCapturer = new InputCapturer(node);
            this.own(this.inputCapturer);

            this.events = {};
            for (gestureEventName in GestureRegistry.discrete.events) {
                if (GestureRegistry.discrete.events.hasOwnProperty(gestureEventName)) {
                    gestureEvent = new GestureRegistry.discrete.events[gestureEventName]({
                        options: options ? options : {}
                    });
                    gestureEvent.setInputCapturer(this.inputCapturer);
                    gestureEvent.enable();
                    this.own(gestureEvent);
                    this.events[gestureEventName] = gestureEvent;
                }
            }

            for (gestureEventName in GestureRegistry.discrete.blockers) {
                if (GestureRegistry.discrete.blockers.hasOwnProperty(gestureEventName)) {
                    GestureRegistry.discrete.blockers[gestureEventName].forEach(function (blockedName) {
                        that.events[gestureEventName].prevents(that.events[blockedName]);
                    });
                }
            }

            var listenForGestureStart = function (_gesture, _detector) {
                _gesture.addGestureHandler({
                    start: lang.hitch(_detector, function (domEvent) {
                        this._setGesture(_gesture);
                    })
                });
            };

            this.gestures = {};
            for (gestureName in GestureRegistry.continuous.gestures) {
                if (GestureRegistry.continuous.gestures.hasOwnProperty(gestureName)) {
                    gesture = new GestureRegistry.continuous.gestures[gestureName]({
                        options: options ? options : {}
                    });
                    gesture.setInputCapturer(this.inputCapturer);
                    gesture.enable();
                    this.own(gesture);
                    this.gestures[gestureName] = gesture;
                    listenForGestureStart(gesture, this);
                }
            }

            for (gestureName in GestureRegistry.continuous.blockers) {
                if (GestureRegistry.continuous.blockers.hasOwnProperty(gestureName)) {
                    GestureRegistry.continuous.blockers[gestureName].forEach(function (blockedName) {
                        that.gestures[gestureName].prevents(that.gestures[blockedName]);
                    });
                }
            }

        },

        _setGesture: function (gesture) {
            var that = this;
            this._clearGesture();
            this._currentGesture = gesture;
            gesture._currentDeferred.promise.always(function () {
                that._currentGesture = null;
            });
        },

        _clearGesture: function () {
            if (this._currentGesture) {
                this._currentGesture.reset();
            }
            this._currentGesture = null;
        },

        remove: function () {
            this.inherited(arguments);
            this._clearGesture();
        },

        resetAllGestureEvents: function () {
            var prop;
            for (prop in this.events) {
                if (this.events.hasOwnProperty(prop)) {
                    this.events[prop].reset();
                }
            }
            for (prop in this.gestures) {
                if (this.gestures.hasOwnProperty(prop)) {
                    this.gestures[prop].reset();
                }
            }
        },

        setOptions: function (options) {
            var prop;
            for (prop in this.events) {
                if (this.events.hasOwnProperty(prop)) {
                    this.events[prop].setOptions(options);
                }
            }
            for (prop in this.gestures) {
                if (this.gestures.hasOwnProperty(prop)) {
                    this.gestures[prop].setOptions(options);
                }
            }
        }

    });

});