/**
 * <strong>Purpose:</strong> <br>
 * Provides a boolean property for a widget to indicate whether the "destroy"<br>
 * lifecycle method has been called.  This is useful in preventing memory leaks.
 *
 * @module mw-mixins/property/DestroyedMixin
 *
 * @copyright Copyright 2015 The MathWorks, Inc.
 */

define([
    "dojo/_base/declare"

], function (declare) {

    return declare(null, {

        /**
         * @property  {boolean} _destroyed
         * @instance
         */
        _destroyed: false,

        destroy: function () {
            this._set("_destroyed", true);

            this.inherited(arguments);
        },

        isDestroyed: function () {
            return this.get("_destroyed");
        }
    });

});