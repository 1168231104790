define([
    "dojo/_base/declare",
    "dojo/aspect",
    "dojo/dnd/Manager",
    "mw-utils/Utils"
],
    function (declare, aspect, DnDManager, Utils) {

        var dndMakeAvatarMap = {};

        var findIndexOfSource = function (source) {
            var sourceIndex = -1;
            var res = Object.keys(dndMakeAvatarMap).some(function (element, index, array) {
                if (dndMakeAvatarMap[element].dndSource === source) {
                    sourceIndex = element;
                    return true;
                }
                return false;
            });
            return sourceIndex;
        };

        var findUniqueId = function (source) {
            var key = findIndexOfSource(source);
            if (key === -1) {
                key = Utils.generateUuid();
            }
            return key;
        };

        var makeAvatarHandle = aspect.around(
                DnDManager.manager(), "makeAvatar", function (origionalMakeAvatar) {
            return function () {
                var dndManager = DnDManager.manager();
                var avatar;
                var res = Object.keys(dndMakeAvatarMap).some(function (element, index, array) {
                    if (dndMakeAvatarMap[element].dndSource === dndManager.source) {
                        avatar = dndMakeAvatarMap[element].makeAvatarFunc.call(dndManager);
                        return true;
                    }
                    return false;
                });
                if (res === false) {
                    avatar = origionalMakeAvatar.call(dndManager);
                }
                return avatar;
            };
        });

        return {
            /**
             * This function can be used to register the widget and the corresponding custom Avatar
             * @param dndWidget
             * @param custom avatar
             */
            registerMakeAvatar: function (dndSource, makeAvatar) {
                var key = findUniqueId(dndSource);
                dndMakeAvatarMap[key] = {
                    dndSource: dndSource,
                    makeAvatarFunc: makeAvatar
                };
            },
            /**
             * This function can be used to de-register a custom avatar corresponding to a widget
             * @param dndWidget
             */
            unregisterMakeAvatar: function (dndSource) {
                var key = findIndexOfSource(dndSource);
                if (key !== -1) {
                    delete dndMakeAvatarMap[key];
                }
            },
            destroy: function (makeAvatarHandle) {
                if (makeAvatarHandle) {
                    makeAvatarHandle.remove();
                }
            }
        };

    });
