//Copyright 2017 MathWorks, Inc.

define([
    "dojo/_base/declare",
    "dojo/_base/lang",
    "dojo/Evented",
    "dojo/i18n!../../l10n/nls/FileBrowserResources",
    "mw-filename-utils/FileNameUtil"
], function (declare, lang, Evented, FileBrowserResources, FileNameUtil) {

    return declare([Evented], {
        constructor: function (args) {
            args = args || {};
            this.id = "groupByType";
            this.text = FileBrowserResources.type;
            this.itemType = "check";
            this.grouped = false;
            this.enabled = true;
            this.description = "Action to group file browser column by type";
            this.groups = [];
            this.groupFileInfos = [];
        },

        getGroup: function (item) {

            if (item.isDirectory) {
                return {
                    name: FileBrowserResources.folder, filter: function (item) {
                        if (item.isDirectory) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                };
            } else {
                var extension = FileNameUtil.getFileNameExtension(item.name);
                if (extension !== "") {
                    return {
                        name: extension.toUpperCase() + " " + FileBrowserResources.type,
                        ext: extension,
                        filter: function (item, grpExt, ext) {
                            if (!item.isDirectory && !item.hasChildren && grpExt === ext) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    };
                } else {
                    return {
                        name: FileBrowserResources.file,
                        ext: "",
                        filter: function (item, grpExt, ext) {
                            if (!item.isDirectory && !item.hasChildren && ext === "") {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    };
                }
            }
        },

        getGroupFileInfo: function (groupName, folderInfo) {
            var groupFileInfo = {
                id: groupName.name,
                hasChildren: true,
                isDirectory: true,
                isGroupHeader: true,
                group: groupName.name,
                type: "group",
                filter: groupName.filter,
                ext: groupName.ext
            };

            groupFileInfo.name = groupName.name;
            groupFileInfo.path = groupName.name;
            groupFileInfo.location = groupName.name;
            groupFileInfo.parent = FileNameUtil.pathFromFileInfo(folderInfo);
            return groupFileInfo;
        },

        filter: function (fileInfo) {
            var groupFileInfo;
            this.groupFileInfos.forEach(lang.hitch(this, function (item) {
                var ext = FileNameUtil.getFileNameExtension(fileInfo.name);

                if (item.filter(fileInfo, item.ext, ext)) {
                    groupFileInfo = item;
                    return groupFileInfo;
                }
            }));
            return groupFileInfo;
        }
    });
});
