/**
 * <strong>Purpose:</strong> <br><br>
 * Provides the ability to toggle the value of the checked state between false and true
 * <br><br>
 *
 * <strong>Mixin Dependencies:</strong> <br>
 * <br>
 * <li>{@link module:mw-mixins/event/ChangeEventMixin|ChangeEventMixin}</li>
 *
 * <br><br>
 * @module mw-mixins/property/CheckedMixin
 *
 * @copyright Copyright 2014-2017 The MathWorks, Inc.
 */

define([
    "dojo/_base/declare",
    "dojo/_base/lang",
    "dojo/on",
    "mw-mixins/event/ChangeEventMixin"
], function (declare, lang, on, ChangeEventMixin) {

    var CheckedMixin = declare(null, {

        /**
         * @property  {boolean} checked - Supported Values: true/false
         * @instance
         * @default
         */
        checked: false,

        /**
         * @property  {HTMLElement} checkableNode - The node that responds to click to toggle the state of checked property
         * @instance
         * @default
         */
        checkableNode: null,

        postCreate: function () {
            if (!this.get("checkableNode")) {
                this.set("checkableNode", this.domNode);
            }

            this.inherited(arguments);
            this.domNode.classList.add("mwCheckedMixin");
            this.own(on(this.get("checkableNode"), "click", lang.hitch(this, "_onClick")));
        },

        // DO NOT REMOVE this method: it is required to support overridden methods calling into their super-classes.
        _setCheckedAttr: function (value) {
            this._set("checked", value);

            this._setAriaPressed();
            this._setAriaChecked();

            // Duplicates _CssStateMixin behavior, but is needed for widgets that don't mix in _CssStateMixin
            this.baseClass.split(" ").forEach(function (className) {
                if (value) {
                    this.domNode.classList.add(className + "Checked");
                } else {
                    this.domNode.classList.remove(className + "Checked");
                }
            }, this);
        },

        _setCheckableNodeAttr: function (node) {
            this.checkableNode = node || this.domNode;
        },

        _setAriaPressed: function () {
            var node = this.focusNode ? this.focusNode : this.domNode;
            // Only toggle buttons should have an aria-pressed attribute.
            if (node.hasAttribute('aria-pressed')) {
                node.setAttribute('aria-pressed', this.get('checked'));
            }
        },

        _setAriaChecked: function () {
            var node = this.focusNode ? this.focusNode : this.domNode;
            // Only checkboxes and radio buttons should have an aria-checked attribute.
            if (node.hasAttribute('aria-checked')) {
                node.setAttribute('aria-checked', this.get('checked'));
            }
        },

        // Handles user-interaction of Mouse/KB(Enter, Space) click
        // Updates the property and emits the "change" event
        _onClick: function () {
            var oldValue = this.get("checked"),
                newValue = !this.get("checked");

            // the last argument of true is to let any overriden setCheckedAttr's know that this set() is initiated
            // as a result of user interaction
            this.set("checked", newValue, true);
            this._triggerChangeEvent("checked", oldValue, newValue);
        }
    });

    CheckedMixin._dependencies = [
        {mixin: ChangeEventMixin, orderDependent: false}
    ];
    return CheckedMixin;
});
