/**
 * <strong>Purpose:</strong> <br>
 * Provides the ability to have a scrollable area of MenuItems.
 * Provides the ability to increase the width of popup to accomodate scroll bar.
 * <br><br>
 * @module mw-menu/mixins/property/MaxHeightMixin
 *
 *
 * @copyright Copyright 2014 The MathWorks, Inc.
 */

define([
    "dojo/_base/declare"
], function (declare) {

    return declare(null, {
        maxHeight: 800,

        _setMaxHeightAttr: function (value) {
            if (typeof value !== "number") {
                throw new Error("'MaxHeight' property expects a Number!");
            }
            this.domNode.style.maxHeight = value + "px";
            this._set("maxHeight", value);
        }
    });

});
