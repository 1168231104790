define({
  // MATLAB Drive Online Strings
  root: {
    // index page
    indexPageTitle: `Sign in to MATLAB Drive - MATLAB & Simulink - MathWorks`,
    indexPageToggleNavigation: `Toggle Main Navigation`,
    indexPageStoreAnywhere: `Store, access and manage your files from anywhere.`,
    indexPageLearnMore: `Learn more about MATLAB Drive.`,
    indexPageInstallConnector: `Install MATLAB Drive Connector.`,
    loggedInPageTitle: `MATLAB Drive`,
    rootFolderNameMatlabDrive: `MATLAB Drive`,
    incompatibleBrowser: `Incompatible Browser`,
    moreFolders: `More folders`,
    footerPatents: `Patents`,
    footerTrademarks: `Trademarks`,
    footerPrivacy: `Privacy Policy`,
    footerPiracy: `Preventing Piracy`,

    // files page view
    filesPageUserFirstNameFiles: `Files`,
    filesPageFacetsFiles: `Files`,
    filesPageFacetsSharing: `Shared Content`,
    filesPageFacetsDeletedFiles: `Deleted Files`,
    filesPageToggleNavigation: `Toggle Main Navigation`,
    filesPageDownloadConnectorLinkText: `Install MATLAB Drive Connector`,
    filesPageLogoutLabel: `Sign Out`,
    filesPageHelpLabel: `Help`,
    filesPageUploadLabel: `Upload`,
    filesPageUploadFilesLabel: `Files`,
    filesPageUploadFolderLabel: `Folder`,
    filesPageNewFolderLabel: `New Folder`,
    filesPageDownloadLabel: `Download`,
    filesPageRenameLabel: `Rename`,
    filesPageCutLabel: `Cut`,
    filesPageCopyLabel: `Copy`,
    filesPageCopyToLabel: `Copy to`,
    filesPagePasteLabel: `Paste`,
    filesPageDeleteLabel: `Delete`,
    openToolTipText: `Open selection in MATLAB Online.`,
    viewToolTipText: `View this file.`,
    uploadToolTipText: `Upload files from desktop to MATLAB Drive.`,
    uploadFolderToolTipText: `Upload a folder from desktop to MATLAB Drive.`,
    newFolderToolTipText: `Create folder.`,
    downloadToolTipText: `Download files from MATLAB Drive to your desktop.`,
    renameToolTipText: `Rename selection.`,
    moveToolTipText: `Move selection.`,
    cutToolTipText: `Copy selection to the clipboard.`,
    copyToolTipText: `Copy selection.`,
    pasteToolTipText: `Paste to selected folder.`,
    deleteToolTipText: `Move selection to Deleted Files.`,
    viewInNewTabToolTipText: `View file in new tab.`,
    shareToolTipText: `Share selection via invitation or view-only link.`,
    inviteToolTipText: `Invite specific people to share.`,
    manageToolTipText: `Manage users of this shared folder.`,
    linkToolTipText: `Share folder via view-only link.`,
    leaveToolTipText: `Leave sharing folder`,
    filesPageShareLabel: `Share`,
    filesPageInviteLabel: `Invite Members`,
    filesPageManageLabel: `Manage Members`,
    filesPageViewMembersLabel: `View Members`,
    filesPageLinkLabel: `Link`,
    filesPageCreateLinkLabel: `Create Link`,
    filesPageManageLinkLabel: `Manage Link`,
    filesPageViewLinkLabel: `View Link`,
    filesPageLeaveLabel: `Leave`,
    filesPageMoveLabel: `Move to`,

    // trash page view
    trashPageRestoreFolderDialogTitle: `Restore Folder`,
    trashPageRestoreFolderWarningText: `This folder has been deleted.`,
    trashPageRestoreFolderDialogText: `To view this folder, restore it from your deleted files.`,
    trashPageUserFirstNameDeletedFiles: `Deleted Files`,
    trashPageFacetsFiles: `Files`,
    trashPageFacetsSharing: `Shared Content`,
    trashPageFacetsDeletedFiles: `Deleted Files`,
    trashPageToggleNavigation: `Toggle Main Navigation`,
    trashPageDownloadConnectorLinkText: `Install MATLAB Drive Connector`,
    trashPageLogoutLabel: `Sign Out`,
    trashPageHelpLabel: `Help`,
    trashPageRestoreLabel: `Restore`,
    trashPageDeleteLabel: `Permanently Delete`,
    trashPageEmptyTrashLabel: `Permanently Delete All`,
    trashPageDeletionPolicy: `All deleted files are permanently deleted after 30 days.`,
    restoreToolTipText: `Restore selection to original location.`,
    permanentlyDeleteToolTipText: `Permanently remove selection. You will no longer be able to access this selection.`,
    permanentlyDeleteAllToolTipText: `Permanently remove all items in Deleted Files. You will no longer be able to access these items.`,

    // sharing page view
    sharingPageFacetsFiles: `Files`,
    sharingPageFacetsSharing: `Shared Content`,
    sharingPageFacetsDeletedFiles: `Deleted Files`,
    sharingPageUserFirstNameFiles: `Shared Content`,
    sharingPageToggleNavigation: `Toggle Main Navigation`,
    sharingPageDownloadConnectorLinkText: `Download MATLAB Drive Connector`,
    sharingPageLogoutLabel: `Sign Out`,
    sharingPageHelpLabel: `Help`,
    sharingPagePendingInvitationsGroupLegend: `Pending Invitations`,
    sharingPageCurrentlySharedGroupLegend: `Currently Shared`,
    sharingPageNoPendingInvitations: `You have no pending invitations at this time.`,
    sharingPageInitiator: `Shared By `,
    sharingPageSharedDate: `Shared On `,
    sharingPageSharedViaLink: `URL`,
    sharingPageUnknownSharedOnDate: `Unknown`,
    sharingPageNameTitle: `Name`,
    sharingPageAccessTitle: `Access`,
    sharingPagePreviewTitle: `Preview`,
    sharingPageCopyToDriveTitle: `Copy Folder`,
    sharingPageDeclineTitle: `Decline`,
    sharingPageAcceptInvitationNameConflictDialogTitle: `Accept Invitation For \"\${0}\"`,
    sharingPageAcceptInvitationInitialNamePrompt: `Specify the name you want to use for this shared folder. The default is \${0}.`,
    sharingPageAcceptInvitationNameConflictDialogLabel: `A folder with the name \"\${0}\" already exists. Rename the new folder.`,
    sharingPageCopyInvitationNameConflictDialogLabel: `A folder with the name \"\${0}\" already exists. Rename the new folder.`,
    sharingPageCopyInvitationNameConflictDialogTitle: `Copy folder \"\${0}\"`,
    sharingPageInvitationNotFound: `The sharing invitation could not be found.  It may have been deleted.`,
    sharingPageInvitationAuthorizationError: `Sharing invitations must use the same email address as the UserID associated with your MathWorks account. Contact the folder owner to resolve this issue.`,

    // preview page view
    previewPageFacetsSharing: `Shared Content`,
    previewPageAddToDriveLabel: `Add Shortcut`,
    previewPageShareLinkLabel: `Share Link`,
    previewPageGoToDriveLabel: `Go to Drive`,
    addToDriveToolTipText: `Add a shortcut or copy this folder.`,
    shareLinkToolTipText: `Share folder via view-only link.`,
    goToDriveToolTipText: `Go to folder in your MATLAB Drive`,
    previewPageLoginLabel: `Login`,
    acceptToolTipText: `Adding a shortcut will make this folder visible in your files, and you will continue to see updates made to its contents.`,
    previewPageAcceptLabel: `Accept`,
    declineToolTipText:`Decline Invitation`,
    previewPageDeclineLabel: `Decline`,
    previewPageAddLabel: `Add to my Files`,
    copyToDriveToolTipText: `Copying this folder will create an unshared copy of this folder and its contents within your files.`,
    previewPageCopyToDriveLabel: `Copy Folder`,
    previewPageDownloadActionLabel: `Download Shared Folder`,
    previewPageDownloadActionSelectionLabel: `Download Selection`,

    invitationPreviewModalTitle: `Sharing Preview`,
    invitationPreviewInvitationDeclined: `The invitation to the shared folder \${0} was declined.`,
    invitationPreviewInvitationAccepted: `The shared folder \${0} was added to your drive.`,
    invitationPreviewInvitationNotExist: `The shared content could not be found. It may have been deleted by its owner.`,
    invitationPreviewLoginForPersonalInvitation: `Please login to preview the shared content.`,
    invitationPreviewInvitationAlreadyAddedToDrive: `The shared folder \${0} has already been added to your Drive`,
    invitationPreviewInvitationUndefined: `The shared content could not be found. Please verify your URL.`,
    invitationPreviewAuthorizationError: `You do not have permission to access this shared content.`,

    // info panel
    panelInfoIcon: `Expand to view information about the current folder.`,
    editAccessTypeInfoIcon: `You will be able to edit the shared folder and its contents if you have added a shortcut to your Drive.`,
    viewAccessTypeInfoIcon: `You will be able to view the shared folder and its contents. To edit the shared folder and its contents, contact the owner.`,
    ownerAccessTypeInfoIcon: `As the shared folder owner, you may edit its contents, manage it's members and manage the shared link for this content.`,
    panelOwnedBy: `Owned By`,
    panelAccessType: `Access Type`,
    panelCreatedOn: `Created On`,
    panelModifiedOn: `Modified On`,
    panelModifiedBy: `Modified By`,
    panelSharedFolderRoot: `Shared Parent`,
    panelFolderSize: `Folder Size`,
    panelChildCount: `Total Items`,

    // search
    scopeCurrentDirectory: `Search Current Directory`,
    scopeMatlabDrive: `Search MATLAB Drive`,
    currentDirectory: `Current Directory`,
    noSearchParamError: `Please enter a valid search query`,
    searchResultsFrom: `Search Results from <b>\${0} </b>>`,
    searchResultsPageTitle: `Search Results`,
    searchPageOpenFileLocationLabel: `Open File Location`,
    openFileLocationToolTipText: `Open the folder that contains this file`,

    // sharing page small titles
    smallTitleSharedBy: `shared by`,
    smallTitleSharedOn: `shared on`,
    smallTitleAccess: `access`,

    // grid columns
    locationColumnLabel: `Location`,
    originalLocationColumnLabel: `Original Location`,
    clientDateModifiedColumnLabel: `Date Modified`,
    deletedDateColumnLabel: `Date Deleted`,
    tombstoneColumnLabel: `Tombstone`,
    sharedOnColumnLabel: `Shared On `,
    sharedByColumnLabel: `Shared By `,
    accessTypeColumnLabel: `Access`,
    modifiedByColumnLabel: `Modified By `,
    ownedByColumnLabel: `Owned By `,

    // context menu entries
    contextMenuCutLabel: `Cut`,
    contextMenuCopyLabel: `Copy`,
    contextMenuCopyToLabel: `Copy to`,
    contextMenuDeleteLabel: `Delete`,
    contextMenuDownloadLabel: `Download`,
    contextMenuEmptyTrashLabel: `Permanently Delete All`,
    contextMenuNewFolderLabel: `New Folder`,
    contextMenuPasteLabel: `Paste`,
    contextMenuPermanentlyDeleteLabel: `Permanently Delete`,
    contextMenuRenameLabel: `Rename`,
    contextMenuRestoreLabel: `Restore`,
    contextMenuUploadLabel: `Upload`,
    contextMenuUploadFilesLabel: `Files`,
    contextMenuUploadFolderLabel: `Folder`,
    contextMenuViewFileLabel: `View`,
    contextMenuViewNewTabLabel: `View in New Tab`,
    contextMenuOpenWithLabel: `Open in MATLAB Online`,
    contextMenuShareLabel: `Share`,
    contextMenuManageShareLabel: `Shared Content`,
    contextMenuLinkLabel: `Link`,
    contextMenuCreateLinkLabel: `Create Link`,
    contextMenuManageLinkLabel: `Manage Link`,
    contextMenuViewLinkLabel: `View Link`,
    contextMenuInviteLabel: `Invite Members`,
    contextMenuManageLabel: `Manage Members`,
    contextMenuViewMembersLabel: `View Members`,
    contextMenuLeaveLabel: `Leave`,
    contextMenuMoveLabel: `Move to`,
    contextMenuShareLinkLabel: `Share Link`,

    // create folder dialog
    createFolderDialogCreateFolderTitle: `New Folder`,
    createFolderDialogFolderNameInputLabel: `Enter a name for this folder:`,
    createFolderDialogInputError2Status: `(error)`,
    createFolderDialogButtonCancel: `Cancel`,
    createFolderDialogButtonCreate: `Create`,
    createFolderDialogValidationText: `Folder names cannot contain the characters \${arg1}`,
    createFolderDialogResourceAlreadyExists: `A file or folder with that name already exists. Select a different name.`,
    createFolderDialogIllegalPathElement: `Folder name contains characters that are not allowed.`,
    createFolderDialogFolderCreated: `\${arg1} added to \${arg2}.`,

    // initiate sharing dialog
    initiateSharingDialogResourceNotSharable: `\"\${0}\" cannot be shared. It is the parent of a shared folder, or is contained within a shared folder, or restricted for other reasons.`,
    initiateSharingDialogTitle: `Share \"\${0}\"`,
    initiateSharingDialogTitleCreateLinks: `Create link for \"\${0}\"`,
    initiateSharingDialogTitleManageLinks: `Manage link for \"\${0}\"`,
    initiateSharingDialogTitleViewLinks: `Share \"\${0}\" by Link`,
    initiateSharingDialogTitleManage: `Manage Members of \"\${0}\"`,
    initiateSharingDialogTitleInvite: `Invite Members to Share \"\${0}\"`,
    initiateSharingDialogTitleView: `View Members Of \"\${0}\"`,
    initiateSharingDialogFolderNameInputLabel: `Invite`,
    initiateSharingDialogCurrentlySharedWithLabel: `Shared With`,
    initiateSharingDialogNoParticipants: `Currently there are no participants.`,
    initiateSharingDialogNoParticipantsButHasLink: `Currently there are no personally invited participants but a public link exists.`,
    initiateSharingDialogCreateLink: `Create a view-only link`,
    initiateSharingDialogCopyLink: `Copy Link`,
    initiateSharingDialogDeleteLink: `Delete Link`,
    initiateSharingDialogButtonShare: `Apply`,
    initiateSharingDialogButtonClose: `Close`,
    initiateSharingDialogButtonReturn: `Back`,
    initiateSharingDialogValidationEmptyEmailAddress: `Email address can not be empty`,
    initiateSharingDialogValidationAtSignText: `Email address contains more than one \"@\" character.`,
    initiateSharingDialogInvalidEmailTooLong: `Email address exceeds 70 characters.`,
    initiateSharingDialogInvalidEmailIllegal: `Email address contains illegal character sequence.`,
    initiateSharingDialogInvalidEmailDomain: `Email address \${0} is invalid. The correct format is name@example.com.`,
    initiateSharingDialogInvalidEmailToSelf: `Sending an invitation to yourself is not allowed.`,
    initiateSharingDialogIllegalPathElement: `The email address contains characters that are not allowed.`,
    initiateSharingDialogFolderShared: `\${0} added to \"\${1}\".`,
    initiateSharingDialogPermission_read_write: `Can Edit`,
    initiateSharingDialogPermission_read_only: `Can View`,
    initiateSharingDialogPermission_manage_access: `Can Manage`,
    initiateSharingDialogPermission_owner: `Owner`,
    initiateSharingDialogStatusPending: `Pending`,
    initiateSharingDialogStatusAccepted: `Accepted`,
    initiateSharingDialogStatusDeclined: `Declined`,
    initiateSharingDialogStatusNewInvite: `Sending...`,
    initiateSharingDialogErrorWarning: `Errors occurred while attempting to share. See list below for details.`,
    initiateSharingDialogSharingSuccessful: `\${0} has been successfully shared with:`,
    initiateSharingDialogSomeErrorsWarning: `\${0} has been successfully shared, but some errors occurred. See list below for details.`,
    initiateSharingDialogLinkCopied: `The view-only link was copied to the clipboard.`,
    initiateSharingDialogCreateLinkFailed: `The link you are attempting to create already exists.`,
    initiateSharingDialogCopyLinkFailed: `Failed to copy the link: \${0}.`,
    initiateSharingDialogManuallyCopyLink: `Select and copy the displayed URL.`,
    initiateSharingDialogConfirmLinkDelete: `Delete view-only link?`,
    initiateSharingDialogConfirmCollaboratorDelete: `Remove \"\${0}\" from shared folder?`,
    initiateSharingDialogDeleteCollaboratorWarning: `\"\${0}\" will no longer have access to the shared folder or its contents.`,
    initiateSharingDialogDeleteLinkButton: `Delete`,
    initiateSharingDialogRemovePermissionButton: `Remove`,
    initiateSharingDialogCancelButton: `Cancel`,
    initiateSharingDialogDeleteWarning: `Anyone with the link will no longer be able to access the folder or its contents.`,
    initiateSharingDialogDeleteCollaboratorSuccessful: `Removed \"\${0}\" from shared folder.`,
    initiateSharingDialogAddUserButtonLabel: `Send`,
    initiateSharingDialogNoLinksAvailable: `This folder is shared with specific people and not by a link, so there is no link available.`,
    initiateSharingDailogInviteeAlreadyInList: `An invitation to this folder for this email address already exists.`,
    initiateSharingDailaogEmailAlreadyInList: `You have already issued an invitation to this email address for this folder.`,
    initiateSharingDailogEnterEmailAddress: `Enter email address`,
    initiateSharingDialogEnterEmailComments: `Add a message (optional)`,
    initiateSharingDailogExplainCreateLink: `Create link to give others view-only access to folder \${0}.`,
    initiateSharingDailogExplainCopyLink: `Distribute link to give others view-only access to folder \${0}.`,
    initiateSharingDailogExplainDeleteLink: `Delete link and access to the shared contents. Does not affect access by members who received personal invitations.`,
    initiateSharingDialogmultiEmailTextWarning: `Separate multiple emails with a semicolon or comma`,
    initiateSharingDialogIllegalFolderName: `The folder \${0} contains \".\", \"..\", \"\\\\\" or contains control characters, which are not supported in folder names.`,
    initiateSharingDialogNoPermToCreateLink: `You do not have permission to create an invitation for this folder.`,
    initiateSharingDialogNoPermToDeleteLink: `You do not have permission to delete sharing invitations for this folder.`,
    initiateSharingDialogLinkFolderDoesNotExist: `\${0} does not exist.  It may have been deleted or moved.`,
    initiateSharingDialogInvitationDoesNotExist: `The sharing invitation could not be found.  It may have been deleted.`,
    initiateSharingDialogNoPermToUpdateInvitation: `The sharing invitation cannot be updated at this time.`,
    initiateSharingDialogActiveUsersLabel: `Active Users: `,
    initiateSharingDialogUpdateAccessTypeButtonLabel: `Save`,
    initiateSharingDialogUpdateAccessTypeError: `Failed to update access type for \${0} recipient(s).`,
    initiateSharingDialogUpdateAccessTypeSuccessful: `Successfully updated the access type for \${0} recipient(s).`,
    initiateSharingDialogUpdateAccessTypePartial: `Failed to update access type for \${0} recipient(s), updated for \${1} recipient(s).`,

    // user quota
    userQuotaStorageFull: `Storage is full.  Delete files to free up space.`,
    userQuotaServerError: `A server error occurred. Try again later.`,
    userQuotaUnableToFetchData: `Unable to get data.`,
    userQuotaAmountUsed: `\${0} \${1} / \${2} \${3} used`,

    // gds dao
    actionAborted: `Action aborted.`,

    // ActionHandler notifications
    actionHandlerFile: `file`,
    actionHandlerFiles: `files`,
    actionHandlerFolder: `folder`,
    actionHandlerItem: `item`,
    actionHandlerItems: `items`,
    actionHandlerCannotDisplayMsgTooBig: `The file is too big to display. Download the file to view its contents.`,
    actionHandlerCannotDisplayMsgWrongType: `Preview for this file is unavailable. Download the file to view its contents.`,
    actionHandlerCannotDisplayMsgSecurity: `Preview for this file is not allowed.  Download the file to view its contents.`,
    actionHandlerViewFilePleaseWaitText: `Loading...`,
    actionHandlerViewFileDownloadTimeOut: `Download timed out. Try again.`,
    actionHandlerDownloadInProgress: `Successfully started the download. If you are downloading a large number of files/ folders, this might take a few minutes.`,
    actionHandlerViewFileFileNotExists: `The specified file/folder cannot be found. It may have been deleted or moved.`,
    actionHandlerMaxConcurrentDownloads: `Maximum of \${0} allowed at one time. Select fewer files.`,
    actionHandlerPastedTo: `\${0} pasted to \${1}. `,
    actionHandlerPasteFailed: `A \${0} with that name already exists in \${1}. Paste to a different location or specify a unique name for the \${2}.`,
    actionHandlerMultiPasteSuccess: `Pasted \${0} \${1} to \${2}. ` ,
    actionHandlerMultiPasteFailed: `Failed to paste \${0} \${1} to \${2}. `,
    actionHandlerMultiMoveSuccess: `Moved \${0} \${1} to \${2}. ` ,
    actionHandlerMultiMoveFailed: `Failed to move \${0} \${1} to \${2}. `,
    actionHandlerCopiedTo: `\${0} copied to \${1}. `,
    actionHandlerCopyFailed: `A \${0} with that name already exists in \${1}. Copy to a different location or specify a unique name for the \${2}.`,
    actionHandlerMultiCopySuccess: `Copied \${0} \${1} to \${2}. ` ,
    actionHandlerMultiCopyFailed: `Failed to copy \${0} \${1} to \${2}. `,
    actionHandlerMultiDeleteSuccess: `Deleted \${0} items. `,
    actionHandlerMultiDeleteFailed: `Failed to delete \${0}. `,
    actionHandlerSingleDeleteSuccess: `\${0} moved to Deleted Files.`,
    actionHandlerSingleSharedFolderDeleteSuccess: `Shared folder \${0} moved to Deleted Files for you only. Other members will continue to have access.`,
    actionHandlerSingleSharedFolderOwnerDeleteSuccess: `Shared folder \${0} moved to your Deleted Files for you only.  Other members will continue to have access.`,
    actionHandlerSingleDelectFailed: `Failed to move \${0} to Deleted Files: \${1}`,
    actionHandlerGoToLocation: `Go to location`,
    actionHandlerServerError:  `Server error`,
    actionHandlerNameConflictPrompt: `A file or folder named \${0} already exists in \${1}. Provide a new name. `,
    actionHandlerRenamePromptTitle: `Restore \${0}`,
    actionHandlerRenamePromptLabel: `A \${0} with that name already exists. Rename the \${1}. `,
    actionHandlerPerformRestoreSuccess: `\${0} restored to \${1}.`,
    actionHandlerPerformRestoreFailed: `Failed to restore \${0} to location \${1}: \${2}`,
    actionHandlerPerformRestoreUnknownFailure: `Failed to restore \${0}: \${1}`,
    actionHandlerMultiRestoreSuccess: `Restored \${0} items. `,
    actionHandlerMultiRestoreFailed: `Failed to restore \${0} items. `,
    actionHandlerSinglePermanentDeleteSuccess: `\${0} permanently deleted.`,
    actionHandlerSinglePermanentDeleteSharedFolderSuccess: `Shared folder \${0} deleted for you only.`,
    actionHandlerSingleUnshareAndPermanentDeleteSharedFolderSuccess: `Shared folder \${0} unshared and deleted.`,
    actionHandlerSinglePermanentDeleteSharedLinkFolderSuccess: `Shared folder \${0} deleted for you only. Use the original URL to rejoin.`,
    actionHandlerSinglePermanentDeleteFailed: `Failed to delete \${0}: \${1}`,
    actionHandlerMultiPermanentDeleteSuccess: `\${0} items permanently deleted.`,
    actionHandlerMultiPermanentDeleteFailed: `Failed to delete \${0} items: \${1}`,
    actionHandlerGeneralDeleteFailed: `Delete failed: \${0}`,
    actionHandlerTrashEmptied: `Contents of Deleted Files permanently deleted.`,
    actionHandlerTrashEmptyFailed: `Failed to empty Deleted Files: \${0}`,
    actionHandlerDeleteDialogTitle: `Permanently Delete \${0}`,
    actionHandlerDeleteDialogFilePrompt: `Are you sure you want to permanently delete <b>\${0}</b>?<br>Note: Shared contents will not be deleted.`,
    actionHandlerDeleteDialogFolderPrompt: `<b>The folder and its contents cannot be restored.</b> Other members will continue to have access to any shared folders contained within \${0}.`,
    actionHandlerDeleteDialogSharedFolderPrompt: `You will not be able to restore shared folder \${0} or its contents.<br/> Other members will continue to have access.`,
    actionHandlerDeleteDialogSharedFolderOwnerPrompt: `<b>You will not be able to restore shared folder \${0} or its contents.</b><br/> Other members will continue to have access.`,
    actionHandlerDeleteDialogShouldUnshareFolderLabel: `Unshare folder \${0} with current members`,
    actionHandlerDeleteDialogShouldUnshareMultipleFoldersLabel: `Unshare all selected folders with current members`,
    actionHandlerDeleteDialogShouldUnshareFolderExplanation: `Members of the shared folder will lose access`,
    actionHandlerDeleteDialogShouldUnshareMultipleFoldersExplanation: `Members of those shared folders will lose access`,
    actionHandlerDeleteDialogShouldUnshareSubFoldersLabel: `Unshare any shared folders contained within \${0}`,
    actionHandlerDeleteDialogShouldUnshareMultipleSubFoldersLabel: `Unshare any shared folders contained within this selection`,
    actionHandlerDeleteDialogShouldUnshareSubFoldersExplanation: `Members of those shared folders will lose access`,
    actionHandlerDeleteDialogFolderContentsToo: ` and its contents`,
    actionHandlerDeleteDialogDeleteButtonlabel: `Permanently Delete`,
    actionHandlerDeleteDialogCancelButtonLabel: `Cancel`,
    actionHandlerDeleteDialogMultiTitle: `Delete Items`,
    actionHandlerDeleteDialogMultiSingularPrompt: `Delete this item?`,
    actionHandlerDeleteDialogMultiPluralPrompt: `Delete these items?`,
    actionHandlerPermanentlyDeleteAllDialogTitle: `Permanently Delete All`,
    actionHandlerDeleteAllDialogPrompt: `<b>The folders and their contents cannot be restored.</b><br/>Members of shared folders will continue to have access.`,
    actionHandlerUndo: `Undo`,
    actionHandlerOpenWithLoadFailed: `Open-with service failed to load \${0}.`,
    actionHandlerLargeOperationNotification: `A large file operation is in progress. Some files will be unavailable at this time. Refresh the page to see the updates.`,
    actionHandlerIllegalMoveOrCopyTarget: `Unable to move or copy \${0}. It cannot be moved or copied onto itself or one of its subfolders.`,
    actionHandlerMovedTo: `Moved \${0} to \${1}. `,
    actionHandlerMoveMany: `\${0} items`,

    // FbTreeController
    fbTreeResourceAlreadyExists: `A file or folder with that name already exists. Rename the \${0}.`,
    fbTreeFile: `file`,
    fbTreeFiles: `files`,
    fbTreeFolder: `folder`,
    fbTreeIllegalPath: `The name contains characters that are not allowed. Select a different name.`,
    fbTreeShouldNotContainFolders: `Make sure the selection does not contain folders.`,

    //MLDO File service
    fsServerError: `A server error occurred. Try again later.`,
    fsFolderNotFoundResetting: `Specified folder \${0} not found. Resetting to \"MATLAB Drive\".`,
    fsFolderNotFound: `The specified folder cannot be found. It may have been deleted or moved.`,
    fsMovedFileTo: `Moved \${0} to \${1}.`,
    fsCopiedFileTo: `Copied \${0} to \${1}.`,
    fsMovedFileToFailed: `Failed to move \${0} to \${1}.`,
    fsCopiedFileToFailed: `Failed to copy \${0} to \${1}.`,
    fsFile: `file`,
    fsFolder: `folder`,
    fsResourceAlreadyExists: `A file or folder with that name already exists. Move to a different location or specify a unique name for the \${0}.`,
    fsIllegalPathElement: `The name contains characters that are not allowed. Select a different name.`,
    fsUnknownError: `Unknown error`,
    fsItem: `item`,
    fsItems: `items`,
    fsMultiMoveSuccess: `Moved \${0} \${1} to \${2}. `,
    fsMultiCopySuccess: `Copied \${0} \${1} to \${2}. `,
    fsMultiMoveFailed: `Failed to move \${0} \${1} to \${2}. `,
    fsMultiCopyFailed: `Failed to copy \${0} \${1} to \${2}. `,
    fsNoMoveAccess: `You have Can View access and cannot modify contents of the shared folder \${0}.`,
    fsAcceptInvitationFailed: `Failed to accept the invitation: \${0}`,
    fsDeclineInvitationFailed: `Failed to decline the invitation: \${0}`,
    fsFollowLinkFailed: `Failed to follow link: \${0}`,
    fsFailedToGetPendingInvitations: `Failed to get pending invitations: \${0}.`,
    fsFailedToGetFolderSettings: `Failed to get folder settings: \${0}.`,
    fsNotAuthorized: `You are not authorized to view this resource.`,

    // APS Client
    apsInvalidToken: `Invalid apsToken value returned by AuthNZ`,
    apsInvalidResponse: `Invalid response received from ApsAuthNZ server`,
    apsActionPermanentlyDeletedFrom: `\${0} permanently deleted from \${1}.`,
    apsActionDeletedFrom: `\${0} deleted from \${1}.`,
    apsActionMovedTo: `\${0} moved to \${1}.`,
    apsActionUploadedTo: `\${0} uploaded to \${1}.`,
    apsActionRestoredTo: `\${0} restored to \${1}.`,
    apsActionCreatedIn: `\${0} created in \${1}.`,
    apsActionRenamedTo: `\${0} renamed to \${1}.`,
    apsActionInvitationChangedOn: `\${0} moved to \${1}.`,
    apsActionCopiedTo: `\${0} copied to \${1}.`,

    // Rename prompt
    renamePromptInvalidCharacters: `Names cannot contain the characters \${0}`,
    renameAcceptLabel: `Accept`,
    renameButtonLabel: `Rename`,
    renameCancelButtonLabel: `Cancel`,
    renameErrorLabel: `(error)`,

    // file view
    viewFile: `View`,
    viewCloseButton: `Close`,
    viewDeleteAction: `Delete`,
    viewDownloadAction: `Download`,
    viewInNewTab: `View in New Tab`,
    viewOpenLiveEditor: `Open in MATLAB Online`,
    viewOpenLiveEditorMock: `Cannot open \"\${0}\".`,
    viewOpenLiveEditorNotSupported: `The \"Open in MATLAB Online\" feature is not supported\nin this environment.`,

    // simple file upload
    simpleUploadTitle: `Upload Files To \${0}`,
    simpleUploadFolderTitle: `Upload Folder To \${0}`,
    simpleUploadFilesSelectionText: `Select files to upload`,
    simpleUploadFolderSelectionText: `Select a folder to upload.`,
    simpleUploadEmptyFolderUploadWarning: `(Empty folders cannot be uploaded)`,
    simpleUploadStatusText: `Uploading...`,
    simpleUploadUploadCancelButtonLabel: `Cancel`,
    simpleUploadUploadButtonLabel: `Upload`,
    simpleUploadCloseButtonLabel: `Close`,
    simpleUploadFileUploadSuccess: `File uploaded successfully.`,
    simpleUploadStatusCancelled: `Upload cancelled.  `,
    simpleUploadStatusWithErrors: `Upload completed with errors.  `,
    simpleUploadSummary: `\${0} completed, \${1} cancelled, \${2} failed.`,
    simpleUploadSummaryNoCancelled: `\${0} completed, \${1} failed.`,
    simpleUploadSummaryNoFailed: `\${0} completed, \${1} cancelled.`,
    simpleUploadSummaryNoCompleted: `\${0} cancelled, \${1} failed.`,
    simpleUploadSummaryNoCancelledNoFailed: `\${0} completed.`,
    simpleUploadSummaryNoCompletedNoCancelled: `\${0} failed.`,
    simpleUploadSummaryNoCompletedNoFailed: `\${0} cancelled.`,
    simpleUploadCompletedText: `Upload completed.  `,
    simpleUploadFile: `file`,
    simpleUploadFiles: `files`,
    simpleUploadNumCompleted: `\${0} \${1} uploaded.`,
    simpleUploadCancelOnly: `\${0} upload(s) cancelled to \${1}.`,
    simpleUploadCancelFail: `\${0} upload(s) cancelled, \${1} file(s) failed to upload to \${2}.`,
    simpleUploadCancelUpload: `\${0} upload(s) cancelled, \${1} file(s) uploaded to \${2}.`,
    simpleUploadCancelFailUpload: `\${0} upload(s) cancelled, \${1} file(s) failed to upload, \${2} file(s) uploaded to \${3}.`,
    simpleUploadNumberFailed: `\${0} file(s) failed to upload to \${1}.`,
    simpleUploadFailedUpload: `\${0} file(s) failed to upload, \${1} file(s) uploaded to \${2}.`,
    simpleUploadOnlyUpload: `\${0} file(s) uploaded to \${1}.`,
    simpleUploadResourceAlreadyExists: `A folder with that name already exists on MATLAB Drive, and MATLAB Drive is case insensitive. Rename the file.`,
    simpleUploadResourceDoesNotExist: `Upload failed. Try again.`,
    simpleUploadFileTooLarge: `File is larger than MATLAB Drive allows (1 GB). Make the file smaller.`,
    simpleUploadAuthorizationError: `No permission to save to this folder.`,
    simpleUploadQuotaExceeded: `MATLAB Drive storage is full. Delete content to free up space.`,
    simpleUploadIllegalPathElement: `File name contains characters that are not allowed. Rename the file.`,
    simpleUploadSessionNotFound: `Upload failed. Try again.`,
    simpleUploadProgressText: `\${0} out of \${1}...`,
    simpleUploadFolderUploadForbidden: `Folder upload not allowed.`,
    simpleUploadFileTooLargeToUpload: `File is too large to upload online. Maximum file size allowed is \${0}GB. `,
    simpleUploadCanWithConnector: `This file can be added to MATLAB Drive with \${0}.`,
    simpleUploadMatlabDriveConnector: `MATLAB Drive Connector`,
    simpleUploadReasonInvalid: `File is too large to upload online. This file can be added to MATLAB Drive with MATLAB Drive Connector.`,
    simpleUploadProgressStatus: `Uploading \${0} of \${1} items...`,
    simpleUploadUploadCancelled: `Upload cancelled.`,
    simpleUploadInvalidData: `Invalid data: Unable to do file upload.`,
    simpleUploadResolveButtonLabel: `Resolve`,
    simpleUploadUploadTypeChoiceGroupLabelText: `Type of upload: `,
    simpleUploadUploadUploadFilesChoiceLabelText: `files`,
    simpleUploadUploadUploadDirectoryChoiceLabelText: `folder`,

    // Open In MO
    openInMOTitle: `Open \"\${0}\" in MATLAB Online`,
    openInMOLabel: `To continue opening this file in MATLAB Online, please select one of the following actions for the shared folder:`,
    openInMOFileInTrash: `The file/ folder you requested to be opened in MATLAB Online is in Trash. Restore and try again.`,

    // saveToDriveView
    saveToDriveSuccess: `\${0} saved to folder \${1} as \${2} successfully.`,

    // File Conflict Dialog and View
    fconflictDialogTitle: `File Conflict`,
    fconflictFolderDialogTitle: `Folder Conflict`,
    fconflictSelectAll: `Select all`,
    fconflictReplaceButtonLabel: `Replace`,
    fconflictKeepSeparateButtonLabel: `Keep Separate`,
    fconflictFolderHasConflict: `The folder has \${0} file with the same name.`,
    fconflictFolderHasConflicts: `The folder has \${0} files with the same names.`,
    fconflictReplaceSuccessAndFailure: `Replaced \${0} file and failed to replace \${1} file.`,
    fconflictReplaceSuccessesAndFailure: `Replaced \${0} files and failed to replace \${1} file.`,
    fconflictReplaceSuccessesAndFailures: `Replaced \${0} files and failed to replace \${1} files.`,
    fconflictReplaceSuccessAndFailures: `Replaced \${0} file and failed to replace \${1} files.`,
    fconflictReplaceSuccessOnly: `Replaced \${0} file.`,
    fconflictReplaceSuccessesOnly: `Replaced \${0} files.`,
    fconflictReplaceFailureOnly: `Failed to replace \${0} file.`,
    fconflictReplaceFailuresOnly: `Failed to replace \${0} files.`,
    fconflictSaveSuccessesAndFailures: `Saved \${0} files and failed to save \${1} files.`,
    fconflictSaveSuccessesAndFailure: `Saved \${0} files and failed to save \${1} file.`,
    fconflictSaveSuccessAndFailures: `Saved \${0} file and failed to save \${1} files.`,
    fconflictSaveSuccessAndFailure: `Saved \${0} file and failed to save \${1} file.`,
    fconflictSaveSuccessesOnly: `Saved \${0} files.`,
    fconflictSaveSuccessOnly: `Saved \${0} file.`,
    fconflictSaveFailureOnly: `Failed to save \${0} file.`,
    fconflictSaveFailuresOnly: `Failed to save \${0} files.`,
    fconflictPleaseWait: `Please wait...`,

    restoreButtonLabel: `Restore`,
    restoreCancelButtonLabel: `Cancel`,

    personalInvitationLimitExceeded: `You have reached the maximum number of shared folders within your MATLAB Drive. Remove shared folders from your MATLAB Drive before adding a new shared folder.`,
    resourceInvitationLimitExceeded: `Member limit exceeded. Remove current members to add new members.`,
    linkResourceInvitationLimitExceeded: `Shared folder cannot be added. Member limit exceeded. Contact the owner.`,

    showHiddenFilesLabel: `Show Hidden Files`,
    chooseApplicationLabel: `Select Application:`,
    defaultApplicationLabel: `MATLAB Drive`,
    addonApplicationLabel: `Add-Ons`,
    thingspeakApplicationLabel: `ThingSpeak`,
    instantAddonsApplicationLabel: `Instant Add-Ons`,
    graderApplicationLabel: `MATLAB Grader`,
    otherApplicationLabel: `Other Apps`,

    previewBannerText: `PREVIEW`,
    meMyself: `Me`,

    // Folder chooser
    folderChooserFileNameLabel: `File name:`,
    folderChooserChooseButton: `Choose`,
    folderChooserChooseMoveButton: `Move`,
    folderChooserChooseCopyButton: `Copy`,
    folderChooserCloseButton: `Close`,
    folderChooserModalTitle: `Choose a folder`,
    moveFolderChooserModalTitle: `Move \${0} to`,
    copyToFolderChooserModalTitle: `Copy \${0} to`,
    folderChooserNoSelectionWarning: `Clicking the \"\${0}\" button with nothing selected will choose the \${1} folder.`,
    folderChooserNoSelectionMoveWarning: `If no selection is made, the content will be moved to the current folder.`,
    folderChooserNoSelectionCopyWarning: `If no selection is made, the content will be copied to the current folder.`,
    folderChooserCurrentRootFolder: `current`,
    folderChooserRenameSourceNotAllowed: `Renaming source path during move or copy not allowed.`,

    // Save To Drive
    saveToDriveSaveButtonLabel: `Save`,
    saveToDriveCancelButtonLabel: `Cancel`,
    saveToDriveModalTitle: `Save to MATLAB Drive`,

    // Embedded widget tester
    widgetTestAppPageTitle: `Embedded Widget Test Page`,
    widgetTestAppChooseLabel: `Choose widget`,
    widgetTestAppUseMatlabStyle: `Use MATLAB Desktop styling`,
    widgetTestAppOverrideCss: `Override CSS with custom stylesheet`,
    widgetTestAppCssStylesheetToUse: `CSS Stylesheet URL to use:`,
    widgetTestAppIgnoreResizeMsg: `Ignore widget window resize messages`,
    widgetTestAppIframeWidthPx: `iframe width in pixels: `,
    widgetTestAppIframeHeightPx: `iframe height in pixels: `,
    widgetTestAppRunWidgetButton: `Run selected widget`,
    widgetTestAppResetButton: `Reset`,
    widgetTestAppSelectedWidgetContents: `Selected Widget Test Contents`,
    widgetTestAppLoggedInIntro: `You currently have valid login credentials. The embedded widgets will use those credentials to get their data.`,
    widgetTestAppNotLoggedInIntro: `You currently are not logged in and do not have valid credentials.`,
    widgetTestAppWidgetsWillNotWork: `The embedded widgets will not work without valid login credentials.`,
    widgetTestAppNeedLogin: `You need to first login to this same domain to obtain valid credentials.`,
    // Embedded widget testers
    widgetTestOptionNoOptions: `No Options`,
    widgetTestOptionActions: `Actions`,
    widgetTestOptionColumns: `Columns`,
    widgetTestOptionWidgetOptions: `Widget Options`,
    widgetTestOptionTrashWidgetTitle: `Trash Contents Widget`,
    widgetTestOptionsColumnName: `Name`,
    widgetTestOptionsColumnSize: `Size`,
    widgetTestOptionStartButton: `Start Widget`,
    widgetTestOptionPleaseWait: `Please wait...`,
    widgetTestOptionResetWidgetButton: `Reset widget options`,
    widgetTestOptionSearchWidgetTitle: `Search Files Widget`,
    widgetTestOptionColumnClientDateModified: `Client Date Modified`,
    widgetTestOptionColumnDateModified: `Date Modified`,
    widgetTestOptionColumnType: `Type`,
    widgetTestOptionShowShared: `Show shared items`,
    widgetTestOptionShowHidden: `Show hidden files`,
    widgetTestOptionIncludeFolders: `Include folders`,
    widgetTestOptionEnableViewing: `Enable viewing`,
    widgetTestOptionEnableDnD: `Enable Drag and Drop`,
    widgetTestOptionEnableNavigation: `Enable navigation`,
    widgetTestOptionSaveToDriveTitle: `SaveToDrive Widget`,
    widgetTestOptionUseFolderChooserUI: `Use FolderChooser UI`,
    widgetTestOptionSaveToDriveResultsLabel: `Results of Saving to Drive: `,
    widgetTestOptionFullPathPrompt: `Enter full path of file to save: `,
    widgetTestOptionChooseContentSourcePrompt: `Choose content source for file: `,
    widgetTestOptionContentChoiceUrl: `URL`,
    widgetTestOptionContentChoiceDirect: `Direct content`,
    widgetTestOptionContentChoiceFromFile: `From file`,
    widgetTestOptionContentChooseFilesPrompt: `Choose files:`,
    widgetTestOptionUrlPrompt: `Enter URL of contents to use:`,
    widgetTestOptionTextPrompt: `Enter text:`,
    widgetTestOptionSaveToDriveButton: `Save to Drive`,
    widgetTestOptionClearButton: `Clear`,
    widgetTestOptionQuotaTitle: `Quota Widget`,
    widgetTestOptionInitiateSharingTitle: `InitiateSharing Widget`,
    widgetTestOptionFolderFullPathPrompt: `Enter full path of folder: `,
    widgetTestOptionLinkButton: `Link`,
    widgetTestOptionManageButton: `Manage`,
    widgetTestOptionFolderChooserTitle: `Folder Chooser Widget`,
    widgetTestOptionShowFileNameSelector: `Show file name selector`,
    widgetTestOptionChooseButton: `Choose`,
    widgetTestOptionSelectedFolder: `Selected full path: `,
    widgetTestOptionDesiredHeightInPixels: `Desired height in pixels: `,
    widgetTestOptionDesiredWidthInPixels: `Desired width in pixels: `,
    widgetTestOptionFileNameSuggestionPrompt: `File name selection suggestion: `,
    widgetTestOptionStartingFolderPrompt: `Starting folder full path: `,
    widgetTestOptionFolderPathContentTitle: `FolderPath Contents Widget`,
    widgetTestOptionsSearchNumberOfDays: `Number of days to search until now: `,
    widgetTestOptionsSearchMaxResults: `Maximum result size: `
  },

  "ja-jp": true,
  "ko-kr": true,
  "zh-cn": true

});
