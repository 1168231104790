/**
 * <strong>Purpose:</strong> <br>
 * Provides the ability to explicitly set the size of the widget.
 * By default the widget is auto-sized to fit its contents.
 * If the user wants to explicitly size the widget then they can set the "width" and "height"  property,
 * Content will be clipped if it does not fit in the specified width and height.
 * <br><br>
 * <strong>Contract:</strong> <br>
 * <em>Attach-point:</em> domNode<br>
 * Sizing behaviors vary for widgets with different templates.
 * The default implementation only adjusts the size of the domNode.
 * Each widget that needs sizing should at a minimum mix in the SizeMixin but may also additionally mixin in others
 * which provide special sizing for specific nodes in their template.
 *
 * @module mw-mixins/property/SizeMixin
 *
 * @copyright Copyright 2014 The MathWorks, Inc.
 */
define([
    "dojo/_base/lang",
    "dojo/_base/declare"
], function (lang, declare) {

    return declare(null, {

        /**
         * @property {number} width - Value must be a positive number or "auto"
         * @instance
         * @default
         */
        width: "auto",

        /**
         * @property {number} height - Value must be a positive number or "auto"
         * @instance
         * @default
         */
        height: "auto",

        _allowedValues: ["auto"],

        postCreate: function () {
            this.inherited(arguments);
            this.domNode.classList.add("mwSizeMixin");
            this.on("updatelayout", lang.hitch(this, this._updateLayout));
        },

        startup: function () {
            this.inherited(arguments);

            this._updateLayout();
        },

        _updateLayout: function () {
            // This is needed since DOM calculations are needed in the setter of size
            this._setWidthAttr(this.get("width"));
            this._setHeightAttr(this.get("height"));
        },

        _setWidthAttr: function (width) {
            this._setSize("width", width);
        },

        _setHeightAttr: function (height) {
            this._setSize("height", height);
        },

        _validateSizeInput: function (value) {

            if (!((typeof value === "string" && value === "auto") ||
                (typeof value === "number" && value > 0))) {
                throw new Error(" Property should either be 'auto' or a positive integer");
            }
        },

        _setSize: function (sizeType, value) {
            this._validateSizeInput(value);

            this._set(sizeType, value);

            // Do not attempt DOM sizing during the widget construction phase
            if (!this._started) {
                return;
            }

            var valueForDom = "";
            if (this._allowedValues.indexOf(value) >= 0) {
                valueForDom = value;
            } else {
                valueForDom = this._getAdjustedSize(sizeType, value, this.domNode);
            }

            this.domNode.style[sizeType] = valueForDom;
        },

        _getAdjustedSize: function (sizeType, value, node) {
           
            return value + "px";
        }
    });
});
