define([
  "dojo/_base/declare",
  "mw-filebrowser/actions/Action",
  "dojo/i18n!nls/mldoStringResource",
], function (declare, Action, MLDOStrings) {
  return declare(Action, {
    constructor: function (args) {
      args = args || {};
      var context = args.context;
      var makeActionAvailable = args.showAction || false;
      this.name =  "goToDriveAction";
      this.enabled = makeActionAvailable;
      this.getInvitationDetailsMethod = args.getInvitationDetailsMethod;
      this.label = MLDOStrings.previewPageGoToDriveLabel;
      this.section = {
        id: "MISC",
        name: "MISC",
        allowSubMenu: false,
        forceSubMenu: false
      };
      this.isApplicableTo = function () {
        let invitationStatus;
        if (this.getInvitationDetailsMethod && typeof this.getInvitationDetailsMethod === "function" && this.getInvitationDetailsMethod()) {
          invitationStatus = this.getInvitationDetailsMethod().invitationStatus;
        }
        if (invitationStatus === "ACCEPTED") {
          this.enabled = makeActionAvailable && true;
        } else {
          this.enabled = false;
        }
        return this.enabled;
      };
      this.callback = function () {
        let invitationDetails;
        if (this.getInvitationDetailsMethod && typeof this.getInvitationDetailsMethod === "function" && this.getInvitationDetailsMethod()) {
          invitationDetails = this.getInvitationDetailsMethod();
        }
        if (invitationDetails && typeof invitationDetails === "object") {
          context.handleGoToDriveAction(invitationDetails.invitationId);
        }
      };
    }
  });

});