//Copyright 2016 MathWorks, Inc.

define([
    "dojo/_base/declare"
], function (declare) {
    return declare(null, {
        _columnsMap: null,

        constructor: function () {
            this._columnsMap = [];
        },

        addColumn: function (columnObject) {
            this._columnsMap.push(columnObject.column);
        },

        getColumns: function () {
            return this._columnsMap;
        },

        getColumn: function (field) {
            var columns = this.getColumns();
            var currentColumn = columns.filter(
                function (obj) {
                    return obj.field === field;
                })[0];
            return currentColumn;
        },

        getCompareFunction: function (field, isDescending) {
            var currentColumn = this.getColumn(field);
            if (currentColumn.sort) {
                var compareFunction = function (a, b) {
                    var result = currentColumn.sort(a, b);
                    return result * (isDescending ? -1 : 1);
                };
                return compareFunction;
            }
        }
    });
});