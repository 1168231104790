define([
  "underscore",
  "jquery",
  'bootstrap',
  "backbone",
  "util",
  "templates/restoreFolderDialog",
  "dojo/i18n!nls/mldoStringResource",
  "dojo/string"
], function( _, $, Bootstrap, Backbone, Utils, RestoreFolderDialog, MLDOStrings, DojoString ) {

   return Backbone.View.extend({

       template: RestoreFolderDialog,

       initialize: function(args) {
         if (!args || Object.keys(args).length === 0) {
           throw new TypeError("Invalid arguments");
         }
         if (!args.dialogTitle || typeof args.dialogTitle !== "string") {
           throw new TypeError("Invalid title argument");
         }
         this.dialogTitle = args.dialogTitle;
         if (!args.dialogWarning || typeof args.dialogWarning !== "string") {
           throw new TypeError("Invalid warning argument");
         }
         this.dialogWarning = args.dialogWarning;
         if (!args.dialogText || typeof args.dialogText !== "string") {
           throw new TypeError("Invalid text argument");
         }
         this.dialogText = args.dialogText;
         if (!args.tombstoneId || typeof args.tombstoneId !== "string" || !args.tombstoneId.length) {
           throw new TypeError("Invalid tombstone argument");
         }
         this.tombstoneId = args.tombstoneId;
         if (!args.callback || typeof args.callback !== "function") {
           throw new TypeError("Invalid callback argument");
         }
         this.callback = args.callback;
         _.bindAll(this, "render", "restoreFolder", "dragNotAllowed");
       },

       render: function() {
           document.querySelector('#modalContainer').innerHTML = RestoreFolderDialog({
             dialogTitle: _.escape(this.dialogTitle),
             dialogWarning: this.dialogWarning,
             dialogText: this.dialogText,
             restoreButtonLabel: MLDOStrings.restoreButtonLabel,
             restoreCancelButtonLabel: MLDOStrings.restoreCancelButtonLabel
           });
           const dialogElement = document.querySelector('#restoreFolderDialog');
           dialogElement.addEventListener('shown.bs.modal', this.setInputFocus.bind(this), false);
           dialogElement.addEventListener('hidden.bs.modal', function() {
             let restoreFolderSubmit = document.querySelector('div#restoreFolderDialog .restoreFolderSubmit');
             if (restoreFolderSubmit) {
               restoreFolderSubmit.removeEventListener('click', this.restoreFolder);
             }
             if (dialogElement) {
               const modal = Bootstrap.Modal.getOrCreateInstance(dialogElement);
               modal.dispose();
               dialogElement.remove();
             }
           }.bind(this), false);
          dialogElement.removeEventListener('dragover', this.dragNotAllowed);
          dialogElement.addEventListener('dragover', this.dragNotAllowed, false);
          document.querySelector('div#restoreFolderDialog .restoreFolderSubmit').addEventListener('click', this.restoreFolder, false);
           if (dialogElement) {
             const modal = new Bootstrap.Modal(dialogElement, {backdrop: 'static'});
             modal.show();
           }
           return this;
       },

       setInputFocus: function() {
         $("#restoreFolderSubmit").focus();
       },

       dragNotAllowed: function(e) {
         e.preventDefault();
         e.originalEvent.dataTransfer.dropEffect = 'none';
       },

       disableSubmit: function() {
           $('.restoreFolderSubmit').prop('disabled', true);
       },

       enableSubmit: function() {
           $('.restoreFolderSubmit').prop('disabled', false);
       },

       restoreFolder: function(e) {
         if (e && e.preventDefault) {
           e.preventDefault();
         }
         if (this.tombstoneId.length && typeof this.tombstoneId === "string") {
           this.callback(this.tombstoneId);
         }
         $('#cancelRestoreFolder').click();
       }

   });
});
