define([
    "dojo/_base/declare",
    "mw-filebrowser/actions/Action",
    "dojo/i18n!nls/mldoStringResource"
], function (declare, Action, MLDOStrings) {
  return declare(Action, {
    constructor: function (args) {
        args = args || {};
        let context = args.context;
        let makeActionAvailable = args.showAction || false;
        this.name =  "openFileLocationAction";
        this.label = MLDOStrings.searchPageOpenFileLocationLabel;
        this.enabled = makeActionAvailable;
        this.section = {
            id: "MISC",
            name: "MISC",
            allowSubMenu: false,
            forceSubMenu: false
        };
        this.isApplicableTo = function () {
          let selectedFileCount = context.getSelectedRowCount();
          this.enabled = selectedFileCount === 1;
          return this.enabled;
        };
        this.callback = function (action) {
          let fileInfos = context.getSelectedItemFileInfos();
          let fileInfo = fileInfos[0];
          if (this.isApplicableTo() && fileInfo && typeof fileInfo === "object") {
            context.handleOpenFileLocationAction(fileInfo.parent, fileInfo.path);
          }
        };
    }
  });

});
