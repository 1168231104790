/**
 * <strong>Purpose:</strong> <br>
 * Provides the ability to show an icon or hide icon

 * <br><br>
 * <strong>Contract:</strong> <br>
 * <em>Attach-point:</em> iconNode<br>
 * If showIcon is true, then the icon is placed inside this node.
 *
 * <br><br>
 * @module mw-menu/mixins/property/ShowIconMixin
 *
 * @copyright Copyright 2014 The MathWorks, Inc.
 */

define([
    "dojo/_base/declare"
], function (declare) {

    return declare(null, {
        /**
         * @property  {boolean} ShowIcon Supports boolean values
         * @instance
         * @default true
         */
        showIcon: true,
        postCreate: function () {
            this.inherited(arguments);
            this.domNode.classList.add("mwShowIconMixin");
        },
        _setShowIconAttr: function (value) {
            if (typeof value !== "boolean") {
                throw new Error("'showIcon' property expects a Boolean!");
            }
            if (!value) {
                this.domNode.classList.add("mwHideIcon");
            } else {
                this.domNode.classList.remove("mwHideIcon");
            }
            this._set("showIcon", value);
        }

    });

});
