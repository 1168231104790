/**
 * <strong>Purpose:</strong> <br>
 * Provides ability to manage focusing of FocusableMenuChildMixin Widgets in a Menu
 * <br><br>
 * <strong>Contract:</strong> <br>
 * Do mix in ContainerFocusMixin.
 *
 * @module mw-mixins/MenuFocusMixin
 *
 * @copyright Copyright 2015-2017 The MathWorks, Inc.
 */

define([
    "dojo/_base/declare",
    "dojo/_base/lang",
    "dojo/on",
    "dojo/has",
    "dojo/touch",
    "dojo/keys",
    "dijit/popup",
    "dijit/registry",
    "mw-dom-utils/domUtils",
    "mw-utils/widgetUtils",
    "mw-menu/mixins/ContainerFocusMixin"
], function (declare, lang, on, has, touch, keys, popup, registry, domUtils, widgetUtils, ContainerFocusMixin) {
    var touchOnly = has("touch") && (!window.PointerEvent);

    var MenuFocusMixin = declare(null, {
        postCreate: function () {
            this.inherited(arguments);
            this.domNode.classList.add("mwMenuFocusMixin");
            this.focusNode = this.focusNode || this.domNode;
            this.own(on(this.domNode, "close", lang.hitch(this, this._handleClose)));
        },

        focus: function () {
            this.inherited(arguments);
            this._addFocusListeners();
        },

        _focusLost: function (e) {
            setTimeout( lang.hitch(this, function () {
                if (widgetUtils.isMenuOpen(this)) {
                    var gainedFocusWidget = (e instanceof FocusEvent) ? registry.getEnclosingWidget(domUtils.parseFocusEvent(e).gainedFocusNode) : registry.getEnclosingWidget(e.target),
                        gainedFocusWidgetIsDescendant,
                        gainedFocusMixinIsChildContextMenu,
                        gainedFocusWidgetIsInvokingWidget,
                        // g1564595: Do not grab the
                        node = gainedFocusWidget ?
                            ((gainedFocusWidget.focusNode && (gainedFocusWidget.focusNode instanceof Element)) ?
                                gainedFocusWidget.focusNode :
                                gainedFocusWidget.domNode ? gainedFocusWidget.domNode : null) :
                            null;

                    // TODO: Update logic when CompositionMixin is added
                    // If the input is a child in a composite widget, then get the primary node from the value of the "data-composite-child" attribute
                    if (node && node.hasAttribute("data-composite-child")) {
                        gainedFocusWidget = registry.getEnclosingWidget(document.getElementById(node.getAttribute("data-composite-primary-id")));
                    }

                    gainedFocusWidgetIsDescendant = this.isDescendant(gainedFocusWidget, this);
                    gainedFocusMixinIsChildContextMenu =
                        gainedFocusWidget &&
                        gainedFocusWidget.domNode &&
                        ([].slice.call(document.querySelectorAll(".mwToolstripPopupContextMenu, .mwToolstripPopupContextMenu *")).indexOf(gainedFocusWidget.domNode) > -1);
                    gainedFocusWidgetIsInvokingWidget = (this._invokingWidget && (gainedFocusWidget === this._invokingWidget));

                    if ((!gainedFocusWidget) ||
                        ((!gainedFocusWidgetIsDescendant) &&
                         (!gainedFocusMixinIsChildContextMenu) &&
                         (!gainedFocusWidgetIsInvokingWidget))) {
                        this._focusChange(gainedFocusWidget);
                    }
                }
            }), 0);
        },

        _handleKeydown: function (e) {
            if (e.keyCode === keys.ESCAPE) {
                this._focusLost(e);
            }
        },

        _handleClose: function () {
            this._removeFocusListeners();
        },

        _addFocusListeners: function () {
            if (this.focusNode) {
                this._focusNodeBlurListener = on(this.focusNode, "blur", lang.hitch(this, "_focusLost"));
                this.own(this._focusNodeBlurListener);
                this._windowBlurListener = on(window, "blur", lang.hitch(this, "_focusLost"));
                this.own(this._windowBlurListener);
                this._documentClickListener = on(document.body, touchOnly ? touch.press : "mousedown", lang.hitch(this, "_focusLost"));
                this.own(this._documentClickListener);
                this._escapeKeydownListener = on(document.body, "keydown", lang.hitch(this, "_handleKeydown"));
                this.own(this._escapeKeydownListener);
            }
        },

        _removeFocusListeners: function () {
            if (this._focusNodeBlurListener) {
                this._focusNodeBlurListener.remove();
                delete this._focusNodeBlurListener;
            }
            if (this._windowBlurListener) {
                this._windowBlurListener.remove();
                delete this._windowBlurListener;
            }
            if (this._documentClickListener) {
                this._documentClickListener.remove();
                delete this._documentClickListener;
            }
            if (this._escapeKeydownListener) {
                this._escapeKeydownListener.remove();
                delete this._escapeKeydownListener;
            }
        },

        // WIDGET BASED METHODS
        _focusChange: function (gainedFocusWidget) {
            this._closeEventData = {mwEventData: {
                gainedFocusWidget: gainedFocusWidget
            }};
            if (gainedFocusWidget == null) {
                this.closeRequest(false);
                return;
            }
            if (!this.isInvokingWidget(gainedFocusWidget) && !this.isDescendant(gainedFocusWidget, this)) {
                this.closeRequest(false);
            }
        },

        closeRequest: function (focusParent) {
            if (focusParent == null || [true,false].indexOf(focusParent) < 0) {
                focusParent = true;
            }
            // ContextMenu
            if (this.close) {
                this.close();
            }
            // MenuMixin
            if (this._invokingWidget && this._invokingWidget.isMenuOpen()) {
                this._invokingWidget.closeMenu(focusParent);
            }
            // If the popup is not closed at this point then resort to directly using popup.js
            // The display style of the popup wrapper will be "" if open and "none" if closed
            if (widgetUtils.isMenuOpen(this)) {
                popup.close(this);
            }
        },

        isInvokingWidget: function (widget) {
            if (widget == null) {
                return false;
            }

            if (widget instanceof HTMLElement) {
                widget = registry.getEnclosingWidget(widget);
            }

            return (widget === this._invokingWidget);
        },

        isDescendant: function (child, ancestor) {
            if (child == null) {
                return false;
            } else if (child.domNode) {
                child = child.domNode;
            }

            if (ancestor == null) {
                ancestor = this.domNode;
            } else if (ancestor.domNode) {
                ancestor = ancestor.domNode;
            }

            var getSpouseNode = function getSpouseNode(childNode) {
                if (childNode.hasAttribute('dijitpopupparent')) {
                    return document.getElementById(childNode.getAttribute('dijitpopupparent'));
                }
                // return null by default
                return null;
            };

            // If node is a direct descendant or the child of a sub-menu then return true
            return domUtils.isDescendantOrInLaw(child, ancestor, getSpouseNode, true);
        }
    });

    MenuFocusMixin._dependencies = [
        {mixin: ContainerFocusMixin, orderDependent: true}
    ];

    return MenuFocusMixin;
});
