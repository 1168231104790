// Copyright 2016, The MathWorks, Inc

/**
 * Provides convenient API for removing many listeners all at once (similar
 * to dijit's Destroyable)
 */

define([
    "dojo/_base/declare"
], function (declare) {

    return declare([], {

        constructor: function () {
            this._removables = [];
        },

        own: function (removable) {
            if (removable && removable.remove) {
                this._removables.push(removable);
            }
        },

        remove: function () {
            if (this._removables) {
                this._removables.forEach(function (removable) {
                    removable.remove();
                });
            }
            this._removables = [];
        }

    });

});