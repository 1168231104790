// Copyright 2015-2017 MathWorks, Inc.

define([
    "dojo/_base/lang",
    "dojo/aspect",
    "mw-keybindings/BindingUtils",
    "mw-keybindings/DefaultKeys"
], function (lang, aspect, BindingUtils, DefaultKeys) {
    var fileBrowser;
    return {
        buildKeyMap: function (actionServices, fb) {
            fileBrowser = fb;
            fileBrowser.grid.addKeyHandler(DefaultKeys.LEFT_ARROW, lang.hitch(fileBrowser.grid, "moveFocusLeft"));
            fileBrowser.grid.addKeyHandler(DefaultKeys.RIGHT_ARROW, lang.hitch(fileBrowser.grid, "moveFocusRight"));
            fileBrowser.grid.addKeyHandler(DefaultKeys.LEFT_ARROW, lang.hitch(fileBrowser.grid, "moveFocusLeft"), true);
            fileBrowser.grid.addKeyHandler(DefaultKeys.RIGHT_ARROW, lang.hitch(fileBrowser.grid, "moveFocusRight"), true);
            var keyboardActionBuilder = this;

            actionServices.forEach(function (actionService) {
                var currentActionService = actionService;

                aspect.after(currentActionService, "onActionAdd", function (event) {
                    var action = currentActionService._actionById(event.id)._properties;
                    keyboardActionBuilder.setKeyBinding(action, currentActionService);
                }, true);

                var allActions = [];
                try {
                    allActions = currentActionService.getAllActions();
                } catch (e) {
                    return;
                }
                allActions.forEach(function (action) {
                    keyboardActionBuilder.setKeyBinding(action, currentActionService);
                });
            });
        },

        setKeyBinding: function (action, actionService) {
            if (action.supportsKeyBinding) {
                if (!action.keyCode) {
                    actionService.updateAction(action.id, {keyCode: fileBrowser.keybindings[action.id] ? BindingUtils.getKeyBindingData(fileBrowser.keybindings[action.id]).keyCode : ""});
                }
                fileBrowser.grid.keyMap[action.keyCode] = null;
                fileBrowser.grid.addKeyHandler(action.keyCode, lang.hitch(this, "_performAction", action, actionService));
            }
        },

        _performAction: function (action, actionService, event) {
            if (action.requiresCtrl && !event.ctrlKey) {
                return;
            }
            actionService.executeAction(action.id, actionService._actionById(action.id)._properties);
            event.preventDefault();
            event.stopPropagation();
        }
    };
});