//Copyright 2013-2016 The MathWorks, Inc.

define([
], function () {
    var ALLOWED_FILE_TYPES = ["m", "mlx", "html", "htm", "mat", "txt", "text", "mlappinstall", "mltbx"];

    var getExtension = function (fileInfo) {
        var dotIdx = fileInfo.name.lastIndexOf(".") !== -1 ? fileInfo.name.lastIndexOf(".") + 1 :
            fileInfo.name.length;
        return fileInfo.name.substring(dotIdx, fileInfo.name.length);
    };
    return {
        getExtensionIfValid: function (fileInfo) {
            if (fileInfo.type) {
                return (ALLOWED_FILE_TYPES.indexOf(fileInfo.type) === -1) ? "" : fileInfo.type;
            } else {
                return (ALLOWED_FILE_TYPES.indexOf(getExtension(fileInfo)) === -1) ? "" : getExtension(fileInfo);
            }
        }
    };
});