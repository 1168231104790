/**
 * <strong>Purpose:</strong> <br>
 * Provides the ability to display an icon either via a data-uri or a CSS class. When its given a data-uri, it detects
 * the height and width and sets the iconNode to these dimensions. It also emits an event after the icon image has been
 * applied. When its given a CSS class, the user will need to specify CSS properties on how the icon image should be
 * applied i.e. height, width, background-image
 * <br><br>
 * <strong>Contract:</strong> <br>
 * <em>Attach-point:</em> iconNode <br>
 * The Mixin applies the image to the iconNode attach-point
 *
 * @module mw-mixins/property/IconMixin
 *
 * @copyright Copyright 2014 The MathWorks, Inc.
 */
define([
    "dojo/_base/declare",
    "dojo/_base/lang",
    "dojo/dom-construct",
    "dojo/on"
], function (declare, lang, domConstruct, on) {

    return declare(null, {
        _iconTypes: {
            DATA_URI: "data_uri",
            CSS_CLASS: "css_class"
        },

        /**
         * @property  {string} icon  Supported Values: data-uri / CSS class
         * @instance
         */
        icon: "",
        _lastIconApplied: "",

        postCreate: function () {
            this.inherited(arguments);
            this.domNode.classList.add("mwIconMixin");

            // Since icon's default value is falsy, it will not trigger the setter during the widget construction phase
            // when attributes are being applied we need to call the setter explicitly.
            this.set("icon", this.get("icon"));
        },

        _setIconAttr: function (icon) {
            if (typeof icon !== "string") {
                throw new Error("'icon' property expects a literal string!");
            }

            this._set("icon", icon);
            this._updateDOM(icon);

        },

        _updateDOM: function (value) {
            // Remove previous icon
            this._removeOldIcon();

            // Apply the new icon
            this._applyNewIcon(value);
        },

        _removeOldIcon: function () {
            var value = this._lastIconApplied;
            var oldIconType = this._getIconType(value);
            if (oldIconType === this._iconTypes.DATA_URI) {
                this._removeImageStyles(this.iconNode);
            } else if (oldIconType === this._iconTypes.CSS_CLASS) {
                if (value !== "") {
                    var classes = value.trim().split(' ');
                    classes.forEach(function(className) {
                        this.iconNode.classList.remove(className);
                    }, this);
                }
            }
        },

        _applyNewIcon: function (value) {
            var newIconType = this._getIconType(value);
            if (newIconType === this._iconTypes.DATA_URI) {
                var img = domConstruct.create("img", {
                    src: value
                });

                this.own(
                    on.once(img, "load", lang.hitch(this, function () {
                        this._applyImageStyles(this.iconNode, value, img);
                        // To notify when the icon has finished being applied
                        this.emit("iconapplied");
                        this.emit("updatelayout");
                        // Remove the dom node, its work is done
                        domConstruct.destroy(img);
                    }))
                );
            } else if (newIconType === this._iconTypes.CSS_CLASS) {
                if (value !== '') {
                    var classes = value.trim().split(' ');
                    classes.forEach(function(className) {
                        this.iconNode.classList.add(className);
                    }, this);

                }
            }

            if (value.length === 0) {
                this.domNode.classList.add("mwNoIcon");
            } else {
                this.domNode.classList.remove("mwNoIcon");
            }

            this._lastIconApplied = value;
        },

        // Since the icon property supports two types Data Uri or CSS class, this method determines from the string provided
        // by the user which type they intend to use
        _getIconType: function (icon) {
            var result = "";

            if (icon.indexOf("data:") > -1) {
                result = this._iconTypes.DATA_URI;
            } else {
                result = this._iconTypes.CSS_CLASS;
            }

            return result;
        },

        _applyImageStyles: function (node, dataUri, img) {
            node.style.backgroundImage = "url(" + dataUri + ")";
            node.style.width = img.width + "px";
            node.style.height = img.height + "px";
            
        },

        _removeImageStyles: function (node) {
            node.style.backgroundImage = "";
            node.style.width = "";
            node.style.height = "";
        }

    });

});
