/**
 * This is one of the Standard Widgets in the Web Widgets Framework. It uses a custom template compared
 * to DOJO's Menu Separator
 * TO logically separate group of Menu's child widgets, on ecould use a separator.
 * MenuSeparator is supposed to be used with in a Menu. So, a Menu must be created first and then
 * MenuSeparator should be added to the menu.<br>
 * To see all the available properties for this widget, please look at the corresponding Property Mixins below.
 * <br>
 * <br>
 * <strong>Property Mixins:</strong><br>
 * {@link module:mw-mixins/property/VisualFamilyMixin}<br>
 *
 * <strong>Other Mixins:</strong><br>
 * {@link module:mw-mixins/PreventSelectionMixin}<br>
 *
 * @example <caption>Creating MenuSeparator</caption>
 * var MenuSeparator1 = new MenuSeparator({
 * });
 *
 *
 * MenuSeparator1.placeAt("someMenuId");
 * MenuSeparator1.startup();
 *
 * @module mw-menu/MenuSeparator
 *
 * @copyright Copyright 2015 The MathWorks, Inc.
 */

// TODO: Remove _CSSStateMixin dependency, as seen in SplitButton and ToggleSplitButton

define([
    "dojo/_base/declare",
    "dijit/_WidgetBase",
    "dijit/_TemplatedMixin",
    "dijit/_CssStateMixin",
    "mw-mixins/property/TagMixin",
    "mw-mixins/property/VisualFamilyMixin",
    "mw-mixins/PreventFocusMixin",
    "mw-mixins/mixinDependencyValidator",
    "dojo/text!./templates/MenuSeparator.html"
], function (declare, _WidgetBase, _TemplatedMixin, _CssStateMixin,
             TagMixin, VisualFamilyMixin, PreventFocusMixin,  mixinDependencyValidator, template) {

    return declare(mixinDependencyValidator.validate([_WidgetBase, _TemplatedMixin, _CssStateMixin, TagMixin, VisualFamilyMixin, PreventFocusMixin]), {
        baseClass: "mwWidget mwMenuSeparator",
        templateString: template
    });

});
