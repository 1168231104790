// Copyright 2016, The MathWorks, Inc

/**
 * Provides a library of stateless functions that are used in gesture detection.
 */

define([
    "dojo/mouse"
], function (mouse) {

    var DEFAULT_TAP_TIMEOUT_MS = 300;
    var DEFAULT_MOUSE_TAP_RADIUS_PX = 1;
    var DEFAULT_TOUCH_TAP_RADIUS_PX = 20;

    var DOWN_EVENT_TYPES = {
        "touchstart": true,
        "mousedown": true,
        "pointerdown": true,
        "MSPointerDown": true
    };

    var MOVE_EVENT_TYPES = {
        "touchmove": true,
        "mousemove": true,
        "dojotouchmove": true,
        "pointermove": true,
        "MSPointerMove": true
    };

    var UP_EVENT_TYPES = {
        "touchend": true,
        "mouseup": true,
        "dojotouchend": true,
        "pointerup": true,
        "MSPointerUp": true
    };

    var CANCEL_EVENT_TYPES = {
        "touchcancel": true,
        "mouseout": true,    //See dojo/touch on how they define cancel events
        "pointercancel": true,
        "MSPointerCancel": true
    };

    var SYNTHETIC_EVENT_TYPES = {
        "dojotouchmove": true,
        "dojotouchend": true
    };

    var MOUSE_EVENT_TYPES = {
        "mousedown": true,
        "mousemove": true,
        "mouseup": true
    };

    var TOUCH_EVENT_TYPES = {
        "touchstart": true,
        "touchmove": true,
        "touchend": true,
        "touchcancel": true
    };

    var POINTER_EVENT_TYPES = {
        "pointerdown": true,
        "pointermove": true,
        "pointerup": true,
        "MSPointerDown": true,
        "MSPointerMove": true,
        "MSPointerUp": true
    };

    var GestureUtils = {

        isDownEvent: function (evt) {
            return !!DOWN_EVENT_TYPES[evt.type];
        },

        isMoveEvent: function (evt) {
            return !!MOVE_EVENT_TYPES[evt.type];
        },

        isUpEvent: function (evt) {
            return !!UP_EVENT_TYPES[evt.type];
        },

        isCancelEvent: function (evt) {
            return !!CANCEL_EVENT_TYPES[evt.type];
        },

        isSyntheticEvent: function (evt) {
            return !!SYNTHETIC_EVENT_TYPES[evt.type];
        },

        isPointerEvent: function (evt) {
            return !!POINTER_EVENT_TYPES[evt.type];
        },

        isDragStartEvent: function (evt) {
            return evt.type === "dragstart";
        },

        isEventSinglePrimaryPointer: function (evt) {
            var isSinglePrimary = true;
            if (evt.touches && (evt.touches.length !== 1)) {
                if (!GestureUtils.isSyntheticEvent(evt)) {
                    isSinglePrimary = false;
                }
            }
            if (GestureUtils.isMouseEvent(evt) && !mouse.isLeft(evt)) {
                isSinglePrimary = false;
            }
            if (GestureUtils.isPointerEvent(evt)) {
                //Pointer "up" events never have any button information, so we assume true
                if (GestureUtils.isUpEvent(evt)) {
                    isSinglePrimary = true;
                } else {
                    isSinglePrimary = evt.buttons === 1;
                }
            }
            return isSinglePrimary;
        },

        isEventSingleSecondaryPointer: function (evt) {
            var isSingleSecondary = false;
            if (GestureUtils.isMouseEvent(evt) && mouse.isRight(evt)) {
                isSingleSecondary = true;
            }
            if (GestureUtils.isPointerEvent(evt)) {
                //Pointer "up" events never have any button information, so we assume true
                if (GestureUtils.isUpEvent(evt)) {
                    isSingleSecondary = true;
                } else {
                    isSingleSecondary = evt.buttons === 2;
                }
            }
            return isSingleSecondary;
        },

        isMouseEvent: function (evt) {
            return MOUSE_EVENT_TYPES[evt.type] ? true : false;
        },

        isTouchEvent: function (evt) {
            var result = TOUCH_EVENT_TYPES[evt.type] ? true : false;
            result = result || evt.hasOwnProperty("touches");
            return result;
        },

        /**
         * Checks if two events are in the same location. Note that we use dojo/on for event listening, which adds
         * pageX, pageY, layerX, and layerY fields to touch events.
         * @param evt1
         * @param evt2
         * @returns {boolean}
         */
        areEventsInSameLocation: function (evt1, evt2) {
            return (
                evt1.pageX === evt2.pageX &&
                evt1.pageY === evt2.pageY &&
                evt1.layerX === evt2.layerX &&
                evt1.layerY === evt2.layerY
            );
        },

        areEventsOfSameType: function (evt1, evt2) {
            return evt1.type === evt2.type;
        },

        areIdenticMouseEvents: function (evt1, evt2) {
            return (
                GestureUtils.isMouseEvent(evt1) &&
                GestureUtils.isMouseEvent(evt2) &&
                GestureUtils.areEventsOfSameType(evt1,evt2) &&
                GestureUtils.areEventsInSameLocation(evt1,evt2)
            );
        },

        getTimeoutCheckFcn: function (evtBase, timeoutMs) {
            return function (evt) {
                if (!timeoutMs) {
                    timeoutMs = DEFAULT_TAP_TIMEOUT_MS;
                }
                // Allows function to be called without a base event (useful for cases where
                //  base event may not exist in the matching sequence)
                if (!evtBase) {
                    return false;
                }
                return Math.abs(evtBase._softTimestamp - evt._softTimestamp) <= timeoutMs;
            }
        },

        getLocalCheckFcn: function (evtBase, radiusPx) {
            return function (evt) {
                var distance, deltaX, deltaY;
                // Allows function to be called without a base event (useful for cases where
                //  base event may not exist in the matching sequence)
                if (!evtBase) {
                    return false;
                }
                if (!radiusPx) {
                    radiusPx = GestureUtils.isTouchEvent(evtBase) ? DEFAULT_TOUCH_TAP_RADIUS_PX :
                        DEFAULT_MOUSE_TAP_RADIUS_PX;
                }
                deltaX = evt.pageX - evtBase.pageX;
                deltaY = evt.pageY - evtBase.pageY;
                distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
                return distance < radiusPx;
            }
        },

        conglomerateChecks: function (evtCheckFcnList) {
            return function (evt) {
                var result = true;
                evtCheckFcnList.forEach(function (evtCheckFcn) {
                    result = result && evtCheckFcn(evt);
                });
                return result;
            };
        },

        matchDragStart: function (toMatch) {
            return GestureUtils.matchOn(toMatch, [
                GestureUtils.isDragStartEvent
            ]);
        },

        matchCancel: function (toMatch) {
            return GestureUtils.matchOn(toMatch, [
                GestureUtils.isCancelEvent
            ]);
        },

        matchDown: function (toMatch) {
            return GestureUtils.matchOn(toMatch, [
                GestureUtils.isDownEvent,
                GestureUtils.isEventSinglePrimaryPointer
            ]);
        },

        matchDownMove: function (toMatch) {
            var matched = toMatch;
            matched = GestureUtils.matchOn(matched, [
                GestureUtils.isDownEvent,
                GestureUtils.isEventSinglePrimaryPointer
            ]);
            matched = GestureUtils.matchMultipleOn(matched, [
                GestureUtils.isMoveEvent,
                GestureUtils.isEventSinglePrimaryPointer
            ]);
            return matched;
        },

        matchDownUp: function (toMatch) {
            var matched = toMatch;
            matched = GestureUtils.matchOn(matched, [
                GestureUtils.isDownEvent,
                GestureUtils.isEventSinglePrimaryPointer
            ]);
            matched = GestureUtils.matchMultipleOn(matched, [
                GestureUtils.isMoveEvent,
                GestureUtils.isEventSinglePrimaryPointer
            ], true);
            matched = GestureUtils.matchOn(matched, [
                GestureUtils.isUpEvent,
                GestureUtils.isEventSinglePrimaryPointer
            ]);
            return matched;
        },

        matchTap: function (toMatch) {
            var downMatch;
            var matched = toMatch;
            downMatch = GestureUtils.matchOn(matched, [
                GestureUtils.isDownEvent,
                GestureUtils.isEventSinglePrimaryPointer
            ]);
            matched = GestureUtils.matchMultipleOn(downMatch, [
                GestureUtils.isMoveEvent,
                GestureUtils.isEventSinglePrimaryPointer,
                GestureUtils.getLocalCheckFcn(downMatch.matchedEvent)
            ], true);
            matched = GestureUtils.matchOn(matched, [
                GestureUtils.isUpEvent,
                GestureUtils.isEventSinglePrimaryPointer,
                GestureUtils.getLocalCheckFcn(downMatch.matchedEvent),
                GestureUtils.getTimeoutCheckFcn(downMatch.matchedEvent, toMatch.options.tapTimeout)
            ]);
            return matched;
        },

        matchTapDown: function (toMatch) {
            var tapMatched;
            var matched = toMatch;
            tapMatched = GestureUtils.matchTap(matched);
            matched = GestureUtils.matchMultipleOn(tapMatched, [
                GestureUtils.isMoveEvent,
                GestureUtils.isEventSinglePrimaryPointer,
                GestureUtils.getLocalCheckFcn(tapMatched.matchedEvent)
            ], true);
            matched = GestureUtils.matchOn(matched, [
                GestureUtils.isDownEvent,
                GestureUtils.isEventSinglePrimaryPointer,
                GestureUtils.getLocalCheckFcn(tapMatched.matchedEvent),
                GestureUtils.getTimeoutCheckFcn(tapMatched.matchedEvent, toMatch.options.tapTimeout)
            ]);
            return matched;
        },

        matchTapMove: function (toMatch) {
            var matched = toMatch;
            matched = GestureUtils.matchTapDown(matched);
            matched = GestureUtils.matchMultipleOn(matched, [
                GestureUtils.isMoveEvent,
                GestureUtils.isEventSinglePrimaryPointer
            ]);
            return matched;
        },

        matchTapUp: function (toMatch) {
            var matched = toMatch;
            matched = GestureUtils.matchTapDown(matched);
            matched = GestureUtils.matchMultipleOn(matched, [
                GestureUtils.isMoveEvent,
                GestureUtils.isEventSinglePrimaryPointer
            ], true);
            matched = GestureUtils.matchOn(matched, [
                GestureUtils.isUpEvent,
                GestureUtils.isEventSinglePrimaryPointer
            ]);
            return matched;
        },

        matchDoubleTap: function (toMatch) {
            var downMatch, tapMatch;
            var matched = toMatch;
            tapMatch = GestureUtils.matchTap(matched);
            matched = GestureUtils.matchMultipleOn(tapMatch, [
                GestureUtils.isMoveEvent,
                GestureUtils.isEventSinglePrimaryPointer,
                GestureUtils.getLocalCheckFcn(tapMatch.matchedEvent)
            ], true);
            downMatch = GestureUtils.matchOn(matched, [
                GestureUtils.isDownEvent,
                GestureUtils.isEventSinglePrimaryPointer,
                GestureUtils.getLocalCheckFcn(tapMatch.matchedEvent),
                GestureUtils.getTimeoutCheckFcn(tapMatch.matchedEvent, toMatch.options.tapTimeout)
            ]);
            matched = GestureUtils.matchMultipleOn(downMatch, [
                GestureUtils.isMoveEvent,
                GestureUtils.isEventSinglePrimaryPointer,
                GestureUtils.getLocalCheckFcn(downMatch.matchedEvent)
            ], true);
            matched = GestureUtils.matchOn(matched, [
                GestureUtils.isUpEvent,
                GestureUtils.isEventSinglePrimaryPointer,
                GestureUtils.getLocalCheckFcn(downMatch.matchedEvent),
                GestureUtils.getTimeoutCheckFcn(downMatch.matchedEvent, toMatch.options.tapTimeout)
            ]);
            return matched;
        },

        matchDoubleTapDown: function (toMatch) {
            var doubleTapMatch;
            var matched = toMatch;
            doubleTapMatch = GestureUtils.matchDoubleTap(matched);
            matched = GestureUtils.matchMultipleOn(doubleTapMatch, [
                GestureUtils.isMoveEvent,
                GestureUtils.isEventSinglePrimaryPointer,
                GestureUtils.getLocalCheckFcn(doubleTapMatch.matchedEvent)
            ], true);
            matched = GestureUtils.matchOn(matched, [
                GestureUtils.isDownEvent,
                GestureUtils.isEventSinglePrimaryPointer,
                GestureUtils.getLocalCheckFcn(doubleTapMatch.matchedEvent),
                GestureUtils.getTimeoutCheckFcn(doubleTapMatch.matchedEvent, toMatch.options.tapTimeout)
            ]);
            return matched;
        },

        matchDoubleTapMove: function (toMatch) {
            var matched = toMatch;
            matched = GestureUtils.matchDoubleTapDown(matched);
            matched = GestureUtils.matchMultipleOn(matched, [
                GestureUtils.isMoveEvent,
                GestureUtils.isEventSinglePrimaryPointer
            ]);
            return matched;
        },

        matchDoubleTapUp: function (toMatch) {
            var matched = toMatch;
            matched = GestureUtils.matchDoubleTapDown(matched);
            matched = GestureUtils.matchMultipleOn(matched, [
                GestureUtils.isMoveEvent,
                GestureUtils.isEventSinglePrimaryPointer
            ], true);
            matched = GestureUtils.matchOn(matched, [
                GestureUtils.isUpEvent,
                GestureUtils.isEventSinglePrimaryPointer
            ]);
            return matched;
        },

        matchTripleTap: function (toMatch) {
            var downMatch, tapMatch;
            var matched = toMatch;
            tapMatch = GestureUtils.matchDoubleTap(matched);
            matched = GestureUtils.matchMultipleOn(tapMatch, [
                GestureUtils.isMoveEvent,
                GestureUtils.isEventSinglePrimaryPointer,
                GestureUtils.getLocalCheckFcn(tapMatch.matchedEvent)
            ], true);
            downMatch = GestureUtils.matchOn(matched, [
                GestureUtils.isDownEvent,
                GestureUtils.isEventSinglePrimaryPointer,
                GestureUtils.getLocalCheckFcn(tapMatch.matchedEvent),
                GestureUtils.getTimeoutCheckFcn(tapMatch.matchedEvent, toMatch.options.tapTimeout)
            ]);
            matched = GestureUtils.matchMultipleOn(downMatch, [
                GestureUtils.isMoveEvent,
                GestureUtils.isEventSinglePrimaryPointer,
                GestureUtils.getLocalCheckFcn(downMatch.matchedEvent)
            ], true);
            matched = GestureUtils.matchOn(matched, [
                GestureUtils.isUpEvent,
                GestureUtils.isEventSinglePrimaryPointer,
                GestureUtils.getLocalCheckFcn(downMatch.matchedEvent),
                GestureUtils.getTimeoutCheckFcn(downMatch.matchedEvent, toMatch.options.tapTimeout)
            ]);
            return matched;
        },

        matchSecondaryDown: function (toMatch) {
            return GestureUtils.matchOn(toMatch, [
                GestureUtils.isDownEvent,
                GestureUtils.isEventSingleSecondaryPointer
            ]);
        },

        matchSecondaryDownMove: function (toMatch) {
            var matched = toMatch;
            matched = GestureUtils.matchOn(matched, [
                GestureUtils.isDownEvent,
                GestureUtils.isEventSingleSecondaryPointer
            ]);
            matched = GestureUtils.matchMultipleOn(matched, [
                GestureUtils.isMoveEvent,
                GestureUtils.isEventSingleSecondaryPointer
            ]);
            return matched;
        },

        matchSecondaryDownUp: function (toMatch) {
            var matched = toMatch;
            matched = GestureUtils.matchOn(matched, [
                GestureUtils.isDownEvent,
                GestureUtils.isEventSingleSecondaryPointer
            ]);
            matched = GestureUtils.matchMultipleOn(matched, [
                GestureUtils.isMoveEvent,
                GestureUtils.isEventSingleSecondaryPointer
            ], true);
            matched = GestureUtils.matchOn(matched, [
                GestureUtils.isUpEvent,
                GestureUtils.isEventSingleSecondaryPointer
            ]);
            return matched;
        },

        matchOn: function (toMatch, evtCheckFcnList, isOptional) {
            return GestureUtils.matchEvent(toMatch,
                GestureUtils.conglomerateChecks(evtCheckFcnList), isOptional);
        },

        matchMultipleOn: function (toMatch, evtCheckFcnList, isOptional) {
            return GestureUtils.matchMultipleEvents(toMatch,
                GestureUtils.conglomerateChecks(evtCheckFcnList), isOptional);
        },

        matchMultipleEvents: function (toMatch, evtCheckFcn, isOptional) {
            var matched = GestureUtils.matchEvent(toMatch, evtCheckFcn, isOptional);
            var nextMatched = matched;
            while (nextMatched.matchedEvent) {
                nextMatched = GestureUtils.matchEvent(matched, evtCheckFcn, true);
                if (nextMatched.matchedEvent) {
                    matched = nextMatched;
                }
            }
            return matched;
        },

        matchEvent: function (toMatch, evtCheckFcn, isOptional) {
            var eventList, detectionList, eventOffset, nextEvent, matchedEvent, hasFailed, options;
            eventList = toMatch.eventList;
            detectionList = toMatch.detectionList;
            eventOffset = toMatch.eventOffset;
            hasFailed = toMatch.hasFailed;
            options = toMatch.options;
            matchedEvent = null;

            if (hasFailed) {
                return toMatch;
            }

            nextEvent = eventList[eventOffset];
            if (nextEvent) {
                if (evtCheckFcn(nextEvent)) {
                    detectionList[eventOffset] = true;
                    matchedEvent = nextEvent;
                    eventOffset++;
                } else {
                    if (!isOptional) {
                        detectionList[eventOffset] = false;
                        hasFailed = true;
                        eventOffset++;
                    }
                }
            } else {
                if (!isOptional) {
                    hasFailed = true;
                }
            }

            return {
                eventList: eventList,
                detectionList: detectionList,
                eventOffset: eventOffset,
                hasFailed: hasFailed,
                matchedEvent: matchedEvent,
                options: options
            }
        },

        detectGesture: function (eventList, matchFcn, opts) {
            var i, detected, tryMatching, canMatch, eventOffset, detectionList, result, potentialEventList,
                matchingReachedEnd, hasPotentialEventList, options;

            detected = false;
            eventOffset = 0;
            tryMatching = eventOffset < eventList.length;
            detectionList = new Array(eventList.length);
            options = opts ? opts : {};
            while (tryMatching) {

                result = matchFcn({
                    eventList: eventList,
                    detectionList: detectionList,
                    eventOffset: eventOffset,
                    hasFailed: false,
                    matchedEvent: null,
                    options: options
                });

                potentialEventList = [];
                for (i = eventOffset; i < result.detectionList.length; i++) {
                    if (result.detectionList[i]) {
                        potentialEventList.push(result.eventList[i]);
                    } else {
                        potentialEventList = [];
                        break;
                    }
                }

                matchingReachedEnd = result.eventOffset === result.eventList.length;
                hasPotentialEventList = potentialEventList.length !== 0;

                if ((!result.hasFailed) && matchingReachedEnd) {
                    detected = true;
                    tryMatching = false;
                } else {
                    if (matchingReachedEnd) {
                        tryMatching = (result.hasFailed && (!hasPotentialEventList));
                    } else {
                        tryMatching = true;
                    }

                }

                canMatch = eventOffset < (result.eventList.length-1);
                tryMatching = tryMatching && canMatch;

                if (tryMatching) {
                    eventOffset++;
                    for (i = 0; i < detectionList.length; i++) {
                        detectionList[i] = null;
                    }
                }
            }

            return {
                detected: detected,
                potentialEventList: potentialEventList
            };
        }

    };

    return GestureUtils;

});