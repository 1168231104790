// Copyright 2016 The MathWorks, Inc.
define([
    ], function () {
        return {
            compareDate: function (aDate, bDate) {
                return aDate < bDate ? -1 : (aDate > bDate ? 1 : 0);
            },
            separateFoldersFromFiles: function (aFileInfo, bFileInfo) {
                var aDate = new Date(aFileInfo.modifiedUtc);
                var bDate = new Date(bFileInfo.modifiedUtc);
                if ((aFileInfo.isDirectory && bFileInfo.isDirectory)) {
                    return this.compareDate(aDate, bDate);
                } else if (aFileInfo.isDirectory) {
                    return -1;
                } else if (bFileInfo.isDirectory) {
                    return 1;
                }
            }
        };
    }
);