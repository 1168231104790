// Copyright 2016-2017 MathWorks, Inc.

define([
    "dojo/_base/declare",
    "dojo/dnd/Avatar",
    "dojo/dom-attr",
    "dojo/dom-construct",
    "dojo/hccss",
    "dojo/i18n!../../l10n/nls/FileBrowserResources",
    "dojo/string"
], function (declare, Avatar, domAttr, domConstruct, hccss, FileBrowserResources, string) {
    return declare([Avatar], {
        construct: function () {
            var avatarTable = domConstruct.create("table", {
                "class": "fileBrowserGridDnDAvatar dojoDndAvatar"
            });
            var avatarTableBody = domConstruct.create("tbody", null, avatarTable);
            var avatarTableRow = domConstruct.create("tr", null, avatarTableBody);
            var avatarTableCell = domConstruct.create("td", null, avatarTableRow);

            if (hccss("highcontrast")) {
                domConstruct.create("span", {
                    id: "a11yIcon",
                    innerHTML: this.manager.copy ? '+' : "<"
                }, avatarTableCell);
            }
            domConstruct.create("span", {
                innerHTML: this.manager.source.generateText ? this._generateText() : ""
            }, avatarTableCell);

            // we have to set the opacity on IE only after the node is live
            domAttr.set(avatarTableRow, {
                "class": "dojoDndAvatarHeader"
            });
            this.node = avatarTable;
        },

        _generateText: function () {
            if (this.manager.copy) {
                return string.substitute(FileBrowserResources.dndCopyMessage, [this.manager.nodes.length.toString()]);
            } else {
                return string.substitute(FileBrowserResources.dndMoveMessage, [this.manager.nodes.length.toString()]);
            }
        }
    });
});