/** Copyright 2015 The MathWorks, Inc. */
/**
 * Takes in array of mixins and checks if all the dependencies are met or not.
 * The user should list all the dependencies in an _dependencies object and attach it to the mixin.
 * The _dependencies is an array of objects.
 * Each object should list the mixin it is dependent on and if it is order dependent or not.
 * Example:
 * ButtonGroupMixin._dependencies = [
 *    {mixin: CheckedMixin, orderDependent: true},
 *    {mixin: ChangeEventMixin, orderDependent: false}
 * ];
 *
 **/
define([
], function () {
    var evaluateOrder = function (mixinIndex, dependencyIndex) {
        if (mixinIndex < dependencyIndex) {
            throw new Error("mixin dependency specified in invalid order");
        }
    };
    var mixinDependencyValidator = function (mixins) {
        // var widgetBase = require("dijit/_WidgetBase");
        // mixins.forEach(function (mixin) {
        //     // For subclass widgets
        //      var parentClassMixins = mixin._meta.bases;
        //     // Loop through all mixins and check if it is a widget.
        //     parentClassMixins.forEach(function (parentClassMixin) {
        //         if (parentClassMixin === widgetBase) {
        //             //Add all mixins present in current widget to parent class mixins.
        //             mixins = parentClassMixins.concat(mixins);
        //         }
        //     }, this);
        //     //check if mixin has any dependencies
        //     if (mixin._dependencies && mixin._dependencies.length > 0) {
        //         mixin._dependencies.forEach(function (mixinDependency) {
        //             //First Check if dependency is present or not
        //             // if (mixins.indexOf(mixinDependency.mixin) === -1) {
        //             //     throw new Error("mixin dependency not found");
        //             // }
        //             // Check for order dependency
        //             if (mixinDependency.orderDependent) {
        //                 evaluateOrder(mixins.indexOf(mixin),
        //                     mixins.indexOf(mixinDependency.mixin));
        //             }
        //         }, this);
        //     }
        // }, this);

        return mixins;
    };

    return  {
        validate: mixinDependencyValidator
    };

});
