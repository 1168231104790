// Copyright 2016, The MathWorks, Inc

define([
    "dojo/_base/declare",
    "dojo/on",
    "./GestureRecognizer",
    "../util/RemovableGroup",
    "mw-log/Log"
], function (declare, on, GestureRecognizer, RemovableGroup, Log) {

    return declare([GestureRecognizer], {

        addEventHandler: function (listener) {
            var handle = on(this, "discreteGestureOccurred", listener);
            this.own(handle);
            return handle;
        },

        broadcastEvents: function (evt) {
            if (this.getRecognizerState() === this.RECOGNIZER_STATES.MATCHED) {
                this.setRecognizerState(this.RECOGNIZER_STATES.ACTIVE);
                try {
                    this.emit("discreteGestureOccurred", evt);
                } catch (e) {
                    Log.warn("Error executing discrete gesture callback. " + e.message);
                    Log.warn(e.stack);
                }
                this.setRecognizerState(this.RECOGNIZER_STATES.INACTIVE);
            }
        }

    });

});