define({
    indexPageTitle: `MATLAB Drive にサインイン - MATLAB & Simulink - MathWorks`,
    indexPageToggleNavigation: `メイン ナビゲーションの切り替え`,
    indexPageStoreAnywhere: `どこからでもファイルを保存、アクセス、および管理できます。`,
    indexPageLearnMore: `MATLAB Drive の詳細を確認します。`,
    indexPageInstallConnector: `MATLAB Drive Connector をインストールします。`,
    loggedInPageTitle: `MATLAB Drive`,
    rootFolderNameMatlabDrive: `MATLAB Drive`,
    incompatibleBrowser: `互換性のないブラウザー`,
    moreFolders: `その他のフォルダー`,
    footerPatents: `特許`,
    footerTrademarks: `商標`,
    footerPrivacy: `プライバシー ポリシー`,
    footerPiracy: `違法コピー防止`,
    filesPageUserFirstNameFiles: `ファイル`,
    filesPageFacetsFiles: `ファイル`,
    filesPageFacetsSharing: `共有コンテンツ`,
    filesPageFacetsDeletedFiles: `削除されたファイル`,
    filesPageToggleNavigation: `メイン ナビゲーションの切り替え`,
    filesPageDownloadConnectorLinkText: `MATLAB Drive Connector のインストール`,
    filesPageLogoutLabel: `サインアウト`,
    filesPageHelpLabel: `ヘルプ`,
    filesPageUploadLabel: `アップロード`,
    filesPageUploadFilesLabel: `ファイル`,
    filesPageUploadFolderLabel: `フォルダー`,
    filesPageNewFolderLabel: `新規フォルダー`,
    filesPageDownloadLabel: `ダウンロード`,
    filesPageRenameLabel: `名前の変更`,
    filesPageCutLabel: `切り取り`,
    filesPageCopyLabel: `コピー`,
    filesPageCopyToLabel: `コピー先`,
    filesPagePasteLabel: `貼り付け`,
    filesPageDeleteLabel: `削除`,
    openToolTipText: `選択項目を MATLAB Online で開きます。`,
    viewToolTipText: `このファイルを表示します。`,
    uploadToolTipText: `ファイルをデスクトップから MATLAB Drive にアップロードします。`,
    uploadFolderToolTipText: `フォルダーをデスクトップから MATLAB Drive にアップロードします。`,
    newFolderToolTipText: `フォルダーを作成します。`,
    downloadToolTipText: `ファイルを MATLAB Drive からデスクトップにダウンロードします。`,
    renameToolTipText: `選択の名前を変更します。`,
    moveToolTipText: `選択を移動します。`,
    cutToolTipText: `選択をクリップボードにコピーします。`,
    copyToolTipText: `選択をコピーします。`,
    pasteToolTipText: `選択したフォルダーに貼り付けます。`,
    deleteToolTipText: `選択を [削除されたファイル] に移動します。`,
    viewInNewTabToolTipText: `新しいタブでファイルを表示します。`,
    shareToolTipText: `招待または表示専用リンクを使用して選択項目を共有します。`,
    inviteToolTipText: `特定のユーザーを招待して共有します。`,
    manageToolTipText: `この共有フォルダーのユーザーを管理します。`,
    linkToolTipText: `表示専用リンクを使用してフォルダーを共有します。`,
    leaveToolTipText: `共有フォルダーから移動`,
    filesPageShareLabel: `共有`,
    filesPageInviteLabel: `メンバーの招待`,
    filesPageManageLabel: `メンバーの管理`,
    filesPageViewMembersLabel: `メンバーの表示`,
    filesPageLinkLabel: `リンク`,
    filesPageCreateLinkLabel: `リンクの作成`,
    filesPageManageLinkLabel: `リンクの管理`,
    filesPageViewLinkLabel: `リンクの表示`,
    filesPageLeaveLabel: `移動`,
    filesPageMoveLabel: `移動先`,
    trashPageRestoreFolderDialogTitle: `フォルダーの復元`,
    trashPageRestoreFolderWarningText: `このフォルダーは削除されました。`,
    trashPageRestoreFolderDialogText: `このフォルダーを表示するには、削除されたファイルから復元してください。`,
    trashPageUserFirstNameDeletedFiles: `削除されたファイル`,
    trashPageFacetsFiles: `ファイル`,
    trashPageFacetsSharing: `共有コンテンツ`,
    trashPageFacetsDeletedFiles: `削除されたファイル`,
    trashPageToggleNavigation: `メイン ナビゲーションの切り替え`,
    trashPageDownloadConnectorLinkText: `MATLAB Drive Connector のインストール`,
    trashPageLogoutLabel: `サインアウト`,
    trashPageHelpLabel: `ヘルプ`,
    trashPageRestoreLabel: `元に戻す`,
    trashPageDeleteLabel: `完全に削除`,
    trashPageEmptyTrashLabel: `完全にすべてを削除`,
    trashPageDeletionPolicy: `削除されたすべてのファイルは 30 日後に完全に削除されます。`,
    restoreToolTipText: `選択を元の場所に戻します。`,
    permanentlyDeleteToolTipText: `選択を完全に削除します。この選択にはアクセスできなくなります。`,
    permanentlyDeleteAllToolTipText: `[削除されたファイル] 内のすべての項目を完全に削除します。これらの項目にはアクセスできなくなります。`,
    sharingPageFacetsFiles: `ファイル`,
    sharingPageFacetsSharing: `共有コンテンツ`,
    sharingPageFacetsDeletedFiles: `削除されたファイル`,
    sharingPageUserFirstNameFiles: `共有コンテンツ`,
    sharingPageToggleNavigation: `メイン ナビゲーションの切り替え`,
    sharingPageDownloadConnectorLinkText: `MATLAB Drive Connector のダウンロード`,
    sharingPageLogoutLabel: `サインアウト`,
    sharingPageHelpLabel: `ヘルプ`,
    sharingPagePendingInvitationsGroupLegend: `保留中の招待`,
    sharingPageCurrentlySharedGroupLegend: `現在共有中のフォルダー`,
    sharingPageNoPendingInvitations: `現在保留中の招待はありません。`,
    sharingPageInitiator: `共有元のユーザー `,
    sharingPageSharedDate: `共有日 `,
    sharingPageSharedViaLink: `URL`,
    sharingPageUnknownSharedOnDate: `不明`,
    sharingPageNameTitle: `名前`,
    sharingPageAccessTitle: `アクセス権`,
    sharingPagePreviewTitle: `プレビュー`,
    sharingPageCopyToDriveTitle: `フォルダーのコピー`,
    sharingPageDeclineTitle: `拒否`,
    sharingPageAcceptInvitationNameConflictDialogTitle: `\"\${0}\" への招待の承諾`,
    sharingPageAcceptInvitationInitialNamePrompt: `この共有フォルダーに使用する名前を指定してください。既定は \${0} です。`,
    sharingPageAcceptInvitationNameConflictDialogLabel: `\"\${0}\" という名前のフォルダーは既に存在します。新しいフォルダーの名前を変更してください。`,
    sharingPageCopyInvitationNameConflictDialogLabel: `\"\${0}\" という名前のフォルダーは既に存在します。新しいフォルダーの名前を変更してください。`,
    sharingPageCopyInvitationNameConflictDialogTitle: `フォルダー \"\${0}\" のコピー`,
    sharingPageInvitationNotFound: `共有の招待が見つかりませんでした。削除された可能性があります。`,
    sharingPageInvitationAuthorizationError: `共有する招待には、MathWorks アカウントに関連付けられた UserID と同じ電子メール アドレスを使用しなければなりません。この問題を解決するには、フォルダー所有者に問い合わせてください。`,
    previewPageFacetsSharing: `共有コンテンツ`,
    previewPageAddToDriveLabel: `ショートカットの追加`,
    previewPageShareLinkLabel: `リンクの共有`,
    previewPageGoToDriveLabel: `Drive に移動`,
    addToDriveToolTipText: `ショートカットを追加するか、このフォルダーをコピーします。`,
    shareLinkToolTipText: `表示専用リンクを使用してフォルダーを共有します。`,
    goToDriveToolTipText: `MATLAB Drive 内のフォルダーに移動`,
    previewPageLoginLabel: `ログイン`,
    acceptToolTipText: `ショートカットを追加すると、このフォルダーがファイルに表示され、そのコンテンツに対する更新が引き続き表示されます。`,
    previewPageAcceptLabel: `承諾`,
    declineToolTipText: `招待の拒否`,
    previewPageDeclineLabel: `拒否`,
    previewPageAddLabel: `自分のファイルに追加`,
    copyToDriveToolTipText: `このフォルダーをコピーすると、このフォルダーとそのコンテンツの非共有コピーがファイル内に作成されます。`,
    previewPageCopyToDriveLabel: `フォルダーのコピー`,
    previewPageDownloadActionLabel: `共有フォルダーのダウンロード`,
    previewPageDownloadActionSelectionLabel: `選択した項目のダウンロード`,
    invitationPreviewModalTitle: `プレビューの共有`,
    invitationPreviewInvitationDeclined: `共有フォルダー \${0} への招待が拒否されました。`,
    invitationPreviewInvitationAccepted: `共有フォルダー \${0} が Drive に追加されました。`,
    invitationPreviewInvitationNotExist: `共有コンテンツが見つかりませんでした。所有者により削除された可能性があります。`,
    invitationPreviewLoginForPersonalInvitation: `共有コンテンツをプレビューするには、ログインしてください。`,
    invitationPreviewInvitationAlreadyAddedToDrive: `共有フォルダー \${0} は既に Drive に追加されています`,
    invitationPreviewInvitationUndefined: `共有コンテンツが見つかりませんでした。URL を確認してください。`,
    invitationPreviewAuthorizationError: `この共有コンテンツへのアクセス権限がありません。`,
    panelInfoIcon: `展開して現在のフォルダーに関する情報を表示します。`,
    editAccessTypeInfoIcon: `ご利用の Drive にショートカットを追加している場合は、共有フォルダーとそのコンテンツを編集できるようになります。`,
    viewAccessTypeInfoIcon: `共有フォルダーとそのコンテンツを表示できるようになります。共有フォルダーとそのコンテンツを編集するには、所有者に連絡してください。`,
    ownerAccessTypeInfoIcon: `共有フォルダーの所有者は、そのコンテンツの編集、そのメンバーの管理、コンテンツの共有リンクの管理を行うことができます。`,
    panelOwnedBy: `所有者`,
    panelAccessType: `アクセス タイプ`,
    panelCreatedOn: `作成日`,
    panelModifiedOn: `変更日`,
    panelSharedFolderRoot: `共有された親`,
    panelFolderSize: `フォルダー サイズ`,
    panelChildCount: `合計項目`,
    smallTitleSharedBy: `共有元のユーザー`,
    smallTitleSharedOn: `共有日`,
    smallTitleAccess: `アクセス権`,
    originalLocationColumnLabel: `元の場所`,
    clientDateModifiedColumnLabel: `更新日`,
    deletedDateColumnLabel: `削除日`,
    tombstoneColumnLabel: `廃棄状態`,
    sharedOnColumnLabel: `共有日 `,
    sharedByColumnLabel: `共有元のユーザー `,
    accessTypeColumnLabel: `アクセス権`,
    ownedByColumnLabel: `所有者 `,
    contextMenuCutLabel: `切り取り`,
    contextMenuCopyLabel: `コピー`,
    contextMenuCopyToLabel: `コピー先`,
    contextMenuDeleteLabel: `削除`,
    contextMenuDownloadLabel: `ダウンロード`,
    contextMenuEmptyTrashLabel: `完全にすべてを削除`,
    contextMenuNewFolderLabel: `新規フォルダー`,
    contextMenuPasteLabel: `貼り付け`,
    contextMenuPermanentlyDeleteLabel: `完全に削除`,
    contextMenuRenameLabel: `名前の変更`,
    contextMenuRestoreLabel: `元に戻す`,
    contextMenuUploadLabel: `アップロード`,
    contextMenuUploadFilesLabel: `ファイル`,
    contextMenuUploadFolderLabel: `フォルダー`,
    contextMenuViewFileLabel: `表示`,
    contextMenuViewNewTabLabel: `新しいタブに表示`,
    contextMenuOpenWithLabel: `MATLAB Online で開く`,
    contextMenuShareLabel: `共有`,
    contextMenuManageShareLabel: `共有コンテンツ`,
    contextMenuLinkLabel: `リンク`,
    contextMenuCreateLinkLabel: `リンクの作成`,
    contextMenuManageLinkLabel: `リンクの管理`,
    contextMenuViewLinkLabel: `リンクの表示`,
    contextMenuInviteLabel: `メンバーの招待`,
    contextMenuManageLabel: `メンバーの管理`,
    contextMenuViewMembersLabel: `メンバーの表示`,
    contextMenuLeaveLabel: `移動`,
    contextMenuMoveLabel: `移動先`,
    contextMenuShareLinkLabel: `リンクの共有`,
    createFolderDialogCreateFolderTitle: `新規フォルダー`,
    createFolderDialogFolderNameInputLabel: `このフォルダーの名前を入力:`,
    createFolderDialogInputError2Status: `(エラー)`,
    createFolderDialogButtonCancel: `キャンセル`,
    createFolderDialogButtonCreate: `作成`,
    createFolderDialogValidationText: `フォルダー名に文字 \${arg1} を含めることはできません`,
    createFolderDialogResourceAlreadyExists: `その名前をもつファイルまたはフォルダーが既に存在します。別の名前を選択してください。`,
    createFolderDialogIllegalPathElement: `フォルダー名に許可されていない文字が含まれています。`,
    createFolderDialogFolderCreated: `\${arg1} が \${arg2} に追加されました。`,
    initiateSharingDialogResourceNotSharable: `\"\${0}\" は共有できません。共有フォルダーの親であるか、他の理由により制限されています。`,
    initiateSharingDialogTitle: `\"\${0}\" の共有`,
    initiateSharingDialogTitleCreateLinks: `\"\${0}\" のリンクの作成`,
    initiateSharingDialogTitleManageLinks: `\"\${0}\" のリンクの管理`,
    initiateSharingDialogTitleViewLinks: `リンクで \"\${0}\" を共有`,
    initiateSharingDialogTitleManage: `\"\${0}\" のメンバーの管理`,
    initiateSharingDialogTitleInvite: `\"\${0}\" を共有するメンバーの招待`,
    initiateSharingDialogTitleView: `\"\${0}\" のメンバーの表示`,
    initiateSharingDialogFolderNameInputLabel: `招待`,
    initiateSharingDialogCurrentlySharedWithLabel: `共有するユーザー`,
    initiateSharingDialogNoParticipants: `現在、参加者はいません。`,
    initiateSharingDialogNoParticipantsButHasLink: `現在、個人的に招待された参加者はいませんが、パブリック リンクが存在します。`,
    initiateSharingDialogCreateLink: `表示専用リンクの作成`,
    initiateSharingDialogCopyLink: `リンクのコピー`,
    initiateSharingDialogDeleteLink: `リンクの削除`,
    initiateSharingDialogButtonShare: `適用`,
    initiateSharingDialogButtonClose: `閉じる`,
    initiateSharingDialogButtonReturn: `戻る`,
    initiateSharingDialogValidationEmptyEmailAddress: `電子メール アドレスを空にすることはできません`,
    initiateSharingDialogValidationAtSignText: `電子メール アドレスに複数の \"@\" 文字があります。`,
    initiateSharingDialogInvalidEmailTooLong: `電子メール アドレスが 70 文字を超えています。`,
    initiateSharingDialogInvalidEmailIllegal: `電子メール アドレスに無効な文字シーケンスがあります。`,
    initiateSharingDialogInvalidEmailDomain: `メール アドレス \${0} は無効です。正しい形式は、name@example.com です。`,
    initiateSharingDialogInvalidEmailToSelf: `自分自身に招待を送信することは許可されていません。`,
    initiateSharingDialogIllegalPathElement: `電子メール アドレスに許可されていない文字があります。`,
    initiateSharingDialogFolderShared: `\${0} が \"\${1}\" に追加されました。`,
    initiateSharingDialogPermission_read_write: `編集可能`,
    initiateSharingDialogPermission_read_only: `表示可能`,
    initiateSharingDialogPermission_manage_access: `管理可能`,
    initiateSharingDialogPermission_owner: `所有者`,
    initiateSharingDialogStatusPending: `保留中`,
    initiateSharingDialogStatusAccepted: `承諾済み`,
    initiateSharingDialogStatusDeclined: `拒否済み`,
    initiateSharingDialogStatusNewInvite: `送信中...`,
    initiateSharingDialogErrorWarning: `共有を試行したときにエラーが発生しました。詳細については、以下のリストを参照してください。`,
    initiateSharingDialogSharingSuccessful: `\${0} が次のユーザーと正常に共有されました:`,
    initiateSharingDialogSomeErrorsWarning: `\${0} は正常に共有されましたが、いくつかのエラーが発生しました。詳細については、以下のリストを参照してください。`,
    initiateSharingDialogLinkCopied: `表示専用リンクがクリップボードにコピーされました。`,
    initiateSharingDialogCreateLinkFailed: `作成しようとしているリンクは既に存在します。`,
    initiateSharingDialogCopyLinkFailed: `リンクをコピーできませんでした: \${0}。`,
    initiateSharingDialogManuallyCopyLink: `表示されている URL を選択してコピーしてください。`,
    initiateSharingDialogConfirmLinkDelete: `表示専用リンクを削除しますか?`,
    initiateSharingDialogConfirmCollaboratorDelete: `\"\${0}\" を共有フォルダーから削除しますか?`,
    initiateSharingDialogDeleteCollaboratorWarning: `\"\${0}\" は、共有フォルダーまたはそのコンテンツにアクセスできなくなります。`,
    initiateSharingDialogDeleteLinkButton: `削除`,
    initiateSharingDialogRemovePermissionButton: `削除`,
    initiateSharingDialogCancelButton: `キャンセル`,
    initiateSharingDialogDeleteWarning: `このリンクをもつすべてのユーザーが、このフォルダーまたはそのコンテンツにアクセスできなくなります。`,
    initiateSharingDialogDeleteCollaboratorSuccessful: `\"\${0}\" が共有フォルダーから削除されました。`,
    initiateSharingDialogAddUserButtonLabel: `送信`,
    initiateSharingDialogNoLinksAvailable: `このフォルダーはリンク以外の方法で特定のユーザーと共有されているため、使用できるリンクはありません。`,
    initiateSharingDailogInviteeAlreadyInList: `この電子メール アドレスには、このフォルダーへの招待が既に存在します。`,
    initiateSharingDailaogEmailAlreadyInList: `この電子メール アドレスには、このフォルダーへの招待が既に発行されています。`,
    initiateSharingDailogEnterEmailAddress: `電子メール アドレスを入力`,
    initiateSharingDialogEnterEmailComments: `メッセージを追加 (オプション)`,
    initiateSharingDailogExplainCreateLink: `フォルダー \${0} への表示専用アクセスを他のユーザーに付与するリンクを作成します。`,
    initiateSharingDailogExplainCopyLink: `フォルダー \${0} への表示専用アクセスを他のユーザーに付与するリンクを配布します。`,
    initiateSharingDailogExplainDeleteLink: `共有コンテンツへのリンクとアクセス権を削除します。個人的な招待を受け取ったメンバーによるアクセスには影響しません。`,
    initiateSharingDialogmultiEmailTextWarning: `電子メール アドレスが複数ある場合は、セミコロンまたはコンマで区切ってください`,
    initiateSharingDialogIllegalFolderName: `フォルダー \${0} に \".\"、\"..\"、\"\\\\\" または制御文字を含めることは、フォルダー名でサポートされていません。`,
    initiateSharingDialogNoPermToCreateLink: `このフォルダーに関する招待を作成する権限がありません。`,
    initiateSharingDialogNoPermToDeleteLink: `このフォルダーを共有する招待を削除する権限がありません。`,
    initiateSharingDialogLinkFolderDoesNotExist: `\${0} は存在しません。既に削除されたか、移動された可能性があります。`,
    initiateSharingDialogInvitationDoesNotExist: `共有の招待が見つかりませんでした。削除された可能性があります。`,
    initiateSharingDialogNoPermToUpdateInvitation: `現在、共有の招待を更新できません。`,
    initiateSharingDialogActiveUsersLabel: `アクティブなユーザー: `,
    initiateSharingDialogUpdateAccessTypeButtonLabel: `保存`,
    initiateSharingDialogUpdateAccessTypeError: `\${0} 人の受信者のアクセス タイプを更新できませんでした。`,
    initiateSharingDialogUpdateAccessTypeSuccessful: `\${0} 人の受信者のアクセス タイプを正常に更新しました。`,
    initiateSharingDialogUpdateAccessTypePartial: `\${0} 人の受信者のアクセス タイプの更新に失敗しましたが、\${1} 人の受信者が更新されました。`,
    userQuotaStorageFull: `ストレージがいっぱいです。ファイルを削除して容量を空けてください。`,
    userQuotaServerError: `サーバー エラーが発生しました。しばらくしてから、再度お試しください。`,
    userQuotaUnableToFetchData: `データを取得できません。`,
    userQuotaAmountUsed: `\${0} \${1}/\${2} \${3} が使用中`,
    actionAborted: `アクションが中止されました。`,
    actionHandlerFile: `ファイル`,
    actionHandlerFiles: `ファイル`,
    actionHandlerFolder: `フォルダー`,
    actionHandlerItem: `項目`,
    actionHandlerItems: `項目`,
    actionHandlerCannotDisplayMsgTooBig: `ファイルが大きすぎるため表示できません。コンテンツを表示するには、ファイルをダウンロードしてください。`,
    actionHandlerCannotDisplayMsgWrongType: `このファイルのプレビューは使用できません。コンテンツを表示するには、ファイルをダウンロードしてください。`,
    actionHandlerCannotDisplayMsgSecurity: `このファイルのプレビューは許可されていません。コンテンツを表示するには、ファイルをダウンロードしてください。`,
    actionHandlerViewFilePleaseWaitText: `読み込み中...`,
    actionHandlerViewFileDownloadTimeOut: `ダウンロードがタイムアウトしました。もう一度試してください。`,
    actionHandlerDownloadInProgress: `ダウンロードが正常に開始しました。大量のファイルやフォルダーをダウンロードする場合、数分かかることがあります。`,
    actionHandlerViewFileFileNotExists: `指定したファイル/フォルダーが見つかりません。既に削除されたか、移動された可能性があります。`,
    actionHandlerMaxConcurrentDownloads: `一度に許可されているのは最大 \${0} までです。選択するファイル数を減らしてください。`,
    actionHandlerPastedTo: `\${0} が \${1} に貼り付けられました。 `,
    actionHandlerPasteFailed: `その名前をもつ \${0} は既に \${1} に存在します。別の場所に貼り付けるか、\${2} に一意の名前を指定してください。`,
    actionHandlerMultiPasteSuccess: `\${0} \${1} が \${2} に貼り付けられました。 `,
    actionHandlerMultiPasteFailed: `\${0} \${1} を \${2} に貼り付けることができませんでした。 `,
    actionHandlerMultiMoveSuccess: `\${0} \${1} が \${2} に移動されました。 `,
    actionHandlerMultiMoveFailed: `\${0} \${1} を \${2} に移動できませんでした。 `,
    actionHandlerCopiedTo: `\${0} が \${1} にコピーされました。 `,
    actionHandlerCopyFailed: `その名前をもつ \${0} は既に \${1} に存在します。別の場所にコピーするか、\${2} に一意の名前を指定してください。`,
    actionHandlerMultiCopySuccess: `\${0} \${1} が \${2} にコピーされました。 `,
    actionHandlerMultiCopyFailed: `\${0} \${1} を \${2} にコピーできませんでした。 `,
    actionHandlerMultiDeleteSuccess: `\${0} 個の項目が削除されました。 `,
    actionHandlerMultiDeleteFailed: `\${0} を削除できませんでした。 `,
    actionHandlerSingleDeleteSuccess: `\${0} が [削除されたファイル] に移動されました。`,
    actionHandlerSingleSharedFolderDeleteSuccess: `あなたに対してのみ、共有フォルダー \${0} が [削除されたファイル] に移動されました。他のメンバーは引き続きアクセスできます。`,
    actionHandlerSingleSharedFolderOwnerDeleteSuccess: `あなたに対してのみ、共有フォルダー \${0} が [削除されたファイル] に移動されました。他のメンバーは引き続きアクセスできます。`,
    actionHandlerSingleDelectFailed: `\${0} を [削除されたファイル] に移動できませんでした: \${1}`,
    actionHandlerGoToLocation: `場所に移動`,
    actionHandlerServerError: `サーバー エラー`,
    actionHandlerNameConflictPrompt: `\${0} という名前のファイルまたはフォルダーは既に \${1} に存在します。新しい名前を指定してください。 `,
    actionHandlerRenamePromptTitle: `\${0} の復元`,
    actionHandlerRenamePromptLabel: `その名前をもつ \${0} は既に存在します。\${1} の名前を変更してください。 `,
    actionHandlerPerformRestoreSuccess: `\${0} が \${1} に復元されました。`,
    actionHandlerPerformRestoreFailed: `\${0} を場所 \${1} に復元できませんでした: \${2}`,
    actionHandlerPerformRestoreUnknownFailure: `\${0} を復元できませんでした: \${1}`,
    actionHandlerMultiRestoreSuccess: `\${0} 個の項目が復元されました。 `,
    actionHandlerMultiRestoreFailed: `\${0} 個の項目を復元できませんでした。 `,
    actionHandlerSinglePermanentDeleteSuccess: `\${0} が完全に削除されました。`,
    actionHandlerSinglePermanentDeleteSharedFolderSuccess: `あなたに対してのみ、共有フォルダー \${0} が削除されました。`,
    actionHandlerSingleUnshareAndPermanentDeleteSharedFolderSuccess: `共有フォルダー \${0} は共有が解除され、削除されました。`,
    actionHandlerSinglePermanentDeleteSharedLinkFolderSuccess: `あなたに対してのみ、共有フォルダー \${0} が削除されました。再度参加するには、元の URL を使用してください。`,
    actionHandlerSinglePermanentDeleteFailed: `\${0} を削除できませんでした: \${1}`,
    actionHandlerMultiPermanentDeleteSuccess: `\${0} 個の項目が完全に削除されました。`,
    actionHandlerMultiPermanentDeleteFailed: `\${0} 個の項目を削除できませんでした: \${1}`,
    actionHandlerGeneralDeleteFailed: `削除できませんでした: \${0}`,
    actionHandlerTrashEmptied: `[削除されたファイル] のコンテンツが完全に削除されました。`,
    actionHandlerTrashEmptyFailed: `[削除されたファイル] を空にすることができませんでした: \${0}`,
    actionHandlerDeleteDialogTitle: `\${0} を完全に削除`,
    actionHandlerDeleteDialogFilePrompt: `<b>\${0}</b> を完全に削除してよろしいですか?<br>メモ: 共有コンテンツは削除されません。`,
    actionHandlerDeleteDialogFolderPrompt: `<b>フォルダーとそのコンテンツは復元できません。</b>他のメンバーは引き続き \${0} 内にあるすべての共有フォルダーにアクセスできます。`,
    actionHandlerDeleteDialogSharedFolderPrompt: `共有フォルダー \${0} またはそのコンテンツを復元できなくなります。<br/> 他のメンバーは引き続きアクセスできます。`,
    actionHandlerDeleteDialogSharedFolderOwnerPrompt: `<b>共有フォルダー \${0} またはそのコンテンツを復元できなくなります。</b><br/> 他のメンバーは引き続きアクセスできます。`,
    actionHandlerDeleteDialogShouldUnshareFolderLabel: `現在のメンバーとのフォルダー \${0} の共有を解除`,
    actionHandlerDeleteDialogShouldUnshareMultipleFoldersLabel: `選択したすべてのフォルダーについて現在のメンバーとの共有を解除`,
    actionHandlerDeleteDialogShouldUnshareFolderExplanation: `共有フォルダーのメンバーはアクセスできなくなります`,
    actionHandlerDeleteDialogShouldUnshareMultipleFoldersExplanation: `これらの共有フォルダーのメンバーはアクセスできなくなります`,
    actionHandlerDeleteDialogShouldUnshareSubFoldersLabel: `\${0} 内に含まれるすべての共有フォルダーの共有を解除`,
    actionHandlerDeleteDialogShouldUnshareMultipleSubFoldersLabel: `この選択に含まれるすべての共有フォルダーの共有を解除`,
    actionHandlerDeleteDialogShouldUnshareSubFoldersExplanation: `これらの共有フォルダーのメンバーはアクセスできなくなります`,
    actionHandlerDeleteDialogFolderContentsToo: ` およびそのコンテンツ`,
    actionHandlerDeleteDialogDeleteButtonlabel: `完全に削除`,
    actionHandlerDeleteDialogCancelButtonLabel: `キャンセル`,
    actionHandlerDeleteDialogMultiTitle: `項目の削除`,
    actionHandlerDeleteDialogMultiSingularPrompt: `この項目を削除しますか?`,
    actionHandlerDeleteDialogMultiPluralPrompt: `これらの項目を削除しますか?`,
    actionHandlerPermanentlyDeleteAllDialogTitle: `完全にすべてを削除`,
    actionHandlerDeleteAllDialogPrompt: `<b>フォルダーとそのコンテンツは復元できません。</b><br/>共有フォルダーのメンバーは引き続きアクセスできます。`,
    actionHandlerUndo: `元に戻す`,
    actionHandlerOpenWithLoadFailed: `開いているサービスでは \${0} を読み込めませんでした。`,
    actionHandlerLargeOperationNotification: `大きなファイルの操作を処理中です。現在、一部のファイルが使用できなくなります。更新を確認するには、ページを更新してください。`,
    actionHandlerIllegalMoveOrCopyTarget: `\${0} の移動またはコピーができません。フォルダーまたはそのサブフォルダーのいずれかへの移動またはコピーはできません。`,
    actionHandlerMovedTo: `\${0} が \${1} に移動されました。 `,
    actionHandlerMoveMany: `\${0} 個の項目`,
    fbTreeResourceAlreadyExists: `その名前をもつファイルまたはフォルダーが既に存在します。\${0} の名前を変更してください。`,
    fbTreeFile: `ファイル`,
    fbTreeFiles: `ファイル`,
    fbTreeFolder: `フォルダー`,
    fbTreeIllegalPath: `名前に許可されていない文字が含まれています。別の名前を選択してください。`,
    fbTreeShouldNotContainFolders: `選択にフォルダーが含まれていないことを確認してください。`,
    fsServerError: `サーバー エラーが発生しました。しばらくしてから、再度お試しください。`,
    fsFolderNotFoundResetting: `指定されたフォルダー \${0} が見つかりません。\"MATLAB Drive\" にリセットします。`,
    fsFolderNotFound: `指定されたフォルダーが見つかりません。既に削除されたか、移動された可能性があります。`,
    fsMovedFileTo: `\${0} が \${1} に移動されました。`,
    fsCopiedFileTo: `\${0} が \${1} にコピーされました。`,
    fsMovedFileToFailed: `\${0} を \${1} に移動できませんでした。`,
    fsCopiedFileToFailed: `\${0} を \${1} にコピーできませんでした。`,
    fsFile: `ファイル`,
    fsFolder: `フォルダー`,
    fsResourceAlreadyExists: `その名前をもつファイルまたはフォルダーが既に存在します。別の場所に移動するか、\${0} に一意の名前を指定してください。`,
    fsIllegalPathElement: `名前に許可されていない文字が含まれています。別の名前を選択してください。`,
    fsUnknownError: `不明なエラー`,
    fsItem: `項目`,
    fsItems: `項目`,
    fsMultiMoveSuccess: `\${0} \${1} が \${2} に移動されました。 `,
    fsMultiCopySuccess: `\${0} \${1} が \${2} にコピーされました。 `,
    fsMultiMoveFailed: `\${0} \${1} を \${2} に移動できませんでした。 `,
    fsMultiCopyFailed: `\${0} \${1} を \${2} にコピーできませんでした。 `,
    fsNoMoveAccess: `アクセス権が [表示可能] であるため、共有フォルダー \${0} のコンテンツを変更できません。`,
    fsAcceptInvitationFailed: `招待を承諾できませんでした: \${0}`,
    fsDeclineInvitationFailed: `招待を拒否できませんでした: \${0}`,
    fsFollowLinkFailed: `リンク先に移動できませんでした: \${0}`,
    fsFailedToGetPendingInvitations: `保留中の招待を取得できませんでした: \${0}。`,
    fsFailedToGetFolderSettings: `フォルダーの設定を取得できませんでした: \${0}。`,
    fsNotAuthorized: `このリソースを表示する権限がありません。`,
    apsInvalidToken: `AuthNZ によって無効な apsToken 値が返されました`,
    apsInvalidResponse: `ApsAuthNZ サーバーから無効な応答を受信しました`,
    apsActionPermanentlyDeletedFrom: `\${0} が \${1} から完全に削除されました。`,
    apsActionDeletedFrom: `\${0} が \${1} から削除されました。`,
    apsActionMovedTo: `\${0} が \${1} に移動されました。`,
    apsActionUploadedTo: `\${0} が \${1} にアップロードされました。`,
    apsActionRestoredTo: `\${0} が \${1} に復元されました。`,
    apsActionCreatedIn: `\${0} が \${1} に作成されました。`,
    apsActionRenamedTo: `\${0} が \${1} に名前変更されました。`,
    apsActionInvitationChangedOn: `\${0} が \${1} に移動されました。`,
    apsActionCopiedTo: `\${0} が \${1} にコピーされました。`,
    renamePromptInvalidCharacters: `名前に文字 \${0} を含めることはできません`,
    renameAcceptLabel: `承諾`,
    renameButtonLabel: `名前の変更`,
    renameCancelButtonLabel: `キャンセル`,
    renameErrorLabel: `(エラー)`,
    viewFile: `ビュー`,
    viewCloseButton: `閉じる`,
    viewDeleteAction: `削除`,
    viewDownloadAction: `ダウンロード`,
    viewInNewTab: `新しいタブに表示`,
    viewOpenLiveEditor: `MATLAB Online で開く`,
    viewOpenLiveEditorMock: `\"\${0}\" を開くことができません。`,
    viewOpenLiveEditorNotSupported: `\"MATLAB Online で開く\" 機能はこの環境で\nサポートされていません。`,
    simpleUploadTitle: `\${0} へのファイルのアップロード`,
    simpleUploadFolderTitle: `\${0} へのフォルダーのアップロード`,
    simpleUploadFilesSelectionText: `アップロードするファイルを選択してください`,
    simpleUploadFolderSelectionText: `アップロードするフォルダーを選択してください。`,
    simpleUploadEmptyFolderUploadWarning: `(空のフォルダーはアップロードできません)`,
    simpleUploadStatusText: `アップロード中...`,
    simpleUploadUploadCancelButtonLabel: `キャンセル`,
    simpleUploadUploadButtonLabel: `アップロード`,
    simpleUploadCloseButtonLabel: `閉じる`,
    simpleUploadFileUploadSuccess: `ファイルが正常にアップロードされました。`,
    simpleUploadStatusCancelled: `アップロードがキャンセルされました。  `,
    simpleUploadStatusWithErrors: `アップロードは完了しましたがエラーがあります。  `,
    simpleUploadSummary: `完了: \${0}、キャンセル: \${1}、失敗: \${2}。`,
    simpleUploadSummaryNoCancelled: `完了: \${0}、失敗: \${1}。`,
    simpleUploadSummaryNoFailed: `完了: \${0}、キャンセル: \${1}。`,
    simpleUploadSummaryNoCompleted: `キャンセル: \${0}、失敗: \${1}。`,
    simpleUploadSummaryNoCancelledNoFailed: `完了: \${0}。`,
    simpleUploadSummaryNoCompletedNoCancelled: `失敗: \${0}。`,
    simpleUploadSummaryNoCompletedNoFailed: `キャンセル: \${0}。`,
    simpleUploadCompletedText: `アップロードが完了しました。  `,
    simpleUploadFile: `ファイル`,
    simpleUploadFiles: `ファイル`,
    simpleUploadNumCompleted: `\${0} \${1} がアップロードされました。`,
    simpleUploadCancelOnly: `\${0} 件の \${1} へのアップロードがキャンセルされました。`,
    simpleUploadCancelFail: `\${0} 件のアップロードがキャンセルされ、\${1} 個のファイルが \${2} へのアップロードに失敗しました。`,
    simpleUploadCancelUpload: `\${0} 件のアップロードがキャンセルされ、\${1} 個のファイルが \${2} にアップロードされました。`,
    simpleUploadCancelFailUpload: `\${0} 件のアップロードがキャンセルされ、\${1} 個のファイルがアップロードに失敗し、\${2} 個のファイルが \${3} にアップロードされました。`,
    simpleUploadNumberFailed: `\${0} 個のファイルが \${1} へのアップロードに失敗しました。`,
    simpleUploadFailedUpload: `\${0} 個のファイルのアップロードに失敗しましたが、\${1} 個のファイルが \${2} にアップロードされました。`,
    simpleUploadOnlyUpload: `\${0} 個のファイルが \${1} にアップロードされました。`,
    simpleUploadResourceAlreadyExists: `その名前をもつフォルダーが既に MATLAB Drive 上に存在し、MATLAB Drive は大文字小文字を区別しません。ファイルの名前を変更してください。`,
    simpleUploadResourceDoesNotExist: `アップロードできませんでした。もう一度試してください。`,
    simpleUploadFileTooLarge: `ファイルが MATLAB Drive で許可されている 1 GB を超えています。ファイルを小さくしてください。`,
    simpleUploadAuthorizationError: `このフォルダーに保存する権限がありません。`,
    simpleUploadQuotaExceeded: `MATLAB Drive ストレージがいっぱいです。コンテンツを削除して容量を解放してください。`,
    simpleUploadIllegalPathElement: `ファイル名に許可されていない文字が含まれています。ファイルの名前を変更してください。`,
    simpleUploadSessionNotFound: `アップロードできませんでした。もう一度試してください。`,
    simpleUploadProgressText: `\${0}/\${1}...`,
    simpleUploadFolderUploadForbidden: `フォルダーのアップロードは許可されていません。`,
    simpleUploadFileTooLargeToUpload: `ファイルが大きすぎるため、オンラインでアップロードできません。許可されているファイル サイズは最大 \${0} GB です。 `,
    simpleUploadCanWithConnector: `\${0} を使用してこのファイルを MATLAB Drive に追加できます。`,
    simpleUploadMatlabDriveConnector: `MATLAB Drive Connector`,
    simpleUploadReasonInvalid: `ファイルが大きすぎるため、オンラインでアップロードできません。MATLAB Drive Connector を使用してこのファイルを MATLAB Drive に追加できます。`,
    simpleUploadProgressStatus: `\${0}/\${1} 個の項目をアップロード中...`,
    simpleUploadUploadCancelled: `アップロードがキャンセルされました。`,
    simpleUploadInvalidData: `無効なデータ: ファイルをアップロードできません。`,
    simpleUploadResolveButtonLabel: `解決`,
    simpleUploadUploadTypeChoiceGroupLabelText: `アップロードのタイプ: `,
    simpleUploadUploadUploadFilesChoiceLabelText: `ファイル`,
    simpleUploadUploadUploadDirectoryChoiceLabelText: `フォルダー`,
    openInMOTitle: `MATLAB Online で \"\${0}\" を開く`,
    openInMOLabel: `引き続き MATLAB Online でこのファイルを開くには、共有フォルダーに対する次のアクションのいずれかを選択してください:`,
    openInMOFileInTrash: `MATLAB Online で開くようにリクエストされたファイル/フォルダーはごみ箱内にあります。復元してから再度お試しください。`,
    saveToDriveSuccess: `\${0} がフォルダー \${1} に \${2} として正常に保存されました。`,
    fconflictDialogTitle: `ファイルの競合`,
    fconflictFolderDialogTitle: `フォルダーの競合`,
    fconflictSelectAll: `すべて選択`,
    fconflictReplaceButtonLabel: `置き換え`,
    fconflictKeepSeparateButtonLabel: `個別に保持`,
    fconflictFolderHasConflict: `同じ名前をもつ \${0} 個のファイルがフォルダーにあります。`,
    fconflictFolderHasConflicts: `同じ名前をもつ \${0} 個のファイルがフォルダーにあります。`,
    fconflictReplaceSuccessAndFailure: `\${0} 個のファイルが置き換えられましたが、\${1} 個のファイルの置き換えに失敗しました。`,
    fconflictReplaceSuccessesAndFailure: `\${0} 個のファイルが置き換えられましたが、\${1} 個のファイルの置き換えに失敗しました。`,
    fconflictReplaceSuccessesAndFailures: `\${0} 個のファイルが置き換えられましたが、\${1} 個のファイルの置き換えに失敗しました。`,
    fconflictReplaceSuccessAndFailures: `\${0} 個のファイルが置き換えられましたが、\${1} 個のファイルの置き換えに失敗しました。`,
    fconflictReplaceSuccessOnly: `\${0} 個のファイルが置き換えられました。`,
    fconflictReplaceSuccessesOnly: `\${0} 個のファイルが置き換えられました。`,
    fconflictReplaceFailureOnly: `\${0} 個のファイルの置き換えに失敗しました。`,
    fconflictReplaceFailuresOnly: `\${0} 個のファイルの置き換えに失敗しました。`,
    fconflictSaveSuccessesAndFailures: `\${0} 個のファイルが保存されましたが、\${1} 個のファイルの保存に失敗しました。`,
    fconflictSaveSuccessesAndFailure: `\${0} 個のファイルが保存されましたが、\${1} 個のファイルの保存に失敗しました。`,
    fconflictSaveSuccessAndFailures: `\${0} 個のファイルが保存されましたが、\${1} 個のファイルの保存に失敗しました。`,
    fconflictSaveSuccessAndFailure: `\${0} 個のファイルが保存されましたが、\${1} 個のファイルの保存に失敗しました。`,
    fconflictSaveSuccessesOnly: `\${0} 個のファイルが保存されました。`,
    fconflictSaveSuccessOnly: `\${0} 個のファイルが保存されました。`,
    fconflictSaveFailureOnly: `\${0} 個のファイルの保存に失敗しました。`,
    fconflictSaveFailuresOnly: `\${0} 個のファイルの保存に失敗しました。`,
    fconflictPleaseWait: `お待ちください...`,
    restoreButtonLabel: `元に戻す`,
    restoreCancelButtonLabel: `キャンセル`,
    personalInvitationLimitExceeded: `MATLAB Drive での共有フォルダーの最大数に達しました。新しい共有フォルダーを追加する前に、MATLAB Drive から共有フォルダーを削除してください。`,
    resourceInvitationLimitExceeded: `メンバー制限を超過しました。新しいメンバーを追加するには、現在のメンバーを削除してください。`,
    linkResourceInvitationLimitExceeded: `共有フォルダーを追加できません。メンバー制限を超過しました。所有者に問い合わせてください。`,
    showHiddenFilesLabel: `隠しファイルを表示`,
    chooseApplicationLabel: `アプリケーションの選択:`,
    defaultApplicationLabel: `MATLAB Drive`,
    addonApplicationLabel: `アドオン`,
    thingspeakApplicationLabel: `ThingSpeak`,
    instantAddonsApplicationLabel: `インスタント アドオン`,
    graderApplicationLabel: `MATLAB Grader`,
    otherApplicationLabel: `その他のアプリ`,
    previewBannerText: `プレビュー`,
    meMyself: `本人`,
    folderChooserFileNameLabel: `ファイル名:`,
    folderChooserChooseButton: `選択`,
    folderChooserChooseMoveButton: `移動`,
    folderChooserChooseCopyButton: `コピー`,
    folderChooserCloseButton: `閉じる`,
    folderChooserModalTitle: `フォルダーの選択`,
    moveFolderChooserModalTitle: `\${0} を次に移動:`,
    copyToFolderChooserModalTitle: `\${0} を次にコピー:`,
    folderChooserNoSelectionWarning: `何も選択せずに \"\${0}\" ボタンをクリックすると、\${1} フォルダーが選択されます。`,
    folderChooserNoSelectionMoveWarning: `何も選択しない場合、コンテンツが現在のフォルダーに移動されます。`,
    folderChooserNoSelectionCopyWarning: `何も選択しない場合、コンテンツが現在のフォルダーにコピーされます。`,
    folderChooserCurrentRootFolder: `現在のフォルダー`,
    folderChooserRenameSourceNotAllowed: `移動時またはコピー時にソース パスの名前を変更することは許可されていません。`,
    saveToDriveSaveButtonLabel: `保存`,
    saveToDriveCancelButtonLabel: `キャンセル`,
    saveToDriveModalTitle: `MATLAB Drive に保存`,
    widgetTestAppPageTitle: `組み込みウィジェットのテスト ページ`,
    widgetTestAppChooseLabel: `ウィジェットの選択`,
    widgetTestAppUseMatlabStyle: `MATLAB デスクトップのスタイルを使用`,
    widgetTestAppOverrideCss: `カスタム スタイルシートで CSS を上書き`,
    widgetTestAppCssStylesheetToUse: `使用する CSS スタイルシートの URL:`,
    widgetTestAppIgnoreResizeMsg: `ウィジェットのウィンドウ サイズ変更メッセージを無視する`,
    widgetTestAppIframeWidthPx: `iframe の幅 (ピクセル単位): `,
    widgetTestAppIframeHeightPx: `iframe の高さ (ピクセル単位): `,
    widgetTestAppRunWidgetButton: `選択したウィジェットを実行`,
    widgetTestAppResetButton: `リセット`,
    widgetTestAppSelectedWidgetContents: `選択したウィジェットのコンテンツのテスト`,
    widgetTestAppLoggedInIntro: `現在有効なログイン資格情報があります。組み込みウィジェットはこれらの資格情報を使用してデータを取得します。`,
    widgetTestAppNotLoggedInIntro: `現在ログインしておらず、有効な資格情報がありません。`,
    widgetTestAppWidgetsWillNotWork: `有効なログイン資格情報なしでは、組み込みウィジェットは機能しません。`,
    widgetTestAppNeedLogin: `有効な資格情報を取得するには、まずこの同じドメインにログインする必要があります。`,
    widgetTestOptionNoOptions: `オプションなし`,
    widgetTestOptionActions: `アクション`,
    widgetTestOptionColumns: `列`,
    widgetTestOptionWidgetOptions: `ウィジェットのオプション`,
    widgetTestOptionTrashWidgetTitle: `コンテンツをごみ箱に移動する`,
    widgetTestOptionsColumnName: `名前`,
    widgetTestOptionsColumnSize: `サイズ`,
    widgetTestOptionStartButton: `ウィジェットを開始`,
    widgetTestOptionPleaseWait: `お待ちください...`,
    widgetTestOptionResetWidgetButton: `ウィジェットのオプションをリセット`,
    widgetTestOptionSearchWidgetTitle: `ファイルを検索する`,
    widgetTestOptionColumnClientDateModified: `クライアント更新日`,
    widgetTestOptionColumnDateModified: `更新日`,
    widgetTestOptionColumnType: `タイプ`,
    widgetTestOptionShowShared: `共有項目を表示`,
    widgetTestOptionShowHidden: `隠しファイルを表示`,
    widgetTestOptionIncludeFolders: `フォルダーを含める`,
    widgetTestOptionEnableViewing: `表示を有効にする`,
    widgetTestOptionEnableDnD: `ドラッグ アンド ドロップを有効にする`,
    widgetTestOptionEnableNavigation: `ナビゲーションを有効にする`,
    widgetTestOptionSaveToDriveTitle: `Drive に保存する`,
    widgetTestOptionUseFolderChooserUI: `[フォルダー選択] の UI を使用する`,
    widgetTestOptionSaveToDriveResultsLabel: `Drive への保存の結果: `,
    widgetTestOptionFullPathPrompt: `保存するファイルの絶対パスを入力: `,
    widgetTestOptionChooseContentSourcePrompt: `ファイルのコンテンツ ソースを選択: `,
    widgetTestOptionContentChoiceUrl: `URL`,
    widgetTestOptionContentChoiceDirect: `直接のコンテンツ`,
    widgetTestOptionContentChoiceFromFile: `ファイルから`,
    widgetTestOptionContentChooseFilesPrompt: `ファイルを選択:`,
    widgetTestOptionUrlPrompt: `使用するコンテンツの URL を入力:`,
    widgetTestOptionTextPrompt: `テキストを入力:`,
    widgetTestOptionSaveToDriveButton: `Drive に保存`,
    widgetTestOptionClearButton: `クリア`,
    widgetTestOptionQuotaTitle: `クォータ ウィジェット`,
    widgetTestOptionInitiateSharingTitle: `共有を開始する`,
    widgetTestOptionFolderFullPathPrompt: `フォルダーの絶対パスを入力: `,
    widgetTestOptionLinkButton: `リンク`,
    widgetTestOptionManageButton: `管理`,
    widgetTestOptionFolderChooserTitle: `フォルダーを選択する`,
    widgetTestOptionShowFileNameSelector: `ファイル名セレクターを表示する`,
    widgetTestOptionChooseButton: `選択`,
    widgetTestOptionSelectedFolder: `選択した絶対パス: `,
    widgetTestOptionDesiredHeightInPixels: `目的の高さ (ピクセル単位): `,
    widgetTestOptionDesiredWidthInPixels: `目的の幅 (ピクセル単位): `,
    widgetTestOptionFileNameSuggestionPrompt: `ファイル名選択の候補: `,
    widgetTestOptionStartingFolderPrompt: `開始フォルダーの絶対パス: `,
    widgetTestOptionFolderPathContentTitle: `フォルダー パスのコンテンツ`,
    widgetTestOptionsSearchNumberOfDays: `今までの検索日数: `,
    widgetTestOptionsSearchMaxResults: `結果の最大サイズ: `
});