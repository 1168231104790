 define([
   "underscore",
   "jquery",
   "backbone",
   "bootstrap",
   "util",
   "templates/renamePromptDialog",
   "dojo/i18n!nls/mldoStringResource",
   "dojo/string"
 ], function( _, $, Backbone, Bootstrap, Utils, RenamePromptDialog, MLDOStrings, DojoString ) {

    return Backbone.View.extend({

        template: RenamePromptDialog,

        initialize: function(args) {
          if (!args || Object.keys(args).length === 0) {
            throw new TypeError("Invalid arguments");
          }
          if (!args.promptTitle || typeof args.promptTitle !== "string") {
            throw new TypeError("Invalid title argument");
          }
          this.promptTitle = args.promptTitle;
          if (!args.promptLabel || typeof args.promptLabel !== "string") {
            throw new TypeError("Invalid label argument");
          }
          this.promptLabel = args.promptLabel;
          if (!args.itemType || typeof args.itemType !== "string") {
            throw new TypeError("Invalid item type argument");
          }
          if (typeof args.initialText === "string") {
            this.initialText = args.initialText;
          } else {
            this.initialText = "";
          }
          this.itemType = args.itemType.toLowerCase();
          if (!args.callback || typeof args.callback !== "function") {
            throw new TypeError("Invalid callback argument");
          }
          this.promptAsInfo = (args.promptAsInfo && args.promptAsInfo === true);
          this.callback = args.callback;
          this.callbackCalled = false;
          this.onCloseWindowCallback = args.onCloseWindowCallback;
          _.bindAll(this, "render", "rename", "onKeydown");
        },

        render: function() {
            var self = this;
            document.querySelector('#modalContainer').innerHTML = RenamePromptDialog({
              promptTitle: _.escape(this.promptTitle),
              promptLabel: _.escape(this.promptLabel),
              initialText: _.escape(this.initialText),
              errorLabel: MLDOStrings.renameErrorLabel,
              renameButtonLabel: MLDOStrings.renameButtonLabel,
              renameCancelButtonLabel: MLDOStrings.renameCancelButtonLabel
            });
           const dialogElement = document.querySelector('#renamePromptDialog');
            dialogElement.addEventListener('shown.bs.modal', this.setInputFocus.bind(this), false);
            dialogElement.addEventListener('hidden.bs.modal', function() {
              if (self.onCloseWindowCallback && typeof self.onCloseWindowCallback === "function") {
                self.onCloseWindowCallback(self.callbackCalled);
              }
              document.querySelector('div#renamePromptDialog .renamePromptSubmit').removeEventListener('click', this.rename);
              document.querySelector('div#renamePromptDialog #renamePromptInput').removeEventListener('keydown', this.onKeydown);
              if (dialogElement) {
                const modal = Bootstrap.Modal.getOrCreateInstance(dialogElement);
                modal.dispose();
                dialogElement.remove();
              }
            }.bind(this), false);
            const dragNotAllowedMethod = this.dragNotAllowed.bind(this);
            dialogElement.removeEventListener('dragover', dragNotAllowedMethod);
            dialogElement.addEventListener('dragover', dragNotAllowedMethod, false);
            dialogElement.addEventListener('input', function() {
              var text = document.querySelector("#renamePromptInput").value;
              var re = new RegExp("^" + self.initialText + "$");
              if (/[\/\\:?*"<>|]/.test(text)) {
                self.displayValidationError(DojoString.substitute(MLDOStrings.renamePromptInvalidCharacters, ["\" / : \\ ? * < | >."]));
                self.disableSubmit();
              } else if (/^\s*$/.test(text) || re.test(text)) {
                self.hideValidationError();
                self.disableSubmit();
              } else {
                self.hideValidationError();
                self.enableSubmit();
              }
            }.bind(this), false);
            if (this.promptAsInfo) {
              document.querySelector('#renamePromptDialog div#mldo-error-container').removeClass('importantDialogError').addClass('importantDialogInfo');
              document.querySelector('#renamePromptDialog div#mldo-error-container span.notificationImageError').removeClass('notificationImageError').addClass('notificationImageInfo');
              document.querySelector('#renamePromptDialog div#mldo-error-container span.notificationContentTextError').removeClass('notificationContentTextError').addClass('notificationContentTextInfo');
              document.querySelector('#renamePromptDialog #renamePromptSubmit').text(MLDOStrings.renameAcceptLabel);
              this.enableSubmit();
            }
           document.querySelector('div#renamePromptDialog .renamePromptSubmit').addEventListener('click', this.rename, false);
           document.querySelector('div#renamePromptDialog #renamePromptInput').addEventListener('keydown', this.onKeydown, false);
            if (dialogElement) {
              const modal = new Bootstrap.Modal(dialogElement, {backdrop: 'static'});
              modal.show();
            }
            return this;
        },

        setInputFocus: function() {
          document.querySelector("#renamePromptInput").focus();
        },

        dragNotAllowed: function(e) {
          e.preventDefault();
          e.originalEvent.dataTransfer.dropEffect = 'none';
        },

        disableSubmit: function() {
            $('.renamePromptSubmit').prop('disabled', true);
        },

        enableSubmit: function() {
            $('.renamePromptSubmit').prop('disabled', false);
        },

        displayValidationError: function(err) {
            $('.inline_form_error_msg').html(err);
            $('.inline_form_error').show();
            $('#rename_prompt_error_notification_group').addClass('has-error has-feedback');
            $('.form-control-feedback').show();
        },

        hideValidationError: function() {
            $('.inline_form_error').hide();
            $('.inline_form_error_msg').empty();
            $('#rename_prompt_error_notification_group').removeClass('has-error has-feedback');
            $('.form-control-feedback').hide();
        },

        rename: function(e) {
          if (e && e.preventDefault) {
            e.preventDefault();
          }
          var alternate = document.querySelector("#renamePromptInput").value; // _.escape(document.querySelector("#renamePromptInput").val());

          // Bail out if no input
          if (!alternate || !alternate.trim()) {
              return;
          }
          alternate = alternate.trim();
          this.callback(alternate);
          this.callbackCalled = true;
          document.querySelector('#cancelRenamePrompt').click();
        },

        onKeydown: function(e) {
            var code = e.keyCode || e.which;
            if (code == 13) { // Enter key
                e.preventDefault();
                var name = document.querySelector("#renamePromptInput").value; // _.escape(document.querySelector("#renamePromptInput").val());

                // Bail out if no input
                if (name && name.trim()) {
                  document.querySelector(".renamePromptSubmit").click();
                }
            }
        }
    });
});
