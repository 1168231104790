// Copyright 2016, The MathWorks, Inc

define([
    "dojo/_base/declare",
    "dojo/_base/lang",
    "dojo/Evented",
    "dojo/Deferred",
    "dojo/on",
    "./GestureRecognizer",
    "../util/RemovableGroup"
], function (declare, lang, Evented, Deferred, on, GestureRecognizer, RemovableGroup) {

    return declare([GestureRecognizer], {

        constructor: function (args) {
            this._temporaryHandlers = new RemovableGroup();
        },

        onRecognizerStateChange: function () {
            var recognizerState = this.getRecognizerState();
            switch (recognizerState) {
                case this.RECOGNIZER_STATES.ACTIVE:
                    this._setCurrentDeferred(new Deferred());
                    break;
                case this.RECOGNIZER_STATES.MATCHED:
                    this._clearDeferred();
                    break;
                case this.RECOGNIZER_STATES.INACTIVE:
                    this._clearDeferred();
                    break;
            }
        },

        _setCurrentDeferred: function (deferred) {
            var that = this;
            this._clearDeferred();
            this._currentDeferred = deferred;
            this._currentDeferred.promise.always(function () {
                that.emit("always");
                that._temporaryHandlers.remove();
                that._currentDeferred = null;
            })
        },

        getGestureActive: function () {
            return this.getRecognizerState() === this.RECOGNIZER_STATES.ACTIVE;
        },

        _clearDeferred: function () {
            if (this._currentDeferred && !this._currentDeferred.isFulfilled()) {
                this._currentDeferred.cancel();
            }
        },

        addGestureHandler: function (gestureImpl) {
            var handles = this._attachGestureHandler(gestureImpl);
            this.own(handles);
            return handles;
        },

        addTemporaryGestureHandler: function (gestureImpl) {
            var handles = this._attachGestureHandler(gestureImpl);
            this._temporaryHandlers.own(handles);
            return handles;
        },

        _attachGestureHandler: function (gestureImpl) {
            var handles = new RemovableGroup();
            if (gestureImpl) {
                if (gestureImpl.start) { handles.own(on(this, "start", lang.hitch(gestureImpl, gestureImpl.start))) }
                if (gestureImpl.progress) { handles.own(on(this, "progress", lang.hitch(gestureImpl, gestureImpl.progress))) }
                if (gestureImpl.end) { handles.own(on(this, "end", lang.hitch(gestureImpl, gestureImpl.end))) }
                if (gestureImpl.cancel) { handles.own(on(this, "cancel", lang.hitch(gestureImpl, gestureImpl.cancel))) }
                if (gestureImpl.always) { handles.own(on(this, "always", lang.hitch(gestureImpl, gestureImpl.always))) }
            }
            return handles;
        },

        onStart: function (domEvent) {
            this.setRecognizerState(this.RECOGNIZER_STATES.ACTIVE);
            this.emit("start", domEvent);
        },

        onProgress: function (domEvent) {
            if (this.getGestureActive()) {
                this.emit("progress", domEvent);
            }
        },

        onEnd: function (domEvent) {
            if (this.getGestureActive()) {
                this.emit("end", domEvent);
                this._currentDeferred.resolve();
                this.setRecognizerState(this.RECOGNIZER_STATES.INACTIVE);
            }
        },

        onCancel: function (domEvent) {
            if (this.getGestureActive()) {
                this.emit("cancel", domEvent);
                this._currentDeferred.cancel();
                this.setRecognizerState(this.RECOGNIZER_STATES.INACTIVE);
            }
        }

    });

});