/* Copyright 2014-2017 The MathWorks, Inc. */
/*jslint regexp: false */

define([
    "dojo/_base/array"
], function (array) {

    return {

        MATLAB_FILE_PATTERN: /^[a-z][a-z0-9_]*\.m$/i,

        USER_DIR_PATH: "users/",

        USERS_DIR_PATH: "/users/",
        USER_PUBLISHED_DIR: "Published",
        USER_PUBLISHED_DIR_PATH_PATTERN: "^/users/[a-zA-Z][a-zA-Z0-9]*/Published$",
        USER_SHARED_DIR_PATH_PATTERN: "^/users/[a-zA-Z][a-zA-Z0-9]*/Shared$",
        IN_USER_SHARED_DIR_PATTERN: "^/users/[a-zA-Z][a-zA-Z0-9]*/Shared/*",
        BROWSER_TYPES: ["htm", "html", "pdf", "doc", "docx", "dot", "dotx",
            "rtf", "docm", "dotm", "xls", "asv", "dat", "exe", "dll", "xlsx", "zip"],
        LOAD_TYPES: ["mat"],
        MATLAB_OPEN_TYPES: ["fig", "tif", "png", "jpg", "jpeg", "gif", "au", "bmp", "mlx"],
        LIVE_SCRIPT_EXTENSION: "mlx",
        MATLAB_CODE_FILE_EXTENSION: "m",

        FILENAME_PATTERN: /^[^\"\/\*:><\?\\|]+$/,
        MATLAB_EXTENSION_PATTERN: /\.(m|mln|mlx)$/i,
        SUPPORTED_EXTENSION_PATTERN: /\.(pdf|html|htm)$/i,
        SUPPORTED_FILENAME_PATTERN: /^[a-z][a-z0-9_]*\.(m|mln|mlx|pdf|html|htm)$/i,
        MATLAB_FILENAME_PATTERN: /^[a-z][a-z0-9_]*\.(m|mln|mlx)$/i,

        MATLAB_NAME_LENGTH: 63,
        FILE_NAME_LENGTH: 128,

        isMatlabFileInfo: function (fileInfo) {
            return this._fileInfoNameMatchesRegExp(fileInfo, this.MATLAB_FILE_PATTERN);
        },

        isMatlabFileName: function (fileName) {
            return this._fileNameMatchesRegExp(fileName, this.MATLAB_FILE_PATTERN);
        },

        isValidFileName: function (fileName) {
            return this._fileNameMatchesRegExp(fileName, this.FILENAME_PATTERN);
        },

        checkFileInfoExtension: function (fileInfo, extensions) {
            return fileInfo && fileInfo.name && this.checkFileNameExtension(fileInfo.name,
                extensions);
        },

        checkFileNameExtension: function (fileName, extensions) {
            if (!(extensions instanceof Array)) {
                extensions = [extensions];
            }

            return array.some(extensions, function (extension) {
                var fileExt = this.getFileNameExtension(fileName).toLocaleLowerCase();
                return fileExt === extension.toLocaleLowerCase();
            }, this);
        },

        /**
         * Constructs a string suitable for sending to MATLAB for evaluation,
         * escapes the single quote ( ' ). Also wraps in MATLAB single quotes.
         */
        constructMatlabFilePath: function (fileInfo) {
            var matlabFilePath = this.pathFromFileInfo(fileInfo).replace(/'/g, "''");
            return "'" + matlabFilePath + "'";
        },

        constructUrlFromFileInfo: function (fileInfo, loginName) {
            if (!loginName) {
                // TODO: remove this assumption that the username is second
                var parts = fileInfo.location.split(this.getFileSeparator(fileInfo.location));
                loginName = parts[Math.min(2, parts.length - 1)];
            }
            // using Math.min to strip off any trailing path separators while making sure we don't
            // go past the end of the string
            var dir = "/" + fileInfo.location.substring(
                Math.min(fileInfo.location.indexOf(loginName) + loginName.length + 1,
                    fileInfo.location.length)
            );

            return this.USER_DIR_PATH + loginName + dir + fileInfo.name;
        },

        getFileSeparator: function (location) {
            // check for windows drive letter or UNC path
            if (location.search(/^[a-z]:/i) !== -1 ||
                location.search(/^\\\\/i) !== -1) {
                return "\\"; // Windows
            } else {
                return "/"; // Linux and Mac
            }
        },

        getFileNameNoExtension: function (fileName) {
            var isFileNameString = (typeof fileName === "string");
            if (!fileName || !isFileNameString) {
                return fileName;
            }

            // Use the last dot in the string as the start of the extension, or the whole string.
            var dotIdx = fileName.lastIndexOf(".") !== -1 ? fileName.lastIndexOf(".") :
                fileName.length;

            return fileName.slice(0, dotIdx);
        },

        /**
         * Returns the portion of the file name after the last dot. For example:
         *  foo.m --> m
         *  foo.exe.txt.html --> html
         *  foo --> ""  (an empty string)
         *
         * @param fileName The name of the file as a string.
         * @return The extension, not including the dot, or an empty string if the file name doesn't
         *  have an extension
         */
        getFileNameExtension: function (fileName) {
            var isFileNameString = (typeof fileName === "string");
            if (!fileName || !isFileNameString) {
                return fileName;
            }

            // Use the last dot in the string as the start of the extension, or the whole string.
            var dotIdx = fileName.lastIndexOf(".") !== -1 ? fileName.lastIndexOf(".") + 1 :
                fileName.length;

            return fileName.substring(dotIdx, fileName.length);
        },

        normalizePath: function (path) {
            var fileSep = this.getFileSeparator(path);
            // Normalize the path to have one separator at the end
            return path.replace(/[\/\\]*$/, fileSep);
        },

        /**
         * Parses the user's home folder from the path and ensures that there is a file separator on
         * the end.
         */
        parseHomeFolderFromPath: function (path, userId) {
            var userIdIndex = path.indexOf(userId);

            var homeFolder = "";
            if (userIdIndex > 0) {
                homeFolder =
                    path.substring(0, userIdIndex + userId.length) + this.getFileSeparator(path);
            }
            return homeFolder;
        },

        getPackageStartFromLocation: function (location) {
            location = this.normalizeLocation(location);
            var fileSep = this.getFileSeparator(location);
            if (fileSep === "\\") {
                fileSep = fileSep + fileSep;
            }

            return location.search("(\\" + fileSep + "\\+[a-zA-Z][a-zA-Z0-9_]*)+\\" +
                fileSep + "$");
        },

        getFullyQualifiedMatlabName: function (fileInfo) {
            var filePackage = this.convertLocationToPackage(fileInfo.location);
            var fileNameNoExt = this.getFileNameNoExtension(fileInfo.name);
            return filePackage + fileNameNoExt;
        },

        getPathForPublish: function (fileInfo) {
            var path;
            // If not a package, then return full path, else return package.fileName
            if (fileInfo.location.indexOf("+") > -1) {
                path = this.getFullyQualifiedMatlabName(fileInfo);
            } else {
                path = fileInfo.location + fileInfo.name;
            }
            return path;
        },

        /**
         * This method returns a boolean describing whether or not two files are equal based on a comparison of the
         * fully qualified path of both file info objects, as calculated by <code>pathFromFileInfo</code>.
         */
        areFilesEqual: function (fileInfoOne, fileInfoTwo) {
            return this.pathFromFileInfo(fileInfoOne) === this.pathFromFileInfo(fileInfoTwo);
        },

        convertPathToRowClass: function (path) {
            return path.replace(/[\/\.]/g, "_");
        },

        convertLocationToPackage: function (location) {
            location = this.normalizeLocation(location);
            var packageStart = this.getPackageStartFromLocation(location);
            if (packageStart < 0) {
                return "";
            }
            var fileSep = this.getFileSeparator(location);
            if (fileSep === "\\") {
                fileSep = fileSep + fileSep;
            }
            var filePackage = location.substring(packageStart + 1, location.length);
            return filePackage.replace(/\+/g, "").replace(new RegExp("\\" + fileSep, "g"), ".");
        },

        // todo: remove this once the server is returning the isShareable flag
        isShareable: function (item) {
            //        if (item.parent && item.parent.pathId === "ROOT") {
            if (item.location === this.USERS_DIR_PATH) {
                return false;
            }
            //        if (item.parent && item.name === USER_PUBLISHED_DIR && item.parent.parent.pathId ===
            // "ROOT") {
            if (this.isUserPublishedDirectoryPath(item.path)) {
                return false;
            }
            if (item.shareAttributes && item.shareAttributes.canProduceShare !== undefined) {
                return item.shareAttributes.canProduceShare;
            }
            return (item.isDirectory && !(item.parent && item.parent.pathId === "ROOT"));
        },

        isUserSharedDirectoryPath: function (filePath) {
            return filePath.match(this.USER_SHARED_DIR_PATH_PATTERN);
        },
        /**
         * Determine if the filePath is located in the users Shared (with me) folder
         * @param filePath
         * @returns Boolean true if the Shared folder is part of the filePath, false otherwise
         */
        isInUsersSharedDirectory: function (filePath) {
            return filePath.search(this.IN_USER_SHARED_DIR_PATTERN) !== -1;
        },

        /**
         * Determine if the folderPath string exists withing the CurrentFolderPath.
         * @param folderPath
         * @param currentFolderPath
         * @returns Boolean true if the current folder path is a sub folder of the folderPath.
         */
        isCurrentFolderInPath: function (folderPath, currentFolderPath) {
            return currentFolderPath.search(folderPath) !== -1;
        },

        isUserPublishedDirectoryPath: function (filePath) {
            return filePath.match(this.USER_PUBLISHED_DIR_PATH_PATTERN);
        },

        isVersioned: function (item) {
            return !item.isDirectory && this.isWritable(item) && this.isMatlabEditorOpenType(item);
        },

        isWritable: function (item) {
            return item && item.filePermissions && item.filePermissions.canWrite;
        },

        isLiveScriptFile: function (fileName) {
            return this.getFileNameExtension(fileName) === this.LIVE_SCRIPT_EXTENSION;
        },

        isPlainCodeFile: function (fileName) {
            return this.getFileNameExtension(fileName) === this.MATLAB_CODE_FILE_EXTENSION;
        },

        canRename: function (item) {
            if (!item) {
                return false;
            }
            if (item.parent && item.parent.pathId === "ROOT") {
                return false;
            }
            if (item.parent && item.name === this.USER_PUBLISHED_DIR &&
                item.parent.parent.pathId === "ROOT") {
                return false;
            }

            return this.isWritable(item);
        },

        canDelete: function (item) {
            return this.canRename(item);
        },

        canDownload: function (item) {
            if (!item) {
                return false;
            }
            /* Disable download for RTE documents. This may change in the future. */
            if (item.name && this.getFileNameExtension(item.name).toLocaleLowerCase() === "mln") {
                return false;
            }
            return !item.isDirectory;
        },

        _fileInfoNameMatchesRegExp: function (fileInfo, re) {
            var matches = false;

            if (fileInfo && fileInfo.name) {
                matches = this._fileNameMatchesRegExp(fileInfo.name, re);
            }

            return matches;
        },

        _fileNameMatchesRegExp: function (fileName, re) {
            var matches = false;
            var regexp = this._getCaseInsensitiveRegExp(re);

            if (fileName && (typeof fileName === "string") && fileName.search(regexp) !== -1) {
                matches = true;
            }

            return matches;
        },

        // Converts an object (eg a String or RegExp) into a case insensitive RegExp
        // Note that this drops any other flags if you pass it an existing RegExp object.
        _getCaseInsensitiveRegExp: function (re) {
            var regexp;
            if (re instanceof RegExp) {
                regexp = new RegExp(re.source, "i");
            } else {
                // make it case insensitive
                regexp = new RegExp(re, "i");
            }
            return regexp;
        },

        /**
         * This function asserts whether the item passed in is a 'File' object. If the assertion
         * fails, then it throws and <code>Error</code>. It checks if the item has
         * <code>content</code> and <code>FileInfo</code> properties.
         *
         * @param item The item to test for basic 'File' properties.
         * @throws Error if the item does not pass the <code>File</code> checks.
         */
        assertIsFile: function (item) {
            if (!this.isFile(item)) {
                throw new Error("MW.utils.FileNameUtil: the object is not a 'File'.");
            }
        },

        /**
         * This function tests whether the item passed in is a 'File' object. It
         * checks if the item has <code>content</code> and <code>fileInfo</code> properties.
         *
         * @param item The item to test for basic 'File' properties.
         * @returns boolean true if the item is a <code>File</code>, otherwise false.
         */
        isFile: function (item) {
            return item && item.hasOwnProperty('content') && item.hasOwnProperty('fileInfo') &&
                this.isFileInfo(item.fileInfo);
        },

        /**
         * This function asserts whether the item passed in is a 'FileInfo' object. If the assertion
         * fails, then it throws an <code>Error</code>. It checks if the item has
         * <code>location</code>, <code>name</code>, and <code>isDirectory</code> properties.
         *
         * @param item The item to test for basic 'FileInfo' properties.
         * @throws Error if the item does not pass the <code>FileInfo</code> checks.
         */
        assertIsFileInfo: function (item) {
            if (!this.isFileInfo(item)) {
                throw new Error("MW.utils.FileNameUtil: the object is not a 'FileInfo'.");
            }
        },

        /**
         * This function tests whether the item passed in is a 'FileInfo' object. It
         * checks if the item has <code>location</code>, <code>name</code>, and
         * <code>isDirectory</code> properties.
         *
         * @param item The item to test for basic 'FileInfo' properties.
         * @returns boolean true if the item is a <code>FileInfo</code>, otherwise false.
         */
        isFileInfo: function (item) {
            return item && item.hasOwnProperty('location') && item.hasOwnProperty('name') &&
                item.hasOwnProperty('isDirectory');
        },

        /**
         * Returns the given path, insuring that it ends with a trailing directory
         * separator.
         *
         * @param path The path to normalize.
         */
        normalizeLocation: function (path) {
            if ((typeof path === "string") && (path.length === 0 ||
                path.lastIndexOf(this.getFileSeparator(path)) !== path.length - 1)) {
                path = path + this.getFileSeparator(path);
            }

            return path;
        },

        /**
         * Generates a file path string based on the given location and name.
         *
         * @param location The file's location.
         * @param name The file's name.
         */
        createFilePath: function (location, name) {
            return this.normalizeLocation(location) + name;
        },

        /**
         * Creates and returns a 'FileInfo' object based on the passed in path. Uses
         * the last path element for the FileInfo's name, and the rest of the path as
         * the FileInfo's location.
         * <b>NOTE:</b> assumes an absolute path, and that path represents a folder.
         *
         * @param folderPath The absolute path of the folder.
         */
        folderInfoFromPath: function (folderPath) {
            var fileInfo = this.fileInfoFromPath(folderPath);
            fileInfo.isDirectory = true;
            return fileInfo;
        },

        /**
         * Creates and returns a 'FileInfo' object based on the passed in path. Uses
         * the last path element for the FileInfo's name, and the rest of the path as
         * the FileInfo's location.
         * <b>NOTE:</b> assumes an absolute path.
         *
         * @param filePath The absolute path of the file or folder.
         */
        fileInfoFromPath: function (filePath) {
            return {
                location: this.locationFromPath(filePath),
                name: this.nameFromPath(filePath),
                filePermissions: {canWrite: true},
                isDirectory: false,
                separator: this.getFileSeparator(filePath)
            };
        },

        /**
         * Creates a string path from a valid 'FileInfo' object. This is equivalent to
         * <pre>
         * utils.createFilePath(fileInfo.location, fileInfo.name);
         * </pre>
         * @param fileInfo the FileInfo object to be converted.
         */
        pathFromFileInfo: function (fileInfo) {
            if (!this.isFileInfo(fileInfo)) {
                throw new Error("MW.utils.FileNameUtil: the object is not a 'FileInfo'.");
            }
            return fileInfo.location + fileInfo.name;
        },

        /**
         * Extracts the (parent) location from the given file path. A location always ends with a separator.
         * @param filePath The file path from which we'll extract the location.
         * @returns location The location of the file path.
         */
        locationFromPath: function (filePath) {
            var name, location;
            var separator = this.getFileSeparator(filePath);
            var pathParts = filePath.split(separator);

            if (pathParts[pathParts.length - 1]) {
                name = pathParts[pathParts.length - 1];
                location = filePath.slice(0, (filePath.length - name.length));
            } else if (pathParts[pathParts.length - 2]) {
                name = pathParts[pathParts.length - 2];
                location = filePath.slice(0, (filePath.length - name.length - 1));
            }

            /* If filePath is file system root (/), just return it */
            if (!location && filePath === separator) {
                location = separator;
            }

            return location;
        },

        /**
         * Extracts the parent path from the given file path. A path never ends with a separator, unless the path is
         * either a Windows disk designator with a backslash (e.g., "C:\") or the Linux root ("/").
         * @param filePath The file path from which we'll extract the parent path.
         * @returns location The parent path of the file path.
         */
        getParentPath: function (filePath) {
            var location = this.locationFromPath(filePath);
            return this._getPath(location);
        },

        _getPath: function (filePath) {
            if (filePath.length === 0) {
                return filePath;
            }

            var separator = this.getFileSeparator(filePath);
            if (filePath.charAt(filePath.length - 1) !== separator) {
                return filePath;
            }

            var locationIsWindowsDiskDesignatorWithABackslash = // "Windows disk designator with a backslash" appears to the correct term for a string such as "C:\". See http://msdn.microsoft.com/en-us/library/windows/desktop/aa365247(v=vs.85).aspx#fully_qualified_vs._relative_paths
                filePath.length === 3 &&
                filePath.charAt(0).match(/[a-z]/i) &&
                filePath.charAt(1) === ":" &&
                filePath.charAt(2) === "\\";
            if (locationIsWindowsDiskDesignatorWithABackslash) {
                return filePath;
            }

            var locationIsLinuxRoot = filePath === "/";
            if (locationIsLinuxRoot) {
                return filePath;
            }

            return filePath.slice(0, filePath.length - 1);
        },

        /**
         * Extracts the name from the given file path.
         *
         * @param filePath The path from which we'll extract the name.
         */
        nameFromPath: function (filePath) {
            var name = "";
            var fileSep = this.getFileSeparator(filePath);

            // sometimes the server gives us paths with double slashes in them
            filePath = filePath.replace(fileSep + fileSep, fileSep);

            var pathParts = filePath.split(fileSep);

            if (pathParts[pathParts.length - 1]) {
                name = pathParts[pathParts.length - 1];
            } else if (pathParts[pathParts.length - 2]) {
                name = pathParts[pathParts.length - 2];
            }

            return name;
        },

        /**
         * Creates a <code>File</code> object which represents an "empty" newly created file
         * using the fileName and the location where the file is to be created
         * @param location
         * @param fileName
         */
        createEmptyFileObject: function (location, fileName) {
            return {
                content: "",
                fileInfo: this.fileInfoFromPath(this.createFilePath(location, fileName))
            };
        },

        /**
         * This function takes a client <code>File</code> object, and returns an object containing
         * only the necessary attributes for server messaging. If the file argument isn't a
         * <code>File</code> object, then it's returned unchanged.
         *
         * TODO: Should this function be here? It's really part of the messaging protocol. Move to
         * remote layer?
         *
         * @param file A client <code>File</code> object, which may contain additional client
         *  properties. The object should conform to the <code>File</code> API, and return true when
         *  passed to the <code>FileDataUtils.isFile()</code> function.
         * @returns File If the file argument is a <code>File</code> object, then this function
         *  returns a <code>File</code> object containing only those attributes recognized by the
         *  server. If the file argument isn't a <code>File</code> object, then it's returned
         *  unchanged.
         */
        remoteFile: function (file) {
            var remoteFile = file;

            if (this.isFile(file) && this.isFileInfo(file.fileInfo)) {
                remoteFile = {
                    content: file.content,
                    fileInfo: this.remoteFileInfo(file.fileInfo)
                };
            }

            return remoteFile;
        },

        /**
         * This function takes a client <code>FileInfo</code> object, and returns an object
         * containing only the necessary attributes for server messaging. If the fileInfo argument
         * isn't a <code>FileInfo</code> object, then it's returned unchanged.
         *
         * TODO: Should this function be here? It's really part of the messaging protocol. Move to
         * remote layer?
         *
         * @param fileInfo A client <code>FileInfo</code> object, which may contain additional
         *  client properties. The object should conform to the <code>FileInfo</code> API, and
         *  return true when passed to the <code>FileDataUtils.isFileInfo()</code> function.
         * @returns FileInfo If the fileInfo argument is a <code>FileInfo</code> object, then this
         *  function returns a <code>FileInfo</code> object containing only those attributes
         *  recognized by the server. If the fileInfo argument isn't a <code>FileInfo</code> object,
         *  then it's returned unchanged.
         */
        remoteFileInfo: function (fileInfo) {
            var remoteFileInfo = fileInfo;

            if (this.isFileInfo(fileInfo)) {
                remoteFileInfo = {
                    location: fileInfo.location,
                    name: fileInfo.name,
                    isDirectory: fileInfo.isDirectory
                };
            }

            return remoteFileInfo;
        },

        /**
         * This function extracts file name from given fully qualified file path
         *
         * @param selectedFileName
         */
        extractSelectedFileName: function (selectedFileName) {
            if (!selectedFileName || selectedFileName === "" || selectedFileName.length < 1) {
                return "";
            }
            var sep = this.getFileSeparator(selectedFileName);
            if (selectedFileName.lastIndexOf(sep) < 0) {
                return selectedFileName;
            }

            return selectedFileName.substring(selectedFileName.lastIndexOf(sep) + 1,
                selectedFileName.length);
        },

        isBrowserType: function (fileInfo) {
            return this.checkFileInfoExtension(fileInfo, this.BROWSER_TYPES) ? true : false;
        },

        isLoadType: function (fileInfo) {
            return this.checkFileInfoExtension(fileInfo, this.LOAD_TYPES) ? true : false;
        },

        isMatlabOpenType: function (fileInfo) {
            return this.checkFileInfoExtension(fileInfo, this.MATLAB_OPEN_TYPES) ? true : false;
        },

        isMatlabEditorOpenType: function (fileInfo) {
            return !(this.checkFileInfoExtension(fileInfo, this.BROWSER_TYPES) ||
                this.checkFileInfoExtension(fileInfo, this.LOAD_TYPES) ||
                this.checkFileInfoExtension(fileInfo, this.MATLAB_OPEN_TYPES));
        }
    };

});
