// Copyright 2016 The MathWorks, Inc.
define([
    ], function () {
        function isDotChar(charCode) {
            return charCode == 46;
        }

        function isDigitChar(charCode) {
            return (charCode >= 48 && charCode <= 57);
        }

        function breakupString(fileName) {
            var result = new Array();
            var x = 0, y = -1, previousIsDotOrDigitChar = 0, charCode, fileNameChar;

            while (charCode = (fileNameChar = fileName.charAt(x++)).charCodeAt(0)) {
                var isDotOrDigitChar = (isDotChar(charCode) || isDigitChar(charCode));
                if (isDotOrDigitChar !== previousIsDotOrDigitChar) {
                    result[++y] = "";
                    previousIsDotOrDigitChar = isDotOrDigitChar;
                }
                result[y] += fileNameChar;
            }
            return result;
        }

        return {
            caseSensitiveStringComparator: function (a, b) {
                var aArray = breakupString(a.toLocaleLowerCase());
                var bArray = breakupString(b.toLocaleLowerCase());

                for (var i = 0; aArray[i] && bArray[i]; i++) {
                    if (aArray[i] !== bArray[i]) {
                        var c = Number(aArray[i]), d = Number(bArray[i]);
                        if (c == aArray[i] && d == bArray[i]) {
                            return c - d;
                        } else {
                            return (aArray[i] > bArray[i]) ? 1 : -1;
                        }
                    }
                }
                return aArray.length - bArray.length;
            },

            separateFoldersFromFiles: function (aFileInfo, bFileInfo) {
                if ((aFileInfo.isDirectory && bFileInfo.isDirectory)) {
                    return this.caseSensitiveStringComparator(aFileInfo.name, bFileInfo.name);
                } else if (aFileInfo.isDirectory) {
                    return -1;
                } else if (bFileInfo.isDirectory) {
                    return 1;
                }
            }
        };
    }
);