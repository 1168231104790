/**
 * <strong>Purpose:</strong> <br>
 * Provides the ability to navigate through children of a parent using up/down arrows, page up/down, and HOME/END.
 * <br><br>
 * <strong>Contract:</strong> <br>
 * Do mix in ContainerFocusMixin.
 *
 * @module mw-menu/mixins/KeyNavMixin
 *
 * @copyright Copyright 2014-2015 The MathWorks, Inc.
 */

define([
    "dojo/_base/declare",
    "dojo/_base/lang",
    "dojo/on",
    "dojo/keys",
    "mw-menu/mixins/ContainerFocusMixin"
], function (declare, lang, on, keys, ContainerFocusMixin) {

    var KeyNavMixin = declare(null, {
        postCreate: function () {
            this.inherited(arguments);
            this.own(
                on(this.domNode, "keydown", lang.hitch(this, "_onKeyDown"))
            );
        },

        _onKeyDown: function (event) {
            if (event.keyCode === keys.DOWN_ARROW) {
                this._handleDownArrow(event.target);
                //Prevent scrolling
                event.preventDefault();
            } else if (event.keyCode === keys.UP_ARROW) {
                this._handleUpArrow(event.target);
                //Prevent scrolling
                event.preventDefault();
            } else if (event.keyCode === keys.PAGE_UP || event.keyCode === keys.HOME) {
                this._moveToFirstChild(event.target);
                //Prevent scrolling
                event.preventDefault();
            } else if (event.keyCode === keys.PAGE_DOWN || event.keyCode === keys.END) {
                this._moveToLastChild(event.target);
                //Prevent scrolling
                event.preventDefault();
            }
        },

        _handleDownArrow: function (targetNode) {
            // If the event target does not equal the active element, then the focus has already changed
            if (targetNode === document.activeElement || targetNode === null) {
                this.focusNext(this._getTargetNode(targetNode));
            }
        },

        _handleUpArrow: function (targetNode) {
            // If the event target does not equal the active element, then the focus has already changed
            if (targetNode === document.activeElement || targetNode === null) {
                this.focusPrevious(this._getTargetNode(targetNode));
            }
        },

        _moveToFirstChild: function () {
            this.focusFirst();
        },

        _moveToLastChild: function () {
            this.focusLast();
        },

        // Overridable by widgets to allow for differences between the focusNode and the desired targetNode
        _getTargetNode: function (node) {
            return node;
        }
    });

    KeyNavMixin._dependencies = [
        {mixin: ContainerFocusMixin, orderDependent: false}
    ];

    return KeyNavMixin;
});
