/* Copyright 2021-2022 The MathWorks, Inc. */
define(['./instanceEnums'], function (instanceEnums) {
    const serviceHostRegEx = /AppServiceHost/i;

    const isAppServiceHost = (options) => {
        return options.serviceName.match(serviceHostRegEx) !== null;
    };

    const getInstance = (messageServiceInstances, options) => {
        if (isAppServiceHost(options)) {
            return messageServiceInstances[instanceEnums.APP_SERVICE_HOST.name]();
        }
        return messageServiceInstances[instanceEnums.MATLAB_SERVICE_HOST.name]();
    };

    return function messageServiceFactory (messageServiceInstances, options) {
        if (!options.serviceName) {
            throw new Error('Must provide a serviceName');
        } else if (!options.serviceName.length || options.serviceName.length < 5) {
            throw new Error('serviceName must be longer than 4 characters');
        }

        const instance = getInstance(messageServiceInstances, options);

        const newInstance = {};
        // Decrease instance API area to:
        [
            'subscribe',
            'unsubscribe',
            'publish',
            'isStarted',
            'isConnected',
            'isFatallyDisconnected',
            'isDisconnected',
            'setLatency',
            'setErrorRate',
            'getTransport',
            'on',
            'onConnected',
            'onDisconnected'
        ].forEach((method) => {
            newInstance[method] = instance[method].bind(instance);
        });

        // Add messageService = this is a obj - so don't use
        // bind.
        newInstance.messageService = instance.messageService;

        return newInstance;
    };
});
