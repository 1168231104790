//Copyright 2017 MathWorks, Inc.

define([
        "dojo/_base/declare",
        "dojo/_base/lang",
        "dojo/Evented",
        "dojo/i18n!../../l10n/nls/FileBrowserResources",
        "mw-filename-utils/FileNameUtil"
    ], function (declare, lang, Evented, FileBrowserResources, FileNameUtil) {

        return declare([Evented], {
            constructor: function (args) {
                args = args || {};
                this.id = "groupBySize";
                this.text = FileBrowserResources.size;
                this.itemType = "check";
                this.grouped = false;
                this.enabled = true;
                this.description = "Action to group file browser column by size";
                this.groups = [];
                this.groupFileInfos = [];
            },

            getGroup: function (item) {

                if (item.size < 8 * 1024) {
                    return {
                        name: FileBrowserResources.small, filter: function (item) {
                            if (item.size < 8 * 1024) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    };
                } else if (item.size > 8 * 1024 && item.size < 256 * 1024) {
                    return {
                        name: FileBrowserResources.medium,
                        filter: function (item) {
                            if (item.size > 8 * 1024 && item.size < 256 * 1024) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    };
                } else {
                    return {
                        name: FileBrowserResources.large,
                        filter: function (item) {
                            if (item.size > 256 * 1024) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    };
                }
            },

            getGroupFileInfo: function (groupName, folderInfo) {
                var groupFileInfo = {
                    id: groupName.name,
                    hasChildren: true,
                    isDirectory: true,
                    isGroupHeader: true,
                    group: groupName.name,
                    type: "group",
                    filter: groupName.filter
                };

                groupFileInfo.name = groupName.name;
                groupFileInfo.path = groupName.name;
                groupFileInfo.location = groupName.name;
                groupFileInfo.parent = FileNameUtil.pathFromFileInfo(folderInfo);
                return groupFileInfo;
            },

            filter: function (fileInfo) {
                var groupFileInfo;
                this.groupFileInfos.forEach(lang.hitch(this, function (item) {
                    if (item.filter(fileInfo)) {
                        groupFileInfo = item;
                        return groupFileInfo;
                    }
                }));
                return groupFileInfo;
            }
        });
    }
);