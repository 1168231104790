define([
  "dojo/_base/declare",
  "mw-filebrowser/columns/helpers/NameComparator",
  "dojo/i18n!nls/mldoStringResource"
], function (declare, NameComparator, MLDOStrings) {

  return declare([], {
    constructor: function (args) {
      args = args || {};
      this.args = args;
      this.column = {
        id: "LocationColumn",
        label: MLDOStrings.locationColumnLabel,
        renderHeaderCell: function (th) {
          if (!th || !th.appendChild || typeof th.appendChild !== "function") {
            throw new TypeError("Invalid th argument");
          }
          let locationColumn = document.createElement("div");
          locationColumn.className = "columnHeaderLabel";
          let labelNode = document.createTextNode(MLDOStrings.locationColumnLabel);
          locationColumn.appendChild(labelNode);
          th.appendChild(locationColumn);
        },
        field: "location",
        get: function (fileInfo) {
          if (!fileInfo || typeof fileInfo !== 'object') {
            throw new TypeError("Invalid fileInfo argument");
          }
          if ((!('fullPath' in fileInfo) || !fileInfo.fullPath) && (!('linkPath' in fileInfo) || !fileInfo.linkPath)) {
            throw new TypeError("Invalid fileInfo argument");
          }
          if (("linkPath" in fileInfo) && fileInfo.linkPath) {
            return fileInfo.linkPath;
          }
          return fileInfo.fullPath;
        },
        sortable: true,
        sort: function (a, b) {
          if (!a || typeof a !== "object") {
            throw new TypeError("Invalid a argument");
          }
          if (!b || typeof b !== "object") {
            throw new TypeError("Invalid b argument");
          }
          if (a.parent === b.parent) {
            return NameComparator.caseSensitiveStringComparator(a.name, b.name);
          } else {
            return NameComparator.caseSensitiveStringComparator(a.parent, b.parent);
          }
        },
        hidden: true
      };
    }
  });
});
