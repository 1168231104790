/*
  This was contents of index.html.  Moved here for Webpack
*/
define([
  "underscore",
  "jquery",
], function (_, $) {
  // jquery uses underscore.
  // jquery needs to be loaded before using bootstrap
  // Bootstrap looks for "jQuery" in the environment.
  window._ = _;
  window.jQuery = $;
  const _isMacSafari = (navigator && (navigator.userAgent.indexOf('Safari') != -1 &&
    navigator.userAgent.indexOf('Chrome') == -1 &&
    !navigator.userAgent.match(/iP(ad|hone)/i))) ? true : false;
  const adownload = ("download" in document.createElement("a"));
  const touchevents = (('ontouchstart' in window) ||
    ("DocumentTouch" in window &&
      window.DocumentTouch &&
      document instanceof window.DocumentTouch));
  const ASSETS_FOLDER = getAssetsFolderName();
  // Browser check 1 is performed in index.html via a script tag calling advancedBrowserSupportCheck.
  // Browser check 2
  const ERROR_URL = "/" + ASSETS_FOLDER + "/errors/incompatibleBrowser.html";
  define(["mw-browser-utils/BrowserCheck"], function (BrowserCheck) {
    BrowserCheck.load(
      ERROR_URL,
      require,
      function () {
        // Browser check 3
        define(["validation/advancedBrowserSupportCheck"], function () {
          let isSupported = require("validation/advancedBrowserSupportCheck");
          if (isSupported && typeof isSupported === 'function' && isSupported()) {
            // All browser checks passed.  Load application
            define([
              "mldoWebApp"
            ], function () {
              $(document).ready(function () {
                $('html').removeClass('no-js');
                $('html').addClass('js');
                if (touchevents) {
                  $('html').addClass("touchevents");
                }
                require([
                  "dojo/i18n!nls/mldoStringResource",
                  "dojo/string",
                  "mldofb",
                  "config"
                ], function (MLDOStrings, DojoString, MldoFbApp, GCONFIG) {
                  // set i18n strings
                  document.title = MLDOStrings.indexPageTitle;
                  var matlabDriveApp = new MldoFbApp({
                    applicationOptions: {
                      appContainerId: 'matlabDriveApp',
                      authContainerId: 'loginContainer',
                      loginIframeId: 'loginIframe',
                      notificationAreaId: 'notificationArea',
                      loginTextContainerId: 'loginTextContainer',
                      loginFooterId: 'mldoFooter',
                      loginLinkAreaId: 'loginLinkArea',
                      allowDnD: (!touchevents) ? true : false,
                      allowDownloads: ((adownload) || (navigator && (navigator.msSaveBlob || _isMacSafari))) ? true : false
                    }, config: GCONFIG
                  });
                  matlabDriveApp.startApplication();
                  $('#header_mobile_login .navbar-brand span.visually-hidden').text(MLDOStrings.indexPageToggleNavigation);
                  $('div#mldoFooter ul.footernav li.footernav_patents > a').text(MLDOStrings.footerPatents);
                  $('div#mldoFooter ul.footernav li.footernav_trademarks > a').text(MLDOStrings.footerTrademarks);
                  $('div#mldoFooter ul.footernav li.footernav_privacy > a').text(MLDOStrings.footerPrivacy);
                  $('div#mldoFooter ul.footernav li.footernav_piracy > a').text(MLDOStrings.footerPiracy);
                  if (!touchevents) {
                    $('[data-bs-toggle="tooltip"]').tooltip();
                  }
                });
              });
              let matrixScript = document.createElement('script');
              matrixScript.type = 'text/javascript';
              matrixScript.src = "/" + ASSETS_FOLDER + "/lib/matrix/main.js";
              document.body.appendChild(matrixScript);
              sessionStorage.removeItem("externalIPv4");

            });
          } else {
            window.location.replace(ERROR_URL);
          }
        });
      }
    );
  });
});