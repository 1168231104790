/**
 * This is one of the Standard Widgets in the Web Widgets Framework. It uses a custom template compared
 * to DOJO's PopupMenuItem
 * PopupMenuItem widget is supposed to be used with in a Menu. So, a Menu must be created first and
 * then the widget should be added to the Menu.<br>
 * To see all the available properties for this widget, please look at the corresponding Property Mixins below.
 * <br>
 * <br>
 * <strong>Property Mixins:</strong><br>
 * {@link module:mw-mixins/property/TagMixin}<br>
 * {@link module:mw-mixins/property/CloseMenuOnClickMixin}<br>
 * {@link module:mw-mixins/property/DescriptionMixin}<br>
 * {@link module:mw-mixins/property/DisabledMixin}<br>
 * {@link module:mw-mixins/property/HasDynamicMenuMixin}<br>
 * {@link module:mw-mixins/property/IconMixin}<br>
 * {@link module:mw-mixins/property/MenuMixin}<br>
 * {@link module:mw-mixins/property/ShortcutMixin}<br>
 * {@link module:mw-mixins/property/ShowDescriptionMixin}<br>
 * {@link module:mw-mixins/property/ShowIconMixin}<br>
 * {@link module:mw-mixins/property/ShowShortcutMixin}<br>
 * {@link module:mw-mixins/property/ShowTextMixin}<br>
 * {@link module:mw-mixins/property/TextMixin}<br>
 * {@link module:mw-mixins/property/VisualFamilyMixin}<br>
 *
 * <strong> Events: </strong>
 * Emits open event when hovered on PopupMenuItem and close event when drop down is closed <br>
 * <strong> Method: </strong>
 * ShowMenu <br>

 * @example <caption>Creating PopupMenuItem</caption>
 *
 * var Menu = new Menu({})
 *
 * var menuItem = new MenuItem({
 *     text: "I am Menu Item"
 * });
 *
 * Menu.add(menuItem);
 *
 * var PopupMenuItem1 = new PopupMenuItem({
 *      icon: "help_24",
 *      text: "My List Item",
 *      shortcut: "Ctrl+z",
 *      menu: Menu
 *      label: "Item has description available",
 * });
 *
 *
 * myPopupMenuItem1.placeAt("someMenuId");
 * myPopupMenuItem1.startup();
 *
 * @module mw-menu/PopupMenuItem
 *
 * @copyright Copyright 2015-2017 The MathWorks, Inc.
 */

// TODO: Remove _CSSStateMixin dependency, as seen in SplitButton and ToggleSplitButton

define([
    "dojo/_base/declare",
    "dijit/focus",
    "dojo/on",
    "dojo/keys",
    "dojo/dom-geometry",
    "dojo/_base/lang",
    "dijit/_WidgetBase",
    "dijit/_TemplatedMixin",
    "dijit/_CssStateMixin",
    "dijit/_Contained",
    "dijit/_FocusMixin",
    "mw-menu/mixins/OnOpenMixin",
    "mw-menu/mixins/OnCloseMixin",
    "mw-mixins/property/TagMixin",
    "mw-menu/mixins/property/CloseMenuOnClickMixin",
    "mw-mixins/property/DescriptionMixin",
    "mw-mixins/property/DisabledMixin",
    "mw-mixins/property/MenuMixin",
    "mw-mixins/property/HasDynamicMenuMixin",
    "mw-mixins/property/IconMixin",
    "mw-menu/mixins/property/IconSizeCalculatorMixin",
    "mw-mixins/property/ShortcutMixin",
    "mw-menu/mixins/property/ShowDescriptionMixin",
    "mw-menu/mixins/property/ShowIconMixin",
    "mw-menu/mixins/property/ShowTextMixin",
    "mw-menu/mixins/property/ShowShortcutMixin",
    "mw-mixins/property/TextMixin",
    "mw-menu/mixins/SingleLineTextMixin",
    "mw-mixins/property/VisualFamilyMixin",
    "mw-menu/mixins/FocusableMenuChildMixin",
    "mw-menu/mixins/MenuChildResizeMixin",
    "mw-menu/mixins/MenuItemTextColorMixin",
    "mw-mixins/PreventSelectionMixin",
    "mw-mixins/mixinDependencyValidator",
    "dojo/text!./templates/PopupMenuItem.html"
], function (declare, focusUtil, on, keys, domGeom, lang, _WidgetBase,
             _TemplatedMixin, _CssStateMixin, _Contained, _FocusMixin, OnOpenMixin, OnCloseMixin,
             TagMixin, CloseMenuOnClickMixin, DescriptionMixin, DisabledMixin, MenuMixin, HasDynamicMenuMixin, IconMixin,
             IconSizeCalculatorMixin, ShortcutMixin, ShowDescriptionMixin, ShowIconMixin, ShowTextMixin, ShowShortcutMixin,
             TextMixin, SingleLineTextMixin, VisualFamilyMixin, FocusableMenuChildMixin, MenuChildResizeMixin,
             MenuItemTextColorMixin, PreventSelectionMixin, mixinDependencyValidator,
             template) {

    return declare(mixinDependencyValidator.validate([_WidgetBase, _TemplatedMixin, _CssStateMixin,
        _Contained, _FocusMixin, OnOpenMixin, OnCloseMixin, TagMixin,
        CloseMenuOnClickMixin, DescriptionMixin, DisabledMixin, MenuMixin, FocusableMenuChildMixin,
        HasDynamicMenuMixin, IconMixin, IconSizeCalculatorMixin, ShortcutMixin, ShowDescriptionMixin, ShowIconMixin,
        ShowTextMixin, ShowShortcutMixin, TextMixin, SingleLineTextMixin, VisualFamilyMixin, MenuItemTextColorMixin,
        PreventSelectionMixin, MenuChildResizeMixin]), {
        baseClass: "mwWidget mwSharedMenuItem mwPopupMenuItem",
        templateString: template,
        _focusSubMenuOnOpen: false, // Internal switch to determine if menu should be focused
        menuOrientation: "horizontal", // Have menu open horizontally
        keyPressButtons: "right_arrow_a11y", // Enable right arrow key to open menu

        postCreate: function () {
            this.inherited(arguments);
            this.own(on(this.domNode, "keydown", lang.hitch(this, "_handleRightArrow")));
        },

        _setFocusSubMenuOnOpenAttr: function (/*Boolean*/ doFocus) {
            if (typeof doFocus !== "boolean") {
                throw new Error("'doFocus' argument expects a boolean value.");
            }

            this._set("_focusSubMenuOnOpen", doFocus);
        },

        _emitClickEvent: function(){
            this.emit("click", {
                mwEventData: {
                    value: this.get("text")
                },
                _dojo_click: true
            });
        },

        focusLost: function (gainedFocusWidget) {
            if (this.get("menu") && this.get("menu").isDescendant && !this.get("menu").isDescendant(gainedFocusWidget, this.get("menu"))) {
                this.get("menu")._closeEventData = {mwEventData: {
                    gainedFocusWidget: gainedFocusWidget
                }};
                this.closeMenu(false);
            }
        },

        focus: function () {
            this.inherited(arguments);

            if (this.get("disabled")) {
                return;
            }
            this.openMenu();
        },

        _handleRightArrow: function (event) {
            if (event.keyCode === keys.RIGHT_ARROW) {

                if (this.isMenuOpen()) {
                    this.get("menu").focusFirst();
                } else {
                    // Focus menu on open
                    this.set("_focusSubMenuOnOpen", true);
                    this.openMenu();
                }
            }
        },

        // Override from MenuMixin.js
        _showMenu: function () {
            var menu = this.get("menu");

            if ((menu !== null) && !this._doNotOpen && !this.isMenuOpen()) {
                // Handle left arrow on drop down Menu as when you press left arrow on any of the menu items, the focus should leave the menu
                menu._leftArrowListener = on(menu.domNode, "keydown", lang.hitch(menu, this._handleLeftArrow));

                // Add special method to menu close to clean up PopupMenuItem specific artifacts
                menu._menuCloseListener = on(menu.domNode, "close", lang.hitch(menu, this._handleCloseMenu));

                // Store properties that are going to be set to restore them later
                menu._storeFocusOnOpen = menu.get("_focusOnOpen");
                menu._storeOnOpen = menu.onOpen;
                menu._storeMatchMinWidthToInvokingWidget = menu.get("_matchMinWidthToInvokingWidget");

                // Set properties of menu that are specific to this widget
                menu.set("_focusOnOpen", this.get("_focusSubMenuOnOpen"));
                menu.onOpen = this.onOpen;
                menu._matchMinWidthToInvokingWidget = false;

                //The dropdown that opens should not have left border
                menu.domNode.classList.add("mwPopupMenuItemPopup");

                this.inherited(arguments);
            }
        },

        // The "this" in this method refers to the sub-menu
        _handleCloseMenu: function (event) {
            if (event && event.mwEventData && event.mwEventData.hasOwnProperty("gainedFocusWidget")) {
                var parentWidget = this._invokingWidget.getParentWidget(),
                    gainedFocusWidget = event.mwEventData.gainedFocusWidget;
                if (!parentWidget.isInvokingWidget(gainedFocusWidget) && !parentWidget.isDescendant(gainedFocusWidget, parentWidget)) {
                    // Restore the Menu to the state it was on open
                    if (this._leftArrowListener) {
                        this._leftArrowListener.remove();
                    }
                    if (this._menuCloseListener) {
                        this._menuCloseListener.remove();
                    }
                    delete this._leftArrowListener;
                    delete this._menuCloseListener;
                    this._focusOnOpen = this._storeFocusOnOpen;
                    this.onOpen = this._storeOnOpen;
                    this._matchMinWidthToInvokingWidget = this._storeMatchMinWidthToInvokingWidget;
                    this.domNode.classList.remove("mwPopupMenuItemPopup");

                    parentWidget._focusChange(gainedFocusWidget);
                }
            }
        },

        // The "this" in this method refers to the sub-menu
        _handleLeftArrow: function (event) {
            if (event.keyCode === keys.LEFT_ARROW) {
                this._doNotOpen = true;
                this._invokingWidget.focus();
                this._doNotOpen = false;
            }
        },

        // Override of sub-menu onOpen method.  The "this" in this method refers to the sub-menu.
        onOpen: function () {
            this.inherited(arguments);
            this._emitOpenEvent();
            this._updateLayout();
            var popupMenuItemX = domGeom.position(this.domNode).x;
            if(popupMenuItemX > domGeom.position(this._invokingWidget.domNode).x) {
                //The dropdown that opens should not have left border
                this.domNode.classList.add("mwPopupMenuItemPopupRight");
                this.domNode.classList.remove("mwPopupMenuItemPopupleft");

                this.domNode.parentElement.classList.add("mwPopupMenuItemParentRight");
                this.domNode.parentElement.classList.remove("mwPopupMenuItemParentLeft");
            } else {
                //The dropdown that opens should not have right border
                this.domNode.classList.add("mwPopupMenuItemPopupLeft");
                this.domNode.classList.remove("mwPopupMenuItemPopupRight");

                this.domNode.parentElement.classList.remove("mwPopupMenuItemParentRight");
                this.domNode.parentElement.classList.add("mwPopupMenuItemParentLeft");
            }
            this.domNode.parentElement.classList.add("mwPopupMenuItemParent");

            if (this.getChildren().length > 0) {
                //Give focus to the first child if there is a child and Menu is to focus on opening
                if (this._focusOnOpen && this.focusFirst) {
                    /**
                     * OnOpenMixin has a timeout before focusing the Menu.  This focus call in
                     * OnOpenMixin may be called after the following code can execute, so we allow
                     * the OnOpenMixin focus call to occur, but replace the functionality with the
                     * desired behavior temporarily.
                     */
                    this._storeFocus = this.focus;
                    this.focus = function () {
                        this.focusFirst();
                        this.focus = this._storeFocus;
                        delete this._storeFocus;
                    }
                }
            }
            this._invokingWidget.set("_focusSubMenuOnOpen", false);
        },

        // Override the method from MenuMixin
        _closeMenuOnParentClick: function () {
            return false;
        },

        // Override the method from MenuMixin
        _updateWidth: function () {
            // Do not automatically set the width of the sub-menu
        }
    });

});
