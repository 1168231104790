// Copyright 2016, The MathWorks, Inc

define([
    "dojo/_base/declare",
    "dojo/_base/lang",
    "dojo/Evented",
    "dojo/on",
    "dojo/touch",
    "./DomEventDetector",
    "../GestureUtils",
    "../util/RemovableGroup"
], function (declare, lang, Evented, on, touch, DomEventDetector, GestureUtils, RemovableGroup) {

    return declare([Evented], {

        constructor: function (node) {
            this._useSoftTimestamps = true;
            this._prevEvent = null;
            this._nodeHandlers = new RemovableGroup();
            this._documentHandlers = new RemovableGroup();
            this._addNodeHandlers(node);
        },

        _addNodeHandlers: function (node) {
            this._nodeHandlers.remove();
            this._nodeHandlers.own(on(node, touch.press, lang.hitch(this, function (evt) {
                this._onDown(evt, node);
            })));
        },

        _addTemporaryHandlers: function (node) {
            // We need to detect release / move / cancel events in the capture phase, in case nodes are
            // stopping propagation of events.
            var documentEventDetector = new DomEventDetector(node.ownerDocument, true);
            this._documentHandlers.remove();
            this._documentHandlers.own(on(documentEventDetector, touch.release, lang.hitch(this, "_onUp")));
            this._documentHandlers.own(on(documentEventDetector, touch.move, lang.hitch(this, "_onMove")));
            this._documentHandlers.own(on(documentEventDetector, touch.cancel, lang.hitch(this, "_onCancel")));

            this._documentHandlers.own(on(node, "dragstart", lang.hitch(this, "_onDragStart")));
            this._documentHandlers.own(on(documentEventDetector, "drag", lang.hitch(this, "_onDrag")));
            this._documentHandlers.own(on(documentEventDetector, "dragend", lang.hitch(this, "_onDragEnd")));
        },

        _onDown: function (evt, node) {
            this._addTemporaryHandlers(node);
            this._preprocessInput(evt);
        },

        _onUp: function (evt) {
            this._documentHandlers.remove();
            this._preprocessInput(evt);
        },

        _onMove: function (evt) {
            this._preprocessInput(evt);
        },

        _onDragStart: function (evt) {
            this._preprocessInput(evt);
        },

        _onDrag: function (evt) {
            this._preprocessInput(evt);
        },

        _onDragEnd: function (evt) {
            this._documentHandlers.remove();
            this._preprocessInput(evt);
        },

        _onCancel: function (evt) {
            if (evt.type === "mouseout") {
                // dojo/touch provides an emulation of mouseleave on IE, which it treats as a cancel. This has the
                // effect of cancelling the gesture if the user's mouse exits the node, which we do not want.
                return;
            }
            this._documentHandlers.remove();
            this._preprocessInput(evt);
        },

        _preprocessInput: function (evt) {
            var prevEvent = this._prevEvent;
            var currentEvt = evt;

            // Sometimes, browsers will emit multiple identical mouse events when there are multiple layers.
            // This preprocessing prevents those multiple consecutive mouse events with no differences from being
            // considered as new input.
            if (prevEvent && GestureUtils.areIdenticMouseEvents(currentEvt, prevEvent)) {
                return;
            }

            //Comparing timestamps typically works well, except in the case where dojo/touch fakes them using Date.now.
            // In that case, the timestamp can be drastically different from the true event timestamp, and comparing
            // a synthetic timestamp to a high-res one throws off the gesture detection, especially on iOS devices
            // after the device has gone to sleep and then later been woken up. So, for now, we'll just always
            // use a soft timestamp from Date.now.  In the future, if we move away from synthetic events, we should take
            // advantage of high res timestamps:  https://w3c.github.io/hr-time/#time-origin
            evt._softTimestamp = this._useSoftTimestamps ? Date.now() : evt.timeStamp;

            this._prevEvent = evt;
            this._broadcastInput(currentEvt);
        },

        remove: function () {
            this._nodeHandlers.remove();
            this._documentHandlers.remove();
        },

        _broadcastInput: function (evt) {
            this.emit("receiveInput", evt);
            this.emit("checkBlockers", evt);
            this.emit("broadcastEvents", evt);
        }

    });


});