//Copyright 2016 - 2017 MathWorks, Inc.

define([
    "mw-menu/CheckBoxMenuItem"
], function (CheckBoxMenuItem) {
    var createContextMenuItem = function (column) {
        var checkBoxMenuItem = new CheckBoxMenuItem({
            closeMenuOnClick: true,
            checked: !column.hidden,
            text: column.label,
            id: column.id
        });
        return checkBoxMenuItem;
    };

    var buildColumnHiderContextMenu = function (columnRegistry) {
        var hidableColumns = [];
        var columns = columnRegistry.getColumns();
        columns.forEach(function (column) {
            if (!column.unhidable) {
                hidableColumns.push(column);
            }
        });
        return hidableColumns;
    };

    return {
        buildContextMenu: function (headerContextMenu, columnRegistry, grid, updateColumnVisible) {
            headerContextMenu.destroyDescendants();
            var hidableColumns = buildColumnHiderContextMenu(columnRegistry);
            hidableColumns.forEach(function (column) {
                var checkBoxMenuItem = createContextMenuItem(column);
                headerContextMenu.addChild(checkBoxMenuItem);
                checkBoxMenuItem.startup();
                checkBoxMenuItem.on("change", function (e) {
                    var columnId = e.currentTarget.id;
                    updateColumnVisible(columnId, grid.isColumnHidden(columnId)); // grid.isColumnHidden(columnId) is whether the column is currently hidden (before this checkbox click)
                });
            });
        }
    };
});