define ([
    "mw-widget-api/WidgetBase",
    "mw-widget-api/defineWidget",
    "mw-widget-api/facade/html",
], function (
    WidgetBase,
    defineWidget,
    html
) {
    class ListItem extends WidgetBase {
        static get properties() {
            return {
                image: {type: String},
                title: {type: String},
                subtitle: {type: String},
                options: {type: String}
            }
        }
        render () {
            return html`
              <div class="list-item" @click="${this.onClick}" @mousedown="${this.onMouseDown}">
                    <div class="title">
                    ${this.title}
                    </div>
                    <div class="subtitle">
                    ${this.subtitle}
                    </div>
              </div>
            `;
        }

        onClick (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            const evt = new CustomEvent('select', {
                detail: {
                    title: this.title,
                    subtitle: this.subtitle,
                },
                bubbles: true,
            });
            this.dispatchEvent(evt);
        }

        onMouseDown (e) {
            e.preventDefault();
        }
    }
    const ListItemWidget = defineWidget({
        name: "mw-mldo-list-item",
        widgetClass: ListItem
    });
    return ListItemWidget;
});