/**
 *  BindingUtils is a module to validate and create binding data given a key sequence.
 *  An example of binding data for the key sequence: [KeyBindings.Keys.Ctrl,KeyBindings.Keys.X]
 *  would be an object with the following properties:
 *
 *  {
 *       hasAltKey: false,
 *       hasCtrlKey: true,
 *       hasMetaKey: false,
 *       hasShiftKey: false,
 *       keyCode: 88,
 *       platform: "Unspecified",
 *       shortcut: "Ctrl+X",
 *       keySequenceIdentifier: "{"modiferKeys:"[17],"keyCode":88,"platform":"Unspecified"}"
 *  }
 *
 *  An example of binding data for the key sequence: [KeyBindings.Keys.Windows.Ctrl,KeyBindings.Keys.X]
 *  would be an object with the following properties:
 *
 *  {
 *       hasAltKey: false,
 *       hasCtrlKey: true,
 *       hasMetaKey: false,
 *       hasShiftKey: false,
 *       keyCode: 88,
 *       platform: "Windows",
 *       shortcut: "Ctrl+X",
 *       keySequenceIdentifier: "{"modiferKeys:"[17],"keyCode":88,"platform":"Windows"}"
 *  }
 *
 *  When processing the key sequence, exceptions will be thrown for invalid key sequences
 *  For example, a key sequence of  [KeyBindings.Keys.Ctrl,KeyBindings.Keys.X,KeyBindings.Keys.Y]  is invalid
 *  and will throw an exception
 *
 *  @copyright Copyright 2015 The MathWorks, Inc.
 */

define([

    "dojo/_base/lang",
    "dojo/sniff",

    // keybindings
    "mw-keybindings/DefaultKeys",
    "mw-keybindings/ModifierKeys",
    "mw-keybindings/Platforms",
    "mw-keybindings/shortcutStringGenerator",
    "dojo/text!./browserconflicts.json"

], function (lang, sniff, DefaultKeys, ModifierKeys,Platforms,
             shortcutStringGenerator, browserConflictsJSON) {

    // iterate over the DefaultKeys and create an array of valid keyCodes that a binding can
    // be created with
    var _validKeyCodes = [];
    for ( var key in DefaultKeys ){
        _validKeyCodes.push(DefaultKeys[key]);
    }

    // iterate over the Platforms and create an array of valid platform types that a binding can
    // be created on
    var _validPlatformTypes = [];
    for ( var key in Platforms ){
        _validPlatformTypes.push(Platforms[key]);
    }

    /**
     * create the keyBinding data for the given keySequence. An example of binding data for the key sequence:
     *    [KeyBindings.Keys.Ctrl, KeyBindings.Keys.X]
     * would be an object with the following properties:
     *  {
     *   hasAltKey: false,
     *   hasCtrlKey: true,
     *   hasMetaKey: false,
     *   hasShiftKey: false,
     *   keyCode: 88,
     *   platform: "Unspecified",
     *   shortcut: "Ctrl+X",
     *   keySequenceIdentifier: "{"modiferKeys:"[17],"keyCode":88,"platform":"Unspecified"}"
     *  }
     *
     * @param keySequence
     * @param bindingOptions
     * @returns {keyBindingData}
     */
    var _createKeyBindingData = function (keySequence,bindingOptions) {

        // validate the keySequence is a valid array of key codes
        _validateKeySequence(keySequence);

        // make sure the keySequence input arg is an array
        if (!Array.isArray(keySequence)) {
            keySequence = [keySequence];
        }

        // By default the platform for the binding is unspecified
        var platform = Platforms.Unspecified;

        // if the bindingOptions arg is set make sure its valid
        if ( bindingOptions !== undefined && !lang.isObject(bindingOptions)) {
            throw new Error("Optional platform must be an object with a Platform property");
        }

        // determine the platform if it is set.  The optional bindingOptions argument
        // must be an object with a "Platform" property
        if ( lang.isObject(bindingOptions)){

            if ( !bindingOptions.hasOwnProperty("platform")){
                throw new Error("No platform property specified in optional platform object");
            }

            if (_validPlatformTypes.indexOf(bindingOptions.platform) === -1) {
                throw new Error("Invalid platform for a binding : " + bindingOptions.platform);
            }

            // retrieve the platform
            platform = bindingOptions.platform;

            if ( platform !== Platforms.Mac && _containsKeyCode(DefaultKeys.COMMAND,keySequence)) {
                throw new Error("Can not create a binding with the COMMAND key and specify platform other than mac");
            }
        }

        // loop over the keySequence and extract the modifiers and keyCodes
        var modifierKeysArray = [];
        var keyCodesArray = [];
        for (var i = 0; i < keySequence.length; i++) {
            var key = keySequence[i];

            if ( ModifierKeys.indexOf(key) !== -1 ){
                // then its a modifier
                // check to see if the modifier came after a keycode.  if yes, then error
                if ( keyCodesArray.length !== 0 ){
                    throw new Error(" modifier keys must come before keycode")
                }
                modifierKeysArray.push(key);
            } else {
                keyCodesArray.push(key);
            }
        }

        var hasCtrlKey  = _containsKeyCode(DefaultKeys.CTRL,modifierKeysArray);
        var hasShiftKey = _containsKeyCode(DefaultKeys.SHIFT,modifierKeysArray);
        var hasAltKey   = _containsKeyCode(DefaultKeys.ALT,modifierKeysArray);
        var hasMetaKey  = _containsKeyCode(DefaultKeys.COMMAND,modifierKeysArray);

        // now process if the SYSCTRL key is in the modifierKeysArray.  If it is
        // and the platform is a mac, hasMetaKey will be set to true. If it is
        // and the platform is windows or linux, hasCtrlKey will be set to true
        if ( _containsKeyCode(DefaultKeys.SYSCTRL,modifierKeysArray )) {
            if (sniff("mac")){
               hasMetaKey = true;
            } else {
                hasCtrlKey = true;
            }
        }

        // create the shortcut
        var shortcut = shortcutStringGenerator.getString(modifierKeysArray.concat(keyCodesArray));

        // validate that the binding must contain exactly one keyCode, excluding any modifiers
        _validateOneKey(keyCodesArray,shortcut);

        // generate a unique id for the binding using data that will guarantee uniqueness among all bindings
        var keySequenceId = _generateKeySequenceIdentifier([hasCtrlKey,hasShiftKey,hasAltKey,hasMetaKey],keyCodesArray[0],platform);

        // got this far so there are no errors with the keySequence. Create and return an object that
        // encapsulates all data for the binding
        var keyBindingData = {
            hasCtrlKey:  hasCtrlKey,
            hasShiftKey: hasShiftKey,
            hasAltKey:   hasAltKey,
            hasMetaKey:  hasMetaKey,
            keyCode:     keyCodesArray[0],
            shortcut:    shortcut,
            platform:    platform,
            keySequenceIdentifier: keySequenceId
        };

        return keyBindingData;
    };

    /**
     * generate a key sequence identifier for the binding.  The identifier will be a concatenation of the key codes for
     * the binding and the platform the binding is to be executed on.  For example, if the keySequence for the binding
     * is [KeyBindings.Keys.CTRL,KeyBindings.Keys.C], the keySequenceString will be generated as:
     *        "{"modifierKeys":[17],"keyCode":67,"platform":"Unspecified"}"
     * @param modifierKeys
     * @param keyCode
     * @param platform
     * @returns {string}
     */
    var _generateKeySequenceIdentifier = function (modifierKeys,keyCode,platform) {

        // create an object of all the data
        var dataToEnsureUniqueness = {
            modifierKeys: modifierKeys,
            keyCode: keyCode,
            platform: platform
        }

        // stringify the object
        var keySequenceIdentifier = JSON.stringify(dataToEnsureUniqueness);

        return keySequenceIdentifier;
    };

    var _containsKeyCode = function (keyCode,keyArray) {
        var hasKeyCode = false;
       var index = keyArray.indexOf(keyCode);
        if ( index !== -1 ){
            hasKeyCode = true;
        }

        return hasKeyCode;
    }

    /*
     *
     * Error checking
     */

    /**
     * verify that the keySequence can be used to create a binding. The keyArray is validated against
     * the array of _validKeyCodes defined above.  If an error, an exception is thrown.
     * @param keySequence
     */
    var _validateKeySequence = function (keySequence) {

        // perform validation on the keySequence input arg
        if (keySequence === undefined) {
            throw new Error("Invalid key sequence for a binding: cannot be null");
        }

        // make sure the keySequence input arg is an array
        if (!Array.isArray(keySequence)) {
            keySequence = [keySequence];
        }

        // validate the keySequence is not an empty array
        if (keySequence.length === 0) {
            throw new Error("Invalid key sequence for a binding: cannot be empty");
        }

        // validate that the keySequence contains valid entries to create a binding
        for (var i = 0; i < keySequence.length; i++) {
            var key = keySequence[i];
            // If key is not an object and indexOf() on the validKeyCodes array returns a -1,
            // then the keyCode was not found in the _validKeyCodes array and is in error.
            if (key === undefined || _validKeyCodes.indexOf(key) === -1) {
                throw new Error("Invalid keyCode for a binding : " + keySequence[i]);
            }

        }
    };

    /**
     * validate that the binding must contain exactly one keyCode, excluding any modifiers
     * @param keyCodesArray
     * @param shortcut
     */
    var _validateOneKey = function(keyCodesArray,shortcut){

        // excluding the modifier keys, verify there is one keyCode for the binding
        if( keyCodesArray.length ===  0 ) {
            throw new Error("Invalid key sequence: " + shortcut + ". Excluding modifiers, there must be one key for the binding" );
        }

        // excluding the modifier keys, verify there is at most one keyCode for the binding
        if( keyCodesArray.length !== 1 ) {
            throw new Error("Invalid key sequence: " + shortcut + ". Excluding modifiers, there can only be one key for the binding" );
        }
    };

    // Creating a JS object from the JSON file
    var browserConflicts = JSON.parse(browserConflictsJSON);

    /**
     * This function parses through the browser conflicts JSON file and creates a map
     * with the key as the keySequenceIdentifier value of the binding and value to be the
     * browser details
     * @returns {conflictMap} Map[keySequenceIdentifier] = browserConflictData
     * @private
     */
    var _createBrowserConflictingData = function () {
        var browserConflictList = Object.keys(browserConflicts);
        var conflictsMap = {};
        browserConflictList.forEach(function (entry) {

            var entryData = browserConflicts[entry];
            var keysList = entryData.keys;
            var keySequence = [];

            var platforms = Object.keys(entryData.platform);
            platforms.push(Platforms.Unspecified);
            keysList.forEach( function(keyEntry){
                if(DefaultKeys.hasOwnProperty(keyEntry)) {
                    keySequence.push(DefaultKeys[keyEntry]);
                } else {
                    throw new Error("Invalid key entry in the json file for the conflicting Key Binding");
                }
            });
            platforms.forEach(function(platform) {
                var conflictBindingData =_createKeyBindingData(keySequence,
                    {platform : Platforms[platform]}).keySequenceIdentifier;
                conflictsMap[conflictBindingData] = entryData;
            });

        });
        return conflictsMap;
    };

    var browserConflictsMap = _createBrowserConflictingData();

    /**
     * Verify that a keyBinding is not a known binding conflict
     *
     * @param bindingData
     */
    var _validateNoBrowserConflicts = function (bindingData) {

        var bindingString = bindingData.keySequenceIdentifier;

        if(browserConflictsMap[bindingString]) {
            var platform = browserConflictsMap[bindingString].platform;
            console.warn("The requested key binding " + bindingData.shortcut + " is a known browser" +
                " conflict which is not supported in \n" + JSON.stringify(platform) );
        }
    };

   return {
        generateKeySequenceIdentifier : _generateKeySequenceIdentifier,
        getKeyBindingData: _createKeyBindingData,
        validateNoBrowserConflicts : _validateNoBrowserConflicts
    };
});
