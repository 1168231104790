/**
 * <strong>Purpose:</strong> <br>
 * Provides the ability to navigate through children of a container.
 * <br><br>
 * <strong>Contract:</strong> <br>
 * The Mixin should be mixed in with container widgets. The children widget's dom node can
 * contain data-refuse-kay-nav attribute.
 * <br><br>
 * @module mw-menu/mixins/ContainerFocusMixin
 *
 * @copyright Copyright 2015-2016 The MathWorks, Inc.
 */

define([
    "dojo/_base/declare",
    "mw-dom-utils/domUtils",
    "dijit/focus"
], function (declare, domUtils, focusUtil) {

    return declare(null, {
        isCyclic: true, // Container Navigation

        _setIsCyclicAttr: function (value) {
            if ((typeof value !== "boolean")) {
                throw new Error("'isCyclic' property expects a boolean value.");
            }

            this._set("isCyclic", value);
        },

        // ----- Container Focus -----
        postCreate: function () {
            this.inherited(arguments);
            this.domNode.classList.add("mwContainerFocusMixin");
            this.focusNode = this.focusNode || this.domNode;
        },

        focus: function (/*HTMLElement?*/ containerChild) {
            this.inherited(arguments);

            if (containerChild) {
                this._focusNearestFocusableNode(containerChild, true, "next");
            } else {
                // If no node is specified then try to focus the container itself
                if (this.focusNode && domUtils.isFocusable(this.focusNode)) {
                    focusUtil.focus(this.focusNode);
                }
            }
        },

        focusNext: function (/*HTMLElement*/ containerChild) {
            this.inherited(arguments);
            if (!containerChild) {
                if (document.activeElement && domUtils.isDescendant(document.activeElement, this.domNode)) {
                    containerChild = document.activeElement;
                } else {
                    containerChild = this.domNode;
                }
            }
            this._focusNearestFocusableNode(containerChild, false, "next");
        },

        focusPrevious: function (/*HTMLElement*/ containerChild) {
            this.inherited(arguments);
            if (!containerChild) {
                if (document.activeElement && domUtils.isDescendant(document.activeElement, this.domNode)) {
                    containerChild = document.activeElement;
                } else {
                    containerChild = this.domNode;
                }
            }
            this._focusNearestFocusableNode(containerChild, false, "previous");
        },

        focusFirst: function () {
            this.inherited(arguments);
            this._focusNearestFocusableNode(null, false, "next");
        },

        focusLast: function () {
            this.inherited(arguments);
            this._focusNearestFocusableNode(null, false, "previous");
        },

        _focusNearestFocusableNode: function (/*HTMLElement*/ node, /*Boolean?*/ inclusive, /*String ("next"|"previous")?*/ direction) {
            node = this._getAppropriateNodeFromInput(node);

            if (domUtils.getChildren(this.domNode).length > 0) {
                var focusableNode;

                if (node != null) {
                    if (!domUtils.isDescendant(node, this.domNode, true)) {
                        // node is not a child of the container
                        return;
                    }
                }

                focusableNode = this._findFocusableChild(node, inclusive, direction);

                if (focusableNode === null) {
                    // No focusable nodes in this container
                    return;
                }

                if(!domUtils.isElementInViewport(focusableNode)) {
                    focusableNode.scrollIntoView(true);
                }

                focusUtil.focus(focusableNode);
            }
        },

        _findFocusableChild: function (/*HTMLElement*/ node, /*Boolean?*/ inclusive, /*String ("next"|"previous")?*/ direction) {
            var findChild = false,
                adjacentNode,
                sawNode = false;

            node = this._getAppropriateNodeFromInput(node);

            if (inclusive == null) {
                inclusive = true;
            }

            if (!direction || ["next", "previous"].indexOf(direction) < 0) {
                direction = "next";
            }

            if (node === this.domNode) {
                findChild = true;
            } else {
                adjacentNode = node;

                if (adjacentNode && !inclusive) {
                    adjacentNode = domUtils.getAdjacentElement(adjacentNode, direction);
                }

                while (adjacentNode) {
                    if (domUtils.isFocusable(adjacentNode)) {
                        break;
                    }
                    if (adjacentNode === node) {
                        if (sawNode) {
                            // Looped through the entire set of children and none were focusable
                            // TODO: Find a way to prevent looping through the entire set of children twice if inclusive is false
                            return null;
                        }
                        sawNode = true;
                    }
                    adjacentNode = domUtils.getAdjacentElement(adjacentNode, direction);
                }
            }

            // If adjacentNode is null and isCyclic is true, then we need to find the first/last element depending on direction
            if (findChild || (!adjacentNode && this.isCyclic) || (node == null)) {
                switch (direction) {
                    case "next":
                        adjacentNode = domUtils.getFirstChild(this.domNode);
                        break;
                    case "previous":
                        adjacentNode = domUtils.getLastChild(this.domNode);
                        break;
                    default:
                    // Not possible to reach
                }
                adjacentNode = this._findFocusableChild(adjacentNode, true, direction);
            }

            if (adjacentNode) {
                node = this._getAppropriateNodeFromInput(adjacentNode);
            }

            return node;
        },

        //
        _getAppropriateNodeFromInput: function (input) {
            if (input == null) {
                return null;
            }

            input = input.focusNode || input.domNode || input;

            if (!(input instanceof Element)) {
                throw new Error("Input argument expects a Widget or DOM node.");
            }

            return input;
        }
    });
});
