define([
    "mw-widget-api/WidgetBase",
    "mw-widget-api/defineWidget",
    "mw-widget-api/facade/html",
    "./ListItemWidget"
], function (
    WidgetBase,
    defineWidget,
    html,
    ListItemWidget,
) {

    class List extends WidgetBase {
        static get properties() {
            return {
                items: { type: Array },
                parent: { type: HTMLElement }
            }
        }
        render () {
            let style = {};
            if (this.parent) {
                // find the position of this element
                const parentRect = this.parent.getBoundingClientRect();
                this.style.position = 'fixed';
                this.style.top = `${parentRect.bottom}px`;
                this.style.width = `${parentRect.width}px`;
                this.style.left = `${parentRect.left}px`;
            }
            return html`
              <ul class="list">
                    ${this.items.map(item => {
                        return html`
                            <li class="list-item-li">
                                <mw-mldo-list-item .title="${item.title}" .subtitle="${item.subtitle}"/>
                            </li>
                        `;
                    })}
              </ul>
            `;
        }
    }
    const ListWidget = defineWidget({
        name: "mw-mldo-list",
        widgetClass: List
    });

    return ListWidget;
});