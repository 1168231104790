// Copyright 2021-2022 The MathWorks, Inc.
define([
    'dojo/_base/lang',
    'dojo/aspect',
    'dojo/on',
    './MessageServiceBase'
], function (lang,
    aspect,
    on,
    MessageServiceBase) {
    // Used for Backwards compatiblility
    return function createMessageServiceDefaultInstance (messageServiceDelgateInstance) {
        const service = new MessageServiceBase();
        const messageServiceInstance = {
            messageService: service,

            subscribe: lang.hitch(service, 'subscribe'),
            unsubscribe: lang.hitch(service, 'unsubscribe'),
            publish: lang.hitch(service, 'publish'),

            start: lang.hitch(service, 'start'),
            stop: lang.hitch(service, 'stop'),

            isRunning: lang.hitch(service, 'isStarted'), // backwards compatibility isRunning==isStarted
            isStarted: lang.hitch(service, 'isStarted'),
            isConnected: lang.hitch(service, 'isConnected'),
            isFatallyDisconnected: lang.hitch(service, 'isFatallyDisconnected'),
            isDisconnected: lang.hitch(service, 'isDisconnected'),

            // for testing/debugging purposes
            setLatency: lang.hitch(service, 'setLatency'),
            setErrorRate: lang.hitch(service, 'setErrorRate'),
            getTransport: lang.hitch(service, 'getTransport'),

            onConnected: function () {},
            onDisconnected: function () {},
            onReconnected: function () {},
            onFatallyDisconnected: function () {},
            onNotConnected: function () {},

            on: function (event, handler) {
                return on(this.messageService, event, handler);
            },

            handleMessage: lang.hitch(service, 'handleMessage'),

            // Used in MotwApplication to turn off start until Worker is Available.
            internalMotwDisableStart: function () {
                this._startFunction = this.start;
                this.start = function () {};
            },
            // Used in MotwApplication to turn on start once Worker is Available.
            internalMotwEnableStart: function () {
                if (this._startFunction) {
                    this.start = this._startFunction;
                    this._startFunction = null;
                }
            }
        };

        aspect.after(service, 'onConnected', lang.hitch(messageServiceInstance, 'onConnected'), true);
        aspect.after(service, 'onDisconnected', lang.hitch(messageServiceInstance, 'onDisconnected'), true);
        aspect.after(service, 'onReconnected', lang.hitch(messageServiceInstance, 'onReconnected'), true);
        aspect.after(service, 'onFatallyDisconnected', lang.hitch(messageServiceInstance,
            'onFatallyDisconnected'), true);
        aspect.after(service, 'onNotConnected', lang.hitch(messageServiceInstance, 'onNotConnected'), true);

        service.setDelegate(messageServiceDelgateInstance);

        return messageServiceInstance;
    };
});
