/**
 * <strong>Purpose:</strong> <br>
 * Provides the ability to show a descriptive string or hide the string

 * <br><br>
 * <strong>Contract:</strong> <br>
 * <em>Attach-point:</em> descriptionNode<br>
 * If showDescription is true, then the description string is placed inside this node.
 * <br><br>
 * @module mw-menu/mixins/property/ShowDescriptionMixin
 *
 * @copyright Copyright 2014 The MathWorks, Inc.
 */

define([
    "dojo/_base/declare"
], function (declare) {

    return declare(null, {
        /**
         * @property  {boolean} ShowDescription Supports boolean values
         * @instance
         * @default true
         */
        showDescription: true,

        postCreate: function () {
            this.inherited(arguments);
            this.domNode.classList.add("mwShowDescriptionMixin");
        },

        _setShowDescriptionAttr: function (value) {
            if (typeof value !== "boolean") {
                throw new Error("'showDescription' property expects a Boolean!");
            }
            if(value===false) {
                this.domNode.classList.add("mwHideDescription");
            } else {
                if (this.descriptionNode && this.descriptionNode.textContent === '') {
                    this.domNode.classList.add("mwHideDescription");
                } else {
                    this.domNode.classList.remove("mwHideDescription");
                }
            }
            this._set("showDescription", value);
        }

    });

});
