/**
 * This is one of the Standard Widgets in the Web Widgets Framework. It uses the consolidated CheckBox widget
 * and a custom template.
 *
 * CheckBoxMenuItem widget is a child widget and these widgets are supposed to be used with in a Menu.
 * So, a Menu must be created first and then CheckBoxMenuItem widgets should be added to the Menu.<br>
 * To see all the available properties for this widget, please look at the corresponding Property Mixins below.
 * <br>
 * <br>
 * <strong>Property Mixins:</strong><br>
 *
 * {@link module:mw-mixins/property/TagMixin}<br>
 * {@link module:mw-mixins/property/CheckedMixin}<br>
 * {@link module:mw-menu/mixins/property/CloseMenuOnClickMixin}<br>
 * {@link module:mw-mixins/property/DescriptionMixin}<br>
 * {@link module:mw-mixins/property/DisabledMixin}<br>
 * {@link module:mw-mixins/property/ShortcutMixin}<br>
 * {@link module:mw-menu/mixins/property/ShowDescriptionMixin}<br>
 * {@link module:mw-menu/mixins/property/ShowTextMixin}<br>
 * {@link module:mw-menu/mixins/property/ShowShortcutMixin}<br>
 * {@link module:mw-mixins/property/TextMixin}<br>
 * {@link module:mw-mixins/property/VisualFamilyMixin}<br>
 *
 * <strong>Widgets</strong><br>
 * {@link module:mw-menu/CheckBoxMenuItem}<br>

 *
 * <strong> Events: </strong>
 * CheckBoxMenuItem emits a "change" event when it is interacted with(KB Space/Enter, Mouse-click) and
 * the following event data is available.<br>
 *  detail: {
 *      propertyName: "checked",
 *      oldValue: oldValue,
 *      newValue: newValue
 *  }
 *
 * @example <caption>Creating a new CheckBoxMenuItem</caption>
 * var CheckBoxMenuItem = new CheckBoxMenuItem({
 *     checked: true,
 *     text: "Check me!",
 *     shortcut: "ctrl + A"
 * });
 *
 * var Menu1 = new Menu({
       maxHeight: 600
 * });
 * Menu1.add(CheckBoxMenuItem);
 * CheckBoxMenuItem.startup();
 *
 * CheckBoxMenuItem.on("change", function(e){
 *   console.dir(e.detail);
 * });
 *

 * @module mw-menu/CheckBoxMenuItem
 *
 * @copyright Copyright 2015 The MathWorks, Inc.
 */

// TODO: Remove _CSSStateMixin dependency, as seen in SplitButton and ToggleSplitButton

define([
    "dojo/_base/declare",
    "dijit/focus",
    "dijit/_WidgetBase",
    "dijit/_TemplatedMixin",
    "dijit/_CssStateMixin",
    "dijit/_Contained",
    "dijit/_FocusMixin",
    "mw-form/CheckBox",
    "mw-mixins/property/TagMixin",
    "mw-mixins/property/CheckedMixin",
    "mw-menu/mixins/property/CloseMenuOnClickMixin",
    "mw-mixins/property/DescriptionMixin",
    "mw-mixins/property/DisabledMixin",
    "mw-mixins/property/ShortcutMixin",
    "mw-menu/mixins/property/ShowDescriptionMixin",
    "mw-menu/mixins/property/ShowTextMixin",
    "mw-menu/mixins/property/ShowShortcutMixin",
    "mw-mixins/property/TextMixin",
    "mw-menu/mixins/SingleLineTextMixin",
    "mw-mixins/property/VisualFamilyMixin",
    "mw-menu/mixins/FocusableMenuChildMixin",
    "mw-menu/mixins/MenuChildResizeMixin",
    "mw-menu/mixins/MenuItemTextColorMixin",
    "mw-mixins/AccessibilityClickMixin",
    "mw-mixins/event/ChangeEventMixin",
    "mw-mixins/PreventSelectionMixin",
    "mw-mixins/mixinDependencyValidator",
    "dojo/text!./templates/CheckBoxRadioButtonMenuItem.html"
], function (declare, focusUtil, _WidgetBase, _TemplatedMixin, _CssStateMixin, _Contained,
             _FocusMixin, CheckBox, TagMixin, CheckedMixin, CloseMenuOnClickMixin,
             DescriptionMixin, DisabledMixin, ShortcutMixin, ShowDescriptionMixin, ShowTextMixin,
             ShowShortcutMixin, TextMixin, SingleLineTextMixin, VisualFamilyMixin, FocusableMenuChildMixin, MenuChildResizeMixin, MenuItemTextColorMixin,
             AccessibilityClickMixin, ChangeEventMixin, PreventSelectionMixin, mixinDependencyValidator, template) {

    return declare(mixinDependencyValidator.validate([_WidgetBase, _TemplatedMixin, _CssStateMixin, _Contained, _FocusMixin,
        FocusableMenuChildMixin, MenuChildResizeMixin, TagMixin, CheckedMixin,
        CloseMenuOnClickMixin, DescriptionMixin, ShortcutMixin, ShowDescriptionMixin, ShowTextMixin,
        ShowShortcutMixin, TextMixin, SingleLineTextMixin,
        DisabledMixin, VisualFamilyMixin, MenuItemTextColorMixin, AccessibilityClickMixin,
        ChangeEventMixin, PreventSelectionMixin]), {

        baseClass: "mwWidget mwSharedMenuItem mwCheckBoxMenuItem",
        templateString: template,

        constructor: function () {
            this.checkBox = new CheckBox({
                text: ""
            });
            this.own(this.checkBox);
        },

        // Override from Checked Mixin to set checkbox checked state
        _setCheckedAttr: function (value) {
            this.inherited(arguments);
            this.checkBox.set("checked", value);
            if (this.get("closeMenuOnClick")) {
                if (value) {
                    this.checkBox.domNode.classList.add("mwSpecialCheckBoxIcon");
                    this.checkBox.domNode.classList.remove("mwNoCheckBoxIcon");
                } else {
                    this.checkBox.domNode.classList.add("mwNoCheckBoxIcon");
                    this.checkBox.domNode.classList.remove("mwSpecialCheckBoxIcon");
                }
            }
        },

        buildRendering: function () {
            this.inherited(arguments);
            this.checkBox.placeAt(this.domNode, "first");
        },

        postCreate: function () {
            this.inherited(arguments);
            this.checkBox._triggerChangeEvent = function () {/*Do Nothing*/};
            this.checkBox._onClick = function () {/*Do Nothing*/};
            this.domNode.setAttribute("data-composite-primary", "");
            (this.checkBox.focusNode || this.checkBox.domNode).setAttribute("data-composite-child", "");
            (this.checkBox.focusNode || this.checkBox.domNode).setAttribute("data-composite-primary-id", this.get("id"));
        },

        startup: function () {
            this.inherited(arguments);
            this.checkBox.startup();
        },

        _onClick: function () {
            this.inherited(arguments);
            this.checkBox.set("checked", this.get("checked"));
        },

        _emitClickEvent: function(){
            this.emit("click", {
                mwEventData: {
                    checked: this.get("checked")
                },
                _dojo_click: true
            });
        }
    });
});
