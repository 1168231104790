//Copyright 2015-2017 MathWorks, Inc.

define([
    "dojo/_base/declare",
    "dojo/_base/lang"
], function (declare, lang) {
    return declare(null, {
        label: "",
        keyCode: false,
        shortcut: "",
        constructor: function (args) {
            lang.mixin(this, args);
        },
        isApplicableTo: function (filePaths) {
            console.log("Please implement isApplicableTo for action");
            return false;
        },
        callback: function () {
            console.log("Please implement callback for action");
        },
        section: {
            id: "MISC",
            name: "MISC",
            allowSubMenu: false,
            forceSubMenu: false
        }
    });
});