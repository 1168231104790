/**
 * <strong>Purpose:</strong> <br>
 * Provides the ability to show text in a single line

 * <br><br>
 * <strong>Contract:</strong> <br>
 * <em>Attach-point:</em> textNode<br>
 * @module mw-menu/mixins/property/SingleLineTextMixin
 *
 * @copyright Copyright 2016 The MathWorks, Inc.
 */

define([
    "dojo/_base/declare",
    "mw-mixins/property/TextMixin"
], function (declare, TextMixin) {


    var SingleLineTextMixin = declare(null, {
        // Override the method in mw-mixins/property/TextMixin
        _getTextForDOM: function (text) {
            text = this.inherited(arguments);
                text = text.replace(/\n/g, " ");
            return text;
        }
    });
    SingleLineTextMixin._dependencies = [
        {mixin: TextMixin, orderDependent: true}
    ];

    return SingleLineTextMixin;

});
