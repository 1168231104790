define([
    "matlabdriveapp"
], function( MldoApplication ) {

    /**
     * This subclass of MldoApplication uses
     */
    class MldoWebApp extends MldoApplication {
        constructor(opts) {
            super(opts);
        }

    }
    return MldoWebApp;
}); // require
