define([
    "underscore",
    "jquery",
    "bootstrap",
    "dojo/_base/declare",
    "mw-filebrowser/actions/Action",
    "mw-keybindings/DefaultKeys",
    "mw-filename-utils/FileNameUtil",
    "util",
    "dojo/i18n!nls/mldoStringResource",
    "dojo/string"
], function ( _, $, Bootstrap, declare, Action, DefaultKeys, FileNameUtil, Util, MLDOStrings, DojoString) {
  return declare(Action, {
    constructor: function (args) {
        args = args || {};
        var context = args.context;
        var makeActionAvailable = args.showAction || false;
        this.name =  "uploadFolderAction";
        this.label = MLDOStrings.contextMenuUploadFolderLabel;
        this.id = "mldoUploadFolderAction";
        this.enabled = makeActionAvailable;
        this.section = args.isSubMenu ? {
            id: "uploadSub",
            enabled: false,
            isApplicableTo: function() { return false; },
            label: "uploadFolder",
            parent: {
                id: "mldoUploadAction",
                name: "uploadFolderAction",
                label: MLDOStrings.contextMenuUploadFolderLabel,
                allowSubMenu: true,
                forceSubMenu: true
            }
        } : {
          id: "MISC",
          name: "MISC",
          allowSubMenu: false,
          forceSubMenu: false
        };
        this.supportsKeyBinding = false;
        this.isApplicableTo = function (path) {
          var fileInfos;
          var fileInfo;
          var selectedFileCount = context.getSelectedRowCount();
          if (selectedFileCount > 1) {
            this.enabled = false;
          } else {
            if (selectedFileCount === 1) {
              fileInfos = context.getSelectedItemFileInfos();
              fileInfo = fileInfos[0];
              if ((fileInfo.isDirectory && fileInfo.filePermissions.canWrite) || (!fileInfo.isDirectory && fileInfo.filePermissions.parentCanWrite)) {
                this.enabled = true;
              } else {
                this.enabled = false;
              }
            } else if (selectedFileCount === 0) {
              var currentFolder = context.getCurrentFolderInfo();
              if (currentFolder.filePermissions.canWrite) {
                this.enabled = true;
              } else {
                this.enabled = false;
              }
            }
          }
          return true;
        };
        this.callback = function (action) {
          var fileInfos = context.getSelectedItemFileInfos();
          var fileInfo = fileInfos[0];
          if (this.isApplicableTo(fileInfo)) {
            context.handleUploadAction(true);
            const dialogElement = document.querySelector('#simpleFileUploadModal');
            if (dialogElement) {
              const modal = new Bootstrap.Modal(dialogElement, {backdrop: 'static'});
              modal.show();
            }
          }
        };
    }
  });

});
