define({
    root: ({
        ALT: "Alt",
        BACKSPACE: "Backspace",
        CAPS_LOCK: "Caps Lock",
        CLEAR: "Clear",
        CTRL: "Ctrl",
        DELETE: "Delete",
        DOWN: "Down",
        ENTER: "Enter",
        END: "End",
        ESCAPE: "Escape",
        HELP: "Help",
        HOME: "Home",
        INSERT: "Insert",
        LEFT: "Left",
        NUM_LOCK: "Num Lock",
        NUM_PAD: "Num Pad",
        PAGE_UP: "Page Up",
        PAGE_DOWN: "Page Down",
        PAUSE_BREAK: "Pause",
        RIGHT: "Right",
        SCROLL_LOCK: "Scroll Lock",
        SHIFT: "Shift",
        SPACE: "Space",
        TAB: "Tab",
        UP: "Up",
        WINDOWS: "Windows"
    })
});