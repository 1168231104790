// Copyright 2016, The MathWorks, Inc

/**
 * dojo/on does not allow you to listen to events in the capture phase if you just pass
 * it a DOM node. This class provides a workaround by providing an object that is compatible
 * with dojo/on but provides the ability to listen using the capture phase if desired.
 */

define([
    "dojo/_base/declare"
], function (declare) {

    return declare([], {

        constructor: function (node, useCapturePhase) {
            this._node = node;
            this._useCapturePhase = !!useCapturePhase;
        },

        on: function (type, listener) {
            var that = this;
            this._node.addEventListener(type, listener, this._useCapturePhase);
            return {
                remove: function () {
                    that._node.removeEventListener(type, listener, that._useCapturePhase);
                }
            }
        }

    });

});