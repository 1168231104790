/*
 */
/**
 * <strong>Purpose:</strong> <br>
 * Generic mixin for Error state and Warning State
 * This mixin will help in setting the validation state, error text and warning text for different widgets
 * <br><br>
 * <strong>Contract:</strong> <br>
 * <em>Attach-point:</em> focusNode <br>
 *
 * @module mw-mixins/property/ValidationMixin
 *
 * @copyright Copyright 2014 The MathWorks, Inc.
 */
define([
    "dojo/_base/declare",
    "dojo/_base/lang",
    "dojo/mouse",
    "dojo/dom-geometry",
    "dojo/dom-construct",
    "dojo/on",
    "dojo/_base/window",
    "dijit/_WidgetBase",
    "dijit/_TemplatedMixin"
], function (declare, lang, mouse, domGeom, domConstruct, on, win, _WidgetBase,
             _TemplatedMixin) {

    //Tooltip

    var Tooltip = declare([_WidgetBase, _TemplatedMixin], {
        templateString: '<div class="mwValidationTooltipDialog">' +
            '<div class="mwArrowNode" data-dojo-attach-point="arrowNode"></div>' +
            '<div class="mwContentsNode" data-dojo-attach-point="contentsNode">' +
            '<div data-dojo-attach-point="iconNode" class="mwIconNode"></div>' +
            '<div data-dojo-attach-point="textNode" class="mwTextNode"></div>' +
            '</div></div>',
        _setTextAttr: function (text) {
            this.textNode.textContent = text;
            this._set("text", text);
        }
    });

    var TOOLTIP_LEFT = "left", TOOLTIP_RIGHT = "right";

    var supportedValidationStateValues = ["error", "warning", "normal"];

    return declare(null, {
        warningText: "",
        errorText: "",
        validationState: "normal",

        _setErrorTextAttr: function (value) {
            if (typeof value !== "string") {
                throw new Error("errorText property expects a 'string'!");
            }
            //If tooltip is already created, update its content
            if (this.validationTooltip) {
                this.validationTooltip.set("text", value);
            }
            this._set("errorText", value);
        },

        _setWarningTextAttr: function (value) {
            //If tooltip is already created, update its content
            if (typeof value !== "string") {
                throw new Error("warningText property expects a 'string'!");
            }
            if (this.validationTooltip) {
                this.validationTooltip.set("text", value);
            }
            this._set("warningText", value);
        },

        _setValidationStateAttr: function (value) {
            //Throw error if not supported
            if (typeof value !== "string") {
                throw new Error("validationState property expects a 'string'!");
            }

            if (supportedValidationStateValues.indexOf(value.toLowerCase()) === -1) {
                throw new Error("validationState property expects the value to be 'normal', 'error' or 'warning'!");
            }
            this._set("validationState", value);
            var inWarning = false, inError = false;
            if (value.toLowerCase() === "error") {
                inError = true;
                this._createValidationTooltip();
                this.validationTooltip.set("text", this.get("errorText"));
            } else if (value.toLowerCase() === "warning") {
                inWarning = true;
                this._createValidationTooltip();
                this.validationTooltip.set("text", this.get("warningText"));
            }
            if (this.validationTooltip) {
                //On Typing spinner sets validation state dynamically
                //Can be a usecase for setting validation state on typing
                if (this.domNode.classList.contains("mwWidgetFocused")) {
                    this.validationTooltip.domNode.classList.add("mwValidationFocused");
                    this._placeTooltip();
                }
                
                if (inError) {
                    this._getDomNodeForValidationClasses().classList.add("mwValidationError");
                    this.validationTooltip.domNode.classList.add("mwErrorTooltip");
                } else {
                    this._getDomNodeForValidationClasses().classList.remove("mwValidationError");
                    this.validationTooltip.domNode.classList.remove("mwErrorTooltip");
                }
                
                if (inWarning) {
                    this.validationTooltip.domNode.classList.add("mwWarningTooltip");
                } else {
                    this.validationTooltip.domNode.classList.remove("mwWarningTooltip");
                }
                
                //Show only on hover or focus: hide it
                if (value.toLowerCase() === "normal") {
                    this.validationTooltip.domNode.style.visibility = "hidden";
                }
            }

        },

        // If there is no tooltip created, create a tooltip and place it in body
        _createValidationTooltip: function () {
            if (!this.validationTooltip) {
                this.validationTooltip = new Tooltip();
                this.validationTooltip.startup();
                this.own(this.validationTooltip);
                this._addTooltipEvents();
            }
        },

        // Based on Tooltip being placed left or right
        _flipDomOrder: function (tooltip) {
            var firstChild = tooltip.children[0], lastChild = tooltip.children[1];
            // Place first child after last child so that the two nodes are swapped
            domConstruct.place(firstChild, domConstruct.place(lastChild, tooltip), "after");
            domConstruct.place(lastChild.children[0],
                domConstruct.place(lastChild.children[1], lastChild), "after");
        },

        _tooltipOrientation: function () {
            if (this.validationTooltip.domNode.classList.contains("mwTooltipLeft")) {
                return TOOLTIP_LEFT;
            } else {
                return TOOLTIP_RIGHT;
            }
        },

        //Widgets will override this function to describe which Node to apply the has-warning and mwError classes
        //RadioButton, check box = > widget.labelNode
        //Combo Box => widget.combobox
        // Slider => widget.sliderTrackNode
        _getDomNodeForValidationClasses: function () {
            return this.domNode;
        },
        _placeTooltip: function () {
            if (this.validationTooltip) {
                if ((this.validationState === "error") || (this.validationState === "warning")) {
                    domConstruct.place(this.validationTooltip.domNode, win.body());
                    var domNodePosition = domGeom.position(this.domNode), widgetPositionX, widgetPositionY, widgetWidth, tooltipWidth, widgetHeight, tooltipDomNode, tooltipHeight, browserWidth, positionLeft, positionTop, isLeft = false;
                    widgetPositionX = domNodePosition.x;
                    widgetPositionY = domNodePosition.y;
                    widgetWidth = domNodePosition.w;
                    // If widget is in error state Place the error text in the template of tooltip
                    if (this.validationState === "error") {
                        //For red border
                        this._getDomNodeForValidationClasses().classList.add("mwValidationError");
                        // add the text to be displayed
                        this.validationTooltip.set("text", this.errorText);

                        //For tooltip styling
                        this.validationTooltip.domNode.classList.add("mwErrorTooltip");
                        // If widget is in warning state Place the warning text in the template of tooltip
                    } else if (this.validationState === "warning") {
                        //add the text to be displayed
                        this.validationTooltip.set("text", this.warningText);
                        //For tooltip styling
                        this.validationTooltip.domNode.classList.add("mwWarningTooltip");

                    }
                    // make tooltip visible
                    this.validationTooltip.domNode.style.visibility = "visible";
                    tooltipHeight = domGeom.position(this.validationTooltip.domNode).h / 2;
                    widgetHeight = domGeom.position(this.domNode).h / 2;
                    tooltipDomNode = this.validationTooltip.domNode;
                    browserWidth = domGeom.position(win.body()).w;
                    tooltipWidth = this._calculateTooltipWidth();
                    // Check if Tooltip can fit on right
                    // check if position of widget + widgetWidth of the errortext/warningtext + image + arrow can fit it on right (Tooltip width)
                    if (widgetPositionX + widgetWidth + tooltipWidth > browserWidth) {
                        //Place tooltip on left
                        isLeft = true;
                        positionLeft = widgetPositionX - tooltipWidth + "px";
                        positionTop = widgetPositionY + widgetHeight - tooltipHeight +
                            document.body.scrollTop + "px";
                        // If the tooltip appears on the left, the dom order is incorrect
                        // Flip the dom order when ever the tooltip is on the left of widget
                        if (tooltipDomNode.children[0].classList.contains("mwArrowNode")) {
                            this._flipDomOrder(tooltipDomNode);
                        }
                        // Place tooltip on right
                    } else {
                        positionLeft = widgetPositionX + widgetWidth + "px";
                        positionTop = widgetPositionY + widgetHeight - tooltipHeight +
                            document.body.scrollTop + "px";
                        // If the tooltip appears on the right, make sure the dom order is correct
                    }
                    //Set the position of tooltip
                    if (isLeft) {
                        this.validationTooltip.domNode.classList.add("mwTooltipLeft");
                        this.validationTooltip.domNode.classList.remove("mwTooltipRight");
                    } else {
                        this.validationTooltip.domNode.classList.remove("mwTooltipLeft");
                        this.validationTooltip.domNode.classList.add("mwTooltipRight");
                    }
                    this.validationTooltip.domNode.style.left = positionLeft;
                    this.validationTooltip.domNode.style.top = positionTop;
                }
            }
        },
        _calculateTooltipWidth: function () {
            //Add connector and remove connector to find its width
            this.validationTooltip.domNode.classList.add("mwTooltipLeft");
            var width = domGeom.position(this.validationTooltip.domNode).w;
            this.validationTooltip.domNode.classList.remove("mwTooltipLeft");
            return width;
        },
        _addTooltipEvents: function () {
            this.own(
                // When you mouseover a widget, only if the widget is in error state or warning state show tooltip
                on(this, mouse.enter, lang.hitch(this, function () {
                    this._placeTooltip();
                })),
                // On mouse leave set visibility of tooltip to hidden
                on(this, mouse.leave, lang.hitch(this, function () {
                    if (this.validationTooltip) {
                        this.validationTooltip.domNode.style.visibility = "hidden";
                    }
                })),

                // On focus, if widget is in error or in warning state, make tooltip visible
                on(this.focusNode, "focus", lang.hitch(this, function () {
                    this._placeTooltip();
                    if (this.validationTooltip) {
                        if (this.get("validationState") === "error" ||
                            this.get("validationState") === "warning") {
                            this.validationTooltip.domNode.classList.add("mwValidationFocused");
                        }
                    }
                })),
                // On blur, hide the tooltip
                on(this.focusNode, "blur", lang.hitch(this, function () {
                    if (this.validationTooltip) {
                        this.validationTooltip.domNode.classList.remove("mwValidationFocused");
                        this.validationTooltip.domNode.style.visibility = "hidden";
                    }
                })));
        }
    });
});
