// Copyright 2016-2017 MathWorks, Inc.

define([
    "dojo/_base/declare",
    "dojo/i18n!../l10n/nls/FileBrowserResources",
    "./helpers/FileSizeGenerator",
    "./helpers/NameComparator",
    "./groupingactions/SizeGroupingAction"
], function (declare, FileBrowserResources, FileSizeGenerator, NameComparator, SizeGroupingAction) {
    return declare([], {
        constructor: function (args) {
            args = args || {};
            this.column = {
                id: "sizeColumn",
                label: FileBrowserResources.size,
                renderHeaderCell: function (th) {
                    var sizeColumn = document.createElement("div");
                    sizeColumn.className = "columnHeaderLabel";
                    var labelNode = document.createTextNode(FileBrowserResources.size);
                    sizeColumn.appendChild(labelNode);
                    th.appendChild(sizeColumn);
                },
                field: "size",
                renderCell: function (fileInfo, data, td, options) {
                    var cellData = document.createElement("span");
                    cellData.className = "dgrid-cell-data";

                    var fileSize = document.createTextNode(FileSizeGenerator.convert(fileInfo));

                    cellData.appendChild(fileSize);
                    return cellData;
                },
                sortable: true,
                sort: function (a, b) {
                    if (a.isDirectory || b.isDirectory) {
                        return NameComparator.separateFoldersFromFiles(a, b);
                    } else if (a.size === b.size) {
                        return NameComparator.caseSensitiveStringComparator(a.name, b.name);
                    } else {
                        return (a.size) - (b.size);
                    }
                },
                hidden: true, 
                groupable: true,
                groupingAction: new SizeGroupingAction()
            };
        }
    });
});