define([
    "dojo/_base/declare",
    "mw-filebrowser/actions/Action",
    "mw-keybindings/DefaultKeys",
    "mw-filename-utils/FileNameUtil",
    "util",
    "dojo/i18n!nls/mldoStringResource",
    "dojo/string"
], function (declare, Action, DefaultKeys, FileNameUtil, Util, MLDOStrings, DojoString) {
  return declare(Action, {
    constructor: function (args) {
        args = args || {};
        var context = args.context;
        var makeActionAvailable = args.showAction || false;
        this.name =  "restoreAction";
        this.label = MLDOStrings.contextMenuRestoreLabel;
        this.enabled = makeActionAvailable;
        this.section = {
            id: "MISC",
            name: "MISC",
            allowSubMenu: false,
            forceSubMenu: false
        };
        this.isApplicableTo = function (path) {
          var selectedFileCount = context.getSelectedRowCount();
          if (selectedFileCount < 1) {
            this.enabled = false;
          } else {
            var fileInfos = context.getSelectedItemFileInfos();
            var fileInfo = fileInfos[0];
            this.enabled = makeActionAvailable && FileNameUtil.isFileInfo(fileInfo);
          }
          return this.enabled;
        };
        this.callback = function (action) {
          var fileInfos = context.getSelectedItemFileInfos();
          var sortedFileInfos = Util.sortFileInfosByPath(fileInfos);
          var showGoToLink = Util.areAllOriginalLocationsTheSame(fileInfos);
          var fileInfo = fileInfos[0];
          var currentFolder = context.getCurrentFolder();
          var path = FileNameUtil.pathFromFileInfo(fileInfo);
          if (currentFolder !== "/") {
              path = currentFolder + path;
          }
          if (this.isApplicableTo()) {
            if (fileInfos.length === 1 && fileInfo && path) {
              context.handleRestoreAction(path, fileInfo);
            } else if (fileInfos.length > 1) {
              context.handleMultiSelectRestore(sortedFileInfos, 0, 0, showGoToLink);
            }
          }
      };
    }
  });

});
