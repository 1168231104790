/**
 * ContextMenu widget is a container widget and Menu Items are supposed to be used with in a Context Menu.
 * So, a ContextMenu must be created first and then Menu item's should be added to the Context Menu.<br>
 * To see all the available properties for this widget, please look at the corresponding Property Mixins below.
 * <br>
 * <br>
 * <strong>Property Mixins:</strong><br>
 * targetNodes<br>
 * willOpenCallback<br>
 * {@link module:mw-mixins/property/TagMixin} <br>
 * {@link module:mw-menu/mixins/property/MaxHeightMixin} <br>
 * {@link module:mw-mixins/property/VisualFamilyMixin} <br>
 *
 * <strong> Events: </strong>
 * ContextMenu emits "open", which fires immediately after a ContextMenu opens and "close" event, which
 * Fires immediately after a ContextMenu closes
 *
 * @example <caption>Creating ContextMenu</caption>
 * var contextMenu1 = new ContextMenu({
       maxHeight: 600,
       targetNodes: ["domNode1","domNode2", ".myClass1", ".myClass2"]
 * });
 * var menuItem1 = new MenuItem({
                    "shortcut": "ctrl+F9",
                    "text": "Dummy text"
                });
 * contextMenu1.addChild(menuItem1);

 * var menuItem2 = new MenuItem({
                    "shortcut": "ctrl+F2",
                    "text": "a text"
                });
 * contextMenu1.addChild(menuItem2);
 *
 *
 * @example <caption>Creating ContextMenu with callback</caption>
 *
 * var callback = function (data) {
       if (this.menuItem1) {
           this.menuItem1.destroy();
       }
       if (this.menuItem2) {
           this.menuItem2.destroy();
       }
       if (this.menuItem3) {
           this.menuItem3.destroy();
       }
       this.menuItem1 = new MenuItem({
           "shortcut": "ctrl+ F9",
           "text": "Evaluate Selection",
           "description": "Evaluate me"
       });
       this.menuItem2 = new MenuItem({
           "text": "Open 'S'",
           "shortcut": "Ctrl+D"
       });

       this.menuItem3 = new MenuItem({
           "icon": "help_24",
           "text": "Help ",
           "shortcut": "F1"
       });

       if (data.target === dom.byId("node1")) {
           this.addChild(this.menuItem1);
           this.addChild(this.menuItem2);
           this.addChild(this.menuItem3);
       } else {
           this.addChild(this.menuItem1);
           this.addChild(this.menuItem2);
       }
 };

 * var contextMenu1 = new ContextMenu({
       maxHeight: 600,
       willOpenCallback: callback,
       targetNodes: ["domNode1","domNode2", ".myClass1", ".myClass2"]
 * });
 * var menuItem1 = new MenuItem({
                    "shortcut": "ctrl+F9",
                    "text": "Dummy text"
                });
 * contextMenu1.addChild(menuItem1);

 * var menuItem2 = new MenuItem({
                    "shortcut": "ctrl+F2",
                    "text": "a text"
                });
 * contextMenu1.addChild(menuItem2);
 *
 * @module mw-menu/ContextMenu
 *
 * @copyright Copyright 2015-2017 The MathWorks, Inc.
 */

define([
    "dojo/_base/declare",
    "dojo/_base/lang",
    "dijit/focus",
    "dojo/keys",
    "dojo/on",
    "dojo/query",
    "dijit/registry",
    "dijit/popup",
    "dijit/_WidgetBase",
    "dijit/_TemplatedMixin",
    "dijit/_Container",
    "mw-menu/mixins/KeyNavMixin", /* Custom key Navigation */
    "mw-menu/mixins/OnOpenMixin",
    "mw-menu/mixins/OnCloseMixin",
    "mw-menu/mixins/ContainerFocusMixin",
    "mw-menu/mixins/MenuFocusMixin",
    "mw-menu/mixins/property/MaxHeightMixin",
    "mw-mixins/property/TagMixin",
    "mw-mixins/property/VisualFamilyMixin",
    "mw-mixins/mixinDependencyValidator",
    "dojo/text!./templates/Menu.html"
], function (declare, lang, focusUtil, keys, on, query, registry, popup, _WidgetBase,
             _TemplatedMixin, _Container, KeyNavMixin, OnOpenMixin, OnCloseMixin, ContainerFocusMixin,
             MenuFocusMixin, MaxHeightMixin, TagMixin, VisualFamilyMixin, mixinDependencyValidator, template) {
    return declare(mixinDependencyValidator.validate([_WidgetBase, KeyNavMixin, _TemplatedMixin,
        _Container, OnOpenMixin, OnCloseMixin, ContainerFocusMixin, MenuFocusMixin, MaxHeightMixin, TagMixin,
        VisualFamilyMixin]), {
        baseClass: "mwWidget mwContextMenu",
        disabled: false,

        templateString: template,

        postCreate: function () {
            this.inherited(arguments);

            this._activeEventListeners = [];
            this._onOpenEventListenersInfo = [
                { // Scrolling when mouse is not on child of context menu should close the context menu
                    event: "wheel",
                    target: document.body,
                    callback: lang.hitch(this, "_onWheel")
                },
                { // For safari
                    event: "mousewheel",
                    target: document.body,
                    callback: lang.hitch(this, "_onWheel")
                },
                { // Mousedown anywhere outside of context menu should close context menu
                    event: "mousedown",
                    target: document.body,
                    callback: lang.hitch(this, function (event) {
                        if (this._shouldCloseMenu(event.target)) {
                            this.closeMenu();
                        }
                    }),
                    useCapture: true
                },
                { // Mouse movement events should not steal focus away from context menu
                    event: "mouseenter",
                    target: document.body,
                    callback: lang.hitch(this, this._onMouseMovement),
                    useCapture: true
                },
                { // Mouse movement events should not steal focus away from context menu
                    event: "mouseover",
                    target: document.body,
                    callback: lang.hitch(this, this._onMouseMovement),
                    useCapture: true
                },
                { // On ESC, close the context Menu and focus the node clicked on
                    event: "keydown",
                    target: this.domNode,
                    callback: lang.hitch(this, function (event) {
                        if (event.keyCode === keys.ESCAPE) {
                            this.closeMenu();
                            if(document.body.classList.contains("mwHasFocusManager")) {
                                this.emit("restorefocus");
                            } else {
                                focusUtil.focus(this._previousFocusNode);
                            }
                        }
                    })
                },
                { // Whenever a change event bubbles up from CheckBoxMenuItem, RadioButtonMenuItem or TextFieldmenuItem, close the popup
                    event: "change",
                    target: this.domNode,
                    callback: lang.hitch(this, function (e) {
                        var widget = registry.getEnclosingWidget(e.target);
                        if (widget.get("closeMenuOnClick") || widget.get("closeMenuOnClick") === undefined) {
                            this.closeMenu();
                        }
                    })
                },
                { // On open add a close listener
                    event: "close",
                    target: this.domNode,
                    callback: lang.hitch(this, this._cleanupOnClose)
                }
            ];

            // Add a listener to "open" which sets up menu listeners and layout
            this.own(on(this.domNode, "open", lang.hitch(this, this._handleOpen)));

            // Since disabled's default value is falsy, we must explicitly call it here
            this.set("disabled", this.disabled);
        },

        _setDisabledAttr: function (disabled) {
            if (typeof value === "boolean") {
                throw new Error("Expected 'disabled' property to be a boolean");
            }

            if (disabled) {
                this._removeRightClickEventListener();
            } else {
                this._addRightClickEventListener();
            }

            this._set("disabled", disabled);
        },

        _addRightClickEventListener: function () {
            if (!this._rightClickListener) {
                this._rightClickListener = on(document.body, "contextmenu", lang.hitch(this,
                    this._createCallback(
                        lang.hitch(this, this._onContextMenu),
                        lang.hitch(this, this._onDoubleContextMenu),
                        200,
                        true))
                );

                this.own(this._rightClickListener);
            }
        },

        _removeRightClickEventListener: function () {
            if (this._rightClickListener) {
                this._rightClickListener.remove();
                delete this._rightClickListener;
            }
        },

        _addOnOpenEventListeners: function () {
            if (this._activeEventListeners.length < this._onOpenEventListenersInfo.length) {
                for (var i = 0; i < this._onOpenEventListenersInfo.length; i++) {
                    if (this._onOpenEventListenersInfo[i].useCapture) {
                        this._onOpenEventListenersInfo[i].target.addEventListener(
                            this._onOpenEventListenersInfo[i].event,
                            this._onOpenEventListenersInfo[i].callback,
                            true);
                        this._activeEventListeners[i] = [{
                            target: this._onOpenEventListenersInfo[i].target,
                            event: this._onOpenEventListenersInfo[i].event,
                            callback: this._onOpenEventListenersInfo[i].callback,
                            useCapture: true
                        }]
                    } else {
                        this._activeEventListeners[i] = this.own(on(
                            this._onOpenEventListenersInfo[i].target,
                            this._onOpenEventListenersInfo[i].event,
                            this._onOpenEventListenersInfo[i].callback
                        ));
                    }
                }
            }
        },

        _removeOnOpenEventListeners: function () {
            if (this._activeEventListeners.length > 0) {
                while(this._activeEventListeners.length > 0) {
                    var listener = this._activeEventListeners.pop()[0];
                    if (listener.useCapture) {
                        listener.target.removeEventListener(
                            listener.event,
                            listener.callback,
                            true);
                    } else {
                        listener.remove();
                    }
                }
            }
        },

        // For scrolling
        _onWheel: function (e) {
            if (!this.isDescendant(e.target)) {
                this.closeMenu();
            }
        },

        isMenuOpen: function() {
            //context menu will be added in popup
            // display property is set directly on the element, so no need to use window.getComputedStyle
            return (
                this.domNode &&
                this.domNode.parentElement &&
                this.domNode.parentElement.style.display !== "none");
        },

        // TODO: Deprecate this API
        open: function (obj) {
            this.openMenu(obj);
        },

        openMenu: function (obj) {
            if (this.get("disabled")) {
                return;
            }

            this._computeTargetNodes(obj);

            // Allow Context Menu in Gallery Popup and Section Popup
            obj.doNotClose = this._nodeMatchesSelector(obj.target, ".galleryPopup, .galleryPopup  *, .mwSectionPopup, .mwSectionPopup *");

            // Add data tag that points to target
            if (obj.target) {
                var widget = registry.getEnclosingWidget(obj.target);
                if (widget && widget.id) {
                    this.domNode.setAttribute("data-target-id", widget.id);
                }
            }

            if (obj.type === "contextmenu") { // Right Click event
                this._handleRightClick(obj);
            } else if (obj.target instanceof HTMLElement && isNaN(obj.x) && isNaN(obj.y)) { // Open({target:domNode})
                this._handleOpenWithDomNode(obj);
            } else if (!obj.target && !isNaN(obj.x) && !isNaN(obj.y)) { // Open({x:x,y:y})
                this._handleOpenWithXY(obj);
            } else {
                throw new Error("Invalid parameters passed");
            }
        },

        _onMouseMovement: function (e) {
            // Prevent non-descendants from firing mouse movement events
            if (!this.isDescendant(e.target)) {
                e.stopImmediatePropagation();
                e.preventDefault();
            }
        },

        _nodeMatchesSelector: function (node, selector) {
            var matches = false;

            if (node && selector) {
                if (node.matches) {
                    matches = node.matches(selector);
                } else if (node.matchesSelector) {
                    matches = node.matchesSelector(selector);
                } else if (node.webkitMatchesSelector) {
                    matches = node.webkitMatchesSelector(selector);
                } else if (node.mozMatchesSelector) {
                    matches = node.mozMatchesSelector(selector);
                } else if (node.msMatchesSelector) {
                    matches = node.msMatchesSelector(selector);
                } else if (node.oMatchesSelector) {
                    matches = node.oMatchesSelector(selector);
                }
            }

            return matches;
        },

        _handleRightClick: function (e) {
            var preventOpen;

            if (this._contextMenuNodes.indexOf(e.target) >= 0) {
                if (this.willOpenCallback) {
                    // "shouldOpen"/"willOpen" makes more sense with regards to "willOpenCallback",
                    // but for backwards compatibility with current users, we can use preventOpen.
                    preventOpen = this.willOpenCallback({target: e.target, x: e.clientX, y: e.clientY});
                    if (preventOpen) {
                        return;
                    }
                }
                this._previousFocusNode = e.target;

                // g1524379 - On IE and edge the MouseEvent triggered by "ContextMenu" key sets the
                // location to (0,0), so we need to adjust the popup location based on the target location.
                if (e.pageX < 1 && e.pageY < 1) {
                    this._showMenu({around: e.target, popup: this, doNotClose: e.doNotClose});
                } else {
                    this._showMenu({x: e.pageX, y: e.pageY, popup: this, doNotClose: e.doNotClose});
                }
            }
        },

        _handleOpenWithDomNode: function (obj) {
            var preventOpen;

            if (this._contextMenuNodes.indexOf(obj.target) >= 0) {
                if (this.willOpenCallback) {
                    preventOpen = this.willOpenCallback({target: obj});
                    if (preventOpen) {
                        return;
                    }
                }
                this._previousFocusNode = obj.target;
                this._showMenu({around: obj.target, popup: this, doNotClose: obj.doNotClose});
            }
        },

        _handleOpenWithXY: function (obj) {
            var preventOpen;

            if (this.willOpenCallback) {
                preventOpen = this.willOpenCallback({target: obj.target});
                if (preventOpen) {
                    return;
                }
            }
            this._showMenu({x: obj.x, y: obj.y, popup: this});
        },

        _showMenu: function (options) {
            popup.open(options);
        },

        _handleOpen: function () {
            this._addOnOpenEventListeners();
            this._handleEmptyContextMenu();
        },

        _handleEmptyContextMenu: function () {
            // Do not show any border on context menu when children count iz zero
            if(this.domNode.children.length === 0) {
                if(this.domNode.parentElement) {
                    this.domNode.parentElement.style.display = "none";
                }
            } else {
                if(this.domNode.parentElement) {
                    this.domNode.parentElement.style.display = "";
                }
            }
        },

        // TODO: Deprecate this API
        close: function () {
            this.closeMenu();
        },

        closeMenu: function () {
            popup.close(this);
        },

        // If popup.close() is used to close the menu instead of the published API, then we still need to clean up.
        _cleanupOnClose: function () {
            this._removeOnOpenEventListeners();

            if(this.domNode) {
                this.domNode.removeAttribute("data-target-id");

                //Geck 1387318 => Popup.js does not handle resetting overflow property in IE
                if (this.domNode.parentNode) {
                    this.domNode.parentNode.style.overflowX = "visible";
                    this.domNode.parentNode.style.overflowY = "visible";
                }
            }
        },

        _shouldCloseMenu: function (domNode) {
            var closePopup = true;

            // If the menu is not open, then there is no need to close it
            if (!this.isMenuOpen()) {
                return false;
            }

            // If the next focused element is a menuItem/Child of Context Menu , do not close popup
            //Also do not close popup when you click on target node
            if (domNode  && ((this.isDescendant(domNode) || (this.targetNodes && (this.targetNodes.indexOf(domNode) >= 0))))) {
                closePopup = false;
            }
            return closePopup;
        },

        //TODO: do we need setter for callback?
        _setTargetNodesAttr: function (nodes) {
            if (nodes === null || nodes === undefined) {
                nodes = [];
            }
            this._set("targetNodes", nodes);
        },

        _computeTargetNodes: function(e) {
            this._contextMenuNodes = [];
            if (this.targetNodes) {
                this.targetNodes.forEach(function (key) {
                    if (typeof key === "string") { // CSS query selector
                        //Push all dom nodes
                        query(key).forEach(function (node) {
                            var childNodes = node.querySelectorAll("*");
                            Object.keys(childNodes).forEach(function (childNode) {
                                this._contextMenuNodes.push(childNodes[childNode]);
                            }, this);
                            this._contextMenuNodes.push(node);
                        }, this);
                    } else if (typeof key === "function") { // function
                        var matchingNodes = key(e);
                        if (matchingNodes) {
                            if (matchingNodes.length > 1) {
                                matchingNodes.forEach(function (matchingNode) {
                                    this._contextMenuNodes.push(matchingNode);
                                }.bind(this));
                            } else {
                                this._contextMenuNodes.push(matchingNodes);
                            }
                        }
                    } else { // dom node
                        this._contextMenuNodes.push(key);
                    }
                }, this);
            }
            return this._contextMenuNodes;
        },

        _onContextMenu: function (event) {
            event.preventDefault();
            this.openMenu(event);
        },

        _onDoubleContextMenu: function(event) {
            event.preventDefault();
        },

        _getTargetNode: function (node) {
            // TODO: Update logic when CompositionMixin is added
            // If the node is a child in a composite widget, then get the primary node from the value of the "data-composite-primary-id" attribute
            if (node !== null && node.hasAttribute("data-composite-child")) {
                node = document.getElementById(node.getAttribute("data-composite-primary-id"));
            }

            return node;
        },

        _createCallback: function (singleClickCallback, doubleClickCallback, delay, blockEvent) {
            var clickCount = 0;
            var singleClickTimer;

            if (delay === undefined) {
                delay = 200;
            }

            return function (event) {
                if (blockEvent === true) {

                    if(this._computeTargetNodes(event).indexOf(event.target) >=-0) {
                        event.preventDefault();
                    }
                }
                clickCount += 1;
                if (clickCount === 1) {
                    singleClickTimer = setTimeout(function () {
                        clickCount = 0;
                        singleClickCallback(event);
                    }, delay);
                } else if (clickCount === 2) {
                    clearTimeout(singleClickTimer);
                    clickCount = 0;
                    doubleClickCallback(event);
                }
            };
        },

        destroy: function () {
            this._removeOnOpenEventListeners();
            this.inherited(arguments);
        }
    });
});
