/* Copyright 2012-2022 The MathWorks, Inc. */
define([
    'dojo/_base/declare',
    'dojo/_base/config',
    'dojo/aspect',
    'dojo/json',
    'dojo/Deferred',
    'dojox/cometd/cometd',
    'dojox/cometd/AckExtension',
    './transportEnums',
    './instanceEnums',
    'mw-log/Log',
    'mw-remote/iframeproxy/IframeProxyClient'
], function (declare,
    config,
    aspect,
    json,
    Deferred,
    cometd,
    AckExtension,
    transportEnums,
    instanceEnums,
    Log,
    IframeProxyClient) {
    const oldSetTimeout = cometd.Utils.setTimeout;
    // Remap cometd JSON functions to dojo JSON functions
    cometd.JSON.toJSON = json.stringify;
    cometd.JSON.fromJSON = json.parse;

    // Feature switch for whether message service should consider using
    // mw-remote's new "sendPayload" functionality (and thus potentially
    // avoid the iframe proxy)
    const _CONSIDER_DIRECT_XHR = false;

    // count time using a 200 ms interval and execute registered functions
    // this is used to override the cometd timeout code to better handle
    // the browser/MATLAB process being debugged for longer than the timeout.
    // g1142859
    let tick = 0;
    let timeoutHandleCount = 0;
    const timeoutFunctions = {};
    const MS_PER_TICK = 200;
    setInterval(function () {
        tick += MS_PER_TICK;
        Object.keys(timeoutFunctions).forEach(function (handle) {
            const timeout = timeoutFunctions[handle];
            if (timeout.execTime <= tick) {
                timeout.func();
                delete timeoutFunctions[handle];
            }
        });
    }, MS_PER_TICK);

    const constructCometD = function (name, proxyClient, transports, endPointInfo, remote) {
        const _super = new cometd.LongPollingTransport();
        const longPollTransport = cometd.Transport.derive(_super);

        longPollTransport.xhrSend = function (packet) {
            let future;
            // Check to see if the Remote version being used supports sendPayload Method
            // In the current changeset we are using mw-remote API sendPayload to send the message
            // The endpoint is set up in the EndPointManager and is checked for CORS support.
            // If we have CORS enabled we send the XHR request without creating the IFrame.
            // Here, the url is managed by EndPointManager of mw-remote where we pass the routingHost and BASE_END_POINT of the messageServiceEndPoint,
            // which are set in the Endpoint collection of EndPointManager.
            if (endPointInfo.remoteSupportsSendPayload) {
                packet.headers = packet.headers || {};
                packet.headers.computeToken = endPointInfo.computeToken;
                // Here instead of url we pass the endPointname, which is used by the EndPointManager in mw-remote
                future = remote.sendPayload(packet.body, endPointInfo.endPointName, {
                    headers: packet.headers
                });
            } else {
                // For mw-remote versions where the CORS check feature is not implemented we keep on using the IFrame to send message
                // Here, in order to support the older versions we are fetching  the url using the routingHost and BASE_END_POINT
                // of the messageServiceEndPoint without using mw-remote.
                future = proxyClient.sendMessage(packet.body, endPointInfo.url, {
                    headers: packet.headers
                });
            }

            future.then(packet.onSuccess, function (error) {
                packet.onError(error, error);
            });

            return {
                abort: function () {
                    future.cancel();
                }
            };
        };

        const motwCometD = new cometd.Cometd(name);

        // g1142859 - Override the cometd actual setTimeout function used by cometd
        cometd.Utils.setTimeout = function (cometd, func, delay) {
            if (delay === 0) {
                return oldSetTimeout(cometd, func, delay);
            } else {
                timeoutHandleCount += 1;

                timeoutFunctions[timeoutHandleCount] = {
                    func: function () {
                        // this is similar to the setTimeout function defined in cometd.js
                        try {
                            func();
                        } catch (x) {
                            motwCometD._debug('Exception invoking timed function', func, x);
                        }
                    },
                    execTime: tick + delay
                };

                return timeoutHandleCount;
            }
        };

        cometd.Utils.clearTimeout = function (handle) {
            delete timeoutFunctions[handle];
        };

        // Handle the WebSocket Connection. We don't need to override cometd WebSocket if we are not using Iframes.
        // Therefore, we first check if the endPoint allows Direct Xhr.
        if (endPointInfo.remoteSupportsSendPayload) {
            endPointInfo.endPointAllowsDirectXhr.then(function (directXhrAllowed) {
                if (directXhrAllowed.status !== 'ENABLED') {
                    cometd.WebSocket = IframeProxyClient.WebSocket;
                    IframeProxyClient.WebSocket.proxyClient = proxyClient;
                }
            });
        } else {
            // For mw-remote versions where the CORS check feature is not implemented we keep on using the IFrames for Web Sockets.
            cometd.WebSocket = IframeProxyClient.WebSocket;
            IframeProxyClient.WebSocket.proxyClient = proxyClient;
        }

        motwCometD.registerExtension('ack', new AckExtension());

        if (transports[transportEnums.WEBSOCKET]) {
            motwCometD.registerTransport(transportEnums.WEBSOCKET, new cometd.WebSocketTransport(), 0);
        }
        motwCometD.registerTransport(transportEnums.LONG_POLLING, longPollTransport, 1);

        return motwCometD;
    };

    const processMessageAcknowledgement = function (message, deferred) {
        if (message.successful) {
            deferred.resolve(message);
        } else {
            Log.warn('Message failed: ', message);
            deferred.reject(message);
        }
    };

    const MessageServiceAsync = declare([], {

        BASE_PREFIX: '/matlab',
        BASE_END_POINT: 'messageservice/async',

        logCometdLifecycle: false,
        logCometdSubscriptions: false,

        constructor: function (remote, constructCometDOverride, iframeProxyClient,
            cometdProperties, options) {
            options = options || {};
            this.remote = remote;
            this.constructCometD = constructCometDOverride || constructCometD;

            const instanceType = instanceEnums[options.instanceType];
            if (instanceType) {
                this.BASE_PREFIX = instanceType.BASE_PREFIX;
                this.BASE_END_POINT = instanceType.BASE_END_POINT;
            }

            this.cometdProperties = cometdProperties || {
                connectTimeout: 5000,
                maxBackoff: 10000,
                logLevel: 'info',
                maxNetworkDelay: 20000,
                appendMessageTypeToURL: false,
                autoBatch: true
            };

            // Keep track of whether we have tried
            // using websockets in this session, and if so,
            // if they ever worked.  If they did ever work
            // we want to keep using them.  If they never
            // worked we fall back to long polling when
            // we encounter an error trying to use them.
            this._webSocket = {
                try: true,
                connectedAtLeastOnce: false
            };

            // Get Additional Config from DojoConfig
            const dojoConfigOverrides = ['maxNetworkDelay', 'connectTimeout', 'logLevel'];
            const dojoConfigCometdProps = config.cometdProperties;
            if (dojoConfigCometdProps) {
                for (let i = 0; i < dojoConfigOverrides.length; i += 1) {
                    const key = dojoConfigOverrides[i];
                    if (dojoConfigCometdProps[key] !== undefined) {
                        this.cometdProperties[key] = dojoConfigCometdProps[key];
                    }
                }
            }

            // Ideally, we should wait to create the workerProxyClient until after we determine if
            // we can use direct XHR. However, there were many test failures when we initially
            // attempted this. Leaving in constructor for now
            // ~~~ begin code that should be deferred until connect ~~~

            // this iframe is used by our custom cometd impl to communicate with the server
            // If App Service Host and MATLAB Compute Host
            // operate across machines we will need different iFrameProxy Clients
            // (or hopefully we can get rid of this code and replace with CORS)
            this.workerProxyAsyncClient = iframeProxyClient ||
                new IframeProxyClient('remoteWorkerAsyncProducer');

            const that = this;
            aspect.after(this.workerProxyAsyncClient, 'onError', function (error) {
                if (that.cometd) {
                    if (that.logCometdLifecycle) {
                        Log.info('MessageServiceAsync disconnecting cometd due to IFrame error.');
                    }
                    // disconnect cometd so that it doesn't try to reconnect on it's own
                    that.cometd.disconnect();
                }
                that.onConnectionError(error);
            }, true);
            // ~~~ end code that should be deferred until connect ~~~

            this.subscriptions = {};

            // used to keep track of messages which have already been handled
            this.recentMessages = [];
            // construct this closure up front so that it doesn't get recreated all the time
            this._deferredCleanupFunction = function () {
                that._deferredCleanup = false;
                that.recentMessages = [];
            };
        },

        onMessage: function (message) {},
        onConnectionError: function (/* error */) {},

        connect: function () {
            // If App Service Host and MATLAB Compute Service Host
            // operate across machines or have different auth tokens
            // we will need to update mw-remote endpoints or Discovery
            // service to support this.  Right now we assume both
            // App Service + MATLAB Compute Service Host are
            // using the same auth and are on the same machine
            this.routingHost = MessageServiceAsync.workerHostOverride ||
                this.remote.getWorkerRoutingHost();
            this.routingKey = this.remote.getWorkerRoutingKey();
            this.computeToken = this.remote.getComputeSessionId();

            const asyncWorkerEndPoint = this.remote.getEndPointByName('WORKER_ASYNC_ENDPOINT');
            this.withCredentials = asyncWorkerEndPoint ? asyncWorkerEndPoint.withCredentials : false;

            const endPointInfo = this._setUpEndPoint();

            if (this.computeToken) {
                endPointInfo.computeToken = this.computeToken;
            }

            if (!endPointInfo.remoteSupportsSendPayload) {
                this.workerProxyAsyncClient.setRemotePageHost(
                    this.routingHost,
                    this.routingKey,
                    this.computeToken,
                    '',
                    {
                        withCredentials: this.withCredentials
                    }
                );
            }

            const transports = {};
            const useWebsocket = MessageServiceAsync.enableWebsocket &&
                this._webSocket.try;

            if (useWebsocket) {
                transports.websocket = true;
            } else {
                transports.websocket = false;
                this._webSocket.try = false;
            }

            const cometd = this.constructCometD(
                'messageService',
                this.workerProxyAsyncClient,
                transports,
                endPointInfo,
                this.remote);
            this.cometd = cometd;

            if (this.logCometdLifecycle) {
                Log.info('MessageServiceAsync constructing new cometd instance.');
            }

            const handshake = this._createOneShotMessagePromise('/meta/handshake');
            this._createListenerForUnknownClient();

            const that = this;
            const deferred = new Deferred();
            handshake.then(function (result) {
                if (that.logCometdLifecycle) {
                    Log.info('MessageServiceAsync cometd handshake successful.', result,
                        cometd.getClientId(), cometd);
                }

                if (!cometd.isDisconnected()) {
                    if (cometd.getTransport().getType() === transportEnums.WEBSOCKET) {
                        that._webSocket.connectedAtLeastOnce = true;
                    }

                    deferred.resolve(result);
                } else {
                    // Something strange happened, so we better disconnect and let the
                    // MessageService reconnect.
                    Log.error('MessageServiceAsync cometd handshake successful but still ' +
                        'disconnected!');
                    cometd.disconnect();
                    deferred.reject(result);
                }
            }, function (error) {
                if (that.logCometdLifecycle) {
                    Log.info('MessageServiceAsync disconnecting cometd due to handshake failure.',
                        error, cometd.getClientId(), cometd);
                }
                // disconnect the original cometd instance, not the one stored on "that" to handle
                // timeouts which may happen before this function is called.
                cometd.disconnect();

                // If we have an error initializing
                if (error.request && error.request.supportedConnectionTypes) {
                    const connectionTypes = error.request.supportedConnectionTypes;
                    if (connectionTypes.length > 1) {
                        const transport = connectionTypes[0];
                        if (transport === transportEnums.WEBSOCKET && !that._webSocket.connectedAtLeastOnce) {
                            // We used websockets, failed, and they have not worked prevously in
                            // this session. Since we create a new instance of CometD each time
                            // we connect, we have to maintain this state manually in order
                            // to fall back to "Long Polling"
                            that._webSocket.try = false;
                        }
                    }
                }
                deferred.reject(error);
            });

            this.cometdProperties.url = endPointInfo.url;
            cometd.init(this.cometdProperties);

            return deferred.promise;
        },

        disconnect: function () {
            if (this.logCometdLifecycle) {
                Log.info('MessageServiceAsync disconnecting cometd due to disconnect call.',
                    this.cometd.getClientId());
            }
            const promise = this._createOneShotMessagePromise('/meta/disconnect');
            this.cometd.disconnect();
            return promise;
        },

        cleanup: function () {
            if (this.cometd) {
                if (this.logCometdLifecycle) {
                    Log.info('MessageServiceAsync disconnecting cometd due to cleanup call.',
                        this.cometd, this.cometd && this.cometd.getClientId());
                }
                this.cometd.disconnect();
                this.cometd = null;
            }
        },

        delegateConnected: function () {
            if (this.cometd) {
                return !this.cometd.isDisconnected();
            } else {
                return false;
            }
        },

        doPublish: function (channel, data) {
            const deferred = new Deferred();
            this.cometd.publish(this._getRequestChannel(channel),
                data,
                {},
                function (message) {
                    processMessageAcknowledgement(message, deferred);
                });
            return deferred.promise;
        },

        doSubscribe: function (channel) {
            if (this.logCometdSubscriptions) {
                Log.info('MessageServiceAsync subscribing cometd to channel: ' + channel);
            }
            const promise = this._createOneShotMessagePromise('/meta/subscribe');
            this.subscriptions[channel] = this.cometd.subscribe(this._getRequestChannel(channel),
                this,
                this._handleCometResponse);
            return promise;
        },

        doUnsubscribe: function (channel) {
            if (this.subscriptions[channel]) {
                if (this.logCometdSubscriptions) {
                    Log.info('MessageServiceAsync unsubscribing cometd from channel: ' + channel);
                }

                const promise = this._createOneShotMessagePromise('/meta/unsubscribe');
                this.cometd.unsubscribe(this.subscriptions[channel]);
                delete this.subscriptions[channel];
                return promise;
            } else {
                return (new Deferred()).resolve({
                    successful: true
                });
            }
        },

        doStartBatch: function () {
            this.cometd.startBatch();
        },

        doEndBatch: function () {
            this.cometd.endBatch();
        },

        doSetLatency: function (latency) {
            this.workerProxyAsyncClient.setLatency(latency);
        },

        doSetErrorRate: function (errorRate) {
            this.workerProxyAsyncClient.setErrorRate(errorRate);
        },

        /**
         * Returns transport type if we are connected
         * This is "unknown", "long-polling", or "websockets"
         * New transports may be added in which case additional
         * return values will be possible.
         * @returns string Name of the transport used by messageservice to communicate
         */
        getTransport: function () {
            if (this.cometd && !this.cometd.isDisconnected()) {
                return this.cometd.getTransport().getType();
            } else {
                return transportEnums.UNKNOWN;
            }
        },

        _handleCometResponse: function (response) {
            if (this._shouldHandleMessage(response)) {
                this.onMessage(this._stripBasePrefixAndConvertToJson(response));
            }
        },

        _shouldHandleMessage: function (response) {
            // client to server messages should be ignored
            if (response.id) {
                return false;
            }

            // keep a list of recent messages so that dupes can be ignored
            // this gets cleared out using a timer once all the new messages are handled
            // this is necessary because cometd does it's own dispatching of * and ** subscriptions
            // which duplicates the code in MessageServiceBase
            if (this.recentMessages.indexOf(response) < 0) {
                this.recentMessages.push(response);
                this._deferRecentMessagesCleanup();
                return true;
            } else {
                return false;
            }
        },

        _deferRecentMessagesCleanup: function () {
            if (!this._deferredCleanup) {
                this._deferredCleanup = setTimeout(this._deferredCleanupFunction, 0);
            }
        },

        _createOneShotMessagePromise: function (channel) {
            const deferred = new Deferred();
            this._addOneShotListener(channel, function (message) {
                processMessageAcknowledgement(message, deferred);
            });
            return deferred.promise;
        },

        _addOneShotListener: function (channel, callback) {
            const that = this;
            const cometd = this.cometd;
            const handle = this.cometd.addListener(channel, function (message) {
                // be sure to removeListener from the original cometd instance, not the current on
                // stored in "that"
                cometd.removeListener(handle);
                callback.apply(that, arguments);
            });
        },

        _createListenerForUnknownClient: function () {
            // g1438744 - Unknown Client Reconnects without re-subscribing
            const cometd = this.cometd;
            const that = this;
            cometd.addListener('/meta/connect', function (message) {
                if (!message.successful &&
                    message.advice &&
                    message.advice.reconnect === 'handshake'
                ) {
                    // Do not allow handshake
                    // Instead "fully disconnect" and use
                    // the state manager to reconnect.
                    // This will make sure all subscriptions are
                    // re-registered
                    cometd.disconnect();
                    that.onConnectionError(message.error);
                }
            });
        },

        _setUpEndPoint: function () {
            let url;
            let remoteSupportsSendPayload = false;
            const endPointName = 'MESSAGE_SERVICE_ASYNC_ENDPOINT';
            const isEnabled = true;
            const isMessageContainerBased = true;
            let endPointAllowsDirectXhr;
            // Check to see if the mw-remote version being used supports the sendPayload API
            // else we use the existing functionality of sending message using IFrame
            if (_CONSIDER_DIRECT_XHR && typeof this.remote.sendPayload === 'function') {
                remoteSupportsSendPayload = true;

                // Create a new Endpoint which is used for sending data using mw-remote sendPayload API
                this.remote.createNewEndPoint(endPointName, isEnabled, this.BASE_END_POINT, 'remoteWorkerAsyncProducer', isMessageContainerBased);

                // Set the routingHost and routingKey for the endPoint
                this.remote.setServiceEndPoint(endPointName, this.routingHost, this.routingKey);
                url = this.remote.getUrlForEndPoint(endPointName);

                // Check whether direct Xhr is allowed for the endPoint
                endPointAllowsDirectXhr = this.remote.getEndPointAllowsDirectXhr(endPointName);
            } else {
                // this.routingHost may not be set, and if it is,
                // it may not end in slash. Add the slash so that
                // it may be combined with BASE_END_POINT
                url = this.routingHost || '/';
                if (url.slice(-1) !== '/') {
                    url += '/';
                }
                url += this.BASE_END_POINT;
                if (this.routingKey) {
                    url += '?routingkey=' + this.routingKey;
                }
            }

            return {
                remoteSupportsSendPayload: remoteSupportsSendPayload,
                url: url,
                endPointName: endPointName,
                endPointAllowsDirectXhr: endPointAllowsDirectXhr
            };
        },

        _getRequestChannel: function (channel) {
            return this.BASE_PREFIX + channel;
        },

        _stripBasePrefixAndConvertToJson: function (message) {
            return {
                channel: message.channel.substring(this.BASE_PREFIX.length),
                data: message.data
            };
        }
    });

    MessageServiceAsync.enableWebsocket = false;
    MessageServiceAsync.workerHostOverride = false;

    if (window.location.search.indexOf('websocket=on') >= 0) {
        MessageServiceAsync.enableWebsocket = true;
    }

    return MessageServiceAsync;
});
