define([
    "dojo/_base/declare",
    "mw-filebrowser/columns/helpers/NameComparator",
    "dojo/i18n!nls/mldoStringResource"
  ], function (declare, NameComparator, MLDOStrings) {
  
    return declare([], {
      constructor: function (args) {
        args = args || {};
        this.column = {
          id: "ModifiedByColumn",
          label: MLDOStrings.modifiedByColumnLabel,
          renderHeaderCell: function (th) {
            if (!th || !th.appendChild || typeof th.appendChild !== "function") {
              throw new TypeError("Invalid th argument");
            }
            var modifiedByColumn = document.createElement("div");
            modifiedByColumn.className = "columnHeaderLabel";
            var labelNode = document.createTextNode(MLDOStrings.modifiedByColumnLabel);
            modifiedByColumn.appendChild(labelNode);
            th.appendChild(modifiedByColumn);
          },
          field: "modifiedBy",
          get: function (fileInfo) {
            var retVal = "";
            if (!fileInfo) {
              throw new TypeError("Invalid fileInfo argument");
            }
            if (fileInfo.modifiedBy) {
                retVal = fileInfo.modifiedBy;
            }

            return retVal;
          },
          sortable: true,
          sort: function (a, b) {
            if (!a || typeof a !== "object") {
              throw new TypeError("Invalid a argument");
            }
            if (!b || typeof b !== "object") {
              throw new TypeError("Invalid b argument");
            }
            var valueA, valueB;
            valueA = a.modifiedBy || "";
            valueB = b.modifiedBy || "";
            return NameComparator.caseSensitiveStringComparator(valueA, valueB);
          },
          hidden: true
        };
      }
    });
  });
  