// Copyright 2016-2017 MathWorks, Inc.
define([
        "dojo/i18n!../../l10n/nls/FileBrowserResources",
        "dojo/string"
    ], function (FileBrowserResources, string) {
        var byteUnits = [FileBrowserResources.kb, FileBrowserResources.mb, FileBrowserResources.gb, FileBrowserResources.tb, FileBrowserResources.pb, FileBrowserResources.eb, FileBrowserResources.zb, FileBrowserResources.yb];
        var ONE_KB = 1024;
        var ONE_MB = 1048576;

        var getReadableFileSize = function (byteCount) {
            var size = byteCount;
            if (size < ONE_MB) {
                size = Math.ceil(size / ONE_KB);
                return {
                    size: size.toFixed(0),
                    unit: byteUnits[0]
                };
            }
            else {
                var i = -1;
                do {
                    size = size / ONE_KB;
                    i++;
                } while (size > ONE_KB);
                return {
                    size: Math.round(size * 100) / 100,
                    unit: byteUnits[i]
                };
            }
        };

        return {
            convert: function (fileInfo) {
                if (fileInfo.isDirectory) {
                    return "";
                } else {
                    var sizeInfo = getReadableFileSize(fileInfo.size);
                    return string.substitute(FileBrowserResources.fileSize, [sizeInfo.size, sizeInfo.unit]);
                }
            }
        };
    }
);