/**
 * <strong>Purpose:</strong> <br>
 * Provides the framework required for child of a Menu to update it's layout information if any of its
 * properties change.
 * <br><br>
 *
 * @module mw-menu/mixins/MenuChildResizeMixin
 *
 * @copyright Copyright 2015 The MathWorks, Inc.
 */

define([
    "dojo/_base/declare",
    "dojo/_base/lang"
], function (declare, lang) {

    var resizeTriggeringProperties = [
        'icon',
        'text',
        'shortcut',
        'description',
        'showIcon',
        'showText',
        'showShortcut',
        'showDescription'
    ];

    return declare(null, {
        postCreate: function () {
            this.inherited(arguments);

            this.watch(lang.hitch(this, function (prop, oldVal, newVal) {
                if (resizeTriggeringProperties.indexOf(prop) > -1) {
                    if (prop !== 'icon' || this._getIconType(newVal) !== this._iconTypes.DATA_URI) {
                        this.emit("updatelayout");
                    }
                }
            }));

            this.domNode.classList.add("mwMenuChildResizeMixin");
        }
    });
});
