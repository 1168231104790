// Copyright 2016 MathWorks, Inc.

define([
    "dojo/_base/declare"
], function (declare) {
    var FBSortProperty = declare(null, {
        setFBSortProperties: function (compareFunction, field, isDescending) {
            this._compareFunction = compareFunction;
            this._fbSort = [
                {attribute: field, descending: isDescending}
            ];
        },

        getFBCompareFunction: function () {
            return this._compareFunction;
        },

        getFBSortArray: function () {
            return this._fbSort;
        },

        isFBSortDescending: function () {
            return this._fbSort[0].descending;
        }
    });
    if (!_instance) {
        var _instance = new FBSortProperty();
    }
    return _instance;
});