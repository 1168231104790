/**
 * <strong>Purpose:</strong> <br>
 * Provides the ability to show the text or hide the text in textNode

 * <br><br>
 * <strong>Contract:</strong> <br>
 * <em>Attach-point:</em> textNode<br>
 * If showText is true, then the text is visible inside this node.
 *
 * <br><br>
 * @module mw-menu/mixins/property/ShowTextMixin
 *
 * @copyright Copyright 2014 The MathWorks, Inc.
 */

define([
    "dojo/_base/declare"
], function (declare) {

    return declare(null, {
        /**
         * @property  {boolean} showText Supports boolean values
         * @instance
         * @default true
         */
        showText: true,

        postCreate: function () {
            this.inherited(arguments);
            this.domNode.classList.add("mwShowTextMixin");
        },

        // A description can either be displayed within the widget's DOM or as a tooltip.
        _setShowTextAttr: function (value) {

            if (typeof value !== "boolean") {
                throw new Error("'showText' property expects a Boolean!");
            }
            if (!value) {
                this.domNode.classList.add("mwHideText");
            } else {
                this.domNode.classList.remove("mwHideText");
            }
            this._set("showText", value);

        }

    });

});
