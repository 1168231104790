/* Copyright 2012-2022 The MathWorks, Inc. */
define([
    'mw-messageservice/messageserviceimpl/createMessageServiceDefaultInstance',
    'mw-messageservice/messageserviceimpl/MessageServiceAsync',
    'mw-messageservice/messageserviceimpl/instanceEnums',
    'mw-messageservice/messageserviceimpl/messageServiceFactory',
    'mw-remote/Remote'
], function (createMessageServiceDefaultInstance, MessageServiceAsync, instanceEnums, messageServiceFactory, Remote) {
    function rewireStartStop (defaultInstance, appServiceHostInstance) {
        // Hook up Start/Stop from To Both Instances
        // - long term we need to manage start ourselves -
        // instead of having teams call it. So we don't
        // "expose" it from the new create API

        // _startFunction is used by internalMotwDisableStart
        // so we need to handle cases where internalMotwDisableStart
        // was called and we are rewiring before it is re-enabled.
        // if _startFunction is defined use it - if not, use the
        // standard start function
        const appStart = appServiceHostInstance._startFunction || appServiceHostInstance.start;
        const appStop = appServiceHostInstance.stop;
        const defaultStart = defaultInstance._startFunction || defaultInstance.start;
        const defaultStop = defaultInstance.stop;

        const newStart = function () {
            defaultStart.call(defaultInstance, []);
            appStart.call(appServiceHostInstance, []);
        };

        const newStop = function () {
            appStop.call(appServiceHostInstance, []);
            defaultStop.call(defaultInstance, []);
        };
        if (appServiceHostInstance._startFunction) {
            appServiceHostInstance._startFunction = newStart;
        } else {
            appServiceHostInstance.start = newStart;
        }

        if (defaultInstance._startFunction) {
            defaultInstance._startFunction = newStart;
        } else {
            defaultInstance.start = newStart;
        }

        appServiceHostInstance.stop = newStop;
        defaultInstance.stop = newStop;
    }

    const defaultInstance = createMessageServiceDefaultInstance(
        new MessageServiceAsync(
            Remote,
            null,
            null,
            null,
            {
                instanceType: instanceEnums.MATLAB_SERVICE_HOST.name
            }));

    // We should change this to get this information from EndPointManager
    // or the discovery service, once we understand the requirements around discovery
    // Right now we assume that AppServiceHost is on the same machine as
    // MATLAB Compute Host (aka Worker) and uses the same authentication scheme.
    // This will not be true for the long term.
    const delegate = new MessageServiceAsync(
        Remote,
        null,
        null,
        null,
        {
            instanceType: instanceEnums.APP_SERVICE_HOST.name
        });
    let appServiceHostInstance;

    // See g1219276 for refactor details - removing old implimentation
    Remote.enableServerOnWorkerAsync = function () { };
    Remote.disableServerOnWorkerAsync = function () { };

    const instances = {};
    instances[instanceEnums.APP_SERVICE_HOST.name] = () => {
        if (!appServiceHostInstance) {
            // Don't start the appServiceHostInstance
            // until someone creates one
            appServiceHostInstance = createMessageServiceDefaultInstance(delegate);
            if (defaultInstance.isStarted()) {
                appServiceHostInstance.start();
            }
            rewireStartStop(defaultInstance, appServiceHostInstance);
        }
        return appServiceHostInstance;
    };
    instances[instanceEnums.MATLAB_SERVICE_HOST.name] = () => defaultInstance;
    defaultInstance.create = function (options) {
        return messageServiceFactory(instances, options);
    };
    // Add create to the default instance
    // and return it for backwards compatibility
    return defaultInstance;
});
