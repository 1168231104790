/**
 * <strong>Purpose:</strong> <br>
 * Provides the ability to display shortcut/accel key node across multiple platforms.
 * <br><br>
 * <strong>Contract:</strong> <br>
 * <em>Attach-point:</em> shortcutKeyNode <br>
 * The Mixin will add the shortcut value into the shortcutKeyNode attach-point. If there
 * is no shortcutKeyNode then the shortcut will be appended to the title in the DOM.
 *
 * <strong>Contract:</strong> <br>
 * This works in conjunction with the DescriptionMixin. DO mix this in after the DescriptionMixin.
 *
 * @module mw-mixins/property/ShortcutMixin
 *
 * @copyright Copyright 2014 The MathWorks, Inc.
 */
define([
    "dojo/_base/declare",
    "mw-event-utils/platformize",
    "mw-mixins/property/DescriptionMixin"

], function (declare, platformize, DescriptionMixin) {

    var ShortcutMixin = declare(null, {

        /**
         * @property  {string} shortcut  Supports any unicode text
         * @instance
         */
        shortcut: "",
        platformizeShortcut: true,

        postCreate: function () {
            this.inherited(arguments);
            this.domNode.classList.add("mwShortcutMixin");
            // When the default value is falsy, the setter will not be triggered during the widget's construction phase
            // We need to call the setter explicitly
            this.set("shortcut", this.get("shortcut"));
        },

        _setShortcutAttr: function (value) {

            if (typeof value !== "string") {
                throw new Error("'shortcut' property expects a literal string!");
            }

            this._updateShortcut(value);
            this._set("shortcut", value);
        },

        _updateShortcut: function (value) {
            if(this.shortcutKeyNode) {
                this.shortcutKeyNode.textContent = this._getPlatformizedShortcut(value);
                if(this.get("showShortcut") === false) {
                    this.domNode.classList.add("mwHideShortcut");
                } else {
                    if (value === '') {
                        this.domNode.classList.add("mwHideShortcut");
                    } else {
                        this.domNode.classList.remove("mwHideShortcut");
                    }
                }
            } else {
                // pass boolean as second argument to indicate that the origin is from shortcut mixin
                this._updateTitle(this._getPlatformizedShortcut(value), true);
            }
        },

        /*
         * This method returns the platformized string if the platformizeShortcut
         * is true and returns the non-platformized string if the platformizeShortcut
         * is false.
         * */

        _getPlatformizedShortcut: function (value) {
            if(this.get("platformizeShortcut")){
                return platformize.getPlatformSpecificString(value);
            } else {
                return value;
            }
        },

        /*
         * This method sets the platformizeShortcut property and updates the shortcut
         * */

        _setPlatformizeShortcutAttr: function(value) {
            if (typeof value !== "boolean") {
                throw new Error("'platformizeShortcut' property expects a boolean value!");
            }

            this._set("platformizeShortcut", value);
            this._updateShortcut(this.get("shortcut"));
        }
    });

    ShortcutMixin._dependencies = [
        {mixin: DescriptionMixin, orderDependent: true, type: "shortcut"}
    ];

    return ShortcutMixin;

});
