define([
    "dojo/_base/declare",
    "mw-filebrowser/actions/Action",
    "mw-keybindings/DefaultKeys",
    "dojo/keys",
    "mw-filename-utils/FileNameUtil",
    "dojo/i18n!nls/mldoStringResource",
    "dojo/string"
], function (declare, Action, DefaultKeys, keys, FileNameUtil, MLDOStrings, DojoString) {
  return declare(Action, {
    constructor: function (args) {
        args = args || {};
        var context = args.context;
        var makeActionAvailable = args.showAction || false;
        this.name =  "renameAction";
        this.label = MLDOStrings.contextMenuRenameLabel;
        this.enabled = makeActionAvailable;
        this.section = {
            id: "MISC",
            name: "MISC",
            allowSubMenu: false,
            forceSubMenu: false
        };
        this.shortcut = "F2";
        this.supportsKeyBinding = true;
        this.keyCode = keys.F2;
        this.isApplicableTo = function (path) {
          var selectedFileCount = context.getSelectedRowCount();
          if (selectedFileCount !== 1) {
            this.enabled = false;
          } else {
            var fileInfos = context.getSelectedItemFileInfos();
            var fileInfo = fileInfos[0];
            this.enabled = makeActionAvailable && fileInfo.filePermissions.parentCanWrite && FileNameUtil.isFileInfo(fileInfo);
          }
          return this.enabled;
        };
        this.callback = function (action) {
          var selectedFileCount = context.getSelectedRowCount();
          if (selectedFileCount !== 1) {
            return;
          }
          var fileInfos = context.getSelectedItemFileInfos();
          var fileInfo = fileInfos[0];
          context.dispatchCanonicalizedTreeEvent("renamefile:treecontroller", {fileInfos: [fileInfo]});
        };
    }
  });

});
