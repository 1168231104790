/**
 * <strong>Purpose:</strong> <br>
 * Provides the ability to show the shortcut or hide the shortcut

 * <br><br>
 * <strong>Contract:</strong> <br>
 * <em>Attach-point:</em> shortcutKeyNode<br>
 * If showShortcut is true, then the shortcut text is visible inside this node.
 *
 * <br><br>
 * @module mw-menu/mixins/property/ShowShortcutMixin
 *
 * @copyright Copyright 2014 The MathWorks, Inc.
 */

define([
    "dojo/_base/declare"
], function (declare) {

    return declare(null, {
        /**
         * @property  {boolean} showText Supports boolean values
         * @instance
         * @default true
         */
        showShortcut: true,

        postCreate: function () {
            this.inherited(arguments);
            this.domNode.classList.add("mwShowShortcutMixin");
        },

        // A description can either be displayed within the widget's DOM or as a tooltip.
        _setShowShortcutAttr: function (value) {
            if (typeof value !== "boolean") {
                throw new Error("'showShortcut' property expects a Boolean!");
            }
            if(value===false) {
                this.domNode.classList.add("mwHideShortcut");
            } else {
                if (this.shortcutNode && this.shortcutNode.textContent === '') {
                    this.domNode.classList.add("mwHideShortcut");
                } else {
                    this.domNode.classList.remove("mwHideShortcut");
                }
            }
            this._set("showShortcut", value);
        }

    });

});
