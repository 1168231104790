/**
 * <strong>Purpose:</strong> <br>
 * This mixin provides the feature of ellipsis for clipped text in CheckBox and RadioButton widgets.
 * <br><br>
 * <strong>Contract:</strong> <br>
 * <em>Attach-point:</em> textNode, checkBoxRadioIconNode <br>
 * This works in conjunction with the SizeMixin. DO mix this in after the SizeMixin.
 *
 * @module mw-form/mixins/CheckBoxRadioButtonTextSizingMixin
 *
 * @copyright Copyright 2014 The MathWorks, Inc.
 */
define([
    "dojo/_base/declare",
    "dojo/dom-geometry"
], function (declare, domGeometry) {

    return declare(null, {

        // Overridden to implement the Ellipsis for clipped text
        // This method updates the pixel width of the textNode attach-point
        // This is required because the ellipsis is implemented using the CSS text-overflow property
        // This property affects only inline elements which are wrapped in a block level element (per MDN doc)
        _setWidthAttr: function () {
            this.inherited(arguments);

            // Do not attempt DOM sizing during the widget construction phase
            if (!this._started) {
                return;
            }

            if (this.get("width") !== "auto") {
                // calculate width of content minus textNode width:
                // note that this logic expects that the spacing between the iconNode and textNode is applied only
                // via the CSS margin-left property
                var iconNodePositionObj = domGeometry.position(this.checkBoxRadioIconNode),
                    textNodeMarginExtentsObj = domGeometry.getMarginExtents(this.textNode),
                    widthOfContentSansTextNode = iconNodePositionObj.w + textNodeMarginExtentsObj.l;

                this.textNode.style.width = (this.width - widthOfContentSansTextNode) + "px";
            } else {
                this.textNode.style.width = "auto";
            }
        }
    });
});
