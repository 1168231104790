define([
    "underscore",
    "jquery",
    "bootstrap",
    "dojo/_base/declare",
    "mw-filebrowser/actions/Action",
    "mw-keybindings/DefaultKeys",
    "mw-filename-utils/FileNameUtil",
    "dojo/i18n!nls/mldoStringResource",
    "dojo/string"
], function (_, $, Bootstrap, declare, Action, DefaultKeys, FileNameUtil, MLDOStrings, DojoString) {
  return declare(Action, {
    constructor: function (args) {
        args = args || {};
        var context = args.context;
        var makeActionAvailable = args.showAction || false;
        this.folderUploadEnabled = args.folderUploadEnabled || false;
        this.name =  MLDOStrings.contextMenuUploadLabel;
        this.label = MLDOStrings.contextMenuUploadLabel;
        this.enabled = makeActionAvailable;
        if (this.folderUploadEnabled) {
          this.id = "mldoUploadAction";
          this.allowSubMenu = true;
          this.childActionIds = ["mldoUploadFilesAction", "mldoUploadFolderAction"];
        }
        this.section = {
            id: "MISC",
            name: "MISC",
            allowSubMenu: false,
            forceSubMenu: false
        };
        this.isApplicableTo = function (path) {
          var fileInfos;
          var fileInfo;
          var returnValue = true;
          var selectedFileCount = context.getSelectedRowCount();
          if (selectedFileCount > 1) {
            this.enabled = false;
          } else {
            if (selectedFileCount === 1) {
              fileInfos = context.getSelectedItemFileInfos();
              fileInfo = fileInfos[0];
              if (fileInfo.isDirectory && fileInfo.filePermissions.canWrite) {
                this.enabled = true;
              } else {
                this.enabled = false;
              }
            } else if (selectedFileCount === 0) {
              var currentFolder = context.getCurrentFolderInfo();
              if (currentFolder.filePermissions.canWrite) {
                this.enabled = true;
              } else {
                this.enabled = false;
              }
            }
          }
          if (this.folderUploadEnabled) {
            returnValue = this.enabled;
          }
          return returnValue;
        };
        if (!this.folderUploadEnabled) {
          this.callback = function () {
            var fileInfos = context.getSelectedItemFileInfos();
            var fileInfo = fileInfos[0];
            if (this.isApplicableTo(fileInfo)) {
              context.handleUploadAction(false);
              const dialogElement = document.querySelector('#simpleFileUploadModal');
              if (context.directoryUploadEnabled && Util.isDirectoryUploadSupported() && dialogElement) {
                const modal = new Bootstrap.Modal(dialogElement, {backdrop: 'static'});
                modal.show();
              }
            }
          };
        }
    }
  });

});
